@import '../common';

.QuickRSVP {
  .link-rsvp {
    border-radius: 5px;
    color: $color-primary-green;
    cursor: pointer;
    float: left;
    height: 34px;
    margin-left: -5px;
    margin-top: -1px;
    padding: 7px 10px;
    text-align: center;

    &:hover {
      background-color: $color-border;
      text-decoration: none;
    }

    &:focus {
      outline: $color-outline auto 1px;
    }

    svg {
      fill: $color-primary-green;
      height: 10px;
      width: 10px;
    }
  }
}


.FeedItem {
  .QuickRSVP {
    margin-left: -4px;
    margin-top: -4px;
  }
}

.EventCard {
  .QuickRSVP {
    .actions {
      margin-top: 15px;
    }
  }
}
