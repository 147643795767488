@import '../common';

body {
  background-color: $color-background;
  color: $color-primary-font;
  font-family: $font-default;
  font-size: $font-size-normal;
  margin: 0;
  padding: 0;
}

a {
  color: $color-link-blue;
  text-decoration: none;

  &[aria-disabled='true'] {
    color: $color-link-blue;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.content {
  height: 100%;
  max-width: 100%;
  padding-bottom: 20px;
  padding-top: 90px;
  width: 100%;
}

.chq-pan {
  .chq-edi--img {
    max-width: 100%;
  }

  .nav.nav-tabs {
    margin-bottom: 0;

    .Notification-Badge {
      margin-left: 5px;
      position: initial;
    }
  }

  .padding-left-15px,
  .padding-right-15px {
    padding: 0;
  }
}

.chq-chr.chq-chr-pp {
  overflow: visible;
}

.chq-ttp--bbl {
  z-index: $z-index-medium;
}

.chq-grid {
  &--row {
    display: flex;
  }

  &--col8 {
    flex-basis: 66.66%;
  }

  &--col4 {
    flex-basis: 33.33%;
  }
}

.chq-ffd {
  .react-tenor {
    input {
      border-bottom: 0;
      height: auto;
      margin-bottom: 0;
      padding: .25em .5em;

      &:hover {
        border-bottom: 0;
      }
    }
  }
}

.chq-charts--wrap {
  display: block;
}

.chq-error {
  padding: 10px;
  text-align: center;
  width: 100%;

  &--msg {
    display: none;
  }

  .chq-cir {
    left: 0;
    margin: 0;
    position: relative;
    top: 0;

    &--sm,
    &--yl {
      fill: #d2d2d2;
    }

    &--bl {
      fill: #aeaeae;
    }

    &--gr {
      fill: #a1a1a1;
    }

    &:hover + .chq-error--msg {
      display: block;
    }
  }
}
