@import '../common';

.notification-bell {
  cursor: pointer;
  float: right;
  position: relative;

  &__unseen {
    @include box-shadow;
    background-color: $color-primary-green;
    border-radius: 2rem;
    color: $color-white;
    font-size: $font-size-xs;
    font-weight: $font-weight-light;
    min-width: 2rem;
    padding: 2px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 3px;
  }

  svg {
    @include square(3.5rem);

    path {
      fill: $color-primary-green;
      stroke: $color-primary-green;
      stroke-width: 5;
    }
  }
}
