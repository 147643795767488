@import '../common';

div.NewContent {
  animation: scaleIn .5s linear;
  background-color: lighten($color-primary-green, 35%);
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 15px;
  overflow: hidden;
  padding: 15px;
  text-align: center;
  transition: background-color .5s;

  &:hover {
    background-color: lighten($color-primary-green, 25%);
  }

  &:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  }

  p {
    color: $color-primary-green;
    font-weight: $font-weight-heavy;
    margin-bottom: 0;
  }
}
