.Instagram-Buttons {
  justify-content: start;

  .chq-pbn {
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }

    svg {
      path {
        fill: transparent;
      }
    }

    &.like-btn {
      svg {
        path {
          fill: #262626;
        }
      }
    }
  }
}
