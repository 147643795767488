@import '../common';

.Surveys__Nav {
  background-color: initial;
  border-bottom: 1px solid $color-border;
  display: flex;
  padding-left: 15px;

  a {
    @include border-top-radius;
    border-radius: 4px 4px 0 0;
    color: $color-primary-font;
    cursor: pointer;
    font-weight: 200;
    line-height: 1.42857143;
    margin-bottom: -1px;
    margin-right: 2px;
    margin-top: 7px;
    padding: 15px 15px 15px 0;
    text-decoration: none;
  }

  a.active {
    background-color: $color-white;
    border-bottom: 3px solid $color-primary-green;
    color: $color-primary-green !important;
    font-weight: $font-weight-heavy;

    &:hover path,
    &.active path {
      fill: $color-primary-green;
    }
  }

  path {
    fill: $color-primary-font;
  }

  svg {
    @include square(22px);
    margin: 0 5px -5px -3px;
  }

}
