.Select-Image {
  margin: 15px 0;

  .image-container {
    background-color: $color-background-darker;
    border: 1px solid $color-border-darker;
    border-radius: $size-border-radius;
    display: flex;
    justify-content: center;
    min-height: 150px;
    overflow: hidden;
    position: relative;

    .image {
      border-radius: $size-border-radius;
      height: auto;
      max-height: 200px;
    }

    .Empty-Image {
      align-items: center;
      color: $color-secondary-font;
      display: flex;
      font-weight: $font-weight-normal;

      svg {
        margin-right: 5px;
      }

      path {
        fill: $color-secondary-font;
      }
    }
  }

  &__Buttons {
    align-items: center;
    display: flex;
    margin-top: 15px;

    .chq-pbn {
      align-items: center;
      background-color: $color-dark-blue;
      border: 1px solid;
      border-color: $color-dark-blue;
      border-radius: $size-border-radius;
      color: $color-white;
      display: flex;
      padding: 6px 10px;

      svg {
        height: 18px;
        margin-right: 5px;
        width: 18px;
      }

      path {
        fill: $color-white;
      }
    }

    .unsplash-btn {
      margin-left: 10px;
    }
  }
}
