.Facebook-Buttons {
  align-items: center;
  color: $color-primary-font;
  display: flex;
  font-weight: $font-weight-heavy;
  justify-content: space-around;

  .chq-pbn {
    align-items: center;
    color: $color-secondary-font;
    display: flex;
    padding: 10px 8px;

    .facebook-icon {
      @include square(18px);
      margin-right: 5px;

      .gray-path {
        fill: $color-secondary-font !important;
      }

      .white-path {
        fill: $color-white !important;
      }
    }
  }
}
