.Tiktok-Buttons {
  bottom: 0;
  flex-direction: column;
  position: absolute;
  right: 0;

  .chq-pbn {
    @include square(48px);
    align-items: center;
    background-color: rgba(22, 24, 35, .06);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding: 13px;

    svg {
      margin-right: 0;

      path {
        fill: $color-black;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .Tiktok-Insight {
    display: flex;
    font-size: $font-size-nav;
    justify-content: center;
    margin-top: -5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
