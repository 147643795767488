.video-studio-modal {
  .boolean {
    background-color: $color-background-darker;
    border-radius: 25px;
    color: $color-secondary-font-darker;
    margin-left: 15px;
    padding: 5px 10px;

    .chq-cmk-on {
      color: $color-primary-green;
    }

    .chq-cmk-off {
      svg {
        border-color: $color-secondary-font-darker;
      }
    }

    svg {
      background-color: $color-white;
      border-color: $color-white;
      margin-right: 3px;
    }

    .chq-cmk-ck {
      svg {
        border-color: $color-primary-green;
      }
    }
  }

  .chq-ffd-filter-value,
  .chq-ffd-color-filter {
    margin: 20px 0;
  }

  .closemod-btn {
    font-weight: $font-weight-normal;
    margin-left: 25px;
    margin-right: 5px;

    svg {
      height: 30px;
      margin-right: 5px;
      top: 2px;
      width: 30px;
    }
  }

  .chq-download {
    align-items: center;
    color: $color-black;
    display: flex;
    font-weight: $font-weight-normal;
    text-decoration: none;
  }

  .chq-pan--bd {
    font-weight: 400;
  }

  .chq-sbar input {
    font-weight: 400;
    margin-bottom: 15px;
  }

  .Language-list {
    list-style: none;
    max-height: 60vh;
    overflow: scroll;
    padding: 15px;

    .chq-pbn {
      color: $color-primary-font;
      padding-bottom: 15px;
    }
  }

  .nav-tabs {
    font-weight: $font-weight-normal;
    padding-left: 0;

    .chq-pbn {
      border-bottom: 4px solid transparent;
      color: $color-secondary-font-darker;
      padding: 10px 15px;

      &.active {
        border-color: $color-primary-green;
        color: $color-primary-green;
      }
    }
  }

  .Preview-Editor,
  .Toolbar-Editor {
    .Preview-Editor,
    .Toolbar-Editor {
      &__Controls {
        margin-bottom: 5px;

        .Video-Utilities {
          .Volume-Btn {
            align-items: center;
            display: flex;
          }

          .Split-Btn {
            align-items: center;
            appearance: none;
            background: transparent;
            border: 0;
            color: $color-secondary-font-darker;
            display: flex;
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
            margin-right: 15px;

            svg {
              @include square(18px);
              margin-right: 7px;
            }
          }
        }

        .Video-Options {
          align-items: center;
          display: flex;
          flex: 2;

          .Preview-Btn {
            flex: initial;
            margin-right: 15px;
          }
        }
      }

      &__Items {
        padding-top: 20px;

        &-Slides {
          position: relative;

          .Story_Preview {
            border-radius: $size-border-radius;
            margin: 0 3px;

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }

          .marker {
            border: 12px solid transparent;
            border-top-color: $color-primary-blue;
            bottom: 0;
            content: '';
            height: 115%;
            left: -11px;
            position: absolute;

            &-stick {
              background-color: $color-primary-blue;
              height: 120%;
              width: 3px;
            }
          }
        }
      }
    }
  }

  .Subtitles {
    &-Header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      min-height: 36px;

      &__Title {
        align-items: center;
        display: flex;

        h2 {
          font-size: 20px;
          margin: 0;
        }
      }

      .Subtitles-Options {
        align-items: center;
        display: flex;

        .Styles-Subtitles-btn {
          align-items: center;
          border-color: #ccc;
          color: $color-primary-font;
          display: flex;
          font-size: 16px;
          margin-right: 7px;
          text-decoration: none;

          svg {
            margin: 0 3px 0 0;
          }

          &:hover {
            border-color: #ccc;
            cursor: pointer;
          }

          .section {
            font-size: $font-size-12;
            margin-left: 3px;
          }

          path {
            fill: $color-secondary-font;
          }
        }

        .BurnText {
          margin-right: 5px;
        }
      }

      .Subtitles-Header__Dropdown {
        ul {
          max-height: 400px;
          overflow: scroll;
          right: 0;
          top: 2.5em;
          z-index: $z-index-medium;
        }

        path {
          fill: $color-primary-font;
        }
      }
    }

    &-Loading {
      font-weight: $font-weight-normal;
      padding: 30px;
      text-align: center;

      @media screen and (min-width: $screen-lg) {
        padding: 30px 60px;
      }

      &__Image {
        margin-top: 15px;
        max-width: 250px;
      }
    }

    &-StyleHeader {
      display: flex;
    }
  }

  .MediaMenuHeader {
    display: flex;

    h2 {
      font-size: 20px;
      margin: 0;
    }

    button {
      margin-right: 10px;
    }

    path {
      fill: $color-primary-font;
    }
  }

  .VTT-Container {
    display: flex;
    flex-direction: column;

    &__Section {
      align-items: center;
      border-bottom: 1px solid $color-border;
      display: flex;
      justify-content: space-between;
      padding: 15px 0;
    }

    &__Text {
      flex: 10;
    }

    &__Time {
      display: flex;
      flex: 2;
      flex-direction: column;

      .chq-ffd-border__invalid {
        .chq-ffd--ctrl {
          &:focus {
            border: 2px solid $color-red;
          }
        }
      }
    }

    .chq-btn {
      align-self: end;
      margin-top: 15px;
    }
  }

  .TrimVideo-Container {
    margin-right: 15px;

    .TrimVideo-Btn {
      align-items: center;
      color: $color-primary-font;
      display: flex;

      svg {
        @include square(18px);
        margin-right: 7px;
      }

      path {
        fill: $color-primary-font;
      }
    }
  }

  .Stories-Container {
    position: relative;

    .Captions-Header {
      &__Title {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        min-height: 36px;

        h2 {
          font-size: 20px;
          margin: 0;
        }
      }

      &__Actions {
        align-items: center;
        display: flex;
      }
    }

    .ElementStartEnd {
      align-items: center;
      display: flex;
      margin-bottom: 20px;

      .chq-ffd {
        margin: 0;
        max-width: 150px;

        &:first-child {
          margin-right: 15px;
        }

        &-border__invalid {
          .chq-ffd--ctrl {
            &:focus {
              border: 2px solid $color-red;
            }
          }
        }

        &--ctrl {
          margin-bottom: 0;
        }
      }
    }

    .Captions-Time-Fade {
      align-items: center;
      display: flex;
      margin-bottom: 20px;

      .chq-ffd {
        margin: 0;
        width: 150px;

        &:first-child {
          margin-right: 15px;
        }
      }
    }

    .overlay-ms {
      align-items: start;
      background-color: rgba($color: $color-white, $alpha: .7);
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: $z-index-medium;

      .overlay {
        background-color: rgba($color: $color-black, $alpha: .8);
        border-radius: $size-border-radius;
        color: $color-white;
        margin-top: 150px;
        max-width: 340px;
        padding: 30px;
        text-align: center;
      }
    }
  }

  .Audios-Container {
    margin-bottom: 20px;
  }

  .chq-snv {
    .chq-snv--it {
      margin-right: 16px;

      &[aria-current='true'] {
        font-weight: bold;
      }
    }
  }
}
