@import '../common';

.Discover-Stories {
  .chq-pan {
    margin-bottom: 0;
  }

  path {
    fill: $color-primary-font;
  }

  .Story {
    &--Link {
      align-items: center;
      display: flex;
      margin-bottom: 15px;
      text-decoration: none;
    }

    &--Image {
      background-position: center;
      background-size: cover;
      border-radius: $size-border-radius;
      height: 50px;
      margin-right: 15px;
      min-width: 50px;
      width: 50px;
    }

    &--Texts {
      display: flex;
      flex-direction: column;
    }

    &--Question {
      -webkit-box-orient: vertical; // sass-lint:disable-line no-vendor-prefixes
      -webkit-line-clamp: 2; // sass-lint:disable-line no-vendor-prefixes
      display: -webkit-box; // sass-lint:disable-line no-vendor-prefixes
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--Number {
      color: $color-secondary-font;
      font-size: 14px;
    }
  }

  .chq-pan--hd {
    h2 {
      align-items: center;
      display: flex;
    }

    svg {
      margin-right: 10px;
    }
  }
}
