.StoriesFilterBar {
  border-radius: $size-border-radius;
  width: 100% !important;

  .Select-Tags__Container {
    align-items: center;
    border: 0;
    display: flex;
    position: initial;
    width: 100%;
    z-index: $z-index-low;

    .share-with-field {
      width: 100%;

      .chq-ffd--ctrl {
        border: 1px solid $color-secondary-light-font;
        border-radius: $size-border-radius;
        margin-bottom: 0;
        padding: 12px 40px 2px;

        &-fc {
          border: 1px solid $color-primary-blue;
          box-shadow: inset 0 1px 1px $color-form-inset, 0 0 8px $search-bar-shadow;
        }

        .chq-bdg {
          background-color: $color-background-darker;
          color: $color-dark-blue;
          font-size: 1em;
          font-weight: $font-weight-heavy;
          margin: 0 0 10px 10px;
          margin-left: 0;
          margin-right: 10px;
          margin-top: 0;
          padding: 5px 12px;

          &:hover {
            cursor: pointer;
          }

          path {
            fill: $color-dark-blue;
          }
        }

        &:hover {
          cursor: text;
        }
      }

      .chq-ffd--sl {
        margin-left: 0;
        margin-right: 0;
        z-index: $z-index-low;

        &--caret {
          display: none;
        }

        &--place {
          color: $color-secondary-font;
          font-size: $font-size-large;
          font-weight: $font-weight-heavy;
          margin-bottom: 10px;
        }

        &--opts {
          border-radius: $size-border-radius;
          margin-left: 0;
          margin-top: 6px;
          width: 100%;
        }

        &--match {
          font-size: 18px;
          margin-bottom: 10px;
          margin-top: 0;
        }
      }

      .chq-ffd--lb {
        display: flex;
        justify-content: space-between;
        top: 15px;

        svg {
          display: block;
        }

        path {
          fill: $color-primary-light-font;
        }
      }
    }
  }
}
