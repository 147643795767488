@import '../common';

.Story-Share-Modal {
  border-radius: $size-border-radius;

  &__label {
    color: #646464;
    font-size: 16px;
    font-weight: $font-weight-normal;
  }

  &__explanation {
    color: $color-secondary-font;
    margin-top: 10px;
  }

  .chq-edi {
    border: 0;
    margin-bottom: 12px;
    padding: 0;

    &--cp {
      width: auto;
    }

    .DraftEditor-root {
      display: none;
    }
  }

  .chq-ffd--ctrl-text {
    border: 1px solid $color-border;
    min-height: 4em;
  }

  .unsplash-btn {
    align-items: center;
    border: 1px solid;
    border-color: $color-border-darker;
    border-radius: $size-border-radius;
    color: $color-primary-font;
    display: flex;
    margin-left: 85px;
    padding: 6px 10px;

    svg {
      height: 18px;
      margin-right: 5px;
      width: 18px;
    }
  }

  .note-field {
    margin-top: 20px;

    .chq-ffd--lb {
      color: $color-primary-font;
    }
  }

  .slack-share-option {
    margin-top: 20px;

    .chq-ffd--lb {
      color: $color-primary-font;
    }
  }

  .slack-channel-container {
    color: #646464;
    font-size: 16px;
    font-weight: $font-weight-normal;
    margin-top: 20px;

    .chq-ffd--sl {
      color: initial;
      font-size: initial;
    }
  }

  .sharing-with-container {
    align-items: center;
    color: $color-link-blue;
    display: flex;
    font-size: $font-size-nav;
    font-weight: $font-weight-heavy;
    margin-top: 15px;

    svg {
      height: 20px;
      margin-right: 5px;
      width: 20px;

      path {
        fill: $color-link-blue;
      }
    }
  }

  .tags-container {
    display: block;
    margin-top: 20px;
    position: relative;
    z-index: initial;

    .legend {
      font-size: $font-size-normal;
      font-weight: $font-weight-normal;
    }

    .share-with-field  {
      .chq-ffd--sl {
        border-bottom: 2px solid $color-border;
        color: initial;
        font-size: initial;
        margin: 0;
        z-index: initial;

        &--place {
          color: $color-primary-font;
        }

        &--opts {
          margin-left: 0;
        }
      }

      .chq-ffd--ctrl {
        margin-bottom: 0;
      }
    }

    .Select-Tags__Container {
      border: 0;
      margin-top: 5px;
      position: initial;
      z-index: initial;

      &:hover {
        box-shadow: inset 0 1px 1px $color-form-inset;
      }

      &:focus {
        border-bottom: 2px solid #79b17d;
      }
    }
  }

  .chq-pan {
    &--hd {
      border-radius: $size-border-radius $size-border-radius 0 0;
    }

    &--ft {
      background-color: $color-white;
      border-top: 0;
      padding-top: 0;

      div {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .image-container {
    border-radius: $size-border-radius;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;

    .image {
      border-radius: $size-border-radius;
      max-height: 250px;
    }
  }

  .gallery-lightbox__image-container {
    position: absolute;
  }

  .sharing-modal {
    .chq-edi--ct {
      font-family: inherit;
      outline: none;
      width: initial;
    }
  }
}
