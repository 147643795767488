.rtx-nav {
  &.chq-nav {
    background-color: $color-black;

    .chq-nav {
      &--search {
        input {
          background-color: rgba(255, 255, 255, .3);
        }
      }

      &--items {
        a {
          border-color: $color-black;
          color: $color-white;

          &.active {
            border-color: $color-rtx-red;
            color: $color-white;

            path {
              fill: $color-white;
            }
          }
        }
      }

      &--notit {
        em {
          background-color: $color-rtx-red;
          color: $color-white;
        }
      }

      &--admint {
        .Notification-Badge {
          background-color: $color-rtx-red;
          color: $color-white;
        }

        &:hover {
          background-color: rgba(255, 255, 255, .15);
        }
      }

      &--unseen {
        border-color: $color-rtx-red;
      }
    }
  }
}
