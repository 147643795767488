.job-posting-modal {
  .chq-bdg__active {
    background-color: $color-orange;
    color: $color-white;
    margin-left: 5px;
  }

  &_info {
    margin-bottom: 15px;
  }
}
