@import '../common';

.Recognitions {
  margin: 0 auto;
  max-width: 1440px;

  .chq-bdg {
    &:hover {
      cursor: pointer;
    }
  }
}

.recognitions {
  position: relative;

  .chq-pan--ft {
    margin-top: -1px;
  }

  &-animate .recognitions__recognition-summary {
    &:first-child {
      animation: animSlideIn .4s forwards;
    }

    &:last-child {
      animation: animSlideOut .4s forwards;
      overflow: hidden;
    }
  }

  &__recognition {
    position: relative;

    .QuickOptions {
      top: 5px;

      &:not(.sticky) {
        opacity: 0;
        transition: opacity 150ms ease-in-out;
      }

      @media only screen and (max-width: $screen-sm) {
        opacity: 1;
      }
    }

    &:hover .QuickOptions {
      opacity: 1;
    }

    &__header {
      button {
        background: none;
        border: 0;
        padding: 0;
        text-decoration: underline;
      }

      .Thumbnail {
        @include square(40px);
        margin-bottom: 20px;
        vertical-align: top;

        & + .Thumbnail {
          margin-left: -10px;
        }
      }
    }

    &__date {
      color: $color-primary-light-font;
    }

    &__values {
      margin-top: 10px;
    }

    .chq-bdg {
      margin-bottom: 5px;
    }

    .comments {
      @include border-bottom-radius;
      margin-bottom: -15px;
    }

    @media only screen and (max-width: $screen-sm) {
      .cheers--cheer-list {
        display: block;
        margin: 10px 0 0 10px;
      }
    }
  }

  &__heads {
    float: left;
    margin-right: 15px;
  }

  &__recognition-summary a {
    border-bottom: 0;
    word-wrap: break-word;

    .Thumbnail {
      margin-right: 5px;
    }
  }

  &__recognizer {
    text-align: right;
    @media only screen and (max-width: $screen-sm) {
      margin-bottom: 7px;
      text-align: left;
    }

    .Thumbnail {
      @include square(30px);
      display: inline-block;
      margin-bottom: -8px;
      margin-right: 5px;
    }
  }

  .all {
    border-bottom: 0;
    margin-right: 1rem;
    padding-left: 8px;
    text-decoration: none;
    @media only screen and (max-width: $screen-sm) {
      padding-left: 0;
    }
  }

  .from {
    margin-right: 7px;
  }

  &__right-column {
    padding-left: 0;
  }

  &__no-recognitions {
    background: url('../../images/badges/give-recognition.png') 50% 0 no-repeat;
    background-size: 100px;
    margin-top: 30px;
    padding-top: 140px;
    text-align: center;

    h2 {
      color: $color-primary-blue;
      font-weight: $font-weight-normal;
      margin: 1rem;
    }

    a {
      cursor: pointer;
      display: inline-block;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__middle {
    display: flex;
    margin-top: 15px;

    @media screen and (max-width: $screen-sm) {
      flex-wrap: wrap;
    }

    h3 {
      font-size: $font-size-large;
      margin-top: 0;
    }

    img {
      border-radius: 6px;
      height: 250px;
      margin-bottom: 10px;
      margin-top: 10px;
      padding: 0;
    }
  }

  &__type div {
    margin: 0 30px;

    @media screen and (max-width: $screen-sm) {
      margin-bottom: 30px;
    }
  }
}

.recognize {
  border-radius: $size-border-radius;

  @media (min-width: $screen-sm) {
    width: 700px;
  }

  h1 {
    font-size: $font-size-large;
    margin: 5px 0 20px;
  }

  &__users {
    padding: 10px 0 20px;

    button + a {
      margin-left: 10px;
    }

    .chq-tmb {
      vertical-align: middle;
    }
  }

  h2 {
    font-size: $font-size-large;
  }

  .recognize__type-thumb {
    margin-top: 10px;
  }

  .recognize__user-thumb {
    margin-top: 10px;

    & + .recognize__user-thumb {
      margin-left: -10px;
    }
  }

  &__preview {
    display: flex;
    justify-content: space-between;
    text-align: center;

    &-box {
      width: 30%;
    }

    &-gutter {
      width: 10%;
    }

    &-center {
      line-height: 5em;
      text-transform: uppercase;
      width: 10%;
    }
  }

  .recognize__blue-btn {
    border-color: $color-primary-blue;
    color: $color-primary-blue;

    path {
      fill: $color-primary-blue;
    }

    &:hover,
    &:active,
    &:focus {
      border-color: $color-primary-blue;
      color: $color-primary-blue;

      path {
        fill: $color-primary-blue;
      }
    }
  }

  &__selected-values {
    margin: 10px 0;

    button {
      margin-bottom: 5px;
    }
  }

  .chq-edi {
    background-color: $color-white;
    margin-bottom: 60px;
  }

  .chq-pan {
    &--hd {
      border-radius: $size-border-radius $size-border-radius 0 0;
    }
  }

  .chq-pan--ft {
    align-items: center;
    background-color: $color-white;
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    padding: 15px 0 0;
    position: relative;

    @media only screen and (min-width: $screen-modal-sm) {
      margin-top: 25px;
    }

    .modal-footer-column {
      flex-grow: 1;
      margin-top: 0;
      max-width: 210px;
    }

    .slack-channel-field {
      margin-bottom: 0;

      .chq-ffd {
        margin-bottom: 0;
        margin-right: 10px;
        margin-top: 0;

        &--lb {
          margin-bottom: 0;
        }

        &--ctrl {
          border: 1px solid $color-border-darker;
          border-radius: $size-border-radius;
          margin-bottom: 0;
          padding-left: 40px;
          padding-right: 25px;
        }

        &--sl--opts {
          max-height: 200px;
          overflow-x: hidden;
          overflow-y: scroll;

          .chq-pbn {
            overflow-wrap: anywhere;
          }
        }
      }
    }

    .chq-ffd--sl--icon {
      max-width: 42px;
      padding: 8px;
    }
  }

  .Select-Tags__Container {
    .share-with-field {
      .chq-ffd--sl {
        margin-right: 0;

        &--opts {
          margin-left: 0;
        }
      }
    }
  }
}

@keyframes animSlideIn {
  0% {
    height: 0;
    opacity: 0;
    padding: 0;
  }

  30% {
    opacity: .5;
  }

  100% {
    height: 100%;
    opacity: 1;
    padding: 8px 8px 15px;
  }
}

@keyframes animSlideOut {
  0% {
    opacity: 1;
  }

  30% {
    opacity: .5;
  }

  100% {
    border-bottom: 0;
    height: 0;
    opacity: 0;
    padding: 0;
  }
}
