@import '../common';

.form-group {
  .tz-picker {
    color: $color-primary-font;
    display: block;
    font: {
      family: $font-default;
      size: $font-size-normal;
      weight: $font-weight-light;
    };

    input {
      background: inherit;
      border: 0;
      border-bottom: 2px solid $color-border;
      border-radius: 0;
      height: 42px;
      margin-bottom: 5px;
      padding: 6px 12px 6px 0;
    }

    ul {
      border: 1px solid $color-border;
      border-radius: 3px;
      left: 0;
      margin: 0;
      margin-top: 0;
      max-height: 300px;
      overflow-y: auto;
      padding: 0;
      position: absolute;
      right: 0;
      top: 100%;
      z-index: $z-index-low;
    }

    li {
      color: $color-primary-font;
    }

    button.focus {
      background: $color-border;
    }
  }
}
