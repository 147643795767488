@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  src: url('/fonts/roboto/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url('/fonts/roboto/Roboto-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: url('/fonts/roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('/fonts/roboto/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/roboto/Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url('/fonts/roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('/fonts/roboto/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url('/fonts/roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('/fonts/roboto/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('/fonts/roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  src: url('/fonts/roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: url('/fonts/roboto/Roboto-ThinItalic.ttf') format('truetype');
}

$color-synopsys-lighter-purple: #7e45af;
$color-synopsys-purple: #5a2a82;
$color-synopsys-darker-purple: #2d1541;
$color-synopsys-yellow: #fdb71a;
$color-synopsys-font-darker: #646e81;

.synopsys-nav {
  &.chq-nav {
    background-color: $color-synopsys-purple;

    .chq-nav {
      &--search {
        input {
          background-color: rgba(255, 255, 255, .3);
        }
      }

      &--items {
        a {
          border-color: $color-synopsys-purple;

          &.active {
            border-color: $color-synopsys-yellow;
            color: $color-synopsys-yellow;

            path {
              fill: $color-synopsys-yellow;
            }
          }
        }
      }

      &--notit {
        em {
          background-color: $color-synopsys-yellow;
          color: $color-synopsys-purple;
        }
      }

      &--admint {
        .Notification-Badge {
          background-color: $color-synopsys-yellow;
          color: $color-synopsys-purple;
        }

        &:hover {
          background-color: rgba(255, 255, 255, .15);
        }
      }

      &--unseen {
        border-color: $color-synopsys-yellow;
      }
    }
  }
}

.synopsys-content {
  .widget-610 {
    .chq-pan--hd {
      display: none;
    }
  }
}

.synopsys {
  font-family: 'Roboto', Arial, sans-serif;

  a {
    color: $color-synopsys-purple;

    &:hover {
      color: $color-synopsys-lighter-purple;
    }
  }

  .Section__title {
    h2 {
      color: $color-dark-blue;
    }
  }

  .QuickOptions {
    .Gray-Button {
      align-items: center;
      background-color: $color-white;
      border: 0;
      color: $color-black;
      display: flex;
      font-weight: 500;
      outline: 0;
      padding: 10px 20px;
      position: relative;
      z-index: 20;

      &:hover {
        padding: 10px 20px;
      }

      svg {

        path {
          fill: $color-white;
        }
      }
    }

    .dropdown-menu {
      .chq-btn {
        color: $color-synopsys-purple;

        svg {
          path {
            fill: $color-synopsys-purple;
          }
        }

        &:hover {
          color: $color-synopsys-purple;

          svg {
            path {
              fill: $color-synopsys-purple;
            }
          }
        }
      }
    }
  }

  .LandingPageCallout--Preview {
    color: $color-dark-blue;

    .Person--name {
      color: $color-synopsys-purple;
    }

    .Person--title {
      color: $color-dark-blue;
    }

    .Preview {

      &-Browser__People {
        color: $color-synopsys-purple;

        h2 {
          text-transform: capitalize;
        }
      }

      &-Browser__Stories {
        color: $color-synopsys-purple;

        h2 {
          text-transform: capitalize;
        }
      }

      &-Header__Image {
        background-position: 90%;

        @media only screen and (min-width: $screen-sm) {
          background-position: 10%;
        }

        @media only screen and (min-width: $screen-md) {
          background-position: 50%;
        }
      }

      .Preview-Tags {
        h2 {
          color: $color-dark-blue;
        }
      }

      .back-arrow {
        color: $color-synopsys-purple;

        svg {
          path {
            fill: $color-synopsys-purple;
          }
        }
      }

      &-Header__Text {
        background-image: none;
        font-size: 40px;
        font-weight: 500;
        margin: 0;

        @media only screen and (min-width: $screen-sm) {
          margin: 0 30px;
        }

        @media only screen and (min-width: $screen-md) {
          margin: 0 8.5%;
        }

        @media only screen and (min-width: $screen-lg) {
          margin: 0 11%;
        }
      }

      &-Tags {

        .chq-bdg {
          background-color: #f0f0f4;
          color: $color-dark-blue;
          font-weight: 500;

          svg {
            path {
              fill: $color-dark-blue;
            }
          }

          &.Selected {
            background-color: $color-synopsys-purple;
            color: $color-white;

            path {
              fill: $color-white;
            }

            &:hover {
              background-color: lighten($color-synopsys-purple, 10%);
            }
          }
        }

        .Tags__title {
          h2 {
            color: $color-synopsys-purple;
            font-weight: 600;
          }
        }
      }
    }
  }

  .quote-icon {
    path {
      fill: $color-synopsys-purple;
    }
  }

  .gallery-lightbox {
    &__creator-information {
      span {
        color: $color-dark-blue;
      }
    }

    &__uploader-date {
      color: $color-synopsys-font-darker;
    }
  }

  &.gallery-lightbox--Story {
    .FeedItem__preview__title__Story {
      .Title {
        color: $color-dark-blue;
      }

      .Accordian__title--container {
        .Accordian__title {
          color: $color-synopsys-purple;
        }
      }

      .Accordian__title--icon__container {
        path {
          fill: $color-synopsys-purple;
        }
      }

      .Subprompt {
        &-Container {
          color: $color-synopsys-font-darker;
        }

        &-Arrow {
          path {
            fill: $color-synopsys-font-darker;
          }
        }
      }
    }

    .chq-ffd-tags {

      .chq-bdg {
        background-color: #f0f0f4;
        color: $color-dark-blue;
        font-weight: 500;

        &:hover {
          background-color: rgba($color-synopsys-purple, .1);
          color: $color-dark-blue;
          font-weight: 500;
        }

        svg {
          path {
            fill: $color-dark-blue;
          }
        }
      }
    }
  }

  &.LandingPageUserCallout {

    .Preview {
      &-Header__Overlay {
        background: none;
        justify-content: flex-start;
      }
    }
  }

  &.LandingPageHomeCallout {
    .Preview {
      &-Header__Overlay {
        background: none;
        justify-content: flex-start;
      }
    }
  }

  .SynopsysButton {
    align-items: center;
    background-color: $color-synopsys-lighter-purple;
    border: 0;
    bottom: 15px;
    color: $color-white;
    display: flex;
    font-weight: $font-weight-normal;
    outline: 0;
    padding: 10px 20px;
    position: absolute;
    right: 15px;
    z-index: $z-index-medium;

    &:hover {
      background-color: $color-synopsys-purple;
      border: 0;
      padding: 10px 20px;
    }

    path {
      fill: $color-white;
    }

    svg {
      margin: 0 0 0 5px;
      transform: rotateY(180deg);
    }
  }

  .PromptIcon {
    &.just_images {
      background: linear-gradient(90deg, $color-synopsys-purple 0%, $color-synopsys-lighter-purple 100%);
    }

    &.just_videos {
      background: linear-gradient(90deg, $color-synopsys-darker-purple 0%, $color-synopsys-purple 100%);
    }

    &.all {
      background: linear-gradient(90deg, $color-synopsys-darker-purple 0%, $color-synopsys-purple 50%, $color-synopsys-lighter-purple 100%);
    }
  }
}
