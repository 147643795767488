@import '../common';

.leaderboard {
  .chq-pan--bd {
    padding-bottom: 0;
  }

  .chq-pan--ft {
    margin-top: -1px;
  }

  &-container {
    max-height: 325px;
    overflow: scroll;
  }

  &--row {
    border-bottom: 1px solid $color-border;
    padding: 5px 0;

    &.isMe {
      border-bottom: 7px solid $color-background;
    }
  }

  &--place {
    color: $color-primary-light-font;
    float: left;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 28px;
  }

  &--user {
    margin-left: 28px;
    padding-top: 5px;

    a {
      text-decoration: none;
    }
  }

  &--thumb {
    height: 30px;
    margin-bottom: -9px;
    margin-right: 11px;
    width: 30px;
  }

  &--points {
    color: $color-primary-light-font;
    font-size: $font-size-large;
    padding-top: 8px;
    text-align: right;
  }
}
