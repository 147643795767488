.StoryItemPlaceholder {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);
  margin-left: 10px;
  margin-top: 10px;
  min-height: 100px;
  padding: 15px;
  position: relative;

  &:first-child {
    height: 250px;
  }

  &__Container {
    display: flex;
    margin-top: 15px;
  }

  &__Left {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  &__Right {
    margin-right: 5px;
    width: 50%;

    .StoryItemPlaceholder {
      height: 98%;
    }
  }

  &__date {
    background-color: rgba(0, 0, 0, .06);
    height: 20px;
    width: 40px;
  }

  &__user {
    background-color: rgba(0, 0, 0, .06);
    border-radius: 30px;
    height: 45px;
    width: 45px;
  }

  &__preview {
    height: 67px;

    &:hover {
      .QuickOptions {
        opacity: 1;
      }
    }

    &__title {
      p {
        background-color: rgba(0, 0, 0, .06);
        height: 16px;
        margin-bottom: 9px;
        width: 80%;
      }
    }

    > p {
      background-color: rgba(0, 0, 0, .06);
      height: 24px;
      margin-bottom: 15px;
      width: 100%;
    }
  }
}
