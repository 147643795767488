.Linkedin-Reactions {
  &__Btn {
    &:hover {
      .Linkedin-Reactions__Container {
        display: flex;
      }
    }
  }

  &__Container {
    background-color: $color-white;
    border-radius: 40px;
    box-shadow: $box-shadow-default;
    display: none;
    left: -30px;
    padding: 5px 10px;
    position: absolute;
    top: -40px;

    span {
      margin-right: 5px;
      padding: 0;
    }

    .Linkedin-Reaction {
      @include square(36px);

      &:hover {
        transform: scale(1.35) translateY(-28%);
        transition: transform .3s;
      }
    }
  }
}
