$color-santander-dark-red: #bc0000;
$color-santander-red: #ec0001;
$color-santander-white: #fff;
$color-santander-yellow: #fdca32;

.santander-nav,
.santander-private-space-nav {
  &.chq-nav {
    background-color: $color-santander-red;

    .chq-nav {
      &--search {
        input {
          background-color: $color-santander-dark-red;
        }
      }

      &--admin__container {
        border-right: solid 1px $color-santander-white;
      }

      &--profile {
        .chq-pbn .caret {
          color: $color-santander-white;
        }
      }

      &--items {
        a {
          border-color: $color-santander-red;

          &.active {
            border-color: $color-santander-yellow;
            color: $color-santander-yellow;

            path {
              fill: $color-santander-yellow;
            }
          }
        }

        &--notit {
          em {
            background-color: $color-santander-yellow;
          }
        }
      }

      &--admint {
        .Notification-Badge {
          background-color: $color-santander-yellow;
        }

        &:hover {
          background-color: $color-santander-dark-red;
        }
      }

      &--unseen {
        border-color: $color-santander-yellow;
      }
    }
  }
}
