@import '../common';

.PhotoGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .PhotoGroup__thumbnail {
    cursor: pointer;
    height: 200px;
    padding: 5px;
    position: relative;
    transition: opacity .3s ease-in;
    width: 33.33%;

    &:hover {
      .Story__information {
        &--feed {
          .background-container {
            box-shadow: 0 0 6px $color-black-alpha-40;
          }
        }

        &--profile {
          .background-container {
            background-color: $color-black-alpha-50;

            .chq-edi-op {
              section {
                color: transparent;
                text-shadow: 0 0 5px rgb(255, 255, 255);
              }

              .chq-bdg {
                color: transparent;
                text-shadow: 0 0 5px rgb(255, 255, 255);
              }

              svg {
                filter: blur(3px);
              }
            }

            .Story__creator {
              span {
                color: transparent;
                text-shadow: 0 0 5px rgb(255, 255, 255);
              }

              .chq-tmb {
                filter: blur(3px);
              }
            }
          }
        }
      }

      .Story__answer {
        display: block;

        .background-container {
          .chq-edi-op {
            section {
              color: $color-white;
              display: block;
              text-shadow: none;
            }
          }
        }
      }
    }

    @media screen and (max-width: $screen-sm) {
      min-height: 150px;
    }
  }

  .PhotoGroup__wrapper {
    background-position: center;
    background-size: cover;
    border-radius: 6px;
    height: 100%;
    width: 100%;
  }

  .PhotoGroup__view-all {
    background-color: rgba(0, 0, 0, .85);
    border-radius: $size-border-radius;
    bottom: 0;
    color: $color-white;
    font-size: 1.3em;
    left: 0;
    margin: 5px;
    padding-top: 70px;
    position: absolute;
    right: 0;
    text-align: center;
    text-decoration: none;
    top: 0;

    @media screen and (max-width: $screen-sm) {
      padding-top: 42px;
    }

    svg {
      margin-bottom: -3px;

      path {
        fill: $color-white;
      }
    }
  }

  &-2 > div {
    &:nth-child(1),
    &:nth-child(2) {
      height: 250px;
      width: 50%;

      @media screen and (max-width: $screen-sm) {
        height: 120px;
      }
    }
  }

  &-1 > div,
  &-3 > div {
    &:nth-child(1) {
      height: 380px;
      width: 100%;

      @media screen and (max-width: $screen-sm) {
        height: 200px;
      }
    }

    &:nth-child(2),
    &:nth-child(3) {
      height: 250px;
      width: 50%;

      @media screen and (max-width: $screen-sm) {
        height: 120px;
      }
    }
  }

  &-4 > div {
    &:nth-child(1) {
      height: 380px;
      width: 100%;

      @media screen and (max-width: $screen-sm) {
        height: 200px;
      }
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      height: 200px;
      width: 33.33%;

      @media screen and (max-width: $screen-sm) {
        height: 100px;
      }
    }
  }

  &-5 > div {
    &:nth-child(1),
    &:nth-child(2) {
      // flex-grow: 2;
      height: 280px;
      width: 50%;

      @media screen and (max-width: $screen-sm) {
        height: 140px;
      }
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      @media screen and (max-width: $screen-sm) {
        height: 100px;
      }
    }
  }

  &-6 > div {
    &:nth-child(1),
    &:nth-child(2) {
      height: 250px;
      width: 50%;
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      height: 160px;
      width: 25%;
    }

    @media screen and (max-width: $screen-sm) {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        height: 120px;
        width: 33.33%;
      }
    }
  }
}
