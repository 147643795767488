@import '../common';

.notification {
  align-items: center;
  background-color: $color-white;
  color: $color-primary-font;
  display: flex;
  font-size: $font-size-nav;

  .image {
    border-radius: $size-border-radius;
    display: block;
    margin-bottom: 10px;
    margin-top: 10px;
    max-width: 100%;
  }

  a {
    outline: 0;
  }

  hr {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .badge-img {
    @include square(50px);
    left: 2rem;
    position: absolute;
    top: 2rem;

    img {
      @include square(4rem);
      display: block;
      margin: 0 auto;
    }

    .points-increment {
      display: block;
      margin: 0 auto;
      margin-top: -12px;
      text-align: center;

      > span {
        background-color: rgba(0, 0, 0, .7);
        border-radius: 4px;
        color: $color-white;
        font-size: $font-size-small;
        font-weight: $font-weight-light;
        padding: .5px 7px;
      }
    }
  }

  &-body {
    .chq-edi-op {
      -webkit-box-orient: vertical; // sass-lint:disable-line no-vendor-prefixes
      -webkit-line-clamp: 3; // sass-lint:disable-line no-vendor-prefixes
      display: -webkit-box; // sass-lint:disable-line no-vendor-prefixes
      overflow: hidden;
      text-overflow: ellipsis;

      section {
        display: none;

        &:first-child {
          display: block;
        }
      }
    }
  }
}
