.user-name {
  display: inline-block;
  line-height: 25px;
  vertical-align: bottom;

  .chq-tmb,
  a {
    vertical-align: middle;
  }
}
