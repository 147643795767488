.Story-Question {
  .story-question--image {
    max-width: 160px;
  }

  .chq-sbar {
    input {
      padding: 12px 12px 12px 36px;
    }
  }

  .chq-dd [aria-haspopup='listbox'] {
    background-color: $color-primary-green;
    border: $color-primary-green solid 1px;
    border-radius: 5px;
    color: $color-white;
    font-size: 1em;
    font-weight: 400;
    padding: 10px 20px;

    svg {
      height: 18px;
      margin: 0 0 -3px -3px;
      margin-left: -10px;
    }

    path {
      fill: $color-white;
    }

    .chq-dd--caret {
      display: none;
    }
  }

  .main-action-button-container {
    display: flex;

    .CreatePromptDropdown {
      font-size: 16px;

      &-Options {
        font-size: 16px;
        margin: 14px 0 0;
        padding: 5px 0;
        text-align-last: left;
        top: 35px;
        white-space: nowrap;
        z-index: 1;

        .Dropdown-Option {
          align-items: center;
          display: flex;
          font-weight: $font-weight-normal;
          padding: .5em .8em;
          padding-right: 0;

          &__MainText {
            align-items: center;
            display: flex;
          }

          .description-prompts {
            font-weight: $font-weight-light;
            margin-left: 25px;
          }
        }

        svg {
          @include square(16px);
          align-items: center;
          fill: $color-black;
          margin-right: 8px;

          path {
            fill: $color-primary-font;
          }
        }
      }

      .new-prompt {
        outline: 0;
      }
    }
  }

  .create-trend-button {
    margin-right: 10px;
  }

  .chq-bdg {
    margin-right: 5px;
  }

  .Arrow-column {
    padding-top: 14px;
  }

  .Answers-column {
    padding-top: 17px;
    text-align: center;
  }

  .ChildPrompts {
    border: 1px solid $color-primary-blue;
    border-radius: 10px;
    box-shadow: .5px .5px 3px $color-primary-blue;
    margin-bottom: 20px;
    margin-top: 0;

    &__Container {
      margin-left: 6px;
      padding: 10px 15px;

      &-Title {
        font-size: $font-size-normal;
      }
    }

    .PromptIcon {
      @include square(35px);
    }
  }

  .Story-Question__Title {
    min-width: 300px;
    padding-top: 17px;
  }

  .QuickOptions__open-dropdown__wrapper {
    padding-top: 0;
  }

  .PromptShareOptions__Buttons {
    left: 22px;
    position: absolute;
  }

  .Actions-column {
    position: relative;

    .QuickOptions {
      position: absolute;

      &--Actions {
        margin-right: 45px;
        position: relative;

        .chq-btn {
          margin-top: 4px;
          white-space: nowrap;
        }
      }

      button {
        &:focus {
          outline: none;
        }
      }

      .Subprompt-Arrow {
        transform: scaleX(-1);
      }
    }
  }
}
