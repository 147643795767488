@import '../common';

.Events {
  margin: 0 auto;
  max-width: 1440px;

  .right-column {
    padding-left: 0;
  }

  .EventCard {
    background-color: $color-white;
    border-radius: 6px;
    box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);

    .QuickOptions {
      opacity: 0;
      transition: opacity 150ms ease-in-out;
      @media only screen and (max-width: $screen-md) {
        opacity: 1;
      }
    }

    &:hover {
      .QuickOptions {
        opacity: 1;
      }
    }
  }
}

.chq-umf {
  .chq-btn {
    display: block;
    margin: 10px auto;
  }
}

.EventDetails {
  .comments {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
