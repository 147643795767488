.Accordian {
  &__row {
    display: table-row;

    .Arrow-column {
      svg {
        @include square(18px);
        margin-left: 0;
        margin-top: 5px;
      }
    }
  }

  &__body {
    &-row {
      max-height: 500px;

      &.open {
        &:hover {
          background-color: $color-white;
        }

        td {
          border-top: 0;
        }
      }
    }

    &.closed {
      display: none;
    }
  }
}
