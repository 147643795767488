@import '../common';

.EmbedStories {
  border-radius: $size-border-radius;
  width: 75vw;

  &--SliderCallout {
    margin: 0 auto;
    width: 85%;

    @media only screen and (min-width: $screen-sm) {
      width: 90%;
    }

    @media only screen and (min-width: $screen-md) {
      width: 94%;
    }
  }

  &__Body--open {
    overflow: initial;
  }

  .EmptyState--container {
    display: flex;
  }

  .chq-pan--bd {
    padding: 30px;
  }

  .Separator {
    border-color: $color-border;
    margin-left: -30px;
    margin-right: -30px;
  }

  .SearchBar {
    padding-right: 0;
  }

  .chq-pan--hd {
    border-radius: $size-border-radius $size-border-radius 0 0;

    svg {
      height: 45px !important;
      margin-top: -7px;
      width: 45px !important;
    }
  }

  .Select-Tags__Container {
    align-items: center;
    border: 0;
    display: flex;
    position: initial;
    width: 100%;
    z-index: $z-index-low;

    .share-with-field {
      width: 100%;

      .chq-ffd--ctrl {
        border: 1px solid $color-secondary-light-font;
        border-radius: $size-border-radius;
        margin-bottom: 0;
        padding: 12px 40px 2px;

        &-fc {
          border: 1px solid $color-primary-blue;
          box-shadow: inset 0 1px 1px $color-form-inset, 0 0 8px $search-bar-shadow;
        }

        .chq-bdg {
          background-color: $color-background-darker;
          color: $color-dark-blue;
          font-size: 1em;
          font-weight: $font-weight-heavy;
          margin: 0 0 10px 10px;
          margin-left: 0;
          margin-right: 10px;
          margin-top: 0;
          padding: 5px 12px;

          &:hover {
            cursor: pointer;
          }

          path {
            fill: $color-dark-blue;
          }
        }

        &:hover {
          cursor: text;
        }
      }

      .chq-ffd--sl {
        margin-left: 0;
        margin-right: 0;
        z-index: $z-index-low;

        &--caret {
          display: none;
        }

        &--place {
          color: $color-secondary-font;
          font-size: $font-size-large;
          font-weight: $font-weight-heavy;
          margin-bottom: 10px;
        }

        &--opts {
          border-radius: $size-border-radius;
          margin-left: 0;
          margin-top: 6px;
          width: 100%;
        }

        &--match {
          font-size: 18px;
          margin-bottom: 10px;
          margin-top: 0;
        }
      }

      .chq-ffd--lb {
        display: flex;
        justify-content: space-between;
        top: 15px;

        svg {
          display: block;
        }

        path {
          fill: $color-primary-light-font;
        }
      }
    }
  }

  .Preview-Browser__Trends {
    .Stories__Empty {
      align-items: center;
      background-color: $color-background-darker;
      border-radius: $size-border-radius;
      display: flex;
      height: 340px;
      justify-content: center;
      margin: 20px 0;
      padding: 30px;

      &--image {
        max-height: 150px;
      }
    }
  }

  .clearfix {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    padding: 0 7.5px;
  }

  .chq-bdg {
    background-color: transparent;
    color: $color-white;
    font-size: 18px;
    font-weight: $font-weight-normal;
    margin-top: 0;
    padding: 0;

    path {
      fill: $color-white;
    }
  }

  .Approve {
    &-Button {
      &--icon {
        &__container {
          @include square(40px);
          align-items: center;
          background-color: $color-border;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          margin-right: 15px;
          padding: 10px;
        }

        path {
          fill: $color-primary-font;
        }
      }
    }
  }

  .chq-ffd--ctrl-text {
    border: 1px solid $color-border-darker;
    border-radius: $size-border-radius;
    resize: none;
  }

  .ButtonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;

    .Copy-Button {
      margin-right: 15px;

      svg {
        margin-right: 5px;
      }
    }

    .copyCode {
      margin-left: 10px;
    }
  }

  .chq-ffd-tags {
    overflow: hidden;
  }

  .EmbedStories-Tabs {
    background-color: transparent;
    margin: 0 -30px 15px;
    padding: 0 30px;

    li {
      span {
        align-items: center;
        color: $color-secondary-font;
        display: flex;
        font-weight: $font-weight-normal;
        padding: 15px;

        &:hover {
          background-color: transparent;
          border-bottom: 2px solid $color-green-alpha-45;
          color: $color-primary-font !important;
          cursor: pointer;
        }

        &.active {
          background-color: transparent;
          border-bottom: 3px solid $color-primary-green;
          color: $color-primary-font;
          font-weight: $font-weight-heavy;
        }
      }
    }
  }
}
