@import '../common';

.nav.nav-tabs {
  background-color: initial;
  padding-left: 15px;

  li > a {
    align-items: center;
    color: $color-primary-font;
    display: flex;
    padding: 15px;

    &:hover {
      background-color: transparent;
      border-bottom: 2px solid $color-green-alpha-45;
      color: $color-primary-font !important;
    }
  }

  li.active > a,
  li > a.active {
    background-color: transparent;
    border-bottom: 3px solid $color-primary-green;
    color: $color-primary-green !important;
  }

  a {
    @include border-top-radius;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    color: $color-white;
    cursor: pointer;
    font-weight: 200;
    line-height: 1.42857143;
    margin-right: 2px;
    margin-top: 7px;
    padding: 7px 15px;

    &:hover {
      background-color: $color-border;
      color: $color-link-blue;
      text-decoration: none;
    }
  }

  a.active {
    background-color: $color-white;
    color: $color-link-blue !important;
    font-weight: $font-weight-heavy;
  }

  &.nav-tabs-green {
    background-color: $color-white;
    border-radius: $size-border-radius $size-border-radius 0 0;
    margin-bottom: 0;
    padding-left: 0;

    li > a {
      color: $color-secondary-font;
      padding: 10px 33px 15px;

      &:hover {
        background-color: $color-border;
        color: $color-primary-green;
      }
    }

    li.active > a,
    li > a.active {
      background-color: $color-white;
      border-bottom: 3px solid $color-primary-green;
      color: $color-primary-green !important;
    }

    a {
      font-weight: $font-weight-normal;
    }

    .Button-inverted {
      border: 1px solid $color-primary-green;
      border-radius: $size-border-radius;
      color: $color-primary-green;
      font-size: $font-size-nav;
      margin-top: 9px;
      padding: 8px 16px;
      position: absolute;
      right: 30px;

      @media only screen and (min-width: $screen-sm) {
        right: 15px;
      }
    }
  }
}
