@import '../common';

.box-wrapper {
  float: left;
}

.primary-blue {
  background-color: $color-primary-blue;

  &:hover {
    background-color: $color-primary-blue;
  }
}

.box-shadow {
  @include border-radius;
  background-color: $color-white;
  box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);
}

.no-padding {
  padding: 0;
}

.padding-15px {
  padding: 15px;
}

.padding-left-15px {
  padding-left: 15px;
}

.padding-right-15px {
  padding-right: 15px;
}

.max-width-360 {
  margin: 0 auto;
  max-width: 360px;
}

.max-width-900 {
  margin: 0 auto;
  max-width: 900px;
}

.max-width-1120 {
  display: block;
  float: none;
  margin: 0 auto;
  max-width: 1120px;
  width: 100%;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.display-inline {
  display: inline;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-large {
  font-size: $font-size-large;
}

.flex-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.no-outline-on-focus {
  &:focus {
    outline: none;
  }
}

.no-select {
  user-select: none;
}

.overflow-hidden {
  overflow: hidden;
}

.no-scrollbar { // sass-lint:disable-block no-vendor-prefixes
  -ms-overflow-style: none; // IE 10+
  overflow: -moz-scrollbars-none; // Firefox

  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

.capitalize {
  text-transform: capitalize;
}

.clickable {
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

.rotate-45d {
  transform: rotate(45deg);
}

.ReactModal__Overlay {
  z-index: 990000000 !important;
}

.spaced {
  margin: 24px 0;
}

.light {
  color: $color-primary-light-font;
}

.light-icon {
  fill: #666;
}

.hidden-lg {
  display: none !important;
  @media only screen and (min-width: $screen-lg) {
    display: block !important;
  }
}

.hidden-md {
  display: none !important;
  @media only screen and (min-width: $screen-md) {
    display: block !important;
  }
}

.show-lg {
  display: block;
  @media only screen and (min-width: $screen-lg) {
    display: none !important;
  }
}

.show-md {
  display: block;
  @media only screen and (min-width: $screen-md) {
    display: none !important;
  }
}
