@import '../common';

.Manage.SurveyItems {
  padding: 0;
}

.EditSurveyItemModal {
  width: 920px;

  @media only screen and (max-width: $screen-sm) {
    width: 95%;
  }

  .button {
    margin-bottom: 15px;
  }

  .SurveyItemTypeOptions {
    .SurveyItemTypeOptionWrapper {
      float: left;
      margin-bottom: 10px;
      margin-left: 0;
      margin-right: 1%;
      margin-top: 2px;
      overflow: hidden;
      width: 49%;

      @media only screen and (max-width: $screen-sm) {
        margin: 0 0 15px;
        width: 100%;
      }
    }

    .SurveyItemTypeOption {
      background-color: transparent;
      border: 1px solid $color-border;
      border-radius: 5px;
      color: $color-primary-font;
      cursor: pointer;
      display: block;
      font-weight: $font-weight-light;
      height: 68px;
      padding: 10px;
      text-align: left;
      width: 100%;

      &:hover {
        opacity: .8;
      }

      &.active {
        opacity: 1 !important;
      }

      &:hover,
      &.active {
        background-color: $color-primary-green;
        border: 1px solid $color-primary-green;
        color: $color-white;

        svg path {
          fill: $color-white;
        }
      }

      svg {
        float: left;
        height: 30px;
        margin: 8px 0;
        width: 30px;

        path {
          fill: $color-primary-font;
        }
      }

      p {
        margin-bottom: 0;
        margin-left: 41px;
        white-space: nowrap;

        &.title {
          font-size: $font-size-normal;
          font-weight: $font-weight-heavy;
          margin-bottom: 2px;
        }
      }
    }
  }

  h2 {
    font-size: $font-size-large;
    font-weight: 200;
    margin-bottom: 15px;
  }
}

.EditSurveyHeader {
  margin: 30px 0 15px;

  h1.title {
    font-size: $font-size-28;
    font-weight: $font-weight-light;
    margin-bottom: 0;
    margin-top: 0;

    .pts {
      font-size: $font-size-22;
      padding-left: 10px;
    }
  }

  .back {
    float: right;
    margin-top: 10px;

    @media only screen and (max-width: $screen-sm) {
      float: none;
    }
  }
}
