@import '../common';

.widget-surveys,
.top-scores {
  .chq-snv {
    border: 1px solid $color-border;
    border-radius: 6px;
    display: flex;
    width: 100%;

    &--it {
      flex-basis: 50%;

      &[aria-current='true'] {
        background-color: $color-green-alpha-15;
        border: 1px solid $color-primary-green;
        border-radius: 6px;
        color: $color-primary-green !important;
        font-weight: $font-weight-heavy;
      }
    }
  }

  &--survey {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    small {
      color: $color-primary-light-font;
    }
  }

  .chq-btn {
    float: right;
    margin-left: 15px;
  }
}



