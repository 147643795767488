@import '../common';

.ApproveStoryModal {
  border-radius: $size-border-radius;
  width: 75vw;

  .chq-pan--bd {
    padding: 30px;
  }

  .Separator {
    border-color: $color-border;
    margin-left: -30px;
    margin-right: -30px;
  }

  &-Sort {
    margin-bottom: 0;

    .chq-ffd--ctrl {
      border: 1px solid $color-border;
      border-radius: $size-border-radius;
      margin-bottom: 0;
      padding: 10px 12px;

      &:focus {
        border-bottom: 1px solid $color-border;
        border-color: $color-primary-blue;
        box-shadow: inset 0 1px 1px rgba($color-black, .075), 0 0 8px rgba(140, 180, 214, .6);
      }
    }

    .chq-ffd--lb {
      margin-bottom: 0;
    }
  }

  .SearchBar {
    padding-right: 0;
  }

  &__SelectedStories {
    align-items: center;
    display: flex;

    &__more {
      align-items: center;
      background-color: $color-border;
      color: $color-primary-font;
      display: flex;
      font-weight: $font-weight-heavy;
      justify-content: center;
    }

    .Thumbnail {
      border-radius: 40px;
      height: 40px !important;
      margin-left: -10px;
      min-width: 40px;
      width: 40px !important;
    }
  }

  .chq-pan--hd {
    border-radius: $size-border-radius $size-border-radius 0 0;

    svg {
      height: 45px !important;
      margin-top: -7px;
      width: 45px !important;
    }
  }

  .Stories__cards {
    padding: initial;

    &__SearchBar {
      padding: 0 20px;
    }

    .SearchBar {
      padding-right: 0;
    }

    .chq-sbar {
      margin: 0;

      input {
        background-color: $color-border;
        border-color: $color-border-darker;
        color: $color-primary-font;
        font-weight: $font-weight-normal;
        margin-bottom: 0;
        padding-bottom: 10px;
        padding-top: 10px;

        &:focus {
          border-color: $color-primary-blue;
          box-shadow: inset 0 1px 1px rgba($color-black, .075), 0 0 8px rgba(140, 180, 214, .6);
        }
      }

      path {
        fill: $color-primary-font;
      }
    }

    .clearfix {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      padding: 0 7.5px;
    }

    .Stories__container {
      margin-top: 15px;

      .PhotoGroup__thumbnail {
        padding: 5px;
        position: relative;

        .story--image {
          border-radius: $size-border-radius;
          display: block;
          margin: 0 auto;
          max-height: 540px;
          max-width: 100%;
          min-height: 220px;
          object-fit: cover;
          width: 100%;
        }

        .chq-edi-op {
          section {
            display: none;
          }
        }

        .Story__information {
          &--profile {
            .background-container {
              position: relative;
            }
          }
        }

        &:hover {
          cursor: pointer;

          .Story__information {
            &--profile {
              .background-container {
                background-color: rgba(0, 0, 0, .5);
                position: relative;

                .chq-edi-op {
                  display: none;
                }

                .Story__question {
                  display: none;
                }

                .Story__creator {
                  p {
                    display: none;
                  }

                  .chq-tmb {
                    display: none;
                  }
                }
              }
            }
          }

          .Story__answer {
            display: block;

            .background-container {
              overflow: hidden;
              position: relative;

              .chq-edi-op {
                display: block;

                section {
                  color: $color-white;
                  display: block;
                  text-shadow: none;
                }
              }

              .Story__question {
                color: $color-white;
                text-shadow: none;
              }
            }
          }
        }
      }

      .Story {
        &__Selected {
          .story--image,
          .Story__creator,
          .Story__question,
          .chq-edi-op,
          .chq-ffd-tags {
            filter: opacity(.5);
          }
        }

        &__creator {
          align-items: center;
          bottom: initial;
          display: flex;
          margin-bottom: 15px;
          position: relative;

          .chq-tmb {
            height: 40px;
            width: 40px;
          }

          p {
            margin-bottom: 0;
          }
        }

        &__information--profile {
          .footer {
            bottom: 15px;
            left: 15px;
            position: absolute;
            right: 15px;

            .chq-btn,
            .chq-abn {
              align-items: center;
              display: flex;
              font-weight: $font-weight-normal;
              justify-content: center;
              margin-top: 10px;
              padding: 10px;
              width: 100%;

              svg {
                margin: 0 5px 0 0;
              }
            }

            .chq-btn {
              &:hover {
                padding: 9px;
              }
            }

            .chq-abn {
              background-color: $color-white;
              border: 2px solid $color-primary-font;
              color: $color-primary-font;

              path {
                fill: $color-primary-font;
              }
            }
          }
        }
      }
    }
  }

  .EmptyResults-people {
    background-position: 50% 30px;
    margin: 0 auto;
  }

  .EmptyState {
    &--container {
      align-items: center;
      display: flex;
      font-weight: $font-weight-heavy;
      padding: 15px 45% 15px 0;

      .Approve {
        &-Button {
          &--icon {
            height: 20px;
            margin: 0 !important;
            width: 20px;

            &__container {
              @include square(40px);
              align-items: center;
              background-color: $color-border;
              border-radius: 20px;
              display: flex;
              justify-content: center;
              margin-right: 15px;
              padding: 10px;
            }

            path {
              fill: $color-primary-font;
            }
          }
        }
      }
    }
  }

  .Approve {
    &-Button {
      &--icon {
        &__container {
          @include square(40px);
          align-items: center;
          background-color: $color-border;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          margin-right: 15px;
          padding: 10px;
        }

        path {
          fill: $color-primary-font;
        }
      }
    }
  }
}

.ReactModalPortal {
  .ApproveStoryModal {
    &__SelectedStories {
      margin-bottom: 15px;
    }
  }
}
