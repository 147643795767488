@import '../common';

.AdminFlags {
  &__header-row {
    font-weight: $font-weight-normal;
  }

  &__row {
    &__toggle {
      cursor: pointer;

      svg {
        margin-left: 10px;
        margin-top: 8px;
      }

      path {
        fill: $color-secondary-font;
      }
    }

    &__description {
      margin-bottom: 15px;
    }

    &__image {
      display: block;
      margin-bottom: 15px;
      max-width: 200px;
    }

    &__quote {
      margin-bottom: 15px;
    }

    &__note {
      border-left: 4px solid $color-primary-yellow;
      margin-bottom: 15px;
      padding-left: 10px;
    }

    &__actions {
      .link {
        display: inline-block;
      }
    }

    .user-name {
      bottom: -2px;
      position: relative;
    }

    .date {
      margin-top: 8px;
    }
  }
}
