@import '../common';

.Trends-Slider {
  background-color: $color-white;
  border-radius: $size-border-radius;
  margin-bottom: 15px;

  &__Empty {
    box-shadow: none;

    .chq-btn {
      border: 1px solid $color-primary-green;
    }
  }

  &__Share-btn {
    justify-content: center;
    width: 190px;
  }

  &__banner {
    background-position: center;
    background-size: cover;
    border-radius: $size-border-radius 0 0 $size-border-radius;
    height: 100%;
    margin-right: 7px;
    position: relative;
    width: 66%;

    &__overlay {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .2) 70%, rgba(0, 0, 0, .6) 100%);
      border-radius: $size-border-radius;
      display: flex;
      flex-direction: column;
      height: 350px;
      padding: 15px 10px;
      position: absolute;
      width: 100%;
    }
  }

  &__stories {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 34%;

    &-image {
      background-position: center;
      background-size: cover;
      border-bottom-right-radius: $size-border-radius;
      flex: 1;
      height: 50%;
      position: relative;

      &:first-child {
        border-bottom-right-radius: 0;
        border-top-right-radius: $size-border-radius;
        margin-bottom: 7px;
      }

      .Overlay {
        align-items: center;
        background-color: $color-black-alpha-60;
        border-bottom-right-radius: $size-border-radius;
        bottom: 0;
        color: $color-white;
        display: flex;
        font-size: $font-size-large;
        font-weight: $font-weight-normal;
        justify-content: center;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  .chq-pbn {
    display: flex;

    &:last-child {
      margin-right: 0;
    }
  }

  .Story-Tags {
    &__container {
      color: $color-white;
      display: flex;
      margin-bottom: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    &__item {
      align-items: center;
      display: flex;
      font-size: 14px;
      margin-right: 10px;

      svg {
        height: 15px;
        margin-right: 5px;
        width: 15px;
      }

      path {
        fill: $color-white;
      }
    }

    &__legend {
      align-items: center;
      bottom: 15px;
      color: $color-white;
      display: flex;
      font-size: 14px;
      margin-top: 5px;
      position: absolute;

      &-emoticon {
        background-color: $color-white;
        border-radius: 20px;
        display: flex;
        margin-right: 5px;
        padding: 5px;

        svg {
          height: 16px;
          width: 16px;
        }
      }

      &-points-emoticon {
        margin-left: 15px;
      }
    }

    &__points {
      font-size: 12px;
      margin-left: 5px;
    }
  }

  .chq-btn {
    align-items: center;
    border: 0;
    border-radius: 20px;
    bottom: 15px;
    display: flex;
    font-size: 16px;
    padding: 7px 14px;
    position: absolute;
  }
}
