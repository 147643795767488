@import '../common';

.button {
  @include border-radius;
  background-color: $color-primary-green;
  color: $color-white;
  cursor: pointer;
  padding: 10px 15px;

  transition: background-color .5s, color .5s;

  &:focus,
  &:hover,
  &:active {
    background-color: $color-primary-green;
    color: $color-white;
    outline: none;
    text-decoration: none;
  }

  &:focus {
    outline: $color-outline auto 1px;
  }

  i.fa {
    margin-right: 5px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: .6;
  }
}

button.button {
  border: 0;
  box-shadow: none;
}

button.link {
  background: none;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  margin-left: 15px;
  padding: 0;
  white-space: nowrap;
}

span.link,
button.link,
a.link {
  color: $color-link-blue;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  transition: opacity .5s;

  &.action {
    border: 0;
    border-radius: 5px;
    padding: 4px 10px;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      background-color: $color-border;
    }

    &.active {
      background-color: $color-border;
      color: $color-link-blue;
    }

    &.dark {
      color: $color-primary-font;

      svg path {
        fill: $color-primary-font;
      }
    }

    svg {
      height: 20px;
      margin-bottom: -4px;
      margin-right: 2px;
      width: 20px;

      path {
        fill: $color-link-blue;
      }
    }
  }
}

button.link-ai {
  &.action {
    svg {
      path {
        fill: $color-primary-font;
      }
    }
  }
}
