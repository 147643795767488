@import '../common';

.SurveyTable {
  .SurveyTableRow__title {
    max-width: 250px;
  }

  .actions-cell {
    position: relative;
    text-align: right;
  }

  &__date {
    color: $color-primary-light-font;
    font-size: $font-size-nav;
  }
}

.SurveyManage {
  .actions-cell {
    width: 190px;

    .chq-btn,
    .Button {
      margin-right: 35px;
    }
  }
}
