@import '../common';

.Event__Analytics {
  margin: 15px 0;
  padding: 0 2em;

  .NumberBlock {
    border-radius: 5px;
    height: 130px;
    overflow: hidden;
    padding: 5px 15px 15px;


    &:first-child {
      border: 1px solid $color-primary-light-font;
    }

    h2,
    h3 {
      color: $color-primary-font;
    }

    h3 {
      border-bottom: 2px solid $color-primary-light-font;
      font-size: $font-size-large;
      padding-bottom: 10px;

    }

    h2 {
      font-size: $font-size-30;
      font-weight: $font-weight-light;
      margin-top: 5px;
    }
  }
}
