.ChildPrompts {
  background-color: $color-white;
  border-radius: $size-border-radius;
  box-shadow: $box-shadow-default;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  padding: 20px;
  position: relative;

  &-Title {
    color: rgba($color-primary-font, .7);
    font-size: $font-size-normal;
    font-weight: $font-weight-heavy;
    margin: 0;
  }

  &__QuickOptionWrapper {
    display: flex;
    position: relative;
    width: 100%;

    .QuickOptions {
      right: -18px;
      top: 28px;
    }
  }

  &__Wrapper {
    border-radius: 10px;
    margin-top: 15px;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: calc(100% - 32px);

    &.just_images {
      background: linear-gradient(180deg, rgba(170, 140, 48, 1) 0%, $color-primary-yellow 100%);
    }

    &.just_videos {
      background: linear-gradient(180deg, rgba(78, 109, 135, 1) 0%, $color-primary-blue 100%);
    }

    &.all {
      background: linear-gradient(180deg, $color-primary-blue 0%, $color-primary-yellow 100%);
    }

    &.no_options {
      width: 100%;
    }
  }

  &__DisabledOverlay {
    background-color: $color-dark-blue-alpha-80;
    cursor: not-allowed;
    height: 100%;
    left: 0;
    opacity: .5;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__Container {
    align-items: center;
    background-color: $color-white;
    border: 1px dashed $color-border-darker;
    border-left: 6px solid transparent;
    border-radius: 10px;
    color: $color-primary-font;
    display: flex;
    justify-content: space-between;
    margin-left: 6px;
    padding: 15px 15px 15px 9px;
    position: relative;
    width: calc(100% - 6px);

    &:hover {
      border-style: solid;
      box-shadow: inset 0 1px 1px rgba($color-black, .075), 0 0 8px $search-bar-shadow;
    }

    &-Title {
      font-size: $font-size-large;
      margin: 0 0 15px;
      text-align: left;

      @media only screen and (min-width: $screen-sm) {
        margin: 0;
      }
    }

    &-Left {
      align-items: center;
      display: flex;
      width: 100%;

      @media only screen and (min-width: $screen-sm) {
        width: initial;
      }
    }

    &-Icon {
      align-items: center;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      margin-right: 15px;

      path {
        fill: $color-white;
      }
    }

    &-Users {
      align-items: center;
      display: flex;
      margin-left: 15px;

      .chq-tmb {
        @include square(35px);
        margin-left: -10px;
      }

      &--Empty {
        align-items: center;
        background-color: $color-background-darker;
        border-radius: 20px;
        display: flex;
        padding: 7px 15px;
        white-space: nowrap;

        svg {
          @include square(18px);
          margin-right: 5px;
        }
      }
    }

    &.just_images {
      &:hover {
        box-shadow: inset 0 1px 1px rgba($color-black, .075), 0 0 8px rgba($color-primary-yellow, .5);
      }
    }

    &.just_videos {
      &:hover {
        box-shadow: inset 0 1px 1px rgba($color-black, .075), 0 0 8px rgba($color-primary-blue, .5);
      }
    }

    &.all {
      &:hover {
        box-shadow: inset 0 1px 1px rgba($color-black, .075), 0 0 8px rgba($color-primary-blue, .5);
      }
    }
  }

  &-Mobile {
    &__Container {
      width: 100%;
    }

    &__Footer {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }
}

.AnswerPromptsDisabledTooltip {
  .chq-ttp {
    .chq-ttp--bbl {
      bottom: -40px;
      left: 50% !important;
      top: calc(100% + 16px) !important;
      transform: translate(-50%);

      .chq-ttp--tri {
        bottom: 20px;
        left: 47% !important;
      }
    }
  }
}

.ChildPrompts__Tag {
  margin-left: 5px;

  &.chq-tag-bk {
    background-color: #000;
  }
}
