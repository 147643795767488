@import '../common';

.Widget {
  .tags-container {
    display: block;
    font-weight: normal;
    margin-top: 20px;
    position: relative;
    z-index: 0;

    .share-with-field  {
      .chq-ffd--sl {
        border-bottom: 2px solid $color-border;
        color: initial;
        font-size: initial;
        margin: 0;
        z-index: initial;

        &--place {
          color: $color-primary-font;
        }

        &--opts {
          margin-left: 0;
        }
      }

      .chq-ffd--ctrl {
        margin-bottom: 0;
      }
    }

    .Select-Tags__Container {
      border: 0;
      position: initial;
      z-index: initial;

      &:hover {
        box-shadow: inset 0 1px 1px $color-form-inset;
      }

      &:focus {
        border-bottom: 2px solid #79b17d;
      }

      .chq-ffd--lb {
        position: relative;
      }

      .share-with-field {
        margin-bottom: 5px;
      }
    }
  }

  .chq-btn {
    margin-top: 20px;
  }

  &__explanation {
    color: $color-secondary-font;
    margin-top: 10px;
  }
}
