.EmbedStory {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: -20px;
  margin-top: -90px;

  @media (min-width: $screen-md) {
    align-items: unset;
  }

  &.gallery-lightbox--Story {
    margin-top: -90px;
  }

  &__Container {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;

    &-Overlay {
      background: rgba(0, 0, 0, .9);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .slider-arrow-left {
      margin: 0 15px;
    }

    .media {
      height: 100vh;
      max-width: 100%;
      object-fit: contain;
      width: initial;
      z-index: 1;
    }

    .background-container {
      bottom: 0;
      display: flex;
      flex-direction: column;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;

      &-shadow {
        background-color: rgba(0, 0, 0, .6);
        overflow: scroll;
      }

      .Story {
        &__creator {
          background: linear-gradient(180deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .5), rgba(0, 0, 0, .3), rgba(0, 0, 0, .1), rgba(0, 0, 0, 0));
          color: $color-white;
          display: flex;
          font-size: 1em;
          justify-content: space-between;
          padding: 15px 15px 30px;

          &-container {
            align-items: center;
            display: flex;
          }

          .chq-tmb {
            @include square(45px);
            margin-right: 10px;
            min-width: 45px;
          }

          &-text {
            display: flex;
            font-weight: $font-weight-heavy;
            margin: 0;
          }

          &-title {
            -webkit-box-orient: vertical; // sass-lint:disable-line no-vendor-prefixes
            -webkit-line-clamp: 1; // sass-lint:disable-line no-vendor-prefixes
            display: -webkit-box; // sass-lint:disable-line no-vendor-prefixes
            font-weight: $font-weight-normal;
            margin-top: 3px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &__question {
          color: $color-white;
          font-size: 1.5em;
          margin: 0 15px 15px;
          padding: 0;
        }

        &__more {
          margin: 15px;
          text-align: left;
        }

        &__link {
          path {
            fill: $color-white;
          }
        }
      }

      .chq-edi-op {
        color: $color-white;
        overflow: hidden;
        padding: 0 15px;
        text-align: left;
      }
    }

    .play-icon {
      @include square(40px);
      left: calc(50% - 20px);
      position: absolute;
      top: calc(50% - 20px);
      z-index: 1;

      path {
        fill: $color-white;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .Story {
      &__response {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, .1), rgba(0, 0, 0, .3), rgba(0, 0, 0, .5), rgba(0, 0, 0, .7));
        bottom: 0;
        color: $color-white;
        left: 0;
        padding: 30px 15px 15px;
        position: absolute;
        right: 0;
        z-index: 1;

        @media (max-height: 300px) and (max-width: 300px) {
          display: none;
        }
      }

      &__question {
        color: $color-white;
        font-size: 1.5em;
        margin: 0 0 5px;
        padding: 0;
      }

      &__answer {
        font-size: 1em;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media (max-height: 500px) and (max-width: 500px) {
          display: none;
        }
      }

      &__more {
        color: $color-white;
        font-size: 1em;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .container-lightbox-iframe {
    height: 100vh;
    max-width: 100%;
    object-fit: fill;
    overflow: hidden;
    width: auto;

    .slider-arrow {
      @include square(35px);
      background: rgba(255, 255, 255, .8);
      border: 0;
      border-radius: 30px;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, .5);
      padding: 6px;
      position: absolute;
      top: calc(50% - 28px);
      z-index: $z-index-low;

      &-right {
        padding-right: 4px;
        right: 10px;
      }

      &-left {
        left: 10px;
        padding-left: 2px;
      }
    }

    .image-slider-iframe {
      border-radius: 0;
      display: none;
      margin-bottom: 0;

      &.active {
        display: flex;
        justify-content: center;

        @media only screen and (min-width: $screen-sm) {
          justify-content: flex-start;
        }
      }

      @media only screen and (min-width: $screen-sm) {
        border-radius: $size-border-radius;
      }
    }
  }
}

.EmbedStory--Iframe {
  .EmbedStory__Container {
    .media {
      width: 100%;

      &:fullscreen {
        object-fit: contain;
      }
    }
  }

  &.Intuitive,
  &.Verizon,
  &.Assurant {
    .EmbedStory__Container {
      .media {
        width: auto;
      }
    }
  }
}
