.Template {
  background-size: cover;
  border-radius: $size-border-radius;
  height: 18vw;
  margin: 10px 0;
  width: 100%;

  &-Title {
    font-size: $font-size-normal;
    margin: 0 0 10px;
  }

  &-Modal {
    border-radius: $size-border-radius;
    width: 100vw;

    @media only screen and (min-width: $screen-sm) {
      width: 80vw;
    }

    .chq-pan--bd {
      display: flex;
      overflow: hidden;
      padding: 0;
      position: relative;
    }
  }

  &-Video {
    background-color: transparent;
    border-radius: $size-border-radius;
    max-height: 80vh;
    width: 100%;
    z-index: $z-index-medium;
  }

  &-Image {
    border-radius: $size-border-radius;
    margin: 0 auto;
    max-height: 80vh;
    width: auto;
    z-index: $z-index-medium;
  }

  &-BgImage {
    background-size: cover;
    filter: blur(2px);
    height: 10%;
    left: 50%;
    opacity: .5;
    position: absolute;
    top: 50%;
    transform: scale(11);
    width: 10%;
  }
}
