@font-face { // sass-lint:disable-block no-duplicate-properties
  font-family: 'Segoe UI';
  src: url('/fonts/segoe_sans/Segoe-sans.woff') format('woff');
  src: url('/fonts/segoe_sans/Segoe-sans.ttf') format('truetype');
}

$color-microsoft-light-purple: #8661c5;
$color-microsoft-light-green: #d4ec8e;
$color-microsoft-dark-blue: #091f2c;
$color-microsoft-purple: #5c1b86;
$color-microsoft-white: #fff;

.microsoft-nav {
  &.chq-nav {
    background-color: $color-microsoft-light-purple;

    .chq-nav {
      &--search {
        input {
          background-color: $color-microsoft-purple;
        }
      }

      &--items {
        a {
          border-color: $color-microsoft-light-purple;

          &.active {
            border-color: $color-microsoft-light-green;
            color: $color-microsoft-light-green;

            path {
              fill: $color-microsoft-light-green;
            }
          }
        }
      }

      &--notit {
        em {
          background-color: $color-microsoft-light-green;
          color: $color-microsoft-dark-blue;
        }
      }

      &--admint {
        .Notification-Badge {
          background-color: $color-microsoft-light-green;
          color: $color-microsoft-dark-blue;
        }

        &:hover {
          background-color: $color-microsoft-purple;
        }
      }

      &--unseen {
        border-color: $color-microsoft-light-green;
      }
    }
  }
}

.microsoft {
  font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-microsoft-dark-blue;
    font-weight: 600;
    text-transform: capitalize;
  }

  a {
    color: $color-microsoft-purple;

    &:hover {
      color: $color-microsoft-light-purple;
    }
  }

  .QuickOptions {
    .Gray-Button {
      background-color: rgba($color-microsoft-purple, .9);
      color: $color-microsoft-white;

      svg {

        path {
          fill: $color-microsoft-white;
        }
      }
    }

    .dropdown-menu {
      .chq-btn {
        color: $color-microsoft-purple;

        svg {
          path {
            fill: $color-microsoft-purple;
          }
        }

        &:hover {
          color: $color-microsoft-purple;

          svg {
            path {
              fill: $color-microsoft-purple;
            }
          }
        }
      }
    }
  }

  .LandingPageCallout--Preview {
    color: $color-microsoft-dark-blue;

    .Preview {

      &-Header__Image {
        background-position: 90%;

        @media only screen and (min-width: $screen-sm) {
          background-position: 50%;
        }
      }

      .Preview-Tags {
        h2 {
          border-radius: 12%;
          color: $color-microsoft-dark-blue;
          text-transform: capitalize;
        }
      }

      .back-arrow {
        color: $color-microsoft-purple;

        &:hover {
          color: $color-microsoft-light-purple;

          svg {
            path {
              fill: $color-microsoft-light-purple;
            }
          }
        }

        svg {
          path {
            fill: $color-microsoft-purple;
          }
        }
      }

      &-Header__Text {
        background-image: none;
        font-size: 42px;
        font-weight: 700;
        margin: 0;

        @media only screen and (min-width: $screen-sm) {
          margin: 0 30px;
        }

        @media only screen and (min-width: $screen-md) {
          margin: 0 8.5%;
        }

        @media only screen and (min-width: $screen-lg) {
          margin: 0 11%;
        }
      }

      &-Tags {

        .chq-bdg {
          color: $color-microsoft-purple;

          svg {
            path {
              fill: $color-microsoft-purple;
            }
          }

          &.Selected {
            background-color: $color-microsoft-purple;
            color: $color-microsoft-white;

            path {
              fill: $color-microsoft-white;
            }
          }
        }

        .Tags__title {
          h2 {
            color: $color-microsoft-dark-blue;
            font-weight: 600;
            text-transform: capitalize;
          }
        }

        .chq-ttp {
          svg {
            path {
              fill: $color-microsoft-purple;
            }
          }
        }

        .Section__highlights {
          a {
            color: $color-microsoft-purple;

            &:hover {
              color: $color-microsoft-light-purple;
            }
          }
        }
      }
    }
  }

  .quote-icon {
    path {
      fill: $color-microsoft-purple;
    }
  }

  .gallery-lightbox__creator-information {
    span {
      color: $color-microsoft-dark-blue;
    }
  }

  &.gallery-lightbox--Story {
    .FeedItem__preview__title__Story {
      .Title {
        color: $color-microsoft-dark-blue;
      }

      .Accordian__title--icon__container {
        path {
          fill: $color-microsoft-purple;
        }
      }

      .Subprompt {
        &-Container {
          color: $color-microsoft-dark-blue;
        }

        &-Arrow {
          path {
            fill: rgba($color-microsoft-purple, .4);
          }
        }
      }
    }

    .chq-ffd-tags {
      .chq-bdg {
        color: $color-microsoft-purple;

        svg {
          path {
            fill: $color-microsoft-purple;
          }
        }
      }
    }
  }

  &.LandingPageUserCallout {
    .Preview {
      &-Header__Overlay {
        background: none;
        justify-content: flex-start;
      }

      &-Header__Text {
        color: $color-microsoft-dark-blue;
      }
    }
  }

  &.LandingPageHomeCallout {
    .Preview {
      &-Header__Overlay {
        background: none;
        justify-content: flex-start;
      }

      &-Header__Text {
        color: $color-microsoft-dark-blue;
      }
    }
  }

  .MicrosoftButton {
    align-items: center;
    background-color: rgba($color-microsoft-purple, .9);
    border: 0;
    bottom: 15px;
    color: $color-microsoft-white;
    display: flex;
    font-weight: $font-weight-normal;
    outline: 0;
    padding: 6px 16px;
    position: absolute;
    right: 15px;
    z-index: $z-index-medium;

    &:hover {
      border: 0;
      padding: 6px 16px;
    }

    path {
      fill: $color-microsoft-white;
    }

    svg {
      margin: 0 0 0 5px;
      transform: rotateY(180deg);
    }
  }
}
