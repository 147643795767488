@import '../common';

.TakeSurveyModal {
  max-width: 95vw;
  min-width: 50vw;

  .chq-pan--bd {
    position: relative;
  }

  .BadgeIcon {
    left: 30px;
    position: absolute;
    top: 30px;
    @media only screen and (max-width: $screen-sm) {
      display: block;
      left: auto;
      margin-top: 15px;
      position: relative;
      top: auto;
    }
  }

  .SurveyPage,
  .ConfirmEventAttendance {
    padding-left: 100px;

    @media only screen and (max-width: $screen-sm) {
      padding-left: 0;
    }
  }

  .button.submit {
    font-size: $font-size-normal;
    padding: 8px 35px;
  }

  .info {
    color: $color-secondary-font;
    font-size: $font-size-small;
    margin-bottom: 15px;
    width: 100%;
  }
}
