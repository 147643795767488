.ApproveForSocial {
  border-radius: $size-border-radius;
  max-width: 1400px;
  width: 90vw;

  .chq-pan {
    &--hd {
      background: $linkedin-gradient;
      border-radius: $size-border-radius $size-border-radius 0 0;

      h2 {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .social-icon {
        margin-right: 10px;
        width: 24px;
      }
    }

    &--bd {
      display: flex;
      padding: 15px 24px;

      .Form-Data {
        position: relative;

        &.Blurred {
          filter: blur(5px);
        }

        h4 {
          margin-top: 20px;
        }

        .row {
          margin-top: 10px;
        }

        .chq-ffd--ctrl {
          font-weight: 400;
          padding-right: 25px;
        }

        .chq-ffd--sl--opts {
          z-index: $z-index-high;
        }
      }

      .TipContainer,
      .chq-wrn {
        align-items: center;
        border-radius: 0 0 $size-border-radius $size-border-radius;
        display: flex;
        margin-top: -66px;
        position: absolute;
        width: 100%;
      }

      .chq-wrn {
        margin-top: 0;
        position: relative;
      }

      .Body_Header {
        align-items: center;
        display: flex;
        padding-bottom: 15px;

        &--Information {
          display: flex;
          flex-direction: column;

          &_Creator {
            font-weight: $font-weight-heavy;
          }

          &_Title {
            color: $color-secondary-font;
          }
        }

        .social-icon {
          @include square(45px);
        }

        .chq {
          &-ffd {
            margin-right: 10px;
            margin-top: 0;

            &--sl--icon {
              margin-top: 7px;
              max-width: initial;
              padding-left: 20px;
            }

            &--ctrl {
              border: 2px solid $color-secondary-font;
              border-radius: 30px;
              color: $color-primary-font;
              font-weight: $font-weight-normal;
            }
          }

          &-tmb {
            height: 48px;
            margin-right: 15px;
            min-width: 48px;
            width: 48px;
          }
        }
      }

      .tags-container {
        display: block;
        margin-top: 20px;
        position: relative;
        z-index: initial;

        .legend {
          font-size: $font-size-normal;
          font-weight: $font-weight-normal;
        }

        .share-with-field  {
          .chq-ffd--sl {
            border-bottom: 2px solid $color-border;
            color: initial;
            font-size: initial;
            margin: 0;
            z-index: initial;

            &--place {
              color: $color-primary-font;
              font-weight: $font-weight-normal;
            }

            &--opts {
              margin-left: 0;
            }
          }

          .chq-ffd--ctrl {
            margin-bottom: 0;
          }
        }

        .Select-Tags__Container {
          border: 0;
          margin-top: 5px;
          position: initial;
          z-index: initial;

          &:hover {
            box-shadow: inset 0 1px 1px $color-form-inset;
          }

          &:focus {
            border-bottom: 2px solid #79b17d;
          }
        }
      }

      .sharing-modal {
        .chq-edi--ct {
          font-family: inherit;
          outline: none;
          width: initial;
        }
      }

      .sharing-with-container {
        align-items: center;
        color: $color-link-blue;
        display: flex;
        font-size: $font-size-nav;
        font-weight: $font-weight-heavy;
        margin-top: 15px;

        svg {
          height: 20px;
          margin-right: 5px;
          width: 20px;

          path {
            fill: $color-link-blue;
          }
        }
      }

      .nav {
        background-color: transparent;
        border: 2px solid $color-border;
        border-radius: $size-border-radius $size-border-radius 0 0;
        margin-bottom: 0;
        margin-top: 15px;
        padding: 0;

        .chq-pbn {
          align-items: center;
          color: $color-secondary-font;
          display: flex;
          font-weight: $font-weight-normal;
          padding: 15px;

          &.LinkedIn {
            align-items: flex-start;
          }

          &.active {
            border-bottom: 4px solid;
          }
        }

        .social-icon {
          @include square(18px);
          margin-right: 10px;

          &.image {
            @include square(22px);
          }

          path {
            fill: $color-secondary-font;
          }
        }
      }

      .Disclaimer {
        align-items: center;
        display: flex;
        font-size: $font-size-nav;
        margin-bottom: 20px;
        margin-top: 10px;
        padding-left: 6px;

        .social-icon {
          @include square(20px);
          margin-right: 5px;
        }
      }

      .textarea {
        border: 2px solid $color-border;
        border-radius: 0 0 $size-border-radius $size-border-radius;
        border-top: 0;
        resize: none;

        textarea {
          font-weight: $font-weight-normal;
          min-height: 240px;
          resize: none;

          &:read-only {
            opacity: .6;

            &:hover {
              cursor: not-allowed;
            }
          }
        }

        .chq-ffd--ctrl {
          border: 0;
          box-shadow: none;
        }
      }

      .gallery-lightbox__photo {
        height: initial;
        margin-top: 12px;
        min-height: initial;

        &--image {
          max-width: 100%;
        }
      }

      .Linkedin-Share__Container {
        border: 1px solid $color-border;
        border-radius: $size-border-radius;

        &-Header {
          padding: 12px 8px 0;

          .share-message {
            margin-bottom: 0;
          }
        }
      }

      .Linkedin_Creator {
        align-items: center;
        display: flex;
        margin-bottom: 8px;

        &--Information {
          display: flex;
          flex-direction: column;

          &_Creator {
            font-size: $font-size-nav;
            font-weight: $font-weight-heavy;
          }

          &_Title {
            color: $color-secondary-font;
            font-size: $font-size-12;
            font-weight: $font-weight-normal;
          }
        }

        .chq {
          &-ffd {
            margin-right: 15px;
            margin-top: 0;

            &--sl--icon {
              margin-top: 7px;
            }
          }

          &-tmb {
            height: 40px;
            margin-right: 8px;
            min-width: 40px;
            width: 40px;
          }
        }
      }

      .chq-ldr {
        left: 0;
        position: absolute;
        right: 0;
      }
    }

    &--ft {
      align-items: center;
      display: flex;
      justify-content: end;

      .scheduleat-time {
        align-items: center;
        display: flex;
        margin-right: 10px;

        svg {
          margin-right: 5px;
        }
      }

      .chq-abn {
        background-color: $color-linkedin-blue;
        border-radius: 20px;
        color: $color-white;
        padding: 5px 20px;

        &:disabled {
          cursor: not-allowed;
          opacity: .5;
        }
      }
    }
  }
    }

.Input {
  border-right: 2px solid $color-border;
  margin-right: 45px;
  padding-left: 0;
  padding-right: 45px;

  .chq-cmk {
    color: $color-primary-font;
  }

  .LinkedinOptions {
    align-items: center;
    border-bottom: 2px solid $color-primary-green;
    border-radius: $size-border-radius;
    display: flex;
    padding: 0 15px;

    &--Information {
      display: flex;
      flex-direction: column;

      &_Creator {
        font-weight: $font-weight-heavy;
      }

      &_Title {
        color: $color-secondary-font;
      }
    }

    .Subprompt-Arrow {
      margin-bottom: 10px;
      margin-right: 9px;
      transform: scaleX(-1);

      path {
        fill: $color-border-darker;
      }
    }

    .chq {
      &-ffd {
        margin-right: 10px;
        margin-top: 0;

        &--sl--icon {
          margin-top: 7px;
          max-width: initial;
          padding-left: 20px;
        }

        &--ctrl {
          border: 2px solid $color-border;
          border-radius: 30px;
          color: $color-primary-font;
          font-weight: $font-weight-normal;
        }
      }

      &-tmb {
        height: 48px;
        margin-right: 15px;
        min-width: 48px;
        width: 48px;
      }
    }
  }

  .FieldLabel {
    color: $color-primary-font;
  }
}

.chq {
  &-ffd {
    &--ctrl {
      font-weight: $font-weight-normal;
      padding: 6px 12px;
    }
  }
}

.multiselector {
  .chq-ffd--ctrl {
    padding-left: 1px;
  }
}

.navigation-box {
  align-items: center;
  bottom: 0;
  display: flex;
  position: absolute;
  z-index: $z-index-medium;

  .chq-pag {
    margin: 0 0 20px;
  }

  .chq-icon-pag .chq-pag--bn-nav {
    display: none;
  }
}

.Share-Select {
  .chq-ffd--ctrl {
    &:focus {
      border-bottom: 0;
    }
  }

  &.shareImmediately {
    .chq-ffd--ctrl {
      border-bottom: 0;
    }
  }
}

.Preview {
  max-height: 100vh;
  overflow: scroll;
  padding: 2px;

  &-Title {
    margin-bottom: 20px;
    margin-top: 0;
  }

  .SocialMedia-Preview {
    border: 1px solid $color-border;
    border-radius: 10px;
    box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);
    margin-bottom: 20px;
    position: relative;

    &-Tiktok {
      .Body_Header {
        align-items: flex-start;
      }

      .MainContainer {
        padding: 0 15px;
      }

      .Thumbnail {
        margin-right: 0;
      }

      .Preview-area {
        padding: 0;
      }

      .gallery-lightbox__photo {
        border-radius: $size-border-radius;
        height: calc(450px + (100vw - 768px) / 1152 * 100);
      }

      .Tiktok-Buttons {
        bottom: 15px;
        padding: 0;
        right: 15px;
      }
    }

    &-Instagram {
      padding-bottom: 15px;
    }

    .gallery-lightbox__main-image,
    .gallery-lightbox__photo--image {
      max-height: 600px;
    }
  }

  &-User {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 15px 15px 0;
    position: relative;

    &__Info {
      color: $color-secondary-font;
      font-weight: $font-weight-normal;
      margin-bottom: 0;

      &-Container {
        align-items: center;
        display: flex;
      }

      &-Name {
        color: $color-primary-font;
        font-weight: $font-weight-heavy;
      }
    }

    .chq-ttp {
      position: absolute;
      right: 30px;
      top: 15px;

      &--bbl {
        left: -120px !important;
        top: calc(100% + 10px) !important;
        width: 170px;
      }

      &--tri {
        left: 125px;
      }
    }
  }

  &-area {
    font-weight: $font-weight-normal;
    max-height: 200px;
    overflow: scroll;
    padding: 0 15px;
    white-space: pre-line;

    .Preview-User__Info-Name {
      margin-right: 15px;
    }
  }

  .Preview-Actions {
    display: flex;
    justify-content: space-around;
    padding: 4px 8px;
    position: relative;

    .chq-pbn {
      align-items: center;
      color: $color-primary-font;
      display: flex;
      padding: 10px 8px;

      svg {
        margin-right: 4px;
      }

      path {
        fill: $color-primary-font;
      }

      &:hover {
        cursor: initial;
      }
    }

    &__Facebook {
      color: $color-primary-font;
      font-weight: $font-weight-heavy;

      .chq-pbn {
        color: $color-secondary-font;
      }
    }

    .Linkedin-Reactions__Btn {
      .Linkedin-Reaction {
        @include square(22px);
        margin-bottom: 2px;
        margin-right: 4px;
      }

      .Linkedin-Reactions__Container {
        .Linkedin-Reaction {
          @include square(36px);
        }
      }
    }
  }

  .Instagram-Buttons {
    justify-content: start;

    .chq-pbn {
      margin-left: 0;

      svg {

        path {
          fill: transparent;
        }
      }

      &.like-btn {
        svg {
          path {
            fill: #262626;
          }
        }
      }
    }
  }

  .Tiktok-Buttons {
    position: absolute;

    .chq-pbn {
      padding: 13px;

      svg {
        margin-right: 0;

        path {
          fill: $color-black;
        }
      }
    }
  }
}

.Overlay {
  align-items: center;
  background-color: $color-black-alpha-80;
  border-radius: $size-border-radius;
  bottom: 0;
  color: $color-white;
  display: none;
  flex-direction: column;
  font-size: 20px;
  justify-content: center;
  left: 0;
  padding: 0 15px;
  position: absolute;
  right: 0;
  top: 0;

  &.Display {
    display: flex;
  }

  p {
    text-align: center;
  }

  .Buttons-Container {
    margin-top: 10px;

    .Primary-Button {
      margin-left: 15px;
      padding: 5px 60px;

      &:hover {
        padding: 4px 59px;
      }
    }

    .chq-btn-iv {
      background-color: transparent;
      border-color: $color-white;
      color: $color-white;

      &:hover {
        border-width: 2px;
      }
    }
  }
}

.Preview-Empty {
  .Preview-User {
    &__Info-Container {
      width: 100%;
    }

    &__Title {
      margin-left: 15px;
      width: 100%;

      .Preview-Name {
        width: 40%;
      }
    }

    &__Photo {
      @include square(45px);
      background-color: rgba(0, 0, 0, .06);
      border-radius: 30px;
      min-width: 45px;
    }
  }

  .SocialIcon {
    @include square(45px);
    background-color: rgba(0, 0, 0, .06);
    border-radius: $size-border-radius;
  }

  .Details-Container {
    margin: 15px 15px 0;
  }

  p {
    background-color: rgba(0, 0, 0, .06);
    border-radius: $size-border-radius;
    height: 16px;
    margin-bottom: 9px;
    width: 60%;

    &.Full-Width {
      width: 100%;
    }
  }

  .PreviewActions {
    min-height: 40px;
  }
}

.EditApprovedMessage {
  .chq-pan--bd {
    .textarea {
      border-radius: $size-border-radius;
      border-top: 2px solid $color-border;
    }
  }
}

.SocialMedia-Preview-Tiktok {
  border: 1px solid $color-border;
  border-radius: 10px;
  box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);
  margin-bottom: 20px;
  position: relative;

  .social-icon {
    @include square(45px);
  }

  .chq-tmb {
    @include square(48px);
    margin-right: 15px;
    min-width: 48px;
  }

  .Preview-User__Info-Name {
    font-weight: $font-weight-heavy;
  }

  .Body_Header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    position: relative;
  }

  .MainContainer {
    padding: 0 15px;
  }

  .Thumbnail {
    margin-right: 0;
  }

  .Preview-area {
    padding: 0;
  }

  .gallery-lightbox__photo {
    border-radius: $size-border-radius;
    height: calc(450px + (100vw - 768px) / 1152 * 100);
    min-height: initial;
  }

  .Tiktok-Buttons {
    bottom: 15px;
    padding: 0;
    right: 15px;
  }
}
