.StoryMedia {
  position: relative;

  &-Background {
    background-size: cover;
    bottom: 0;
    filter: blur(2px);
    height: 100%;
    left: 0;
    opacity: .3;
    position: absolute;
    right: 0;
    top: 0;
    transform: unset;
    width: 100%;
  }

  .chq-ffd--bg-img {
    margin-bottom: 0;
  }
}
