@import '../common';

.LinkedinEngageModal {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  .chq-pan {
    &--hd {
      background: $linkedin-gradient;
      border-radius: $size-border-radius $size-border-radius 0 0;
      max-height: 54px;

      h2 {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .social-icon {
        margin-right: 10px;
        width: 24px;
      }
    }

    &--bd {
      border-radius: 0 0 $size-border-radius $size-border-radius;
      display: flex;
      flex: 1;
      justify-content: stretch;
      min-height: initial;
      padding: 0;

      .Story-Image {
        display: flex;
        max-height: initial;
        min-height: initial;
      }

      @media only screen and (min-width: $screen-sm) {
        min-height: 75vh;
      }

      .Linkedin_Creator {
        align-items: center;
        display: flex;
        margin-bottom: 15px;

        &--Information {
          display: flex;
          flex-direction: column;

          &_Creator {
            font-size: $font-size-normal;
            font-weight: $font-weight-heavy;
          }

          &_Title {
            color: $color-secondary-light-font;
            font-size: $font-size-normal;
          }
        }

        .chq {
          &-ffd {
            margin-right: 15px;
            margin-top: 0;

            &--sl--icon {
              margin-top: 7px;
            }
          }

          &-tmb {
            height: 48px;
            margin-right: 8px;
            min-width: 48px;
            width: 48px;
          }
        }
      }

      .Interactions-Container {
        align-items: center;
        display: flex;
        font-size: $font-size-nav;
        font-weight: $font-weight-normal;
        justify-content: space-between;
        margin-left: .4rem;
        padding: 8px 0;

        &__Reactions {
          align-items: center;
          display: flex;
          margin-left: .4rem;
          position: relative;
        }

        .Reactions__Count {
          margin-left: 2px;

          &::after {
            content: '\00b7';
            display: inline;
            font-size: 1.2rem;
            font-weight: 700;
            margin-left: 4px;
          }
        }

        .Comments__Count {
          margin-left: 2px;
        }

        .Linkedin-Reaction {
          @include square(16px);
          margin-left: -.4rem;
        }

        .chq-ttp {
          margin-left: 5px;
          margin-top: 5px;

          svg {
            @include square(16px);
          }

          path {
            fill: $color-primary-font;
          }

          &--bbl {
            font-size: 1em;
            width: 300px;
          }
        }
      }

      .share-message {
        margin-bottom: 8px;
        white-space: pre-wrap;
      }

      .gallery-lightbox__wrapper {
        height: initial;
      }

      .gallery-lightbox__photo {
        align-items: center;
        border-radius: 0 0 0 6px;
        display: flex;
        height: initial;
        justify-content: center;
        margin: 0 auto;
        overflow: hidden;
        padding: 0;
        position: relative;

        &--image {
          border-radius: 0;
          max-width: 100%;
        }
      }

      hr {
        margin: 0;
        width: 100%;
      }

      .gallery-lightbox__details-container {
        border-radius: 0 0 6px;
      }

      .Linkedin-Actions {
        display: flex;
        justify-content: space-around;
        padding: 4px 8px;
        position: relative;

        .chq-pbn {
          align-items: center;
          color: $color-primary-font;
          display: flex;
          padding: 10px 8px;

          svg {
            margin-right: 4px;
          }

          path {
            fill: $color-primary-font;
          }
        }

        .Linkedin-Reactions__Btn {
          .Linkedin-Reaction {
            @include square(22px);
            margin-bottom: 2px;
            margin-right: 4px;
          }

          .Linkedin-Reactions__Container {
            .Linkedin-Reaction {
              @include square(36px);
            }
          }
        }
      }

      .Linkedin-Comments {
        position: relative;

        .chq-ffd--ctrl {
          background-color: $color-border;
          border: 1px solid $color-border;
          border-radius: 30px;
          padding-right: 70px;
        }

        .chq-ffd--rq {
          display: none;
        }

        .chq-btn {
          background-color: $color-linkedin-blue;
          border-radius: 20px;
          margin: 4px;
          position: absolute;
          right: 0;
          top: 5px;

          &:hover {
            border-color: $color-primary-blue;
          }
        }
      }

      .comments {
        .comment-author {
          font-weight: 600;
        }

        .comment-body {
          margin-bottom: 0;
        }
      }

      .chq-ldr {
        background-color: $color-black-alpha-70;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: $z-index-high;
      }

      .scrollable-container {
        .chq-ldr {
          background-color: transparent;
          position: relative;
        }
      }

      .gallery-lightbox__main-video {
        object-fit: revert;
      }
    }
  }

  .chq-spn {
    visibility: visible;
  }
}

.LinkedinEngageMention {
  color: $color-linkedin-blue;
  font-weight: $font-weight-heavy;
}
