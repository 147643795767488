.chq-icon-pag-simple {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0;

  @mixin flex-center {
    align-items: center;
    display: flex;
  }

  @mixin flex-column {
    display: flex;
    flex-direction: column;
  }

  @mixin flex-row {
    display: flex;
    flex-direction: row;
  }

  .flx-row-simple {
    @include flex-row;
    @include flex-center;
  }

  .Nav-simple {
    background-color: $color-secondary-font;
    border: 0;
    border-radius: 10px;
    height: 7px;
    margin: 3px;
    padding: 0;
    width: 7px;

    &:hover {
      cursor: default;
    }

    &[aria-current='true'] {
      background-color: $color-white;
      border-color: $color-white;
      color: $color-white;
    }
  }
}
