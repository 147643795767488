@import '../common';

.SidePanel {
  h1,
  h2 {
    img {
      margin-bottom: 2px;
      margin-right: 7px;
      margin-top: 0;
      width: 18px;
    }

    svg {
      @include square(17px);
      margin-bottom: -2px;
      margin-right: 9px;

      path,
      polygon {
        fill: $color-primary-font;
      }
    }
  }

  &__row {
    margin-bottom: 15px;

    &__date {
      background-color: $color-primary-blue;
      border-radius: 5px;
      color: $color-white;
      left: 15px;
      padding: 3px 0;
      position: absolute;
      text-align: center;
      width: 50px;

      .day {
        font-size: $font-size-large;
      }

      .month {
        font-size: $font-size-normal;
      }
    }

    &__details {
      margin-left: 60px;
    }

    a {
      cursor: pointer;
      text-decoration: none;
    }

    &__time {
      color: $color-primary-light-font;
      margin-top: 2px;
    }
  }
}
