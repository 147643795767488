@import '../common';

.User--Feed {
  .Story {
    width: 100%;

    .chq-edi-op {
      display: none;
    }

    &__answer {
      .chq-edi-op {
        font-size: 20px;
        font-weight: $font-weight-normal;
        max-height: 100%;
        overflow: hidden;
      }

      .chq-btn {
        align-items: center;
        background-color: $color-background-darker;
        border: 0;
        border-radius: 25px;
        color: $color-black;
        display: flex;
        margin-bottom: 6px;
        padding: 7px 25px;

        &:hover {
          border: 0;
        }

        svg {
          height: 16px;
          margin-right: 10px;
          margin-top: -3px;
          width: 16px;
        }

        path {
          fill: $color-black;
        }
      }
    }
  }

  .Stories {
    &__container {
      border-radius: $size-border-radius;
      box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);
      padding: 15px 0;

      @media only screen and (min-width: $screen-xs) {
        background-color: $color-white;
        padding: 15px;
      }
    }

    &__title-container {
      display: flex;
      justify-content: space-between;
      margin: 0 0 20px;

      &__gallery {
        align-items: center;
        background-color: transparent;
        border: 0;
        color: $color-link-blue;
        display: flex;
        font-weight: $font-weight-normal;
        margin: 0;

        &:hover {
          cursor: pointer;
        }

        path {
          fill: $color-link-blue;
        }

        svg {
          margin-right: 5px;
        }
      }

      &__title {
        font-size: 18px;
        margin: 0;
      }
    }

    .Trending-Stories {
      margin-bottom: 15px;
    }

    .PhotoGroup__thumbnail {
      padding: 5px;
      position: relative;

      .story--image {
        border-radius: $size-border-radius;
        display: block;
        margin: 0 auto;
        max-height: 540px;
        max-width: 100%;
        min-height: 220px;
        object-fit: cover;
        width: 100%;
      }

      &:hover {
        cursor: pointer;

        .Story__information {
          &--profile {
            .background-container {
              background-color: rgba(0, 0, 0, .5);

              .chq-edi-op {
                section {
                  color: transparent;
                  text-shadow: 0 0 5px rgb(255, 255, 255);
                }

                svg {
                  filter: blur(3px);
                }
              }

              .chq-bdg {
                color: transparent;
                text-shadow: 0 0 5px rgb(255, 255, 255);
              }

              .Story__question {
                color: transparent;
                text-shadow: 0 0 5px rgb(255, 255, 255);
              }

              .Story__creator {
                span {
                  color: transparent;
                  text-shadow: 0 0 5px rgb(255, 255, 255);
                }

                .chq-tmb {
                  filter: blur(3px);
                }
              }
            }

            .Messages__container,
            .cheers {
              display: none;
            }
          }
        }

        .Story__answer {
          display: block;

          .background-container {
            .chq-edi-op {
              display: block;

              section {
                color: $color-white;
                display: block;
                text-shadow: none;
              }
            }

            .Story__question {
              color: $color-white;
              text-shadow: none;
            }
          }
        }
      }
    }
  }
}

.Stories--Collage {
  .Story {
    width: 100%;

    &__creator {
      position: initial;
    }

    .chq-edi-op {
      margin-top: 10px;
    }

    &__answer {
      .chq-edi-op {
        font-size: 20px;
        font-weight: $font-weight-normal;
        max-height: 100%;
        overflow: hidden;
      }

      .chq-btn {
        align-items: center;
        background-color: $color-background-darker;
        border: 0;
        border-radius: 25px;
        color: $color-black;
        display: flex;
        margin-bottom: 6px;
        padding: 7px 25px;

        &:hover {
          border: 0;
        }

        svg {
          height: 16px;
          margin-right: 10px;
          margin-top: -3px;
          width: 16px;
        }

        path {
          fill: $color-black;
        }
      }
    }
  }

  .Stories {
    &__container {
      border: 1px solid $color-primary-green;
      border-radius: $size-border-radius;
      box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);
      padding: 15px 0;

      @media only screen and (min-width: $screen-xs) {
        background-color: $color-white;
        padding: 15px;
      }
    }

    &__title-container {
      display: flex;
      justify-content: space-between;
      margin: 0 0 20px;

      &__gallery {
        align-items: center;
        background-color: transparent;
        border: 0;
        color: $color-link-blue;
        display: flex;
        font-weight: $font-weight-normal;
        margin: 0;

        &:hover {
          cursor: pointer;
        }

        path {
          fill: $color-link-blue;
        }

        svg {
          margin-right: 5px;
        }
      }

      &__title {
        font-size: 18px;
        margin: 0;
      }
    }

    .Trending-Stories {
      margin-bottom: 15px;
    }

    .PhotoGroup__thumbnail {
      padding: 5px;
      position: relative;

      .story--image {
        border-radius: $size-border-radius;
        max-height: 540px;
        max-width: 100%;
        min-height: 220px;
        object-fit: cover;
        vertical-align: bottom;
        width: 100%;
      }

      &:hover {
        cursor: pointer;

        .background-container {
          box-shadow: 0 0 6px $color-black-alpha-40;
        }
      }
    }
  }
}
