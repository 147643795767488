.WaveformContainer {
  align-items: center;
  background: transparent;
  flex-direction: row;
  justify-content: center;
  min-width: 100%;
  position: relative;

  .Wave {
    background-color: $color-dark-blue;
    border-radius: $size-border-radius;
    height: 26px;
    padding: 3px 0;
    width: 100%;
  }

  .PlayButton {
    left: calc(50% - 11px);
    position: absolute;
    top: -67px;

    path {
      fill: $color-white;
    }
  }

  .close-icon {
    background-color: $color-white;
    border-radius: 15px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .5);
    display: none;
    left: -5px;
    padding: 5px;
    position: absolute;
    top: -5px;

    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    .close-icon {
      display: block;
    }
  }
}
