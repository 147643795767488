@import '../common';

.Filters {
  padding-right: 0;

  @media only screen and (max-width: $screen-lg - 1px) {
    padding-right: 15px;
  }

  .form-control {
    background-color: $color-background;
  }

  &__Applied {
    font-size: $font-size-normal;
    font-weight: $font-weight-normal;
    margin: 0;
  }

  &__Active {
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px 6px;

    &--title {
      font-size: 16px;
      margin: 0 10px 0 0;
    }

    @media only screen and (max-width: $screen-lg - 1px) {
      min-height: auto;
    }

    .tag {
      align-items: center;
      background-color: $color-white;
      border: 1px solid $color-border;
      border-radius: 15px;
      display: flex;
      font-size: $font-size-nav;
      margin-bottom: 5px;
      margin-right: 4px;
      padding: 5px 13px 5px 15px;
      width: auto;

      .small {
        font-size: $font-size-small;
        margin-left: 3px;
        white-space: nowrap;
      }
    }

    .close {
      display: flex;
      margin-left: 5px;

      svg {
        height: 13px;
        margin-bottom: 1px;
        margin-top: -1px;
        width: 13px;

        path {
          fill: $color-primary-font;
        }
      }
    }
  }

  &__Footer {
    display: flex;
    flex-direction: column;
    padding: 0;

    hr {
      border: 0;
      border-top: 1px solid $color-secondary-light-font;
      margin: 15px;
    }

    &--clear {
      text-align: left;
    }

    &--header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 10px 15px 0;
    }
  }

  &__Results {
    padding: 0 15px 10px;
    text-align: left;

    .results {
      margin-bottom: 0;
    }
  }

  .chq-pan {
    &.open {
      display: block;
    }

    &.closed {
      display: block;
      @media only screen and (max-width: $screen-lg - 1px) {
        display: none;
      }
    }

    .FilterAccordian {
      padding-left: 0;
      padding-right: 0;

      &:first-child {
        @media only screen and (min-width: $screen-md + 1) {
          padding-top: 0;
        }
      }
    }
  }

  &__button {
    &:first-child {
      @media only screen and (max-width: $screen-lg - 1px) {
        padding-right: 6px;
      }
    }

    &:last-child {
      @media only screen and (max-width: $screen-lg - 1px) {
        padding-left: 6px;
      }
    }

    &-wrapper {
      min-height: 55px;
    }

    .button {
      background-color: $color-white;
      border: 1px solid $color-border;
      border-radius: 5px;
      color: $color-primary-font;
      cursor: pointer;
      display: block;
      font-size: $font-size-normal;
      margin-bottom: 10px;
      padding: 10px;
      text-align: center;
      text-decoration: none;
      width: 100%;

      @media only screen and (max-width: $screen-lg) {
        font-size: $font-size-nav;
      }

      &.thumbs-up {
        svg {
          margin-bottom: 2px;
          margin-top: -4px;
        }
      }

      img {
        -ms-interpolation-mode: bicubic; // sass-lint:disable-line no-vendor-prefixes
        margin: 0 7px 1px 0;
        width: 18px;
      }

      svg {
        @include square(19px);
        margin-right: 7px;
        vertical-align: bottom;
      }

      path {
        fill: $color-white;
      }

      &.create {
        background-color: $color-primary-green;
        border: 1px solid $color-primary-green;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, .2);
        color: $color-white;
        margin-bottom: 15px;
      }

      &.toggle-filters {
        display: none;
        @media only screen and (max-width: $screen-lg - 1px) {
          display: block;
        }

        path {
          fill: $color-primary-font;
        }
      }

      &.disabled,
      &:disabled {
        background-color: $color-secondary-light-font;
        border-color: $color-secondary-light-font;
        cursor: not-allowed;

        .chq-ttp--bbl {
          display: none;
        }
      }
    }

    img {
      display: block;
      margin: 0 auto;
      max-height: 90px;
      max-width: 90%;
    }
  }

  .btn {
    padding: 0;
    width: 100%;
  }

  .chq-pan--hd {
    h1 {
      font-size: 1.2em;
      font-weight: $font-weight-light;
      margin: 0;
    }

    @media only screen and (max-width: $screen-md) {
      display: none;
    }
  }

  .ManageUsers__Search {
    margin: 0;

    @media only screen and (max-width: $screen-md) {
      margin-top: 16px;
    }
  }
}
