.FormFieldPlaceholder {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);
  margin-bottom: 15px;
  padding: 15px 15px 6px;
  position: relative;

  p {
    background-color: rgba(0, 0, 0, .06);
    height: 16px;
    margin-bottom: 9px;

    &.FormFieldPlaceholder {
      &__title {
        width: 30%;
      }

      &__text {
        width: 60%;
      }

      &__textarea {
        height: 80px;
        width: 100%;
      }

      &__select {
        height: 30px;
        width: 100%;
      }
    }
  }
}
