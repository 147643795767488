@import '../common';

.add-tag {
  @include border-radius;
  display: block;
  float: right;
  overflow: hidden;
  position: relative;
  transition: opacity .2s;
  transition-delay: 1.5s;

  &-anim {
    opacity: 0;
  }

  &--circle {
    animation: animShrinkCircle forwards .3s;
    border: $color-primary-green solid 40px;
    border-radius: 160px;
    display: block;
    position: absolute;
  }

  &--circle-white {
    border: $color-white solid 40px;
  }

  &--check {
    svg {
      animation: animDisplayCheckmark forwards .3s;
      animation-delay: .3s;
      height: 18px;
      left: 50%;
      opacity: 0;
      position: absolute;
      top: 2px;
      transform: translate3d(-40%, 0, 0);

    }

    path {
      fill: $color-white;
    }
  }

  &--check-white {
    svg {
      top: 23%;
    }

    path {
      fill: $color-primary-green;
    }
  }

  .chq-btn {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

@keyframes animShrinkCircle {
  0% {
    @include square(160px);
    left: -46px;
    top: -68px;
  }

  100% {
    @include square(80px);
    left: -7px;
    top: -27px;
  }
}

@keyframes animDisplayCheckmark {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
