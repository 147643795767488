.Admin {
  @media screen and (min-width: 1200px) {
    .work_area {
      margin-left: 270px;

      .chq-btn-pr {
        display: flex;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .work_area {
      margin-left: 0 !important;
      width: 100%;
    }
  }

  .Settings {
    width: 100%;
  }

  .chq-sbar {
    margin: 15px 0;
  }

  .StoriesFilterBar {
    .Select-Tags__Container {
      .share-with-field {
        background-color: $color-background;

        .chq-ffd--lb {
          top: 14px;
        }

        .chq-ffd--sl {
          &--place {
            color: $color-primary-font;
            font-size: $font-size-normal;
            font-weight: $font-weight-light;
          }

          &--match {
            background-color: $color-background;
            font-size: $font-size-normal;
            font-weight: $font-weight-light;
          }

          .chq-ffd--ctrl {
            border: 1px solid $color-border;

            .chq-bdg {
              background-color: $color-white;
              border: 1px solid $color-border;
            }
          }
        }
      }
    }
  }
}
