@import '../common';

.notification-drawer {
  -ms-overflow-style: -ms-autohiding-scrollbar; // sass-lint:disable-line no-vendor-prefixes
  background-color: $color-white;
  bottom: 0;
  min-width: 360px;
  overflow-y: scroll;
  position: fixed;
  right: 0;
  text-align: left;
  top: 0;
  transform: translateX(100%);
  width: 25%;
  z-index: $z-index-medium;

  &-show,
  &-hide {
    animation: animNotificationDrawer .3s ease-in-out forwards;
  }

  &-hide {
    animation-direction: reverse;
  }

  &-show {
    box-shadow: -5px 0 5px 0 rgba(0, 0, 0, .2);
    transform: translateX(0%);

    .notification-drawer__notification {
      animation: animNotificationDrawer .3s forwards;

      @for $nth from 1 through 25 {
        &:nth-child(#{$nth}) {
          animation-delay: $nth * .05s;
        }
      }

      .notification-body {
        -webkit-box-orient: vertical;// sass-lint:disable-line no-vendor-prefixes
        -webkit-line-clamp: 5;// sass-lint:disable-line no-vendor-prefixes
        display: -webkit-box; // sass-lint:disable-line no-vendor-prefixes
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
        word-break: break-word;
      }
    }
  }

  &__header {
    background-color: $color-primary-blue;
    border-bottom: $color-border solid 1px;
    padding: 12px 2rem 13px;
    width: 100%;

    h1 {
      color: $color-white;
      display: inline;
      font-size: $font-size-xl;
      font-weight: $font-weight-normal;
      line-height: 3rem;
      margin: 0;
      padding: 0 1rem;
    }

    svg path {
      fill: $color-white;
    }
  }

  &__empty {
    color: $color-primary-light-font;
    margin: 20px auto;
    text-align: center;
  }

  &__close {
    background-color: $color-primary-blue;
    border: 0;
    position: absolute;
    right: 1rem;
    top: 1.3rem;

    &:focus {
      outline: none;
    }

    svg path {
      fill: $color-white;
    }
  }

  &__bell svg {
    @include square(3rem);
    vertical-align: bottom;

    path {
      stroke: $color-white;
      stroke-width: 5;
    }
  }

  &__notifications h2 {
    background-color: $color-background;
    border-top: $color-border solid 1px;
    color: $color-primary-font;
    font-size: $font-size-normal;
    font-weight: $font-weight-normal;
    margin: 0;
    padding: 7px 2rem 8px;
    text-transform: uppercase;
  }

  &__notification {
    border-top: $color-border solid 1px;
    min-height: 80px;
    padding: 2rem 2rem 1.5rem 8.4rem;
    position: relative;
    transform: translateX(100%);

    .chq-error {
      margin-left: -6.4rem;
      width: calc(100% + 6.4rem);
    }

    .badge-img {
      top: 15px;
    }

    .Thumbnail {
      @include square(4rem);
      left: 2.5rem;
      position: absolute;
      top: 2rem;
    }

    img.img-preview {
      display: block;
      margin-bottom: 5px;
      margin-top: 15px;
      max-width: 100%;
    }

    svg path {
      fill: $color-primary-blue;
    }
  }

  &__timestamp {
    color: $color-primary-light-font;
    font-size: $font-size-small;
    font-weight: $font-weight-light;
  }
}

@keyframes animNotificationDrawer {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}
