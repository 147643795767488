@import '../common';

.EventCard {
  font-size: $font-size-normal;
  margin-bottom: 15px;

  &__Placeholder {
    margin-bottom: 75px;

    .title-block {
      display: none;
    }

    .event-image {
      background-color: $color-border !important;
    }
  }

  .chq-ttp--bbl {
    min-width: 300px;
  }

  .tooltip-rsvp {
    .chq-ttp--bbl {
      min-width: auto;
    }
  }

  &__PlaceholderText {
    background-color: $color-border;
    height: 18px;
    margin-bottom: 15px;
    width: 90%;
  }

  &.cancelled {
    .right-column-wrapper {
      .QuickOptions {
        opacity: 1;
      }

      > div {
        opacity: .55;
      }
    }
  }

  p {
    margin-bottom: 0;
  }

  .Thumbnail {
    height: 30px;
    width: 30px;
  }

  .header {
    align-items: stretch;
    display: flex;

    a {
      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }

    .live-wrapper {
      position: absolute;
      right: 5px;
      top: 10px;
    }

    .live {
      background-color: rgba(#f00, .86);
      border-radius: $size-border-radius;
      color: $color-white;
      font-size: $font-size-15;
      opacity: .9;
      padding: 6px 14px;

      svg {
        height: 8px;
        margin-bottom: 1px;
        width: 8px;

        path {
          fill: $color-white;
        }
      }
    }

    @media only screen and (max-width: $screen-sm) {
      align-items: flex-start;
      flex-direction: column;

      .right-column {
        padding-left: 0 !important;
      }

      .left-column {
        height: 240px;
      }

      .left-column,
      .right-column {
        flex-direction: column;
        min-height: auto !important;
        width: 100% !important;
      }
    }

    .left-column {
      flex-grow: 1;
      width: 55%;

      .event-image {
        background-color: rgba(0, 0, 0, .2);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-top-left-radius: $size-border-radius;
        height: 100%;
        position: relative;
      }

      .title-block {
        background-color: rgba(0, 0, 0, .75);
        bottom: 15px;
        float: left;
        margin-top: 10px;
        min-height: 42px;
        padding: 10px;
        position: absolute;
        width: 100%;
        z-index: $z-index-low;

        a {
          text-decoration: none;
        }

        p {
          &.title {
            color: $color-white;
            font-size: $font-size-normal;
            font-weight: 200;
            margin-bottom: 0;
            margin-top: 0;
            text-align: center;
          }

          &.host {
            font-weight: $font-weight-light;
            margin-bottom: 8px;
          }
        }
      }

      .flag {
        // border: 2px solid red;
        left: 0;
        position: absolute;
        top: 0;
        z-index: $z-index-medium;

        svg {
          fill: $color-primary-green;
          height: 80px;
          width: 80px;
        }

        .points {

          color: $color-white;
          font-size: $font-size-small;
          left: 10px;
          position: absolute;
          text-align: center;
          top: 16px;
          width: 60px;
        }
      }

      .no-flag {
        position: absolute;

        .points {
          color: $color-white;
          font-size: $font-size-small;
          left: 10px;
          position: absolute;
          text-shadow: 1px 1px 4px rgba(0, 0, 0, .6);
          top: 10px;
        }
      }
    }

    .right-column {
      flex-grow: 1;
      min-height: 200px;
      width: 50%;

      h1 {
        padding: 80px 0;
      }
    }
  }

  .section-details {
    padding: 15px;
    position: relative;
    word-wrap: break-word;

    h3 {
      font-size: $font-size-large;
      margin-top: 20px;
    }

    .host {
      color: inherit;
      text-decoration: none;
    }

    .row {
      padding-bottom: 14px;
    }

    .name {
      padding-left: 15px;
      position: absolute;
      width: 70px;
    }

    .value {
      padding-left: 75px;
      padding-right: 15px;
    }
  }

  .section-actions {
    background-color: $color-background;
    border-radius: 0 0 $size-border-radius $size-border-radius;
    border-top: 1px solid $color-border;

    .whos-coming {
      color: $color-secondary-font;
      float: left;
      margin-top: 11px;
      width: 40px;

      @media only screen and (max-width: $screen-lg) {
        margin-top: 20px;
        width: 100px;
      }
    }
  }

  .rsvps-list {
    float: left;
    height: 60px;
    margin-left: 28px;
    padding-left: 10px;

    @media only screen and (max-width: $screen-lg) {
      margin-left: 15px;
    }

    > a {
      @include square(30px);
      float: left;
      margin-left: -10px;
      margin-top: 15px;
    }

    .AttendeesModalButton {
      float: left;
    }
  }

  .rsvp-previews {
    .extra {
      cursor: pointer;
      float: left;
      margin-left: 7px;
      margin-top: 21px;
      text-decoration: none;
    }
  }

  .actions {
    display: flex;
    float: right;
    justify-content: flex-end;

    @media only screen and (max-width: $screen-md) {
      float: none;
    }

    .more-info {
      background-color: $color-primary-green;
      border-radius: $size-border-radius;
      flex-grow: 1;
      float: right;
      margin: 10px 0 10px 10px;
      padding: 10px 27px;
      text-align: center;
      transition: background-color .3s ease-in;
      width: auto;

      &:hover {
        background-color: darken($color-primary-green, 10%);
      }
    }
  }
}

.tags-container {
  align-items: center;
  display: flex;
  z-index: $z-index-high;
}

.tag-wrapper {
  align-items: center;
  border-radius: $size-border-radius;
  color: #fff;
  display: flex;
  font-size: 14px;
  margin: 5px;
  opacity: .9;
  padding: 6px 14px;

  svg {
    height: 18px;
    margin-right: 5px;
    width: 18px;

    path {
      fill: $color-white;
      stroke: $color-white;
      stroke-width: 5;
    }
  }

  span {
    cursor: default;
    font-size: 15px;
    text-decoration: none;
  }
}

.sponsored-wrapper {
  background-color: rgba(121, 177, 125, 1);
}

.private-wrapper {
  background-color: rgba(0, 0, 0, .86);
}

.Event {
  .filter-bar {
    margin-left: 0;
    padding-left: 0;
  }

  .EventCard {
    .right-column-wrapper {
      background-color: $color-white;
      border-radius: 3px;
      box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);

      color: $color-white;

      @media only screen and (min-width: $screen-lg + 1px) {
        min-height: 300px;
      }
    }

    .right-column {
      padding-left: 17px;
      width: 45%;
    }
  }
}
