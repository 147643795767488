.FeedItem__preview__title__Story {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  white-space: initial;

  &.ParentPrompt {
    align-items: flex-start;
    border: 0;
    color: $color-primary-font;
    flex-direction: column;
    font-size: $font-size-22;
    font-weight: $font-weight-heavy;
    padding: 0;

    &.open {
      box-shadow: none;
    }
  }

  &:hover {
    box-shadow: none;
  }

  .Title {
    align-items: center;
    color: $color-primary-font;
    display: flex;
    font-size: 22px;
    font-weight: $font-weight-heavy;
    margin-bottom: 0;
    margin-top: 0;
  }

  .Subprompt {
    &-Container {
      align-items: center;
      color: $color-secondary-font;
      display: flex;
      font-size: $font-size-large;
      font-weight: $font-weight-normal;
      margin-left: 25px;
      margin-top: 5px;

      .PromptIcon {
        @include square(35px);
        margin-right: 10px;
      }
    }

    &-Title {
      align-items: center;
      color: $color-secondary-font;
      display: flex;
      outline: none;
    }

    &-Arrow {
      margin-bottom: 10px;
      margin-right: 9px;
      transform: scaleX(-1);

      path {
        fill: rgba($color-primary-blue, .4);
      }
    }
  }

  a {
    color: $color-primary-font;
  }

  .quote-icon {
    height: 45px;
    margin-right: 18px;
    min-width: 45px;
    width: 45px;

    path {
      fill: $color-primary-blue;
    }
  }

  .Accordian {
    &__body {
      max-height: 150px;

      &.closed {
        max-height: 0;
      }
    }

    &__container {
      width: 100%;
    }

    &__title {
      font-size: $font-size-26;

      &--icon__container {
        background-color: initial;
        height: initial;
        margin-right: 12px;
        padding: 0;

        svg {
          @include square(42px);
          max-width: 42px;
        }

        path {
          fill: $color-primary-blue;
        }
      }
    }

    &__subtitle {
      margin: 0;
    }
  }
}

.story-question {
  border-radius: $size-border-radius;
  display: flex;
  flex-direction: column;
  margin: unset;
  min-height: unset;
  width: 100%;

  .text-container {
    display: flex;
    flex-direction: column;
    padding: unset;

    .form {
      flex-grow: 1;

      .chq-ffd {
        margin-top: 0;
        position: relative;
        z-index: 40;

        &--lb {
          svg {
            height: 28px;
            width: 28px;
          }

          path {
            fill: $color-primary-blue;
          }
        }

        &--ctrl {
          font-size: 1.5em;
          font-weight: $font-weight-heavy;
          padding-left: 40px;

          &::placeholder {
            color: $color-primary-light-font !important;
          }

          &:focus {
            border-color: $color-primary-blue;
            box-shadow: inset 0 1px 1px $color-form-inset, 0 0 8px $search-bar-shadow;
          }
        }
      }
    }
  }

  .Form-Question {
    &__Prefilled {
      display: none;
    }

    .chq-ffd {
      &--lb {
        position: absolute;
        top: 10px;
        z-index: $z-index-low;
      }

      &--sl--opts {
        max-height: 280px;
        overflow-y: scroll;
      }
    }
  }
}
