@import '../common';

.FacebookInstagramShareModal {
  border-radius: $size-border-radius;
  min-width: 680px;

  .chq-pan {
    &--hd {
      background: $facebook-instagram-gradient;
      border-radius: $size-border-radius $size-border-radius 0 0;

      h2 {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .social-icon {
        margin-right: 10px;
        width: 24px;
      }
    }

    &--bd {
      display: flex;
      flex-direction: column;

      .Main-Container {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .Left-Container {
        align-items: center;
        color: $color-black;
        display: flex;
        flex-direction: column;
        margin-right: 50px;

        p {
          font-size: $font-size-large;
          margin-bottom: 0;
        }

        path {
          fill: $color-black;
        }
      }

      .Arrow {
        @include square(60px);
        transform: scaleY(-1) rotate(15deg);
      }

      .QR-Container {
        padding: 10px;
        width: 192px;

        &.QR-Container-1 {
          margin-left: 30px;
        }

        .social-logo {
          height: 40px;
          margin-bottom: 15px;
          width: 172px;
        }
      }

      .TipContainer {
        path {
          fill: $color-primary-font;
        }
      }
    }
  }
}
