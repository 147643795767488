@import '../common';

.People {
  margin: 0 auto;
  max-width: 1440px;

  &__cards {
    .chq-sbar {
      margin: 0 12.5px;

      input {
        background-color: $color-white;
        border-color: $color-white;
        border-radius: 5px;
        box-shadow: 0 3px 15px 0 rgba($color-black, .12);
        margin-bottom: 15px;
        padding-bottom: 10px;
        padding-top: 10px;

        &:focus {
          border-color: $color-primary-blue;
          box-shadow: inset 0 1px 1px rgba($color-black, .075), 0 0 8px rgba(140, 180, 214, .6);
        }
      }
    }
  }

  &__right-column {
    padding-left: 0;

    .clearfix {
      align-items: flex-start;
      display: flex;

      &.suggested-interests {
        display: block;
      }

      .chq-pbn {
        max-width: 78%;
        text-align: left;
      }
    }

    .chq-btn {
      svg {
        height: 13px;
        margin: 0 -4px -2px -5px;
      }
    }

    .add-tag {
      margin-left: 15px;
    }
  }

  .progress-line_line__1Yy2a {
    margin-top: 9px;
  }

  .form-control {
    background-color: $color-background;
  }
}
