.Seek-Bar { // sass-lint:disable-block no-vendor-prefixes
  -webkit-appearance: none;
  height: auto;
  margin: 10px 0;
  width: 100%;

  &::-webkit-slider-runnable-track {
    background: $color-border;
    border: 0 solid $color-border;
    border-radius: 5px;
    box-shadow: 0 0 0 $color-black;
    cursor: pointer;
    height: 8px;
    width: 100%;
  }

  &::-webkit-slider-thumb {
    @include square(14px);
    -webkit-appearance: none;
    background: $color-white;
    border: 1px solid $color-white;
    border-radius: 10px;
    box-shadow: 0 0 3px $color-black;
    cursor: pointer;
    margin-top: -3.5px;
  }

  &::-moz-range-track {
    background: $color-border;
    border: 0 solid $color-border;
    border-radius: 5px;
    box-shadow: 0 0 0 $color-black;
    cursor: pointer;
    height: 8px;
    width: 100%;
  }

  &::-moz-range-thumb {
    @include square(14px);
    background: $color-white;
    border: 1px solid $color-white;
    border-radius: 10px;
    box-shadow: 0 0 3px $color-black;
    cursor: pointer;
  }

  &::-ms-track {
    background: transparent;
    border-color: transparent;
    color: transparent;
    cursor: pointer;
    height: 8px;
    width: 100%;
  }

  &::-ms-fill-lower {
    background: $color-border;
    border: 0 solid $color-border;
    border-radius: 10px;
    box-shadow: 0 0 0 $color-black;
  }

  &::-ms-fill-upper {
    background: $color-border;
    border: 0 solid $color-border;
    border-radius: 10px;
    box-shadow: 0 0 0 $color-black;
  }

  &::-ms-thumb {
    @include square(14px);
    background: $color-white;
    border: 1px solid $color-white;
    border-radius: 10px;
    box-shadow: 0 0 3px $color-black;
    cursor: pointer;
    margin-top: 1px;
  }

  &:focus {
    outline: none;

    &::-webkit-slider-runnable-track {
      background: $color-border;
    }

    &::-ms-fill-lower {
      background: $color-border;
    }

    &::-ms-fill-upper {
      background: $color-border;
    }
  }

  &:disabled {
    cursor: not-allowed;

    &::-webkit-slider-runnable-track {
      cursor: not-allowed;
    }

    &::-webkit-slider-thumb {
      cursor: not-allowed;
    }

    &::-moz-range-track {
      cursor: not-allowed;
    }

    &::-moz-range-thumb {
      cursor: not-allowed;
    }

    &::-ms-track {
      cursor: not-allowed;
    }

    &::-ms-thumb {
      cursor: not-allowed;
    }
  }
}
