@import '../common';

.event-confirmed {
  max-width: 400px;
  text-align: center;
  width: 95%;

  &--icon {
    animation: fadeIn .5s linear;
    animation-fill-mode: both;

    svg {
      display: block;
      height: 100px;
      margin: 20px auto;
      width: 100px;

      path {
        fill: $color-primary-green;
      }
    }
  }

  h2,
  h3 {
    color: $color-primary-light-font;
    font-weight: $font-weight-light;
    margin: 0 0 15px;
    text-align: center;
  }

  &--link {
    border-bottom: 1px dashed $color-primary-light-font;
    color: $color-primary-light-font;
    cursor: pointer;
    display: inline-block;
    font-size: $font-size-xl;
    margin-bottom: 1em;
    text-align: center;
    text-decoration: none;
  }

  h3 {
    font-size: $font-size-large;
  }

  .action {
    margin-bottom: 30px;
    margin-top: 30px;
    text-align: center;
  }
}

