.TiktokShareModal {
  border-radius: $size-border-radius;

  .chq-pan {
    &--hd {
      background: $tiktok-gradient;
      border-radius: $size-border-radius $size-border-radius 0 0;
    }
  }

  &__Accounts {
    align-items: center;
    display: flex;
    margin-bottom: 15px;

    .Thumbnail {
      @include square(45px);
      margin-right: 15px;
    }

    .chq-ffd {
      margin-bottom: 0;
    }
  }

  .ButtonContainer {
    display: flex;
    justify-content: flex-end;

    .chq-btn {
      background-color: $color-black;
      border-radius: 20px;
    }
  }
}
