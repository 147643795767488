@import '../../styles/common';

.ExpandOptions {
  &__minus-option,
  &__plus-option {
    display: inline-block;
    fill: $color-primary-font;

    svg {
      height: 15px;
      margin-right: 5px;
      width: 15px;
    }

    a,
    span {
      align-items: center;
      color: inherit;
      display: flex;
      font-weight: 500;
      text-decoration: none;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
