@import '../common';

.FeedFilterAccordian {
  position: relative;

  &__body {
    margin-left: -196px;
    max-height: 300px;
    overflow-y: auto;
    transition: max-height 300ms;

    &.closed {
      max-height: 0;
    }

    &.open {
      max-height: 300px;
    }

    input {
      margin-bottom: 8px;
    }

    .chq-pbn {
      font-size: $font-size-normal;
    }
  }

  &__title {
    align-items: center;
    display: flex;
  }

  &__badge {
    align-items: center;
    background-color: $color-primary-green;
    border-radius: 10px;
    color: $color-white;
    display: flex;
    font-size: 10px;
    height: 18px;
    justify-content: center;
    position: absolute;
    right: 2px;
    top: -6px;
    width: 18px;
  }

  ul {
    border: 1px solid $color-border;
    border-radius: 6px;
    list-style: none;
    margin: 10px 0;
    padding: 5px;

    li {
      border-radius: 3px;
      color: $color-link-blue;
      cursor: pointer;
      margin-left: 5px;
      padding: 2px;
      padding-left: 27px;
      position: relative;
      user-select: none;

      &:hover {
        color: darken($color-link-blue, 10%);
      }

      .small {
        color: $color-primary-light-font;
        font-size: $font-size-small;
      }

      svg {
        height: 14px;
        left: 4px;
        margin-bottom: 2px;
        margin-top: 2px;
        position: absolute;
        width: 14px;
      }

      path {
        fill: $color-link-blue;
      }
    }
  }
}
