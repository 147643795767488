@import '../common';

.camera-recorder-modal {
  border-radius: $size-border-radius;
  width: 1080px;

  .prompter {
    background-color: $color-black-alpha-20;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    height: 280px;
    justify-content: space-between;
    left: 18%;
    position: absolute;
    top: 110px;
    user-select: none;
    width: 650px;
    z-index: 1;

    &-header {
      display: flex;
      grid-template-columns: 1fr auto;
      justify-content: flex-end;
      position: absolute;
      right: 0;
      width: 100%;
      z-index: 2;

      svg {
        @include square(15px);
        margin: 5px;

        path {
          fill: $color-white;
        }
      }

      .right-side {
        display: flex;
        justify-content: space-between;
        width: 50%;
      }

      .drag-button {
        align-items: center;
        cursor: grab;
        display: flex;
        justify-content: center;
        margin: 5px;

        svg {
          path {
            fill: $color-border-darker;
          }
        }

        &__grabbing {
          cursor: grabbing;
        }
      }

      .close-button {
        align-items: center;
        background: $color-black;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        margin: 5px;
        opacity: .5;
      }
    }

    &-script {
      // sass-lint:disable-block no-vendor-prefixes
      -ms-overflow-style: none; // IE 10+
      color: $color-white;
      font-size: 60px;
      height: 200px;
      letter-spacing: 2px;
      line-height: 1.8;
      mask-image: linear-gradient(0deg, transparent 0%, $color-nav 20px);
      overflow: -moz-scrollbars-none; // Firefox
      overflow-y: scroll;
      padding: 20px 60px;
      word-spacing: 2.5px;

      &:focus {
        outline: none;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &__placeHolder {
        color: lighten($color-primary-font, 35%);
        cursor: text;

        &::before {
          content: attr(data-placeholder);
        }
      }
    }

    &-menu {
      align-items: center;
      background-color: $color-black-alpha-40;
      border-radius: 12px;
      display: flex;
      justify-content: space-between;

      .icon-left,
      .icon-right {
        width: 140px;
      }

      .icon-right {
        color: $color-white;
        cursor: default;
        display: flex;
        font-size: 20px;
        font-weight: bold;
        justify-content: flex-end;
        margin: 0 20px 0 -20px;

        .disabled {
          cursor: not-allowed;
          opacity: .5;
        }
      }

      .resize-handle {
        @include square(18px);
        background: linear-gradient(135deg, transparent 50%, $color-secondary-font 50%);
        border-radius: 2px;
        bottom: 0;
        cursor: nwse-resize;
        position: absolute;
        right: 0;
      }

      .input-range {
        height: 3px;

        &__label {
          display: none;
        }

        &__slider {
          @include square(12px);
          background: $color-primary-green;
          border-color: $color-primary-green;
          margin-top: -9px;
        }

        &__track {
          &--active {
            background-color: $color-primary-green;
            height: 6px;
          }

          &--background {
            height: 6px;
            top: 0;
          }
        }
      }

      .speed-bar {
        padding: 12px 20px;
        width: 100%;
      }

      svg {
        cursor: pointer;
        margin: 5px 20px;

        path {
          fill: $color-white;
        }
      }
    }
  }

  .Video-Top_Info {
    color: $color-white;
    margin-top: 200px;
    text-align: center;

    &--Counter {
      font-size: 160px;
    }
  }

  .chq-pan--hd {
    border-radius: $size-border-radius $size-border-radius 0 0;
  }

  .Button-Container {
    align-items: center;
    align-self: flex-end;
    display: flex;
    margin-bottom: 30px;

    .Button {
      &-Back {
        flex: 1;
      }

      &-Record {
        flex: 2;
        margin-left: 20px;
      }
    }
  }

  .Video-Preview {
    &__Container {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: 0;
      margin-top: 40px;
      position: absolute;
      right: 0;

      &-Top {
        align-items: center;
        display: flex;
        height: 80px;
        justify-content: center;
        width: 100%;
      }

      .Button-Container {
        display: flex;
        margin-top: 10px;
        position: absolute;
        right: 0;

        &__Regular {
          position: relative;
        }

        .chq-btn {
          font-size: 20px;
          margin-left: 10px;
          padding: 5px 15px;

          &:hover {
            padding: 4px 14px;
          }

          svg {
            @include square(20px);
          }
        }

        .Button-Gray {
          background-color: $color-white;
          border-color: $color-white;
          color: $color-primary-font;

          path {
            fill: $color-primary-font;
          }
        }
      }
    }

    &__Source {
      border-radius: $size-border-radius;
      max-height: 85vh;
      position: relative;

      &-Overlay {
        background-color: $color-black;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }

      &-Video {
        border-radius: 6px;
        max-height: 70vh;
        max-width: 95vw;
        min-height: 50vh;
        width: 100%;
      }
    }
  }

  .chq-ffd--sl--icon {
    margin-top: 5px;
  }

  &-full {
    background-color: transparent;
    box-shadow: none;
    margin: 0 auto;

    .chq-pan--bd {
      padding: 0;
    }

    .Video-Preview__Container {
      margin-top: 15px;
    }
  }

  .Options-Container {
    color: $color-white;
    display: flex;
    margin: 12px 0;
    user-select: none;

    .disabled {
      cursor: not-allowed;
      filter: brightness(.5);
    }

    .inactive {
      filter: brightness(.75);
    }

    &__source .option .icon-container {
      background-color: $color-primary-yellow;
    }

    &__effects .option .icon-container {
      background-color: $color-primary-blue;
    }

    &__source,
    &__effects {
      cursor: pointer;
      display: flex;
      margin: 0 25px;
      position: relative;

      .option {
        align-items: center;
        display: flex;
        flex-direction: column;
        position: relative;

        .icon-container {
          @include square(45px);
          align-items: center;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          margin-bottom: 7px;

          &__icon {
            @include square(25px);

            .light {
              fill: $color-primary-blue;
            }

            path,
            .dark {
              fill: $color-dark-blue;
            }
          }
        }

        &-legend {
          bottom: 0;
          position: absolute;
        }
      }

      .arrow {
        align-items: center;
        display: flex;
        height: 45px;
        justify-content: center;
        position: absolute;
        right: -30px;
        width: 30px;

        .chq-dd--caret {
          border-top-color: $color-primary-yellow;
        }

        .video-effects {
          border-top-color: $color-primary-blue;
        }
      }
    }

    .chq-ffd--sl--opts {
      width: auto;
    }

    .option-menu {
      background-color: $color-white;
      border-radius: 12px;
      bottom: 90px;
      left: -125px;
      max-width: 600px;
      width: max-content;

      &__screen {
        left: -75px;
      }

      &__background {
        left: -250px;
      }

      &__effects {
        left: -280px;
      }

      .Carousel {
        width: 600px;

        &-Btn {
          padding: 11px;
          position: absolute;
          width: auto;

          &__Right {
            right: 5px;
          }

          &__Left {
            left: 5px;
          }
        }

        &-Container {
          height: 100px;
          padding: 0;
        }
      }

      .items-container {
        // img height 74
        align-items: center;
        color: $color-black;
        display: flex;
        padding: 5px 15px;

        .range-bar {
          font-size: 12px;
          padding: 12px 20px;
          width: 100px;

          .input-range {
            height: 3px;

            &__label {
              display: none;
            }

            &__slider {
              @include square(12px);
              background: $color-primary-blue;
              border-color: $color-primary-blue;
              margin-top: -9px;
            }

            &__track {
              &--active {
                background-color: $color-primary-blue;
                height: 6px;
              }

              &--background {
                height: 6px;
                top: 0;
              }
            }
          }
        }

        .effects-item {
          align-items: center;
          color: $color-black;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 0 8px;

          .icon-container,
          .icon-container__active {
            @include square(45px);
            align-items: center;
            border: 2px solid $color-border;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 2px;

            svg {
              @include square(25px);

              path,
              rect {
                fill: $color-dark-blue;
              }
            }

            &__active {
              background-color: $color-primary-blue;

              svg {
                @include square(25px);

                path,
                rect {
                  fill: $color-white;
                }
              }
            }
          }

          &__active {
            border: 2px solid $color-primary-green;
          }
        }

        .background-item {
          align-items: center;
          background-color: $color-background-darker;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 12px;
          color: $color-white;
          display: flex;
          flex-direction: column;
          height: 74px;
          justify-content: center;
          margin: 0 6px;
          min-width: 95px;
          overflow: hidden;
          position: relative;
          width: 95px;

          &__active {
            border: 3px solid $color-primary-blue;
          }

          .video-indicator {
            align-items: center;
            bottom: 8px;
            display: flex;
            height: 15px;
            justify-content: center;
            left: 8px;
            position: absolute;
            width: 22px;

            svg {
              fill: $color-primary-blue;
            }
          }
        }

        .background-item.invert-icon {
          color: $color-black;

          svg {
            transform: scaleY(-1);
          }
        }

        .item,
        .item-image {
          cursor: pointer;
          margin: 0 5px;
        }

        .item {
          @include square(95px);

          &__active {
            rect {
              stroke: $color-primary-blue;
            }
          }
        }
      }

      img {
        @include square(100px);
      }
    }

    .vertical-divider {
      border-left: 1px solid $color-border;
      border-radius: 12px;
      height: 70px;
      margin: 2px 15px;
    }
  }
}
