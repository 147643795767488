.ConfirmEventAttendance {
  margin-bottom: 15px;
  padding: 0 15px;

  h2 {
    color: $color-secondary-font;
    font-size: $font-size-large;
    margin-top: 15px;
  }

  .did-you-attend-date {
    color: $color-secondary-font;
  }

  .button {
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    font-size: $font-size-normal;
    margin-right: 10px;
    margin-top: 15px;
    padding: 10px 32px;
    user-select: none;

    &:hover,
    &.active {
      background-color: $color-primary-green !important;
      color: $color-white;
    }

    &.active {
      svg {
        display: inline-block;
        height: 20px;
        width: 20px;
      }
    }

    svg {
      display: none;
      margin-bottom: -4px;
      margin-left: -10px;
      margin-right: 5px;
      margin-top: 0;

      path {
        fill: $color-white;
      }
    }

    &.primary,
    &.secondary {
      background-color: $color-border;
    }
  }
}
