@import '../common';

.PersonCard {
  margin-bottom: 15px;
  padding: 0 7.5px;

  &__Placeholder {
    .people-list-item {
      .header {
        opacity: .3;
      }

      .profile-photo {
        background-color: $color-white;
        opacity: .2;
      }

      p {
        background-color: rgba(0, 0, 0, .06);
        height: 12px;
        margin-bottom: 10px;
        width: 60%;

        &.name {
          background-color: rgba(0, 0, 0, .06);
          height: 24px;
          width: 88%;
        }
      }

      .interest {
        background-color: rgba(0, 0, 0, .06);
        display: inline-block;
        float: left;
        height: 12px;
        margin-right: 10px;
        width: 48px;
      }
    }
  }

  &--program {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .QuickOptions {
    opacity: 0;
    right: 15px;
    transition: opacity 150ms ease-in-out;

    @media only screen and (max-width: $screen-sm) {
      opacity: 1;
    }
  }

  &:hover {
    .QuickOptions {
      opacity: 1;
    }
  }

  .people-list-item {
    @include border-top-radius;
    background-color: $color-white;
    border: 1px solid $color-border;
    float: left;
    height: 315px;
    overflow: hidden;
    padding: 0 0 25px;
    position: relative;
    width: 100%;
  }

  .header {
    background-color: $color-primary-blue;
    height: 100px;

    .profile-photo {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 130px;
      display: block;
      height: 110px;
      margin: 15px auto;
      width: 110px;
      z-index: $z-index-low;
    }
  }

  .footer {
    bottom: 1em;
    position: absolute;
  }

  hr {
    margin: 10px 0;
  }

  p {
    color: $color-secondary-font;
    margin-bottom: 4px;

    &.name {
      color: $color-link-blue;
      margin-top: 30px;
      white-space: nowrap;

      a {
        font-size: $font-size-normal;
        font-weight: $font-weight-heavy;
        white-space: pre-wrap;
        word-wrap: break-word;
      }
    }
  }

  .person-connected {
    .chq-abn {
      border: 1px solid $color-border-darker;
      border-radius: $size-border-radius;
      color: $color-primary-font;
      font-weight: 200;
      padding: 5px 10px;
      width: 100%;
    }
  }

  .add-tag {
    width: 100%;

    .add-tag--circle-white {
      border: $color-white solid 150px;
      border-radius: 10px;
    }

    .chq-btn {
      width: 100%;
    }
  }
}
