@import '../common';

.Video-Uploader {
  .chq-ffd {
    &--fi {
      overflow: hidden;
    }

    &--ch {
      min-width: 150px;
    }
  }
}
