@import '../styles/common';

.title-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.chq-ffd--sl--icon {
  position: absolute;
}

.chq-bdg {
  background-color: $color-background-darker;
  border: 0;
  border-radius: $size-border-radius;
  color: $color-dark-blue;
  font-weight: $font-weight-heavy;
  margin: 4px 5px 4px 0;
  padding: 5px 12px;
  text-align: left;

  &:hover {
    cursor: initial;
  }

  svg {
    margin-right: 5px;
  }

  path {
    fill: $color-dark-blue;
  }

  &-pr {
    color: $color-dark-blue;

    path {
      fill: $color-dark-blue;
    }
  }

  a {
    color: $color-dark-blue;
    text-decoration: none;

    &:focus {
      outline: none;
    }
  }
}

.chq-pan {
  border-radius: $size-border-radius;
}

.table-container {
  overflow-x: auto;
}

.Container-left_nav {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: -15px;
  max-width: 1440px;

  @media only screen and (min-width: $screen-lg) {
    flex-direction: row;
  }

  &--main-column {
    padding: 0;

    @media only screen and (min-width: $screen-lg) {
      margin-left: 270px;
    }
  }

  .Left-nav {
    padding-bottom: 15px;
    padding-right: 15px;

    @media only screen and (min-width: $screen-lg) {
      background-color: $color-white;
      box-shadow: .5px .5px 3px $color-black-alpha-20;
      height: 100vh;
      left: 0;
      overflow-y: scroll;
      padding-top: 90px;
      position: fixed;
      top: 0;
      width: 270px;
    }
  }
}

.Commonality-Icon {
  background-color: $color-dark-blue;
  border-radius: 22px;
  min-width: 22px;
  padding: 4px;

  path {
    fill: $color-white;
  }
}

.transparent-button {
  background-color: transparent;
  border: 0;

  &:active {
    outline-color: 0;
  }
}

.edit-cursor {
  &:hover {
    cursor: url('/pencil.png'), auto;
  }
}

.chq-ffd {
  .chq-ffd--ctrl--validation {
    bottom: initial;
    top: 10px;
  }

  &.ValidationWithLabel {
    .chq-ffd--ctrl--validation {
      top: 35px;
    }
  }
}

iframe {
  body { // sass-lint:disable-block no-vendor-prefixes
    -ms-overflow-style: none; // IE 10+
    overflow: -moz-scrollbars-none; // Firefox

    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
  }
}

.Social-cmk {
  .chq-cmk {
    align-items: center;
    border: 1px solid $color-social-alpha-30;
    border-radius: $size-border-radius;
    color: $color-linkedin-blue;
    display: flex;
    flex-direction: row-reverse;
    padding: 5px 10px;

    svg {
      border-color: $color-linkedin-blue;
      box-shadow: inset 0 0 0 $color-linkedin-blue;
      margin-left: 10px;
      margin-right: 0;

      circle {
        stroke: $color-linkedin-blue;
      }
    }
  }

  &-marked {
    .chq-cmk {
      background-color: $color-social-alpha-30;
      border: 1px solid transparent;

      svg {
        circle {
          fill: $color-linkedin-blue;
        }
      }
    }
  }

  &.chq-ffd {
    &+ .chq-ffd {
      margin-top: 0;
    }
  }

  &:hover {
    .chq-cmk {
      background-color: $color-social-alpha-15;
      border: 1px solid transparent;
    }
  }
}

.Mapped-cmk {
  margin-top: 8px;

  .chq-cmk {
    align-items: center;
    border: 1px solid $color-orange-alpha-30;
    border-radius: $size-border-radius;
    color: $color-orange;
    display: flex;
    flex-direction: row-reverse;
    padding: 5px 10px;

    .connection {
      @include square(18px);
      animation: initial;
      background-color: transparent;
      border: 0;
      margin-left: 0;
      margin-right: 10px;
      stroke: transparent;

      &-black {
        filter: grayscale(100%) brightness(0);
      }

      path {
        fill: $color-orange;
      }
    }

    svg {
      animation: none;
      border: $color-orange solid 1px;
      box-shadow: inset 0 0 0 $color-orange;
      margin-left: 10px;
      margin-right: 0;

      circle {
        animation: none;
        stroke: $color-orange;
      }

      path {
        animation: none;
      }
    }
  }

  &-marked {
    .chq-cmk {
      background-color: $color-orange-alpha-30;
      border: 1px solid transparent;

      svg {
        animation: chqCmkFill .2s ease-in-out .2s forwards, chqCmkScale .15s ease-in-out .45s both;
        border: 0;

        circle {
          animation: chqCmkStroke .3s cubic-bezier(.65, 0, .45, 1) forwards;
          fill: $color-orange;
        }

        path {
          animation: chqCmkStroke .15s cubic-bezier(.65, 0, .45, 1) .4s forwards;
        }
      }
    }
  }

  &.chq-ffd {
    &+ .chq-ffd {
      margin-top: 0;
    }
  }

  &:hover {
    .chq-cmk {
      background-color: $color-orange-alpha-15;
      border: 1px solid transparent;
    }
  }
}

.LandingPage-cmk {
  .chq-cmk {
    align-items: center;
    border: 1px solid $color-green-alpha-30;
    border-radius: $size-border-radius;
    color: $color-primary-green;
    display: flex;
    flex-direction: row-reverse;
    padding: 5px 10px;

    svg {
      box-shadow: inset 0 0 0 $color-primary-green;
      margin-left: 10px;
      margin-right: 0;

      circle {
        stroke: $color-primary-green;
      }
    }
  }

  &-marked {
    .chq-cmk {
      background-color: $color-green-alpha-30;
      border: 1px solid transparent;

      svg {
        circle {
          fill: $color-primary-green;
        }
      }
    }
  }

  &.chq-ffd {
    &+ .chq-ffd {
      margin-top: 0;
    }
  }

  &:hover {
    .chq-cmk {
      background-color: $color-green-alpha-15;
      border: 1px solid transparent;
    }
  }
}

.Confirm__legend {
  .chq-cmk {
    color: $color-primary-font;
    font-weight: $font-weight-light;
  }

  svg {
    margin-right: 5px;
  }
}

.Notification-Badge {
  background-color: $color-primary-yellow;
  border-radius: 30px;
  color: $color-dark-blue;
  font-size: 13px;
  font-style: normal;
  min-width: 35px;
  padding: 2px;
  position: absolute;
  right: 0;
  text-align: center;
  top: -10px;
}

.textarea {
  display: block;
  line-height: 20px;
  min-height: 100px;
  overflow: hidden;
  resize: both;
  white-space: pre-line;
  width: 100%;
}

.chq-pan-lim {
  max-width: 1060px;
}

.Central--column {
  @media only screen and (min-width: $screen-lg) {
    padding-left: 45px;
    padding-right: 45px;
  }
}

.FullScreenLoading {
  align-items: center;
  background-color: $color-black-alpha-80;
  bottom: 0;
  color: $color-white;
  display: flex;
  flex-direction: column;
  font-size: $font-size-24;
  height: 100vh;
  justify-content: center;
  left: 0;
  margin-top: -60px;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;

  .progressbar {
    background-color: lighten($color-primary-green, 25%);
    margin-top: 15px;
    width: 70vw;
  }

  .chq-ldr {
    .chq-spn {
      svg {
        height: initial;
        margin: 0;
        width: initial;
      }
    }
  }
}

.circle-progressbar-icon {
  height: 80px;
  margin-left: 2px;
  margin-top: 2px;
  padding: 3px;
  width: 80px;
}

.TipContainer {
  background-color: $color-light-blue;
  border-radius: $size-border-radius;
  display: flex;
  margin-bottom: 15px;
  padding: 15px;

  svg {
    @include square(30px);
    margin-right: 10px;
    min-width: 30px;

    path {
      fill: $color-primary-font;
    }
  }

  code {
    background-color: $color-background;
    border-radius: $size-border-radius;
    padding: 5px;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
