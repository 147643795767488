@import '../common';

.PointsBadge {
  bottom: 0;
  display: block;
  float: left;
  margin-top: -24px;
  width: 100%;

  a,
  .label {
    @include border-radius;
    background-color: $color-primary-blue;
    color: $color-white;
    display: block;
    font-size: $font-size-normal;
    font-weight: 400;
    margin: 0 auto;
    padding: 4px 12px;
    text-align: center;
    text-decoration: none;
    width: 80px;

    svg {
      margin-bottom: -5px;
      margin-left: -5px;
      margin-right: 4px;
      margin-top: -1px;
      width: 16px;
    }

    path {
      fill: $color-white;
    }
  }

  &--Nav {
    margin-left: 5px;
    margin-top: 0;

    .label {
      border-radius: 12px;
      font-size: 14px;
      font-weight: 200;
      padding: 4px 12px;
      width: auto;
    }
  }
}
