@import '../common';

.ManageUsers {
  .chq-btn {
    margin-bottom: 0;
  }

  .grid-items {
    align-items: center;
    display: flex;

    .grid-item-filter {
      margin-top: 25px;
    }

    .chq-sbar {
      margin: 0;
    }

    .search-users {
      margin-top: 25px;
    }
  }

  h2 {
    display: inline-block;
    font-size: $font-size-large;
    font-weight: $font-weight-light;
    margin: 0;
    margin-left: 15px;

    @media only screen and (max-width: $screen-sm) {
      display: block;
      line-height: 1.4;
      margin-bottom: 15px;
      margin-left: 0;
      margin-top: 5px;
    }
  }

  .score-users {
    align-items: center;
    display: flex;
  }

  .tag {
    background-color: $color-link-blue;
    color: $color-white;
    display: inline;
    font-size: $font-size-small;
    margin-left: 5px;
    padding: 0 6px;

    &.invited {
      background-color: $color-secondary-font;
    }

    &.disabled {
      background-color: $color-red;
    }
  }

  .link.view,
  .link.edit {
    svg {
      height: 12px;
      width: 12px;

      path {
        fill: $color-link-blue;
      }
    }
  }

  tr {
    &.disabled {
      td {
        color: $color-secondary-font;
      }
    }
  }

  p.dropdown-label {
    font-weight: $font-weight-heavy;
    margin-bottom: 5px;
  }

  .Dropdown {
    display: block;

    button {
      display: block;
      margin-right: 0;
      margin-top: 0;
      padding: 6px 12px;
      text-align: left;
      width: 100%;
    }
  }

  td.actions {
    a.action {
      &.resend {
        display: block;
      }
    }
  }
}

.UserWithoutOrganizations {
  .Actions-column {
    .chq-btn {
      margin-bottom: 5px;
    }
  }
}

.ion-load-a.ion-spin-animation {
  animation-timing-function: steps(8, start);
}
