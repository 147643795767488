@import '../common';

.StoryTable {
  &-Details {
    &__Image {
      border-radius: $size-border-radius;
      max-width: 80px;
    }

    &__Title {
      font-size: $font-size-normal;
      font-weight: $font-weight-normal;
      margin: 0 0 5px;
    }

    &__Thumbnail-Container {
      position: relative;
    }

    &__PlayIcon {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      path {
        fill: $color-white;
      }
    }

    &__ImagesIcon {
      bottom: 5px;
      position: absolute;
      right: 5px;

      path {
        fill: $color-white;
      }
    }

    .chq-edi-op {
      section {
        -webkit-box-orient: vertical; // sass-lint:disable-line no-vendor-prefixes
        -webkit-line-clamp: 2; // sass-lint:disable-line no-vendor-prefixes
        display: -webkit-box; // sass-lint:disable-line no-vendor-prefixes
        overflow: hidden;
        text-overflow: ellipsis;

        &+ section {
          display: none;
        }
      }

      .chq-bdg {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .chq-ffd-tags {
      margin-left: -5px;
    }
  }

  .Shares-Number {
    color: $color-link-blue;
    font-weight: $font-weight-normal;

    &:hover {
      text-decoration: underline;
    }
  }

  &-clickable {
    &:hover {
      cursor: pointer;
    }

    .icon-building {
      align-items: flex-start;
      display: flex;
      margin-bottom: 5px;

      svg {
        @include square(18px);
        margin-right: 7px;

        path {
          fill: $color-primary-font;
        }
      }
    }
  }

  th {
    &.actions {
      width: 217px;
    }

    &.users {
      width: 180px;
    }

    &.thumb {
      width: 80px;
    }

    &.shares-number {
      white-space: nowrap;
    }
  }

  td {
    padding: 20px 8px;
  }

  .Actions-column {
    .chq-ffd {
      display: flex;
      justify-content: flex-end;

      &+ .chq-ffd {
        margin-top: 5px;
      }
    }

    .chq-abn {
      color: $color-secondary-font;
      margin-top: 5px;

      svg {
        margin-right: 5px;
      }

      path {
        fill: $color-secondary-font;
      }
    }

    .accordion {
      background-color: inherit;
      border: 0;
      border-radius: 6px;
      color: $color-link-blue;
      cursor: pointer;
      display: block;
      float: left;
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      padding: 9px 12px 11px 45px;
      text-align: left;
      text-decoration: none;
      white-space: pre-wrap;
      width: 230px;
      word-wrap: break-word;

      &-caret {
        float: none;
        margin: 0 0 -7px 10px;
      }

      &-content {
        margin: 10px 0 0 -45px;
      }
    }

    li {
      &:hover {
        .accordion-open {
          background-color: $color-white;

          .chq-btn {
            &:hover {
              background-color: $color-background;
            }
          }
        }
      }
    }
  }

  li {
    &:hover {
      .accordion-open {
        background-color: $color-white;

        .chq-btn {
          &:hover {
            background-color: $color-background;
          }
        }
      }
    }
  }

  .User {
    font-size: $font-size-normal;
    font-weight: $font-weight-normal;
    margin: 0 0 5px;
  }

  &__Approved {
    .Actions-column {
      .chq-abn {
        background-color: $color-linkedin-blue;
        color: $color-white;
        margin-bottom: 2px;
        margin-top: 0;
        padding: 5px 15px;

        path {
          fill: $color-white;
        }
      }
    }
  }
}

.Approved-actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;

  &__Edit {
    background-color: $color-social-alpha-30;
    border: 1px solid transparent;
    border-radius: $size-border-radius;
    padding: 5px 10px;

    path {
      fill: $color-linkedin-blue;
    }

    &:hover {
      background-color: $color-social-alpha-15;
      border-color: transparent;

      path {
        fill: $color-linkedin-blue;
      }
    }
  }

  &__Share {
    font-weight: $font-weight-normal;

    svg {
      rect {
        fill: $color-link-blue;
      }
    }

    .dropdown-menu {
      top: 30px;

      svg {
        margin-left: -27px;
      }
    }
  }
}

.StoryPublishedTable {
  .StoryTable-Details {
    p {
      -webkit-box-orient: vertical; // sass-lint:disable-line no-vendor-prefixes
      -webkit-line-clamp: 3; // sass-lint:disable-line no-vendor-prefixes
      display: -webkit-box; // sass-lint:disable-line no-vendor-prefixes
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: break-spaces;
    }
  }

  .Actions-column {
    align-items: end;
    display: flex;
    flex-direction: column;

    .Buttons {
      display: flex;
    }

    a {
      margin-top: 5px;
    }
  }
}
