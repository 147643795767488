@import '../common';

.GettingStarted {
  &__Step {
    display: flex;

    &--number {
      align-items: center;
      background-color: $color-primary-blue;
      border-radius: 40px;
      color: $color-white;
      display: flex;
      font-size: $font-size-large;
      font-weight: $font-weight-normal;
      height: 30px;
      justify-content: center;
      margin-right: 15px;
      min-width: 30px;
      width: 30px;
    }

    &--description {
      font-weight: $font-weight-normal;
    }
  }

  .close-button {
    float: right;
    padding: 0 5px;

    svg {
      margin-right: 0;
    }
  }
}
