.Photos {
  .gallery {
    background-color: $color-white;
    margin: 10px;

    .button {
      float: left;
    }

    .photo {
      border-bottom: 5px solid $color-background;
      border-right: 5px solid $color-background;
      height: 200px;
      padding: 0;

      .photo-img {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        position: relative;
        width: 100%;
      }
    }
  }
}
