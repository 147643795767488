@import '../common';

$photo-background: $color-nav;
$photo-size: 205px;
$lightbox-background: $color-black;
$lightbox-text-color: $color-secondary-light-font;

@media only screen and (max-width: $screen-sm) {
  .gallery {
    margin-top: 2rem;
  }
}

@media only screen and (min-width: $screen-sm) {
  .gallery .panel-body {
    padding-left: 3rem;
  }
}

.gallery-photo {
  &__fader {
    height: 200px;
    position: relative;
  }

  &__front,
  &__back {
    @include box-shadow;
    border-radius: $size-border-radius;
    height: 98%;
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity .3s;
    width: 98%;
  }

  &__front {
    background-position: center;
    background-size: cover;
    opacity: 1;
  }

  &__back {
    background-color: rgba(0, 0, 0, .8);
    cursor: pointer;
    opacity: 0;
  }

  .Story-Image {
    width: 50%;
  }

  button {
    background-color: inherit;
    border: 0;
    color: $color-link-blue;
    margin: 0;
    padding: 0;
  }

  &:hover &__front {
    opacity: .2;
  }

  &:hover &__back {
    display: block;
    opacity: 1;
  }

  &__metadata {
    bottom: 0;
    color: $color-background;
    padding: 1rem 2rem;
    position: absolute;

    h5 {
      color: $color-white;
      font-size: $font-size-nav;
      font-weight: $font-weight-light;
      margin-bottom: 1rem;
      margin-top: 6px;
      text-decoration: none;
    }

    .Thumbnail + a {
      margin-left: 8px;
      margin-top: 2px;
      position: absolute;
    }

    svg {
      fill: $color-link-blue;
      position: relative;
      top: .5rem;
    }
  }
}

.gallery-lightbox {
  border-radius: $size-border-radius;
  display: grid;
  margin: 16% auto;
  width: 96vw;

  &--Story {
    height: 100%;
    margin: 0;
    max-width: 100%;
    min-height: 92%;
    width: 100%;

    @media only screen and (min-width: $screen-sm) {
      height: initial;
    }

    .Accordian__arrow {
      @include square(22px);
      margin-right: 5px;

      path {
        fill: $color-secondary-font;
      }
    }

    .QuickOptions {
      margin-right: -5px;
    }
  }

  @media only screen and (min-width: $screen-sm) {
    bottom: 0;
    left: 0;
    margin: 7% auto;
    max-width: 90vw;
    min-height: initial;
    position: absolute;
    right: 0;
    top: 0;
    width: 90vw;
  }

  @media only screen and (min-width: $screen-lg) {
    margin: 5% auto;
  }

  &__share {
    align-items: center;
    display: flex;
    float: right;
    font-size: $font-size-nav;

    svg {
      @include square(18px);
      margin-right: 2px;
    }

    .chq-btn {
      border: 0;
      padding: 5px 10px;

      &:hover {
        background-color: $color-background;
      }
    }
  }

  &-tagging {
    img {
      cursor: pointer;
    }
  }

  .QuickOptions {
    right: 0;
    top: 0;
    transition: opacity .15s ease-in-out;
    z-index: $z-index-low;

    @media only screen and (min-width: $screen-sm) {
      right: 0;
    }

    &--Actions {
      position: absolute;
      right: 45px;
      top: 5px;

      .chq-abn {
        background-color: $color-linkedin-blue;
        color: $color-white;

        path {
          fill: $color-white;
        }
      }

      .QuickOptions__menu {
        .SocialOption__Container {
          padding: 9px 15px;
          width: 180px;
        }
      }

      .SocialBadge {
        background: initial;
        background-color: initial;
        color: $color-link-blue;
        margin: 0;
        padding: initial;

        circle,
        path,
        rect {
          fill: $color-link-blue;
        }
      }
    }

    &--Main {
      z-index: $z-index-medium;
    }
  }

  &--actions {
    align-items: center;
    background-color: rgba(255, 255, 255, .9);
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 15px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: $z-index-high;

    @media only screen and (min-width: $screen-sm) {
      background-color: transparent;
      left: initial;
      position: absolute;
      right: -30px;
      top: -60px;
    }

    @media only screen and (min-width: $screen-md) {
      right: -50px;
    }

    .Linkedin-Share {
      margin-right: 5px;
    }

    .navigation {
      @media only screen and (min-width: $screen-sm) {
        display: none;
      }

      .left-arrow {
        margin-right: 20px;
      }
    }

    svg {
      height: 25px;
      opacity: .9;
      transition: opacity 300ms ease-in-out;
      width: 25px;

      @media only screen and (min-width: $screen-sm) {
        height: 35px;
        width: 35px;
      }

      &:hover {
        opacity: 1;
      }
    }

    path {
      fill: $color-black;

      @media only screen and (min-width: $screen-sm) {
        fill: $color-white;
      }
    }

    a,
    button {
      padding: 1px 1px 0;
    }

    .ShareQuickOptions {
      right: 50px;
      top: 15px;

      @media only screen and (min-width: $screen-sm) {
        right: 60px;
      }
    }
  }

  &--active {
    svg {
      opacity: 1;
    }
  }

  .chq-pan--bd {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding: 61px 0 0;
    position: relative;

    &:hover {
      .QuickOptions {
        opacity: 1;
      }
    }

    @media only screen and (min-width: $screen-sm) {
      background-color: $lightbox-background;
      border-radius: $size-border-radius;
      overflow: initial;
      padding: 0;
    }

    &-container {
      flex: 1;
    }

    .Lightbox-Image {
      height: 100%;
    }
  }

  &__full-header {
    align-items: center;
    background-color: $color-dark-blue;
    color: $color-white;
    display: flex;
    justify-content: space-between;
    padding: 15px;

    path {
      fill: $color-white;
    }

    .title {
      align-items: center;
      display: flex;

      svg {
        background-image: url('../../images/chq-gradient-circle.png');
        background-position: center;
        background-size: cover;
        height: 35px;
        margin-right: 10px;
        padding: 8px;
        width: 35px;
      }

      h4 {
        margin: 0;
      }
    }

    .navigation {
      align-items: center;
      display: flex;

      .chq-pbn {
        @include square(22px);
      }

      .chq-pag {
        margin: 0 15px;

        &--bn-nav {
          display: none;
        }
      }
    }

    .actions {
      align-items: center;
      display: flex;

      .Mapped-cmk {
        margin-right: 15px;
        margin-top: 0;

        path {
          fill: none;
        }

        .connection {
          path {
            fill: $color-orange;
          }
        }
      }

      .LandingPage-cmk {
        margin-right: 15px;

        path {
          fill: none;
        }
      }

      .Approved-actions__Share {
        margin-left: 0;
        position: initial;
        z-index: 11;

        .chq-abn {
          background-color: $color-linkedin-blue;
          color: $color-white;
          margin-bottom: 2px;
          margin-top: 0;
          padding: 5px 15px;

          svg {
            margin-right: 5px;
          }

          path {
            fill: $color-white;
          }
        }

        .dropdown-menu {
          top: -5px;
        }
      }

      .close-btn {
        @include square(30px);
        margin-left: 15px;

        svg {
          @include square(30px);
        }
      }
    }
  }

  &__header {
    align-items: center;
    background-color: $color-dark-blue;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    margin: 0 auto;
    padding: 10px 45px;
    position: absolute;
    top: -72px;
    transform: translate(-50%, 0%);
    width: auto;

    @media only screen and (min-width: $screen-modal-sm) {
      flex-direction: row;
      top: -58px;
    }

    svg {
      height: 30px;
      margin-right: 15px;
      width: 30px;
    }

    &--Linkedin {
      background: $linkedin-gradient;
      padding: 10px 90px;

      .legend {
        font-weight: $font-weight-normal;
      }

      svg {
        height: 24px;
        margin-right: 10px;
        width: 24px;
      }

      path {
        fill: $color-white;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .legend {
      align-items: center;
      color: $color-white;
      display: flex;
      font-size: 16px;
      white-space: nowrap;

      @media only screen and (min-width: $screen-modal-sm) {
        font-size: 18px;
      }

      .emoji {
        margin-right: 10px;
      }
    }

    .FeedItem__preview__title {
      background-color: transparent;
      margin-left: 0;
      margin-top: 0;
      padding: 0;

      @media only screen and (min-width: $screen-modal-sm) {
        margin-left: 30px;
      }

      &:hover {
        cursor: pointer;
      }

      span {
        align-items: center;
        color: $color-primary-green;
        display: flex;
        font-size: 18px;
        font-weight: $font-weight-normal;
      }

      svg {
        background-color: $color-primary-green;
        border-radius: 20px;
        height: 18px;
        margin: 0 5px 0 0;
        padding: 2px;
        width: 18px;
      }

      path {
        fill: $color-dark-blue;
      }
    }

    &-post {
      flex-direction: row;
      padding: 10px 30px;
      top: -58px;

      @media only screen and (min-width: $screen-modal-sm) {
        top: -62px;
      }

      .legend {
        color: $color-primary-green;
        font-size: 16px;
        font-weight: $font-weight-heavy;

        @media only screen and (min-width: $screen-modal-sm) {
          font-size: 20px;
        }
      }
    }

    &-container {
      .share-button {
        opacity: .9;
        position: absolute;
        right: 0;
        top: -50px;
        transition: opacity 300ms ease-in-out;

        &:hover {
          cursor: pointer;
          opacity: 1;
        }

        svg {
          height: 35px;
          width: 35px;
        }

        path {
          fill: $color-white;
        }
      }

      .dropdown-menu {
        margin-right: -2px;
        margin-top: -15px;
      }
    }
  }

  &__wrapper {
    border-radius: 6px;
    margin: 0 auto;
    position: relative;
    width: 100%;

    @media only screen and (min-width: $screen-sm) {
      display: flex;
      flex-direction: row;
    }
  }

  &__photo {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    padding: 0;
    position: relative;

    .Media-Iframe {
      height: 100%;
      min-height: $media-height;
      width: 100%;
      z-index: $z-index-low;
    }

    .preview-newest-video-message {
      align-items: center;
      align-self: flex-end;
      background-color: $color-black;
      border-radius: 8px;
      color: $color-background-darker;
      display: flex;
      font-weight: $font-weight-heavy;
      justify-content: space-around;
      margin: 16px 16px 0;
      padding: 2px 16px 2px 32px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: $z-index-medium;
    }

    .chq-ldr {
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .container-lightbox {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: center;
      margin-bottom: 0;
      margin-top: 0;
      width: 100%;

      @media only screen and (min-width: $screen-sm) {
        position: absolute;
      }

      .slider-arrow {
        @include square(35px);
        background: rgba(255, 255, 255, .8);
        border: 0;
        border-radius: 30px;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, .5);
        padding: 6px;
        position: absolute;
        top: calc(50% - 28px);
        z-index: $z-index-low;

        &-right {
          padding-right: 4px;
          right: 10px;
        }

        &-left {
          left: 10px;
          padding-left: 2px;
        }
      }
    }
  }

  &__event-name {
    bottom: 40px;
    left: 0;
    padding: 20px 80px 20px 20px;
    position: absolute;
    text-align: left;
    z-index: $z-index-low;

    span,
    a {
      color: $color-white;
      font-size: $font-size-large;
      text-decoration: none;
    }
  }

  &__title-container {
    align-items: center;
    display: flex;
    padding-right: 20px;

    .title {
      color: $color-primary-font;
      font-size: 20px;
      margin: 0 0 0 12px;

      @media only screen and (min-width: $screen-sm) {
        font-size: 26px;
      }
    }

    svg {
      height: 42px;
      margin-right: 15px;
      min-width: 42px;
      width: 42px;
    }

    path {
      fill: $color-primary-blue;
    }

    &__smaller {
      svg {
        height: 28px;
        min-width: 28px;
        width: 28px;
      }
    }
  }

  .ParentPrompt {
    .Title {
      font-size: $font-size-26;
    }
  }

  &__uploader {
    display: inline-block;
    left: 0;
    padding: 20px 50px 20px 20px;
    position: absolute;
    text-align: left;
    top: 0;
    z-index: $z-index-medium;

    .Thumbnail {
      height: 40px;
      left: 20px;
      position: absolute;
      top: 20px;
      width: 40px;
    }
  }

  &__uploader-date {
    color: $color-secondary-light-font;
    font-weight: $font-weight-light;
    top: 5px;
  }

  &__uploader-name {
    color: $color-link-blue;
    display: inline-block;
    font-weight: $font-weight-heavy;
    padding-bottom: 5px;

    &:hover {
      color: $color-link-blue;
      text-decoration: none;
    }
  }

  .fade-out {
    background-image: linear-gradient(
      to right,
      rgba(1, 1, 1, .8),
      0%,
      rgba(1, 1, 1, .7) 45%,
      rgba(1, 1, 1, .6) 59%,
      rgba(1, 1, 1, .5) 70%,
      rgba(1, 1, 1, 0) 100%
    );
  }

  &__image-container {
    background-size: cover;
    filter: blur(2px);
    left: 50%;
    opacity: .3;
    position: absolute;
    top: 50%;
    transform: scale(11);
    width: 10%;

    @media only screen and (min-width: $screen-sm) {
      height: 10%;
    }
  }

  &__details-container {
    background-color: $color-white;
    border-radius: 0 0 $size-border-radius $size-border-radius;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    min-height: initial;
    padding: 20px;

    @media only screen and (min-width: $screen-sm) {
      border-radius: 0 $size-border-radius $size-border-radius 0;
      min-height: initial;
    }

    .scrollable-container {
      @media only screen and (min-width: $screen-sm) {
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
      }

      .chq-edi-op {
        white-space: pre-wrap;
      }

      .bottom-shadow {
        background-image: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
        bottom: 0;
        display: none;
        height: 10%;
        margin-left: -20px;
        margin-right: -20px;
        position: absolute;
        width: 100%;

        @media only screen and (min-width: $screen-sm) {
          border-bottom-right-radius: $size-border-radius;
          display: block;
        }
      }
    }

    .scrollable-container.full-height {
      @media only screen and (min-width: $screen-sm) {
        max-height: 75vh;
        padding-bottom: 20px;
      }
    }
  }

  &__creator-container {
    align-items: center;
    display: flex;
    margin-bottom: 15px;
    position: relative;

    .chq-tmb {
      height: 45px;
      min-width: 45px;
      width: 45px;
    }
  }

  &__creator-information {
    margin-left: 10px;

    span {
      color: $color-link-blue;
      font-weight: $font-weight-heavy;
      padding-bottom: 5px;
    }

    .chq-btn-select-user {
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      font-size: 1em;
      font-weight: 600;
      outline: 0;
      padding: 0 10px;

      path {
        fill: $color-link-blue;
      }

      .SelectUserBtn {
        svg {
          @include square(18px);
        }
      }
    }

    .user-label {
      align-items: center;
      display: flex;
    }
  }

  .chq-ffd-tags {
    margin-top: 10px;
  }

  &__footer {
    background-color: $color-black;
    margin-top: -30px;
    padding-bottom: 15px;

    .chq-cbn {
      background-color: inherit;
      color: $color-white;

      &:hover {
        background-color: $color-nav;
      }
    }
  }

  &__comment-button {
    color: $color-primary-font;
    float: right;
    margin-top: -2px;
    padding: 2px 17px 7px;
    transition: background-color .3s;

    svg {
      margin-bottom: -6px;
      margin-top: 6px;

      path {
        fill: $color-primary-font;
      }
    }
  }

  .comments {
    background-color: transparent;
    border: 0;
    margin-top: 0;

    &-form {
      &--data {
        align-items: center;
        display: flex;

        &-message {
          align-items: center;
          color: $color-red;
          display: flex;
          justify-content: end;

          path {
            fill: $color-red;
          }

          .chq-btn-iv {
            path {
              fill: $color-primary-green;
            }
          }
        }
      }
    }
  }

  &__comments {
    .comment-form {
      margin-left: 0;

      .chq-edi {
        background-color: $color-border;
        border-radius: 30px;
        box-shadow: none;
      }

      .chq-btn {
        border-radius: 30px;
        right: 23px;
      }
    }
  }

  &__main-image {
    display: block;
    height: auto;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    width: auto;

    @media only screen and (min-width: $screen-sm) {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__main-photo {
    height: auto;
    object-fit: contain;
    width: 100%;
  }

  &__main-image-iframe {
    display: block;
    height: auto;
    max-height: 100vh;
    max-width: 100vw;
    width: auto;

    @media only screen and (min-width: $screen-sm) {
      left: 50%;
      position: relative;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__main-video {
    height: 100%;
    left: 0;
    max-height: 100%;
    object-fit: cover;
    position: relative;
    top: 0;
    transform: initial;

    &.landscape {
      border-radius: $size-border-radius;
      object-fit: contain;
    }

    &.loading {
      display: none;
    }
  }

  &__header--badge {
    background-color: $color-secondary-font;
    border-radius: $size-border-radius;
    color: $color-white;
    padding: 2px 10px;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: $z-index-low;
  }

  &__video-play {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: $z-index-low;

    svg {
      height: 80px;
      width: 80px;
    }

    path {
      fill: $color-white;
    }
  }

  .Story-Image {
    display: flex;
    flex: 1;
    height: 100%;
    max-height: 87vh;
    min-height: 87vh;

    .image-slider {
      border-radius: 0;
      display: none;
      margin-bottom: 0;

      &.active {
        display: block;
      }

      @media only screen and (min-width: $screen-sm) {
        border-radius: $size-border-radius;
      }
    }
  }

  .Story_Badges__Container {
    left: 30px;
    position: absolute;
    top: 30px;

    a {
      &:hover {
        outline: 0;
        text-decoration: none;
      }
    }
  }

  .Story__Trendsetter {
    background-color: rgba(255, 210, 75, .6);
    border-radius: $size-border-radius;
    color: $color-white;
    display: inline-block;
    margin-right: 5px;
    padding: 5px;

    &-text {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    svg {
      height: 14px;
      width: 14px;
    }

    path {
      fill: $color-white;
    }
  }

  .Story__LinkedinBadge {
    background-color: rgba(0, 119, 183, .6);
    border-radius: $size-border-radius;
    color: $color-white;
    display: inline-block;
    padding: 5px 10px;

    &-text {
      align-items: center;
      display: flex;
    }

    svg {
      border-radius: 2px;
      height: 18px;
      margin-right: 3px;
      padding: 3px;
      width: 18px;
    }
  }

  &__chevron-left,
  &__chevron-right {
    background: none;
    border: 0;
    display: none;
    opacity: .75;
    outline: none;
    padding: 6px;
    position: absolute;
    top: 38vh;
    transition: opacity 300ms ease-in-out;
    z-index: $z-index-medium;

    @media only screen and (min-width: $screen-sm) {
      display: block;
    }

    svg {
      @include square(50px);
      fill: $color-white;
      margin-top: 2px;
    }

    &:hover {
      opacity: 1;
    }
  }

  &__chevron-left {
    left: -50px;

    @media only screen and (min-width: $screen-md) {
      left: -70px;
    }
  }

  &__chevron-right {
    right: -50px;

    @media only screen and (min-width: $screen-md) {
      right: -70px;
    }
  }

  .gallery-lightbox__event-name,
  .gallery-lightbox__uploader {
    opacity: 0;
    transition: opacity .3s;

    @media only screen and (max-width: $screen-sm) {
      opacity: 1;
    }
  }

  &:hover {
    .gallery-lightbox__event-name,
    .gallery-lightbox__uploader {
      opacity: 1;
    }
  }

  &-one {
    background-color: $color-black;
    margin-bottom: 30px;
    width: auto;

    .gallery-lightbox__wrapper,
    .gallery-lightbox__photo {
      height: calc(40vh + 90px);
    }

    .gallery-lightbox__image-container {
      height: 40vh;
    }

    .gallery-lightbox__event-name,
    .gallery-lightbox__uploader {
      opacity: 1;
    }
  }

  &__actions {
    align-items: center;
    display: flex;

    .chq-btn {
      flex-grow: 1;

      &:first-child {
        margin-right: 5px;
      }

      &:nth-child(2) {
        margin-left: 5px;
      }

      svg {
        margin-right: 5px;
      }
    }

    .chq-ffd {
      flex-grow: 1;

      .chq-cmk {
        justify-content: center;
        padding: 9px 13px;
        width: 100%;
      }

      &:first-child {
        margin-right: 5px;
      }

      &:nth-child(2) {
        margin-left: 5px;
      }
    }
  }

  &--Story-Review {
    height: 100vh;
    margin: 0;
    max-width: initial;
    width: 100vw;

    .chq-pan--bd {
      padding: 0;
    }

    .gallery-lightbox {
      &__photo {
        @media only screen and (min-width: $screen-sm) {
          min-height: initial;
        }
      }

      &__wrapper {
        height: calc(100vh - 73px);
      }

      &__details-container {
        border-radius: 0;

        .scrollable-container {
          max-height: initial;

          @media only screen and (min-width: $screen-sm) {
            max-height: 68vh;
          }
        }
      }

      &__creator-container {
        .chq-btn {
          border: 0;
          outline: none;
          padding: 0;
        }
      }
    }
  }

  .ApprovedQuickOptions {
    margin: 0;
    position: relative;

    .QuickOptions__open-dropdown {
      border: 1px solid $color-white;
      border-radius: $size-border-radius;
      font-weight: $font-weight-normal;
      margin-right: 15px;
      padding: 7px 15px;

      &__wrapper {
        align-items: center;
        display: flex;
        height: initial;
        padding: 0;
        width: initial;

        svg {
          @include square(18px);
          margin-left: 5px;
        }
      }
    }

    path {
      fill: $color-white;
    }
  }
}

.gallery-delete--modal {
  max-width: 400px;

  img {
    margin: 0 auto 15px;
    width: 100%;
  }
}

.photo-tagging {
  position: absolute;
  z-index: 2147483647;

  &-results {
    .chq-sbar {
      input {
        @include border-bottom-radius(0);
      }
    }
  }

  .chq-pbn {
    display: block;
    padding: .33em .5em;
    text-align: left;
    width: 100%;

    &:hover {
      background-color: $color-background;
    }

    &:last-child {
      @include border-bottom-radius(4px);
    }
  }
}

.chq-photo-tag {
  border: transparent solid 2px;
  border-radius: 4px;
  position: absolute;
  z-index: 2147483647;

  &--del {
    background-color: rgba($color-black, .7);
    border-radius: 20px;
    display: none;
    line-height: 1em;
    padding: 0 1px;
    position: absolute;
    right: -10px;
    top: -10px;

    svg {
      @include square(20px);
    }

    path {
      fill: $color-white;
    }
  }

  &--name {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 4px;
    color: $color-background;
    display: none;
    font-size: .9em;
    padding: 1px 4px;
    position: absolute;
    top: calc(100% + 5px);
    white-space: nowrap;

    &::before {
      border-bottom: 10px solid rgba(0, 0, 0, .5);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      content: '';
      height: 0;
      left: calc(50% - 10px);
      position: absolute;
      top: -10px;
      width: 0;
    }
  }

  &:hover {
    .chq-photo-tag--name {
      display: block;
    }
  }

  &-active {
    border-color: rgba($color-white, .7);
    box-shadow: 0 0 3px rgba($color-black, .5);

    &:hover {
      border-color: $color-white;

      .chq-photo-tag--del {
        display: block;
      }
    }
  }
}
