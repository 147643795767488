$box-shadow-default: .5px .5px 3px 0 rgba(0, 0, 0, .2);

// Colors
$color-black: #000;
$color-black-alpha-0: rgba(0, 0, 0, 0);
$color-black-alpha-10: rgba(0, 0, 0, .1);
$color-black-alpha-20: rgba(0, 0, 0, .2);
$color-black-alpha-40: rgba(0, 0, 0, .4);
$color-black-alpha-50: rgba(0, 0, 0, .5);
$color-black-alpha-60: rgba(0, 0, 0, .6);
$color-black-alpha-70: rgba(0, 0, 0, .7);
$color-black-alpha-80: rgba(0, 0, 0, .8);
$color-social-alpha-30: rgba(0, 119, 183, .3);
$color-social-alpha-15: rgba(0, 119, 183, .15);
$color-green-alpha-15: rgba(121, 177, 125, .15);
$color-green-alpha-30: rgba(121, 177, 125, .3);
$color-green-alpha-45: rgba(121, 177, 125, .45);
$color-form-inset: rgba(0, 0, 0, .075);
$color-nav: #292929;
$color-dark-blue: #2c3e4f;
$color-light-blue: #d7e5f1;
$color-link-blue: #6a89af;
$color-primary-blue: #8cb4d6;
$color-primary-font: #5c5f67;
$color-primary-light-font: lighten($color-primary-font, 25%);
$color-secondary-font: #888;
$color-secondary-font-darker: #818181;
$color-secondary-light-font: #c9c9c9;
$color-primary-green: #79b17d;
$color-primary-yellow: #ffd24b;
$color-red: #c14c4c;
$color-border: #eaeaea;
$color-border-darker: #cacaca;
$color-selected-option: #f1f1f1;
$color-background: #f7f7f7;
$color-background-darker: #f3f3f3;
$color-bdg-hover: #e4e4e4;
$color-outline: #005fcc;
$color-white: #fff;

$color-orange: #ea813b;
$color-orange-alpha-30: rgba(234, 129, 59, .3);
$color-orange-alpha-15: rgba(234, 129, 59, .15);

$color-bar-blue: #5c8aac;
$color-bar-green: #487c4c;
$color-bar-text: #c39a01;
$color-bar-subtitle: #458854;

$color-facebook-blue: #006eff;
$color-instagram-red: #fd1d1d;
$color-linkedin-blue: #0077b7;

$color-rtx-red: #ce1126;

$search-bar-shadow: rgba($color-primary-blue, .6);

$color-dark-blue-alpha-80: rgba($color-dark-blue, .8);

$facebook-instagram-gradient: linear-gradient(90deg, #465993 0%, #0479b9 50%, #f8a00f 100%);
$facebook-gradient: linear-gradient(90deg, $color-facebook-blue 0%, #00a9ff 60%, #00b4ff 100%);
$instagram-gradient: linear-gradient(90deg, rgba(131, 58, 180, 1) 0%, rgba(225, 48, 108, 1) 65%, rgba(247, 119, 55, 1) 100%);
$linkedin-gradient: linear-gradient(90deg, rgba(70, 89, 147, 1) 0%, rgba(4, 121, 185, 1) 48%, rgba(117, 169, 234, 1) 100%);
$tiktok-gradient: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 244, 234, 1) 48%, rgba(255, 0, 80, 1) 100%);

$grabber-width: 24px;

// Typography
$font-default: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-size-xs: 11px;
$font-size-12: 12px;
$font-size-small: 13px;
$font-size-nav: 14px;
$font-size-15: 15px;
$font-size-normal: 16px;
$font-size-17: 17px;
$font-size-large: 18px;
$font-size-xl: 20px;
$font-size-22: 22px;
$font-size-23: 23px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-28: 28px;
$font-size-30: 30px;
$font-size-34: 34px;
$font-size-100: 100px;

$font-weight-lightest: 200;
$font-weight-light: 400;
$font-weight-normal: 400;
$font-weight-heavy: 600;

// Layout
$size-border-radius: 6px;

// Responsive Breakpoints (>= max-width)
$screen-xxs: 320px;
$screen-xs: 480px;
$screen-modal-sm: 615px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1199px;
$screen-xl: 1400px;
$screen-xxl: 1600px;

// (>= max-height)
$windows-screen-height: 769px;
$story-modal-height: 858px;

// z-indices
$z-index-low: 10; // Just above content: Forms, Dropdowns, etc.
$z-index-medium: 20; // Over Content: Notifications, Topnav Dropdown, etc.
$z-index-high: 30; // Overlay Everything: Modals, Alerts

@mixin border-radius($radius: $size-border-radius) {
  border-radius: $radius;
}

@mixin border-bottom-radius($radius: $size-border-radius) {
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin border-left-radius($radius: $size-border-radius) {
  border-bottom-left-radius: $radius;
  border-top-left-radius: $radius;
}

@mixin border-top-radius($radius: $size-border-radius) {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin box-shadow($rules: $box-shadow-default) {
  box-shadow: $rules;
}

@mixin square($size) {
  height: $size;
  width: $size;
}
