@import '../common';

.notification-popup-wrapper {
  bottom: 15px;
  display: flex;
  flex-direction: column-reverse;
  height: 0;
  left: 30px;
  position: fixed;
  z-index: 99999999999999;

  @media screen and (max-width: $screen-sm) {
    left: 10px;
  }
}

.notification-popup {
  border: 1px solid $color-border;
  border-radius: 5px;
  box-shadow: 2.5px 2.5px 10px rgba(0, 0, 0, .13);
  display: block;
  margin-bottom: 8px;
  max-width: 360px;
  pointer-events: none;
  position: relative;
  width: 100%;

  .notification-body-wrapper {
    align-items: center;
    display: flex;
    min-height: 80px;
    padding: 2rem 4rem 2rem 8.5rem;

    .notification-body {
      -webkit-box-orient: vertical;// sass-lint:disable-line no-vendor-prefixes
      -webkit-line-clamp: 5;// sass-lint:disable-line no-vendor-prefixes
      display: -webkit-box; // sass-lint:disable-line no-vendor-prefixes
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }

  &__view-link {
    display: flex;
    padding: 0 4rem 2rem 8.5rem;
  }

  &__show,
  &__hide {
    animation: popupNotificationIn .4s forwards;
  }

  &__show {
    pointer-events: auto;
  }

  &__hide {
    animation-direction: reverse;
  }

  svg,
  .Thumbnail {
    @include square(4rem);
    left: 2.5rem;
    position: absolute;
    top: 2rem;
  }

  svg path {
    fill: $color-background;
  }

  img.img-preview {
    display: block;
    margin-bottom: 8px;
    margin-top: 10px;
    max-width: 60%;
  }

  &__close {
    backface-visibility: hidden;
    background-color: $color-white;
    border: 0;
    cursor: pointer;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 1.2rem;
    top: 1rem;
    transition: opacity .3s ease-in;

    &:focus {
      outline: none;
    }

    &:hover {
      opacity: .4;
    }

    svg {
      @include square(12px);
      cursor: pointer;
      position: initial;

      path {
        fill: $color-secondary-font;
      }
    }
  }

  p {
    margin: 0;
  }
}
