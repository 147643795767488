@import '../../styles/common';

.Interests,
.Skills {
  text-align: left;

  svg {
    height: 15px;
    margin-right: 5px;
    width: 15px;
  }

  &__title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    h2 {
      color: $color-primary-light-font;
      font-size: 18px;
      font-weight: $font-weight-heavy;
      margin: 0;
    }

    svg {
      height: 22px;
      margin-right: 0;
      margin-top: 5px;
      width: 22px;
    }

    path {
      fill: $color-primary-light-font;
    }
  }

  .plus-icon {
    path {
      fill: $color-primary-font;
    }
  }

  .chq-bdg {
    path {
      fill: $color-primary-font;
    }
  }

  .User-Interest {
    color: $color-dark-blue;

    path {
      fill: $color-dark-blue;
    }

    a {
      color: $color-dark-blue;
      text-decoration: none;

      &:focus {
        outline: none;
      }
    }
  }

  .Common-Interest {
    background-color: $color-dark-blue;
    color: $color-white;

    path {
      fill: $color-white;
    }

    a {
      color: $color-white;
    }
  }

  &__minus-option,
  &__plus-option {
    display: inline-block;
    fill: $color-primary-font;
    margin-top: 5px;

    a,
    span {
      align-items: center;
      color: inherit;
      display: flex;
      font-weight: $font-weight-heavy;
      text-decoration: none;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
