@import '../common';

$green-lightened: lighten($color-primary-green, 5%);
$green-darkened: darken($color-primary-green, 5%);

$padding-hrtl: 5px;
$padding-vrtl: 10px;

.Button {
  @include border-radius;
  background-color: $color-primary-green;
  border: $color-primary-green solid 1px;
  color: $color-white;
  display: inline-block;
  font-weight: $font-weight-normal;
  outline: 0;
  padding: $padding-hrtl $padding-vrtl;

  svg {
    height: $padding-vrtl + 8px;
    margin: 0 0 -3px -3px;
  }

  path {
    fill: $color-white;
  }

  &:focus {
    background-color: $color-primary-green;
    color: $color-white;
    text-decoration: none;
  }

  &:hover {
    background-color: $green-lightened;
    border: $green-lightened solid 2px;
    color: $color-white;
    padding: ($padding-hrtl - 1px) ($padding-vrtl - 1px);
    text-decoration: none;
  }

  &:active,
  &.active {
    background-color: $green-darkened;
    border: $green-darkened solid 2px;
    color: $color-white;
    padding: ($padding-hrtl - 1px) ($padding-vrtl - 1px);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: .6;
  }

  &-primary {
    padding: ($padding-hrtl + 4px) ($padding-vrtl + 3px);

    &:hover,
    &:active {
      padding: ($padding-hrtl + 3px) ($padding-vrtl + 2px);
    }
  }

  &-small {
    font-size: $font-size-small;
    padding: ($padding-hrtl - 3px) ($padding-vrtl - 2px);

    &:focus,
    &:hover,
    &:active {
      padding: ($padding-hrtl - 4px) ($padding-vrtl - 3px);
    }

    svg {
      height: $padding-vrtl + 5px;
    }
  }

  &-inverted {
    background-color: $color-white;
    color: $color-primary-green;

    path {
      fill: $color-primary-green;
    }

    &:focus {
      background-color: $color-white;
      color: $color-primary-green;
    }

    &:hover {
      background-color: $color-white;
      color: $green-lightened;

      path {
        fill: $green-lightened;
      }
    }

    &:active,
    &.active {
      background-color: $color-white;
      border-color: $green-darkened solid 2px;
      color: $green-darkened;

      path {
        fill: $green-darkened;
      }
    }
  }

  &-loading {
    svg {
      animation: spin 1s linear infinite;
    }
  }
}
