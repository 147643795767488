@import '../common';

// sass-lint:disable no-vendor-prefixes
.Story {
  &_Badges__Container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    .SocialBadge {
      margin-bottom: 5px;
      margin-right: 5px;
      margin-top: 5px;
      padding: 5px 15px;

      &.instagram {
        background: linear-gradient(180deg, rgba(216, 0, 185, .6) 0%, rgba(255, 169, 0, .6) 100%);
      }

      &.facebook {
        background: linear-gradient(180deg, rgba(24, 119, 242, .6) 0%, rgba(0, 180, 255, .6) 100%);
      }

      &.linkedin {
        background-color: rgba(40, 100, 180, .6);
      }

      &.tiktok {
        background-color: rgba(45, 15, 30, .6);
      }
    }
  }

  .Story {
    &__Single-container {
      position: relative;

      a {
        img {
          max-height: initial;
        }
      }
    }

    &__information {
      &--feed,
      &--profile {
        background-color: initial;
        color: $color-white;
        height: 100%;
        padding-bottom: 5px;
        padding-left: 0;
        padding-right: 9px;
        padding-top: 5px;
        position: absolute;
        top: 0;
        width: 100%;

        .background-container {
          background: linear-gradient(0deg, rgba(0, 0, 0, .3) 0%, rgba(0, 0, 0, .7) 100%);
          border-radius: 5px;
          height: 100%;
          overflow: hidden;
          padding: 15px;
        }

        .play-icon {
          @include square(36px);
          left: calc(50% - 20px);
          position: absolute;
          top: calc(50% - 20px);

          path {
            fill: $color-white;
          }
        }

        .chq-edi-op {
          color: $color-white;
          display: flex;
          flex-direction: column;
          font-weight: 600;
          margin-top: 5px;

          section {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            font-size: 18px;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;

            .chq-edi--img--wrap {
              display: none;
            }

            @media only screen and (min-width: $screen-sm) {
              font-size: $font-size-xl;
            }

            &:nth-child(n+3) {
              display: none;
            }
          }

          .chq-edi--lt {
            display: none;
          }
        }

        .chq-ffd-tags {
          display: none;
          margin-top: 0;
          max-height: 30px;
          order: -1;
          overflow: hidden;
          text-overflow: ellipsis;

          @media only screen and (min-width: $screen-sm) {
            display: block;
          }

          .chq-bdg {
            background-color: transparent;
            color: $color-white;
            font-size: 18px;
            font-weight: $font-weight-normal;
            margin-top: 0;
            padding: 0;

            path {
              fill: $color-white;
            }
          }
        }
      }
    }

    &__question {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      font-size: 24px;
      font-weight: 600;
      margin-top: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__answer {
      display: none;

      .background-container {
        align-items: flex-start;
        background: linear-gradient(0deg, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, .9) 100%);
        display: flex;
      }

      .chq-edi-op {
        section {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: initial;
          display: -webkit-box;
          font-size: 20px;
          font-weight: $font-weight-normal;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &__badge {
      align-items: center;
      background-color: rgba(136, 136, 136, .8);
      border-radius: $size-border-radius;
      bottom: 20px;
      display: flex;
      padding: 5px 10px;
      position: absolute;
      right: 25px;

      svg {
        height: 14px;
        margin-right: 10px;
        width: 14px;
      }

      path {
        fill: $color-white;
      }

      &--play {
        background-color: none;
        bottom: 15px;
        position: absolute;
        right: 25px;

        svg {
          height: 18px;
          width: 18px;
        }

        path {
          fill: $color-white;
        }
      }
    }

    &__creator {
      align-items: center;
      bottom: 15px;
      color: $color-white;
      display: flex;
      font-size: 16px;
      font-weight: $font-weight-normal;
      position: absolute;
      width: 70%;

      @media only screen and (min-width: $screen-sm) {
        font-size: 18px;
      }

      .chq-tmb {
        height: 22px;
        margin-right: 5px;
        min-width: 22px;
        width: 22px;

        @media only screen and (min-width: $screen-sm) {
          height: 25px;
          margin-right: 10px;
          min-width: 25px;
          width: 25px;
        }
      }

      span {
        overflow: hidden;
        overflow-wrap: break-word;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 75%;
        word-break: break-all;
      }

      &-text {
        margin: 0;

        &.Story__creator-title {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &__Trendsetter {
      align-items: center;
      background-color: rgba(255, 210, 75, .6);
      border-radius: $size-border-radius;
      color: $color-white;
      display: flex;
      justify-content: center;
      margin-bottom: 5px;
      margin-right: 5px;
      margin-top: 5px;
      min-height: 30px;
      min-width: 50px;
      padding: 5px;

      &-text {
        align-items: center;
        display: flex;
      }

      svg {
        height: 14px;
        width: 14px;
      }

      path {
        fill: $color-white;
      }
    }

    &__LinkedinBadge {
      background-color: rgba(0, 119, 183, .6);
      border-radius: $size-border-radius;
      color: $color-white;
      display: inline-block;
      margin-top: 5px;
      padding: 5px 10px;

      &-text {
        align-items: center;
        display: flex;
      }

      svg {
        border-radius: 2px;
        height: 18px;
        margin-right: 3px;
        padding: 3px;
        width: 18px;
      }
    }

    &__video-play {
      align-items: center;
      bottom: 0;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;

      svg {
        height: 60px;
        width: 60px;

        @media screen and (min-width: $screen-sm) {
          height: 40px;
          width: 40px;
        }

        @media screen and (min-width: $screen-md) {
          height: 60px;
          width: 60px;
        }
      }

      path {
        fill: $color-secondary-font;
      }
    }

    &__actions {
      align-items: center;
      bottom: 15px;
      color: $color-white;
      display: flex;
      font-size: 18px;
      font-weight: 200;
      position: absolute;
      width: 85%;

      .chq-chr {
        margin-bottom: 0;
      }

      .empty-cheer {
        path {
          fill: $color-white;
        }

        circle {
          fill: $color-white;
        }
      }

      .Messages {
        &__container {
          margin-right: 15px;
          position: relative;

          &--badge {
            background-color: $color-primary-yellow;
            border-radius: 45px;
            color: $color-black;
            font-size: 14px;
            line-height: 0;
            min-width: 20px;
            padding: 10px 5px;
            position: absolute;
            right: -7px;
            text-align: center;
            top: -7px;
          }
        }

        &__icon {
          height: 30px;
          margin-bottom: 3px;
          width: auto;
        }
      }
    }
  }

  .PhotoGroup {
    &-2 > div {
      &:nth-child(1),
      &:nth-child(2) {
        height: 250px;
        width: 50%;

        @media screen and (max-width: $screen-sm) {
          height: 120px;
        }
      }
    }

    &-3 > div {

      &:nth-child(1) {
        height: 380px;
        width: 100%;

        @media screen and (max-width: $screen-sm) {
          height: 200px;
        }
      }

      &:nth-child(2),
      &:nth-child(3) {
        height: 250px;
        width: 50%;

        @media screen and (max-width: $screen-sm) {
          height: 120px;
        }
      }
    }

    &-4 > div {
      &:nth-child(1) {
        height: 380px;
        width: 100%;

        @media screen and (max-width: $screen-sm) {
          height: 200px;
        }
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        height: 200px;
        width: 33.33%;

        @media screen and (max-width: $screen-sm) {
          height: 100px;
          width: 50%;
        }
      }

      &:nth-child(4) {
        @media screen and (max-width: $screen-sm) {
          display: none;
        }
      }
    }

    &-5 > div {
      &:nth-child(1),
      &:nth-child(2) {
        // flex-grow: 2;
        height: 280px;
        width: 50%;
      }

      &:nth-child(1) {
        @media screen and (max-width: $screen-sm) {
          height: 200px;
          width: 100%;
        }
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        @media screen and (max-width: $screen-sm) {
          height: 100px;
        }
      }

      &:nth-child(3),
      &:nth-child(4) {
        @media screen and (max-width: $screen-sm) {
          display: none;
        }
      }

      &:nth-child(2),
      &:nth-child(5) {
        @media screen and (max-width: $screen-sm) {
          height: 120px;
          width: 50%;
        }
      }
    }

    &-6 > div {
      &:nth-child(1),
      &:nth-child(2) {
        height: 250px;
        width: 50%;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        .Story {
          &__information {
            .chq-edi-op {
              font-size: 16px;
            }

            .chq-ffd-tags {
              max-height: 26px;

              .chq-bdg {
                font-size: 12px;
              }
            }
          }

          &__creator {
            .chq-tmb {
              height: 16px;
              width: 16px;
            }

            span {
              font-size: 12px;
            }
          }
        }
      }

      @media screen and (max-width: $screen-sm) {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          height: 120px;
          width: 33.33%;
        }
      }
    }
  }

  .MultiImage__container {
    align-items: center;
    background-color: rgba(136, 136, 136, .8);
    border-radius: 6px;
    bottom: 15px;
    display: flex;
    padding: 5px;
    position: absolute;
    right: 25px;

    svg {
      fill: $color-white;
    }
  }
}

.chq-ffd-tags {
  &__share {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;

    .chq-btn {
      border: 0;
      margin-bottom: 3px;
      padding: 5px 10px;

      &:hover {
        background-color: $color-background;
      }
    }
  }

  &__child {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }
}
