@import '../common';

.stories-video-editor {
  &-btn {
    align-items: center;
    color: $color-primary-font;
    display: flex;
    font-weight: $font-weight-heavy;

    svg {
      @include square(18px);
      margin-right: 5px;
    }

    path {
      fill: $color-primary-font;
    }
  }

  &-modal {
    border-radius: $size-border-radius $size-border-radius 0 0;
    height: calc(100vh - 60px);
    margin: 60px 0 0;
    max-width: 100vw;
    width: 100vw;

    .chq-pan--hd {
      background-color: $color-white;
      border: 1px solid $color-border;
      border-radius: $size-border-radius $size-border-radius 0 0;

      h2 {
        align-items: center;
        color: $color-primary-font;
        display: flex;
        font-size: $font-size-24;
        font-weight: $font-weight-heavy;
        justify-content: space-between;
      }

      &__logo {
        align-items: center;
        display: flex;

        img {
          margin-left: 5px;
          margin-right: 15px;
          width: 44px;
        }
      }

      .chq-pan--hd__title {
        display: none;

        @media only screen and (min-width: $screen-xl) {
          display: block;
        }

        &__smaller {
          display: none;

          @media only screen and (min-width: $screen-lg) {
            display: block;
          }

          @media only screen and (min-width: $screen-xl) {
            display: none;
          }
        }
      }

      &__buttons {
        align-items: center;
        display: flex;
        font-size: $font-size-normal;
        font-weight: $font-weight-normal;

        svg {
          margin-right: 5px;
        }

        .Download-Btn {
          align-items: center;
          background-color: $color-border-darker;
          border: $color-border-darker solid 1px;
          border-color: $color-border-darker;
          border-radius: 5px;
          color: $color-black;
          cursor: pointer;
          display: flex;
          font-size: 1em;
          font-weight: $font-weight-normal;
          margin-right: 15px;
          outline: 0;
          padding: 8px 15px;
          text-decoration: none;

          &_disabled {
            cursor: not-allowed;
            opacity: .5;
          }

          .Download-Text {
            margin-right: 10px;
          }

          svg {
            @include square(16px);
            margin-right: 10px;
          }

          path {
            fill: $color-black;
          }
        }

        .chq-btn {
          padding: 8px 15px;
          white-space: nowrap;

          &:hover {
            padding: 7px 14px;
          }
        }
      }

      .chq-mdl--cl {
        opacity: 1;
        top: -45px;

        path {
          fill: $color-white;
        }
      }
    }

    .chq-pan--bd {
      display: flex;
    }

    .Sidebar {
      height: calc(100vh - 76px);
      min-width: 110px;
    }

    .WorkingArea {
      display: flex;
      flex: 1;
      flex-direction: column;
      position: relative;

      &-Top {
        border-left: 2px solid $color-border;
        display: flex;
        max-height: calc(100vh - 135px);
      }

      &-Draggable {
        border-top: 2px solid $color-border;
        cursor: row-resize;
        height: 2px;
        left: 0;
        padding: 2px;
        position: absolute;
        right: 0;
        top: calc(100vh - 336px);
        z-index: $z-index-high;
      }

      .Preview,
      .Toolbar {
        border-left: 2px solid $color-border;
        bottom: 0;
        left: 0;
        min-height: 260px;
        overflow: visible;
        position: absolute;
        right: 0;
        top: calc(100vh - 336px);
      }

      .DisclaimerContainer {
        align-items: center;
        display: flex;
        margin: 1em 0 -1em;
        padding: 6px 12px;

        p {
          margin-bottom: 0;
        }

        &-Text--Icon {
          @include square(22px);
          margin-right: 6px;

          path {
            fill: $color-secondary-font-darker;
          }
        }
      }

      .area {
        &__json {
          align-items: center;
          display: flex;
          justify-content: space-between;
          margin-top: 1rem;
        }

        .error-message {
          color: $color-red;
          font-size: .8em;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &__textarea-json {
          background-color: $color-background;
          border: 1px solid $color-border;
          border-radius: 6px;
          box-shadow: inset .1px .1px .1px rgba(0, 0, 0, .075);
          color: $color-primary-font;
          display: block;
          height: 350px;
          outline: none;
          position: relative;
          resize: none;
          width: 100%;
        }

        &__copy-json {
          align-items: center;
          display: flex;
          justify-content: space-between;
          margin-top: 1rem;

          button {
            margin: 0;
          }
        }
      }
    }

    .sidebar {
      .chq-pan {
        box-shadow: none;
        box-sizing: initial;
      }

      .view-sec {
        li {
          height: auto;
          margin: 0;

          &.selected {
            .Info-Icon {
              background-color: $color-selected-option;
            }
          }
        }

        .chq-btn {
          align-items: center;
          border-radius: 0;
          display: flex;
          flex-direction: column;
          padding: 15px;
          position: relative;

          svg {
            @include square(24px);
            margin-bottom: 5px;

            path {
              stroke: $color-secondary-font-darker !important;
            }
          }

          .Info-Icon {
            @include square(22px);
            background-color: $color-white;
            border-radius: 20px;
            bottom: 30px;
            position: absolute;
            right: 28px;
          }

          .Required-Icon {
            transform: scaleY(-1);

            path {
              fill: $color-red;
            }
          }

          .Completed-Icon {
            path {
              fill: $color-primary-green;
            }
          }
        }

        .chq-btn-blk {
          margin-left: 5px;
        }

        .Tooltip-replace {
          font-size: $font-size-12;
          margin: 0 8px;
          min-width: 133px;
          text-align: center;
        }
      }
    }

    .Stories {
      display: flex;

      &-Container {
        border-right: 2px solid $color-border;
        flex: 1;
        overflow: scroll;
        padding: 15px;

        &__Header {
          display: flex;
          flex-direction: column;
          margin-bottom: 24px;
          position: relative;
        }

        &__Title {
          align-items: center;
          display: flex;
          font-size: $font-size-xl;
          margin: 0 0 15px;

          svg {
            margin-right: 5px;
          }

          .Required-Icon {
            transform: scaleY(-1);

            path {
              fill: $color-red;
            }
          }

          .Completed-Icon {
            path {
              fill: $color-primary-green;
            }
          }
        }

        .gallery-lightbox__title-container {
          margin-bottom: 15px;
        }

        .Story__creator {
          font-size: .9em;
          position: initial;
          width: initial;

          p {
            margin: 0;
          }
        }

        .play-icon {
          @include square(30px);
          left: calc(50% - 15px);
          top: calc(50% - 15px);
        }

        .footer {
          bottom: 15px;
          left: 15px;
          position: absolute;
          right: 25px;

          .chq-btn,
          .chq-abn {
            align-items: center;
            display: flex;
            font-size: .9em;
            font-weight: $font-weight-normal;
            justify-content: center;
            margin-bottom: 0;
            margin-top: 10px;
            padding: 4px;
            width: 100%;

            svg {
              @include square(14px);
              margin: 0 5px 0 0;
            }
          }

          .chq-btn {
            background-color: $color-primary-green;
            border: $color-primary-green solid 1px;
            border-radius: $size-border-radius;
            color: $color-white;

            path {
              fill: $color-white;
            }

            &:hover {
              padding: 5px;
            }
          }

          .chq-btn-iv {
            background-color: $color-white;
            border: $color-white solid 1px;
            border-radius: $size-border-radius;
            color: $color-primary-green;

            path {
              fill: $color-primary-green;
            }

            &:hover {
              padding: 5px;
            }
          }

          .chq-abn {
            background-color: $color-white;
            border: 2px solid $color-primary-font;
            color: $color-primary-font;

            path {
              fill: $color-primary-font;
            }
          }
        }

        .PhotoGroup__thumbnail {
          &:hover,
          &.selected {
            .Story__answer {
              display: block;
            }
          }

          &.selected {
            .background-container {
              background: linear-gradient(0deg, rgba(126, 126, 126, .3) 0%, rgba(126, 126, 126, .7) 100%);
            }
          }
        }

        .slack-channel-field {
          margin-top: 70px;

          .chq-ffd--sl--icon {
            left: .5%;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
          }
        }

        .chq-edi {
          background-color: $color-white;
        }

        .Stories-Templates {
          .Template {
            background-position: center;
            padding: 0;

            .Story__information--profile {
              padding: 0;
            }
          }
        }
      }
    }

    .Preview,
    .Toolbar {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &-Editor {
        display: flex;
        flex: 3;
        flex-direction: column;
        padding: 15px 0 10px;

        &__Controls {
          align-items: center;
          display: flex;
          flex: 2;
          justify-content: space-between;
          margin: 0 0 15px;
          padding: 0 5px;

          .Preview-Btn {
            align-items: center;
            color: $color-primary-font;
            display: flex;
            flex: 1;
            font-size: 1em;
            font-weight: $font-weight-normal;

            svg {
              @include square(18px);
              margin-right: 7px;
            }

            &:disabled {
              cursor: not-allowed;
              opacity: .5;
            }
          }

          &-Play {
            align-items: center;
            display: flex;
            flex: 2;

            .Btn-Play {
              height: 22px;
              margin-right: 15px;

              &:disabled {
                cursor: not-allowed;
                opacity: .5;
              }
            }

            .Video-Duration {
              margin-left: 15px;
            }
          }

          .Video-Utilities {
            display: flex;
            flex: 1;
            justify-content: end;

            .Volume-Btn,
            .Fullscreen-Btn {
              svg {
                @include square(22px);
              }

              &:disabled {
                cursor: not-allowed;
                opacity: .5;
              }
            }

            .Fullscreen-Btn {
              margin-right: 15px;
            }
          }

          path,
          polygon {
            fill: $color-primary-font;
          }
        }

        &__Items {
          display: flex;
          flex: 10;
          flex-direction: column;
          margin: -7px;
          overflow-x: scroll;
          padding: 7px;

          &-Slides {
            align-items: center;
            display: flex;
            flex: 11;
            margin-bottom: 5px;

            &.empty-stories {
              border: 1px dashed $color-border;
              border-radius: $size-border-radius;
              flex: 10;
              justify-content: center;
            }

            &.empty-audio {
              background-color: $color-white;
              border: 1px dashed $color-border;
              border-radius: $size-border-radius;
              flex: 2;
              justify-content: center;

              .Preview-Editor__Items-Empty {
                font-size: .9em;

                svg {
                  @include square(18px);
                }
              }
            }
          }

          &-Empty {
            align-items: center;
            color: $color-secondary-font;
            display: flex;
            font-size: 1.15em;
            font-weight: $font-weight-normal;

            svg {
              @include square(24px);
              margin-right: 10px;
            }

            path {
              fill: $color-secondary-font;
            }
          }

          .WaveformContainer {
            flex: 1;
            margin-top: 5px;
            padding: 0 5px;
          }

          .PlayButton {
            display: none;
          }
        }
      }

      .Story_Preview {
        background-position: center;
        background-repeat: repeat;
        background-size: contain;
        border: 0;
        border-radius: $size-border-radius;
        height: 100%;
        margin: 5px;
        min-width: 100px;
        position: relative;

        .Story__badge {
          bottom: 5px;
          font-size: $font-size-12;
          right: 5px;
        }

        .close-icon {
          background-color: $color-white;
          border-radius: 15px;
          box-shadow: 1px 1px 3px rgba(0, 0, 0, .5);
          display: none;
          left: -5px;
          padding: 5px;
          position: absolute;
          top: -5px;

          &:hover {
            cursor: pointer;
          }
        }

        &:hover {
          .close-icon {
            display: block;
          }
        }
      }
    }

    .Stories-Audios {
      margin: 0 -15px;

      hr {
        height: 1px;
        margin: 0 15px;
        width: auto;
      }

      &__Audio {
        align-items: flex-start;
        border-radius: 4px;
        display: flex;
        padding: 15px;

        &--Media {
          margin-right: 15px;

          .WaveformContainer {
            margin-top: 5px;

            .Wave {
              height: 16px;
            }
          }
        }

        &--Thumbnail {
          align-items: center;
          display: flex;
          justify-content: center;
          position: relative;

          .Audio {
            &--Image {
              background-position: center;
              background-size: cover;
              border-radius: $size-border-radius;
              height: 100px;
              width: 150px;

              &__Overlay {
                background: linear-gradient(0deg, rgba(0, 0, 0, .3) 0%, rgba(0, 0, 0, .7) 100%);
                position: absolute;
              }
            }

            &--Duration {
              background-color: $color-primary-font;
              border-radius: $size-border-radius;
              bottom: 5px;
              color: $color-white;
              font-size: $font-size-12;
              font-weight: $font-weight-normal;
              padding: 2px 4px;
              position: absolute;
              right: 5px;
            }

            &--Icon {
              position: absolute;

              &:hover {
                cursor: pointer;
              }

              path {
                fill: $color-white;
              }
            }
          }
        }

        &--Details {
          flex: 1;
          min-width: 55%;

          &:hover {
            cursor: pointer;
          }

          .Audio {
            &-Header {
              align-items: center;
              display: flex;
              justify-content: space-between;
              margin-bottom: 5px;
              position: relative;

              &__Name {
                font-size: $font-size-normal;
                font-weight: $font-weight-heavy;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 80%;

                @media only screen and (min-width: $screen-xxl) {
                  width: 100%;
                }
              }

              &__Select {
                align-items: center;
                color: $color-primary-green;
                display: flex;
                font-weight: $font-weight-normal;
                padding-left: 15px;
                position: absolute;
                right: 0;
                white-space: nowrap;

                svg {
                  @include square(18px);
                  margin-right: 5px;
                }

                .display {
                  display: none;

                  @media only screen and (min-width: $screen-xxl) {
                    display: block;
                  }
                }

                path {
                  fill: $color-primary-green;
                }

                &.selected {
                  color: $color-primary-green;

                  path {
                    fill: $color-primary-green;
                  }
                }
              }
            }

            &-Author {
              font-weight: $font-weight-normal;
            }
          }

          .chq-ffd-tags {
            margin-top: 10px;

            .chq-bdg {
              font-size: .85em;
            }

            .ExpandOptions__plus-option,
            .ExpandOptions__minus-option {
              font-size: .85em;

              svg {
                @include square(13px);
                margin-right: 2px;
              }
            }
          }
        }

        &:hover,
        &.selected {

          .Audio-Header__Select {
            display: flex;
          }
        }
      }
    }
  }
}
