@import '../common';

@font-face {
  font-family: 'HelveticaForTargetCHQ';
  font-weight: 700;
  src: url('/fonts/helvetica-for_target/HelveticaforTargetCHQ-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'HelveticaForTargetCHQ';
  font-style: italic;
  font-weight: 700;
  src: url('/fonts/helvetica-for_target/HelveticaforTargetCHQ-BoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'HelveticaForTargetCHQ';
  font-weight: 500;
  src: url('/fonts/helvetica-for_target/HelveticaforTargetCHQ-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'HelveticaForTargetCHQ';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/helvetica-for_target/HelveticaforTargetCHQ-RmPl.otf') format('opentype');
}

@font-face {
  font-family: 'HelveticaForTargetCHQ';
  font-style: italic;
  font-weight: 600;
  src: url('/fonts/helvetica-for_target/HelveticaforTargetCHQ-RmPlIt.otf') format('opentype');
}

@font-face {
  font-family: 'HelveticaForTargetCHQ';
  font-weight: 200;
  src: url('/fonts/helvetica-for_target/HelveticaforTargetCHQ-Thin.otf') format('opentype');
}

@font-face {
  font-family: 'HelveticaForTargetCHQ';
  font-style: italic;
  font-weight: 200;
  src: url('/fonts/helvetica-for_target/HelveticaforTargetCHQ-ThinItalic.otf') format('opentype');
}

@font-face {
  font-family: 'HelveticaForTargetCHQ';
  font-weight: 400;
  src: url('/fonts/helvetica-for_target/HelveticaforTargetCHQ.otf') format('opentype');
}

@font-face {
  font-family: 'HelveticaForTargetCHQ';
  font-weight: 800;
  src: url('/fonts/helvetica-for_target/HelveticaforTargetCHQ-Heavy.otf') format('opentype');
}

@font-face {
  font-family: 'HelveticaForTargetCHQ';
  font-style: italic;
  font-weight: 800;
  src: url('/fonts/helvetica-for_target/HelveticaforTargetCHQ-HeavyItalic.otf') format('opentype');
}

@font-face {
  font-family: 'HelveticaForTargetCHQ';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/helvetica-for_target/HelveticaforTargetCHQ-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'HelveticaForTargetCHQ';
  font-weight: 300;
  src: url('/fonts/helvetica-for_target/HelveticaforTargetCHQ-Light.otf') format('opentype');
}

@font-face {
  font-family: 'HelveticaForTargetCHQ';
  font-style: italic;
  font-weight: 300;
  src: url('/fonts/helvetica-for_target/HelveticaforTargetCHQ-LightItalic.otf') format('opentype');
}

@font-face {
  font-family: 'HelveticaForTargetCHQ';
  font-style: italic;
  font-weight: 500;
  src: url('/fonts/helvetica-for_target/HelveticaforTargetCHQ-MediumItalic.otf') format('opentype');
}

$color-target-red: #c00;
$color-target-white-normal: #fff;
$color-target-dark-normal: #333;

.target {
  &-nav {
    &.chq-nav {
      background-color: $color-target-red;

      .chq-nav {
        &--search {
          input {
            background-color: rgba(255, 255, 255, .3);
          }
        }

        &--items {
          a {
            border-color: $color-target-red;
            color: $color-white;

            &.active {
              border-color: $color-white;
              color: $color-white;

              path {
                fill: $color-white;
              }
            }
          }
        }

        &--notit {
          em {
            background-color: $color-white;
            color: $color-target-red;
          }
        }

        &--admint {
          .Notification-Badge {
            background-color: $color-white;
            color: $color-target-red;
          }

          &:hover {
            background-color: rgba(255, 255, 255, .15);
          }
        }

        &--unseen {
          border-color: $color-white;
        }

        &--profile {
          .chq-pbn {
            color: $color-white;
          }
        }
      }
    }
  }

  &.EmbedStory {
    font-family: 'HelveticaForTargetCHQ', Arial, sans-serif;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $color-target-white-normal;
      font-style: normal;
      font-weight: 700;
      text-transform: capitalize;
    }

    a {
      color: $color-target-red;
      font-size: $font-size-xl;
      font-weight: 700;

      &:hover {
        color: $color-target-red;
      }
    }

    .gallery-lightbox__uploader-creator {
      color: $color-target-red;
      font-size: $font-size-xl;
      font-weight: 700;
    }

    .LandingPageCallout--Preview {
      .Preview {
        &-Header__Image {
          background-position: 90%;

          @media only screen and (min-width: $screen-sm) {
            background-position: 50%;
          }
        }

        &-Header__Text {
          background-image: none;
          font-size: 42px;
          font-weight: 700;
          margin: 0;

          @media only screen and (min-width: $screen-sm) {
            margin: 0 30px;
          }

          @media only screen and (min-width: $screen-md) {
            margin: 0 8.5%;
          }

          @media only screen and (min-width: $screen-lg) {
            margin: 0 11%;
          }
        }
      }
    }

    .gallery-lightbox__creator-information {
      .gallery-lightbox__uploader-name-target {
        color: $color-target-red;
      }
    }

    .gallery-lightbox__uploader-date {
      color: $color-target-dark-normal;
      font-size: $font-size-large;
      font-weight: normal;
    }

    .gallery-lightbox__details-container {
      .scrollable-container {
        .chq-edi-op {
          color: $color-target-dark-normal;
          font-size: $font-size-large;
          font-weight: normal;
        }
      }
    }

    &.gallery-lightbox--Story {
      .FeedItem__preview__title__Story {
        .Title {
          align-items: flex-start;
          color: $color-target-dark-normal;
          display: flex;
          flex-direction: column;
          font-size: $font-size-24;
          font-weight: 700;

          svg {
            @include square(35px);
            margin-bottom: 10px;
          }
        }

        .Subprompt {
          &-Container {
            color: $color-target-dark-normal;
            font-size: $font-size-xl;
            font-weight: 700;
            margin-left: 10px;

            .PromptIcon {
              background: $color-target-red;
            }
          }

          &-Arrow {
            path {
              fill: rgba($color-target-red, .4);
            }
          }
        }
      }

      .chq-ffd-tags {
        .chq-bdg {
          background-color: $color-background;
          color: $color-target-dark-normal;
          font-size: $font-size-12;
          font-weight: bold;
          text-transform: uppercase;

          svg {
            visibility: hidden;
            width: 0;

            path {
              fill: $color-target-dark-normal;
            }
          }
        }
      }
    }

    @media only screen and (max-width: $screen-md) {
      position: relative;

      .EmbedStory__Container {
        .background-container {
          .Story__creator-text {
            font-size: $font-size-xl;
            font-weight: 600;
            line-height: normal;

            &.Story__creator-title {
              font-size: $font-size-large;
              font-weight: 300;
              line-height: normal;
            }
          }
        }

        .Story__response {
          .Story__question {
            -webkit-box-orient: vertical; // sass-lint:disable-line no-vendor-prefixes
            -webkit-line-clamp: 3; // sass-lint:disable-line no-vendor-prefixes
            display: -webkit-box; // sass-lint:disable-line no-vendor-prefixes
            font-size: $font-size-24;
            font-style: normal;
            font-weight: $font-weight-heavy;
            line-height: normal;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .play-container {
      align-items: center;
      background-color: $color-target-red;
      border-radius: 35px;
      cursor: pointer;
      display: flex;
      height: 44px;
      justify-content: center;
      margin-top: 15px;
      width: 117px;
      z-index: 1;

      @media only screen and (min-width: $screen-md) {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .play-text {
        color: $color-target-white-normal;
        font-size: $font-size-normal;
        font-weight: 600;
        margin-right: 8px;
      }

      .TargetPlayButtonIcon {
        @include square(16px);
        min-width: 16px;
      }

      svg {
        fill: $color-target-white-normal;
      }

      @media only screen and (min-width: $screen-md) {
        margin: 0 2.5%;
      }

      @media only screen and (min-width: $screen-lg) {
        margin: 0 2%;
      }
    }
  }
}
