@import '../common';

.LandingPageCallout {
  margin-bottom: -20px;
  margin-top: -90px;

  &--Preview {
    background-color: $color-white;
    min-height: 100vh;

    &__Title {
      align-items: flex-end;
      display: flex;

      h1,
      h4 {
        margin: 0;
      }

      h1 {
        margin-right: 5px;
      }

      h4 {
        margin-left: 5px;
      }
    }

    .Preview {
      border: 0;
      box-shadow: none;
      max-height: initial;
      overflow: initial;
      padding: 0;
      position: relative;

      &-LeftColumn {
        &__Container {
          display: flex;
          margin-bottom: 20px;

          @media only screen and (min-width: $screen-md) {
            flex-direction: column;
            margin-bottom: 0;
          }
        }

        .chq-pan {
          border-radius: 0;
          box-shadow: 0 0 4px rgba(0, 0, 0, .2);
          margin-bottom: 0;

          @media only screen and (min-width: $screen-md) {
            display: none;
          }
        }

        .Filters__button {
          margin-top: 15px;
          padding-right: 0;
          width: 100%;

          .toggle-filters {
            box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
          }

          @media only screen and (min-width: $screen-md) {
            display: none;
          }

          &:last-child {
            padding-left: 15px;
          }
        }
      }

      &-Body {
        margin: 0;
        padding: 0 15px;

        @media only screen and (min-width: $screen-md) {
          padding: 0 7%;
        }

        @media only screen and (min-width: $screen-lg) {
          padding: 0 10%;
        }
      }

      &-Header {
        &__Image {
          background-position: center;
          background-size: cover;
          height: 300px;
          position: relative;
          width: 100%;
        }

        &__Overlay {
          align-items: center;
          background: linear-gradient(90deg, $color-black-alpha-10 0%, $color-black-alpha-40 35%, $color-black-alpha-40 65%, $color-black-alpha-10 100%);
          display: flex;
          height: 100%;
          justify-content: center;
          width: 100%;
        }

        &__Text {
          background-position: 0 100%;
          background-repeat: no-repeat;
          background-size: 100% .2em;
          color: $color-black;
          font-size: 2em;
          margin: 0;
          transition: background-size .25s ease-in;
          width: fit-content;

          @media only screen and (min-width: $screen-sm) {
            color: $color-white;
            margin: 0 auto;
          }
        }
      }

      &-Browser {
        &__Welcome {
          left: initial;
          margin: 30px auto 0;
          padding: 0 5px;
          position: relative;
          right: initial;

          @media only screen and (min-width: $screen-sm) {
            margin: 0 auto;
          }

          @media only screen and (min-width: $screen-md) {
            margin: 45px auto 0;
          }
        }

        &__People {
          margin-top: 30px;
          padding: 0 5px;
        }

        &__Trends {
          padding: 0 5px;
        }

        &__Stories {
          margin-top: 30px;

          h2 {
            padding: 0 5px;
          }

          .Stories__container {
            margin: 20px 0;

            .PhotoGroup__thumbnail {
              padding: 5px;
              position: relative;

              .story--image {
                border-radius: $size-border-radius;
                display: block;
                margin: 0 auto;
                max-height: 540px;
                max-width: 100%;
                min-height: 220px;
                object-fit: cover;
                width: 100%;
              }

              .chq-edi-op {
                section {
                  display: none;
                }
              }

              .Story {
                &__information {
                  &--profile {
                    .background-container {
                      position: relative;
                    }
                  }
                }

                &__creator {
                  align-items: flex-start;
                  margin-bottom: 10px;
                  position: initial;
                  width: 100%;

                  .chq-tmb {
                    height: 45px;
                    margin-top: 4px;
                    min-width: 45px;
                    width: 45px;
                  }

                  p {
                    margin-bottom: 0;
                  }
                }
              }

              &:hover {
                cursor: pointer;

                .Story__information {
                  &--profile {
                    .background-container {
                      background-color: rgba(0, 0, 0, .5);
                      position: relative;

                      .chq-edi-op {
                        display: none;
                      }

                      .Story__question {
                        display: none;
                      }

                      .Story__creator {
                        p {
                          display: none;
                        }

                        .chq-tmb {
                          display: none;
                        }
                      }
                    }
                  }
                }

                .Story__answer {
                  display: block;

                  .background-container {
                    flex-direction: column;
                    overflow: hidden;
                    position: relative;

                    .chq-edi-op {
                      display: block;
                      font-size: $font-size-large;
                      overflow: hidden;

                      section {
                        color: $color-white;
                        display: block;
                        font-size: $font-size-large;
                        text-shadow: none;
                      }
                    }

                    .Story__question {
                      color: $color-white;
                      text-shadow: none;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &-Welcome {
        border: 2px solid;
        border-radius: $size-border-radius;
        font-size: $font-size-large;
        min-height: 70px;
        padding: 15px 15px 15px 40px;
        position: relative;

        &__Icon {
          background-color: $color-white;
          height: 50px;
          left: -25px;
          padding: 5px;
          position: absolute;
          top: -20px;
          width: 50px;

          @media only screen and (min-width: $screen-sm) {
            height: 60px;
            width: 60px;
          }
        }
      }

      &-Logo {
        background-color: $color-white;
        border-radius: 10px;
        box-shadow: 0 0 15px rgba(0, 0, 0, .45);
        height: 140px;
        margin-top: -70px;
        padding: 0;
        width: 140px;

        @media only screen and (min-width: $screen-sm) {
          height: 180px;
          width: 180px;
        }

        &__Icon {
          height: 40px;
          width: 40px;
        }
      }

      &-Tags {
        margin: 0;

        .Tags {
          &__title {
            margin-bottom: 15px;
            margin-top: 30px;

            h2 {
              font-size: $font-size-large;
              text-transform: uppercase;
            }
          }

          .chq-bdg {
            &:hover {
              background-color: $color-bdg-hover;
              cursor: pointer;
            }
          }

          &__minus-option,
          &__plus-option {
            display: inline-block;
            margin-top: 5px;

            a,
            span {
              align-items: center;
              color: inherit;
              display: flex;
              font-weight: $font-weight-heavy;
              text-decoration: none;

              &:hover {
                cursor: pointer;
              }
            }

            svg {
              @include square(16px);
              fill: $color-primary-font;
              padding-right: 2px;
            }
          }
        }

        .chq-ffd-tags {
          align-items: flex-start;
          display: flex;
          flex-direction: column;

          .No-Action {
            &:hover {
              background-color: $color-background-darker;
              cursor: initial;
            }
          }

          .Selected {
            background-color: $color-dark-blue;
            color: $color-white;

            g,
            path {
              fill: $color-white;
            }

            &:hover {
              background-color: lighten($color-dark-blue, 10%);
              cursor: pointer;
            }
          }
        }

        &__Panel {
          margin-bottom: 0;
          margin-top: 0;

          .open {
            margin-bottom: 20px;
          }

          .Tags {
            &:first-child {
              margin-top: -30px;
            }
          }
        }
      }
    }

    h1 {
      font-size: $font-size-26;
      font-weight: $font-weight-heavy;
    }

    h2 {
      font-size: 1.3em;
      margin: 0;
    }
  }

  .Preview-LeftColumn {
    @media only screen and (min-width: $screen-sm) {
      margin-bottom: 15px;
    }

    @media only screen and (min-width: $screen-md) {
      margin-bottom: 0;
    }
  }

  .EmptyResults {
    align-items: center;
    background-image: none;
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    padding-top: 0;
    text-align: left;

    @media only screen and (min-width: $screen-sm) {
      flex-direction: row;
      padding-right: 70px;
    }

    h2 {
      color: $color-primary-light-font;
    }

    img {
      height: 250px;

      @media only screen and (min-width: $screen-sm) {
        margin-right: 30px;
      }
    }
  }

  .EmptyResultsTags {
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: 280px;
    margin-top: 90px;
    padding-top: 210px;
    text-align: center;

    &-feed {
      background-image: url('../../images/no-feed-items.png');
      padding-top: 230px;
      position: relative;
    }

    p {
      color: $color-primary-blue;
      font-size: 30px;
      font-weight: $font-weight-normal;
      margin: 1rem;
    }

    a {
      color: $color-primary-blue;
      font-size: 20px;
      font-weight: $font-weight-normal;
      margin: 1rem 1rem 3rem;
    }
  }

  .underlined {
    text-decoration: underline;
  }

  .back-arrow {
    align-items: center;
    color: $color-white;
    display: flex;
    font-size: 1.1em;
    font-weight: $font-weight-heavy;
    margin-top: -120px;

    svg {
      min-width: 22px;
    }

    path {
      fill: $color-white;

      @media only screen and (min-width: $screen-lg) {
        fill: $color-link-blue;
      }
    }

    @media only screen and (min-width: $screen-lg) {
      color: $color-link-blue;
      left: -110px;
      margin-top: 30px;
      position: absolute;
    }
  }

  .Gray-Button {
    align-items: center;
    background-color: rgba(255, 255, 255, .9);
    border: 0;
    color: $color-black-alpha-70;
    display: flex;
    outline: 0;
    padding: 6px 16px;
    position: relative;
    z-index: $z-index-medium;

    &:hover {
      border: 0;
      padding: 6px 16px;
    }

    path {
      fill: $color-black-alpha-70;
    }

    svg {
      margin: 0 5px 0 0;
    }
  }

  .QuickOptions {
    .QuickOptions__menu {
      svg {
        margin-right: 10px;
      }
    }
  }
}

.LandingPageUserCallout {
  .LandingPageCallout--Preview {
    .Preview {
      &-Browser__Stories {
        h2 {
          font-size: 1.5em;
        }
      }

      &-Header {
        &__Text {
          display: none;

          @media only screen and (min-width: $screen-md) {
            display: block;
          }
        }
      }

      &-Logo {
        margin-top: 15px;

        @media only screen and (min-width: $screen-lg) {
          margin-top: -70px;
        }
      }

      &-Tags {
        margin: 20px 0;

        h2 {
          text-transform: uppercase;
        }
      }
    }

    .Person {
      &--name {
        margin-top: 25px;
      }

      &--title {
        font-weight: $font-weight-normal;
      }
    }

    .e_label {
      border-bottom: 1px solid $color-border;
      display: flex;
      flex-grow: 1;
      margin-left: -30px;
      margin-right: -30px;
      margin-top: 10px;
      padding: 0 30px;

      @media only screen and (min-width: $screen-lg) {
        flex-direction: column;
        padding: 0;
      }

      li {
        border-bottom: 3px solid transparent;
        border-left: 0;
        margin: 0;
        padding-bottom: 5px;

        @media only screen and (min-width: $screen-lg) {
          border-bottom: 0;
          border-left: 3px solid transparent;
          padding-bottom: 5px;
        }

        .chq-btn {
          align-items: center;
          background-color: transparent;
          display: flex;
          padding: 0 10px;

          @media only screen and (min-width: $screen-lg) {
            padding-right: 0;
          }

          svg {
            margin: 0 7px 0 0;
          }

          .chq-nav--unseen {
            border-color: $color-primary-green;
            border-width: .5rem;
            height: 1.3rem;
            left: 0;
            margin-left: 2px;
            position: relative;
            top: -4px;
            width: 1.3rem;
          }
        }

        &.selected {
          background-color: transparent;
          border-bottom: 3px solid $color-primary-green;
          border-left: 0;

          @media only screen and (min-width: $screen-lg) {
            background-color: $color-selected-option;
            border-bottom: 0;
            border-left: 3px solid $color-primary-green;
          }

          .chq-btn {
            background-color: transparent;
          }
        }
      }
    }

    .subtitle {
      color: $color-primary-light-font;
      font-size: $font-size-large;
    }

    .Section__title {
      align-items: center;
      color: $color-primary-light-font;
      display: flex;
      font-size: 18px;
      font-weight: $font-weight-heavy;
      justify-content: space-between;
      margin-bottom: 15px;
    }

    .Section__highlights {
      text-align: left;

      a {
        color: $color-primary-font;
        word-break: break-word;
      }

      p {
        align-items: flex-start;
        display: flex;
        margin-bottom: 15px;
      }

      svg {
        margin-right: 10px;
        min-width: 22px;

        path {
          fill: $color-primary-font;
        }
      }

      &--email {
        .chq-ttp {
          max-width: 90%;

          &--bbl {
            bottom: calc(100% + 8px);
          }
        }
      }

      .chq-ttp {
        &:hover {
          .chq-ttp--bbl {
            left: -15px !important;
            min-width: auto;
            top: -40px;
          }
        }
      }
    }

    .Interests {
      margin-top: 30px;
    }
  }
}

.LandingPageStoriesCallout {
  .LandingPageCallout--Preview {
    align-items: center;
    display: flex;
  }

  .Preview {
    width: 100%;

    &-Body {
      padding: 0;
    }
  }
}

.LandingPageTrendCallout {
  .back-arrow {
    margin-top: -40px;
    white-space: nowrap;

    @media only screen and (min-width: $screen-lg) {
      margin-top: 30px;
    }
  }
}
