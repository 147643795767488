// library-sections.scss

.library-intro {
  &__item-wrapper {
    border-radius: 10px;
    margin-right: 5px;
    width: 33.33%;
  }
}

.library-outro {
  &__item-wrapper {
    border-radius: 10px;
    margin-right: 5px;
    width: 33.33%;
  }
}
