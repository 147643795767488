@import '../common';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  75% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@keyframes scaleIn {
  0% {
    height: 0;
    opacity: 0;
  }

  1% {
    height: 0;
    opacity: .1;
  }

  100% {
    height: 50px;
    opacity: 1;
  }
}


@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes feedIn {
  0% {
    opacity: 0;
    top: 100px;
  }

  75% {
    opacity: .5;
    top: 0;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes popupNotificationIn {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: translate3d(0, calc(200% + 30px), 0) scale3d(0, 1, 1);
  }

  40% {
    animation-timing-function: ease-out;
    opacity: .5;
    transform: translate3d(0, 0, 0) scale3d(.02, 1.1, 1);
  }

  70% {
    opacity: .6;
    transform: translate3d(0, -40px, 0) scale3d(.8, 1.1, 1);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
}
