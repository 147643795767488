$color-assurant-blue: #06c;
$color-assurant-gray: #44464b;
$color-assurant-light-blue: #3385d5;
$color-assurant-orange: #fcc166;

.assurant-nav {
  &.chq-nav {
    background-color: $color-assurant-blue;

    .chq-nav {
      &--search {
        input {
          background-color: $color-assurant-light-blue;
        }
      }

      &--items {
        a {
          border-color: $color-assurant-blue;

          &.active {
            border-color: $color-assurant-orange;
            color: $color-assurant-orange;

            path {
              fill: $color-assurant-orange;
            }
          }
        }
      }

      &--admin {
        em {
          background-color: $color-assurant-orange;
        }
      }

      &--admint {
        &:hover {
          background-color: $color-assurant-light-blue;
        }
      }

      &--unseen {
        border-color: $color-assurant-orange;
      }
    }
  }
}

.assurant {
  font-family: 'Open Sans', sans-serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Open Sans', sans-serif;
  }

  a {
    color: $color-assurant-gray;
  }

  .back-arrow {
    color: $color-assurant-blue;

    path {
      fill: $color-assurant-blue;
    }
  }
}
