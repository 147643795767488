@import '../common';

.Welcome {
  background-color: $color-primary-blue;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: $z-index-medium;

  > .row {
    height: 100%;
    margin: 0;
  }

  h2 {
    color: $color-primary-blue;
    font-size: $font-size-24;
    margin-bottom: 12px;
  }

  h3 {
    font-size: $font-size-normal;
    line-height: 1.4;
    margin-bottom: 25px;
  }

  &__right {
    background-color: $color-primary-blue;
    height: 100%;
    padding: 0;
    position: fixed;
    right: 0;
  }

  &__Profile,
  &__Categories,
  &__Interests,
  &__Stories {
    @media only screen and (max-width: $screen-md) {
      padding-top: 10px;
    }
  }

  &__Categories {
    .Interests__Categories {
      display: block !important;
    }
  }

  .logo {
    display: block;
    margin: 30px auto 35px;
    max-width: 300px;
    width: 100%;
    @media only screen and (max-width: $screen-md) {
      margin-bottom: 0;
    }
  }

  &__Steps {
    background-color: $color-white;
    bottom: 0;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, .2);
    left: 0;
    padding: 15px;
    position: fixed;
    right: 41.6667%;
    text-align: center;
    z-index: $z-index-low;

    .footer_container {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (min-width: $screen-sm) {
        flex-direction: row;
      }

      .event_footer_left {
        margin-top: 5px;
        order: 1;

        @media screen and (min-width: $screen-sm) {
          margin-top: 0;
          order: initial;
        }
      }

      .event_footer_right {
        margin-top: 5px;
        order: 2;

        @media screen and (min-width: $screen-sm) {
          margin-top: 0;
          order: initial;
        }
      }

      .button_container {
        width: 95%;

        @media screen and (min-width: $screen-sm) {
          width: initial;
        }
      }

      .invite_container {
        align-items: center;
        display: flex;
        margin: 0 15px;
        max-width: 95%;
        min-width: 0;

        @media screen and (min-width: $screen-sm) {
          max-width: initial;
        }

        .invite_legend {
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .icon {
          background: $color-primary-blue;
          border: 1px solid $color-primary-blue;
          border-radius: 15px;
          margin-right: 5px;
          min-width: 22px;

          path {
            fill: $color-white;
          }
        }
      }
    }

    @media only screen and (max-width: $screen-md) {
      padding-right: 15px;
      right: 0;

      .pull-left,
      .pull-right,
      .chq-btn {
        width: 100%;
      }
    }

    .pagination {
      margin: 8px auto 0;
      text-align: center;

      .Dot {
        display: inline-block;

        svg {
          path {
            fill: $color-secondary-light-font;
          }
        }

        &:disabled {
          cursor: not-allowed;
        }

        &:focus {
          outline: none;
        }

        &.current,
        &.complete {
          path {
            fill: $color-link-blue;
          }
        }
      }
    }
  }

  &__Accept {
    h2 {
      margin-bottom: 18px;
      text-align: left;
    }
  }

  &__left {
    background-color: $color-white;
    min-height: 100%;
    padding: 0 25px 80px;
    position: relative;

    @media screen and (min-width: $screen-sm) {
      padding: 0 85px 80px;
    }

    @media (min-width: 992px) and (max-width: 1140px) {
      padding: 0 25px 80px;
    }
  }

  &__Profile {
    .chq-ffd {
      &--sl--opts-open {
        z-index: $z-index-medium;
      }

      &--im {
        border: 0;
        box-shadow: none;
        margin: 25px;
        min-height: 160px;

        .chq-ipv {
          border-radius: 200px;
        }
      }
    }
  }

  &__Success {
    display: block;
    margin: 80px auto;
    max-width: 600px;

    &__Preview {
      min-height: 160px;
      padding: 15px;
      position: relative;

      h1 {
        font-size: $font-size-30;
        margin-top: 6px;
      }

      .Thumbnail {
        border-radius: 100px;
        display: block;
        height: 115px;
        width: 115px;

        @media only screen and (min-width: $screen-md) {
          left: 20px;
          position: absolute;
          top: 20px;
        }
      }

      .profile-details {
        margin-left: 150px;
      }

      @media only screen and (max-width: $screen-md) {
        .image {
          left: auto;
          margin: 0 auto;
          position: relative;
          top: 0;
        }

        .profile-details {
          margin-left: 0;
        }
      }
    }

    &--Stories {
      .Stories__container {
        box-shadow: none;
        padding: 15px 0;
      }
    }
  }

  &__Wrapper {
    height: 100%;
    overflow: auto;
    padding-bottom: 50px;

    @media only screen and (max-width: $screen-md) {
      max-width: 100%;
    }

    .Interests__Categories {
      @media only screen and (max-width: $screen-xs) {
        display: block;
      }
    }
  }

  &__Stories {
    &--Answered {
      border: 1px solid $color-primary-green;
      border-radius: 6px;
    }

    &--Empty {
      align-items: center;
      border: 1px dashed $color-primary-light-font;
      border-radius: $size-border-radius;
      display: flex;
      margin: 30px;
      padding: 15px;

      svg {
        height: 20px;
        margin-right: 5px;
        width: 20px;
      }

      .create-own-story {
        display: none;
        height: 190px;

        @media only screen and (min-width: $screen-xs) {
          display: block;
        }
      }

      .Trending-Stories__overlay {
        display: none;
        height: 190px;

        @media only screen and (min-width: $screen-xs) {
          display: block;
        }
      }

      &__Description {
        margin-bottom: 30px;
        padding: 15px;

        .title {
          align-items: center;
          color: $color-primary-blue;
          display: flex;
          font-size: 18px;
          margin: 0 0 15px;
        }

        p {
          color: $color-secondary-font;
        }

        .chq-btn {
          border-radius: 30px;
          font-size: 14px;
          padding: 6px 12px;

          &:hover {
            border: $color-primary-green solid 1px;
            padding: 6px 12px;
          }
        }
      }

      .Story-Tags {
        &__container {
          svg {
            height: 15px;
            width: 15px;

            path {
              fill: $color-white;
            }
          }
        }

        &__item {
          color: $color-white;
        }
      }
    }

    &--Container {
      position: relative;

      &__Close {
        align-items: center;
        background-color: $color-red;
        border-radius: 40px;
        display: flex;
        height: 35px;
        justify-content: center;
        padding: 5px;
        position: absolute;
        right: -10px;
        top: -10px;
        width: 35px;
        z-index: $z-index-medium;

        svg {
          height: 20px;
          width: 20px;
        }

        path {
          fill: $color-white;
        }
      }
    }
  }

  .Trending-Stories {
    box-shadow: none;
    margin-left: -15px;
    margin-right: -35px;
  }
}
