@import '../common';

.Icon {
  background-color: $color-primary-green;
  border-radius: 15px;
  height: 30px;
  margin-top: -3px;
  padding: 4px 5px;
  width: 30px;

  svg {
    height: 20px;
    margin-bottom: -2px;
    margin-top: 2px;
    width: 20px;

    path {
      fill: $color-white;
    }
  }
}
