@import '../common';

.survey-results {
  padding-bottom: 2rem;

  &__percentages {
    color: $color-primary-light-font;
    padding: 0 3rem;
  }

  .chq-charts {
    &--wrap,
    &--chart {
      max-height: 375px;
      min-height: 188px;
      width: 375px;
    }

    &--export {
      svg {
        box-sizing: content-box;
      }
    }

    &--info {
      padding-top: 12%;

      &-show {
        padding-top: 10%;
      }
    }

    &--stars {
      padding-top: 20px;

      rect {
        fill: $color-primary-yellow;
      }
    }

    &--wrap {
      margin: 10px auto;
      max-width: 90%;
    }
  }
}

.survey-item-result {
  position: relative;

  h2 {
    font-size: $font-size-large;
    line-height: 1.4;
  }

  h2,
  h4 {
    font-weight: $font-weight-normal;
  }

  &.open {
    padding-bottom: 70px;

    h2 {
      padding-bottom: 3rem;
    }
  }

  small {
    font-weight: $font-weight-light;
    margin-left: .5rem;
  }

  .toggle {
    color: $color-primary-font;
    cursor: pointer;
    font-size: 2.5rem;
    line-height: 1.8rem;

    &:hover {
      text-decoration: none;
    }
  }

  &__rating {
    svg {
      fill: $color-primary-yellow;
      height: 50px;
      width: 50px;
    }
  }

  &__partial-star {
    @include square(50px);
    background-color: $color-white;
    display: inline-block;
  }

  &__rate {
    bottom: 0;
    color: $color-primary-light-font;
    font-weight: $font-weight-light;
    margin-top: 8px;
    position: absolute;
    right: 45px;

    p {
      margin-bottom: 5px;
      text-align: right;
    }
  }
}
