@import '../common';

.Select-Tags {
  &__Container {
    border-top: 1px solid $color-border-darker;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    z-index: $z-index-medium;

    .share-with-field {
      margin-bottom: 0;

      .chq-ffd {
        &--lb {
          position: absolute;
          top: 10px;
          width: 100%;
          z-index: $z-index-low;

          .chq-ttp {
            position: absolute;
            right: 10px;

            .chq-ttp--bbl {
              top: -80px;
              width: max-content;
            }
          }

          svg {
            height: 22px;
            margin-left: 10px;
            width: 22px;
          }

          path {
            fill: $color-primary-green;
          }
        }

        &--ctrl {
          align-items: center;
          border: 0;
          display: flex;
          flex-wrap: wrap;
          font-size: initial;
          margin-bottom: 5px;
          padding: 6px 12px;

          &:hover {
            box-shadow: none;
          }

          .chq-bdg {
            align-items: center;
            display: flex;
            flex-direction: row-reverse;
            width: initial;

            svg {
              margin: 0 0 0 6px;
            }

            .category-icon {
              height: 18px;
              margin: 0 6px 0 0;
              width: 18px;
            }
          }
        }

        &--sl {
          margin-left: 30px;
          margin-right: 30px;
          z-index: $z-index-medium;

          &--place {
            color: $color-primary-green;
            font-size: initial;
          }

          &--opts {
            margin-left: -30px;
            max-height: 200px;
            overflow-y: auto;

            .option-icon {
              height: 20px;
              width: 20px;

              g {
                fill: $color-white;
              }
            }
          }
        }
      }
    }
  }
}
