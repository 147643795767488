@import '../common';

.EngagementRating {
  height: 24px;

  .options {
    display: flex;
    height: 100%;
    margin-right: 10px;
    padding: 2px;

    :first-child {
      border-radius: 6px 0 0 6px;
    }

    :last-child {
      border-radius: 0 6px 6px 0;
    }
  }

  .square {
    background-color: #f3f3f3;
    margin: 2px;
    width: 15px;
  }
}
