@font-face {
  font-family: 'Akzidenz-Grotesk Std';
  font-weight: 700;
  src: url('/fonts/akzid_gro_std/AkzidGroStdBol.otf') format('opentype');
}

@font-face {
  font-family: 'Akzidenz-Grotesk Std';
  font-style: italic;
  font-weight: 700;
  src: url('/fonts/akzid_gro_std/AkzidGroStdBolIta.otf') format('opentype');
}

@font-face {
  font-family: 'Akzidenz-Grotesk Std';
  font-weight: 400;
  src: url('/fonts/akzid_gro_std/AkzidGroStdReg.otf') format('opentype');
}

@font-face {
  font-family: 'Akzidenz-Grotesk Std';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/akzid_gro_std/AkzidGroStdIta.otf') format('opentype');
}

@font-face {
  font-family: 'Akzidenz-Grotesk Std';
  font-weight: 300;
  src: url('/fonts/akzid_gro_std/AkzidGroStdLig.otf') format('opentype');
}

@font-face {
  font-family: 'Akzidenz-Grotesk Std';
  font-style: italic;
  font-weight: 300;
  src: url('/fonts/akzid_gro_std/AkzidGroStdLigIta.otf') format('opentype');
}

@font-face {
  font-family: 'Akzidenz-Grotesk Std';
  font-weight: 500;
  src: url('/fonts/akzid_gro_std/AkzidGroStdMed.otf') format('opentype');
}

@font-face {
  font-family: 'Akzidenz-Grotesk Std';
  font-style: italic;
  font-weight: 500;
  src: url('/fonts/akzid_gro_std/AkzidGroStdMedIta.otf') format('opentype');
}

@font-face {
  font-family: 'Akzidenz-Grotesk Std';
  font-weight: 900;
  src: url('/fonts/akzid_gro_std/AkzidGroStdSup.otf') format('opentype');
}

@font-face {
  font-family: 'Akzidenz-Grotesk Std';
  font-style: italic;
  font-weight: 900;
  src: url('/fonts/akzid_gro_std/AkzidGroStdSupIta.otf') format('opentype');
}

$color-irc-yellow: #ffc72c;

.international-rescue-committee-nav {
  &.chq-nav {
    background-color: $color-black;

    .chq-nav {
      &--search {
        input {
          background-color: rgba(255, 255, 255, .3);
        }
      }

      &--items {
        a {
          border-color: $color-black;
          color: $color-white;

          &.active {
            border-color: $color-irc-yellow;
            color: $color-irc-yellow;

            path {
              fill: $color-irc-yellow;
            }
          }
        }
      }

      &--notit {
        em {
          background-color: $color-irc-yellow;
          color: $color-black;
        }
      }

      &--admint {
        .Notification-Badge {
          background-color: $color-irc-yellow;
          color: $color-black;
        }

        &:hover {
          background-color: rgba(255, 255, 255, .15);
        }
      }

      &--unseen {
        border-color: $color-irc-yellow;
      }
    }
  }
}

.international-rescue-committee {
  font-family: 'Akzidenz-Grotesk Std', Arial, sans-serif;

  &.gallery-lightbox--Story {
    .Title {
      font-family: 'Akzidenz-Grotesk Std', Arial, sans-serif;
    }
  }

  .chq-ffd-tags {

    .chq-bdg {
      font-family: 'Akzidenz-Grotesk Std', Arial, sans-serif;

      &:hover {
        font-family: 'Akzidenz-Grotesk Std', Arial, sans-serif;
      }
    }
  }

  .LandingPageCallout--Preview {
    .Preview-Header {
      &__Image {
        background-position: 100%;
      }

      &__Overlay {
        background: none;
      }

      &__Text {
        background-image: none !important;
        color: $color-black;
        font-size: 34px;
        margin-bottom: 30px;
        padding: 0 30px;
        text-align: left;

        &--underlined {
          background-image: linear-gradient(120deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%);
          background-position: 0 100%;
          background-repeat: no-repeat;
          background-size: 100% .35em;
          margin-left: 6px;
          padding-left: 4px;
        }
      }
    }
  }

  .Story__question {
    font-family: 'Akzidenz-Grotesk Std', Arial, sans-serif;
  }
}
