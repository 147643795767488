@import '../common';

.chq-event-edit {
  &--edit-type {
    h2 {
      color: $color-primary-light-font;
      font-size: $font-size-large;
      margin-top: 10px;
    }

    &--next {
      clear: left;
      color: $color-primary-light-font;
      display: block;
      margin-top: 10px;
    }
  }

  &--times {
    margin: 20px -15px;
  }

  &--add-tog {
    display: block;
    margin-bottom: 15px;
    outline: none;

    h2 {
      font-size: $font-size-xl;
    }

    svg {
      margin: 4px 0 -4px;
    }

    path {
      fill: $color-link-blue;
    }
  }
}

.chq-event-type-card {
  $height: 180px;
  $height-title: 50px;
  $height-body: $height - $height-title;

  height: $height;
  margin-bottom: 15px;
  padding: 0 10px 0 0;

  p {
    margin-bottom: 0;
  }

  &--title {
    height: $height-title;
    overflow: hidden;
    position: relative;
    vertical-align: bottom;

    p {
      bottom: 8px;
      position: absolute;
    }
  }

  .chq-pbn {
    background-color: $color-border;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    height: $height-body;
    position: relative;
    width: 100%;

    .cover {
      background-color: $color-black-alpha-40;
      border: 4px solid $color-primary-blue;
      bottom: 0;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition-duration: .3s;
      transition-property: opacity;

      &:hover {
        opacity: 1;
      }

      p {
        &.description {
          color: $color-white;
          font-size: $font-size-small;
          font-weight: $font-weight-heavy;
          padding: 8px;
        }
      }
    }
  }
}
