@import '../common';

.cheers {
  display: inline-block;
  vertical-align: middle;

  .chq-cbn + .chq-ttp {
    .chq-chr {
      margin-bottom: 3px;
    }
  }

  &--cheer-list {
    .chq-chr {
      margin-bottom: 3px;
    }
  }

  .cheers--more {
    margin: 10px 0 0 5px;
    vertical-align: top;
  }

  &--row {
    display: flex;
    padding-top: 10px;

    & + .cheers--row {
      border-top: 1px solid $color-background;
    }

    .chq-tmb {
      @include square(35px);
      border-radius: 30px;
      margin-right: 10px;
    }
  }
}
