.PromptShareOptions__Buttons {
  align-items: center;
  display: flex;

  .chq-pbn  {
    @include square(35px);
    align-items: center;
    background-color: $color-dark-blue;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    padding: 5px;

    svg {
      @include square(18px);
    }
  }

  .Slack-Image {
    @include square(18px);
  }

  path {
    fill: $color-white;
  }
}
