@import '../common';


.video-studio-modal-v2 {
  * {
    user-select: none;
  }

  .chq-wrn {
    margin-bottom: 0;
  }

  .chq-ffd--im {
    .chq-ffd--video {
      display: none;
    }
  }

  .RightClick-container {
    position: absolute;

    svg {
      @include square(16px);
      margin-right: 5px;
    }

    path {
      fill: $color-primary-font;
    }
  }

  .Animations {
    .ColorField-Btn {
      border: 1px solid $color-primary-green;
    }

    .Pickers-Container {
      left: 0;
      right: initial;
    }
  }

  .video-studio-title-container {
    display: flex;
  }

  .VideoPlayer {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;

    .chq-ldr {
      position: absolute;
    }

    &-Iframe {
      background-color: $color-background;
      height: 100%;
      position: relative;
      width: 100%;
    }

    .LoaderContainer {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      .story-progressbar {
        bottom: 0;
        position: absolute;
      }

      .chq-ldr {
        align-items: center;
        bottom: 0;
        display: flex;
        left: 0;
        padding: 70px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  .Addtext-Button {
    background-color: $color-white;
    border-color: $color-secondary-font;
    color: $color-secondary-font;
    cursor: pointer;
    outline: 0;
    padding: 5px 20px;

    &:focus {
      outline: none;
    }

    &:hover {
      padding: 4px 19px;
      text-decoration: none;
    }

    path {
      fill: $color-secondary-font;
    }
  }

  .Add-Media {
    align-items: flex-start;
    display: flex;
    margin-left: -2px;
    outline: 0;

    label {
      margin-bottom: 0;

      .chq-ffd--im {
        height: 34.85px;
        margin-bottom: 0;
      }
    }

    .chq-btn {
      background-color: $color-white;
      border-color: $color-primary-green;
      color: $color-primary-green;
      cursor: pointer;
      max-width: 130px;
      outline: 0;
    }

    .chq-ffd--im {
      border: 0;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      text-decoration: none;
    }

    path {
      fill: $color-primary-green;
    }
  }

  .chq-ffd--ctrl--validation {
    top: 36px;
  }

  .boolean {
    background-color: $color-background-darker;
    border-radius: 25px;
    color: $color-secondary-font-darker;
    margin-left: 15px;
    padding: 5px 10px;

    .chq-cmk-on {
      color: $color-primary-green;
    }

    .chq-cmk-off {
      svg {
        border-color: $color-secondary-font-darker;
      }
    }

    svg {
      background-color: $color-white;
      border-color: $color-white;
      margin-right: 3px;
    }

    .chq-cmk-ck {
      svg {
        border-color: $color-primary-green;
      }
    }
  }

  .boolean-field-option {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .boolean-option {
    background-color: $color-background-darker;
    border-radius: 25px;
    color: $color-secondary-font-darker;
    margin-left: 2px;
    padding: 5px 10px;

    .chq-cmk-on {
      color: $color-primary-green;
    }

    .chq-cmk-off {
      svg {
        border-color: $color-secondary-font-darker;
      }
    }

    svg {
      background-color: $color-white;
      border-color: $color-white;
      margin-right: 3px;
    }

    .chq-cmk-ck {
      svg {
        border-color: $color-primary-green;
      }
    }
  }

  .ColorField-Btn {
    &[disabled] {
      cursor: not-allowed;
      opacity: .5;
    }
  }

  .closemod-btn {
    font-weight: $font-weight-normal;
    margin-left: 25px;
    margin-right: 5px;

    svg {
      height: 30px;
      margin-right: 5px;
      top: 2px;
      width: 30px;
    }
  }

  .chq-download {
    align-items: center;
    color: $color-black;
    display: flex;
    font-weight: $font-weight-normal;
    text-decoration: none;
  }

  .chq-ffd--sl {

    .chq-ffd--ctrl {
      align-items: center;
      border: 2px solid $color-border;
      border-radius: 4px;
      display: flex;
      flex-wrap: wrap;
      padding-left: 7px;
    }
  }

  .AlignmentOptions {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;

    .btn-text-align-option {
      align-items: center;
      background-color: transparent;
      border-color: transparent;
      cursor: pointer;
      outline: 0;
      padding: 7px 30px;

      &:hover {
        background-color: $color-border;
        border-width: 1px;
      }

      path {
        fill: $color-primary-font;
      }

      &:disabled {
        background-color: rgba(239, 239, 239, .3);
        color: rgba(59, 59, 59, .3);

        &:hover {
          cursor: not-allowed;
        }
      }
    }

    .btn-align-option-active {
      background-color: $color-border;
      border-radius: 1px;
      border-width: 1px;
    }

    .pnl-align-option {
      align-content: start;
      border: 2px solid $color-border;
      border-radius: $size-border-radius;
      display: flex;
      justify-content: flex-start;
      margin: 0;
      width: fit-content;
    }

    .chq-ffd-border {
      margin: 5 0 0 15px;

      .chq-ffd--ctrl {
        margin: 0;
      }
    }
  }

  .chq-ffd-color {
    display: flex;
  }

  .chq-ffd-color-filter {
    flex: 2 1;
    margin-right: 10px;

    .chq-ffd--ctrl {
      border: 2px solid $color-border;
      border-radius: $size-border-radius;
      margin: 0;
    }
  }

  .chq-ffd-filter-value {
    flex: 1 1;
    margin: 20px 0;

    .chq-ffd--ctrl {
      border: 2px solid $color-border;
      border-radius: $size-border-radius;
      margin: 0;
    }
  }

  .chq-pan--bd {
    font-weight: 400;
  }

  .chq-pan--hd__buttons {
    .btn-dropdown {
      border-color: $color-border;
      border-width: 2px;
      margin-right: 15px;
      padding: 8px 20px;
      text-decoration: none;

      &.Download-Btn {
        border: $color-border-darker solid 1px;
        border-color: $color-border-darker;
      }

      &:hover {
        border-color: $color-border-darker;
        padding: 8px 20px;
      }

      .section {
        font-size: $font-size-12;
        margin-left: 3px;
      }

      .AspectRatio-container {
        margin-right: 10px;
      }
    }

    .ColorField {
      &-Container {
        margin-right: 15px;
      }

      &-Btn {
        height: 38px;
        margin-bottom: 0;
        min-width: 140px;
      }
    }

    .Undo-Icon {
      transform: scaleX(-1);
    }

    .chq-ttp--bbl {
      margin-bottom: -40px;
      top: calc(100% + 2px);

      .Tooltip-uploaded {
        margin: 0;
        min-width: 200px;
        text-align: center;
      }

      .chq-ttp--tri {
        content: '';
        display: block;
        height: 12px;
        left: calc(50% - -1px);
        top: -5px;
        z-index: -1;
      }
    }

    .Video-Options {
      align-items: center;
      display: flex;
      flex: 1;
      padding: 0 15px;

      .chq-pbn {
        &:disabled {
          opacity: .5;

          &:hover {
            cursor: not-allowed;
          }
        }
      }

      svg {
        @include square(26px);
      }
    }
  }

  .MediaVersion {
    margin-left: 16px;

    .chq-dd {
      [role='option'] {
        margin-left: -15px;
        margin-right: -15px;
        padding: 8px 15px;
      }

      &--caret {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 8px solid $color-secondary-font;
        height: 0;
        position: absolute;
        right: 18px;
        top: calc(50% - 1px);
        transition: transform 200ms;
        vertical-align: middle;
        width: 0;
      }

      .btn-dropdown {
        align-items: flex-end;
        border: unset;
        display: flex;
        text-decoration: none;

        svg {
          height: 24px;
          width: 24px;
        }

        path {
          fill: $color-black-alpha-60;
        }

        span {
          color: $color-black-alpha-60;
          font-size: $font-size-15;
          font-weight: $font-weight-heavy;
          margin-left: .5em;
          white-space: nowrap;
        }
      }

      .autosave {
        font-style: italic;
      }

      .options {
        margin-bottom: 0;
        max-height: 500px;
        min-width: 390px;
        overflow: auto;
        padding: 16px;
        top: 45px;
        z-index: 45;

        .title__container {
          align-items: baseline;
          display: flex;
          flex-direction: column;
          font-size: $font-size-17;
          margin-bottom: 10px;

          .title {
            font-weight: $font-weight-heavy;
            margin-bottom: .5em;
          }

          .subtitle {
            font-weight: $font-weight-normal;
          }
        }

        .option {
          .container {
            align-items: flex-start;
            display: flex;
            position: relative;
            width: 100%;

            .icon {
              left: 0;
              position: absolute;

              path {
                fill: $color-primary-green;
              }
            }

            .description {
              align-items: baseline;
              display: flex;
              flex-direction: column;
              font-size: $font-size-normal;
              font-weight: $font-weight-heavy;
              line-height: 1.3em;
              margin-left: 1em;
              margin-right: 28px;

              .updated {
                color: $color-secondary-font;
                font-weight: $font-weight-normal;
              }

              .creator {
                color: $color-primary-light-font;
                font-weight: $font-weight-normal;
              }
            }

            .version-download {
              align-items: center;
              cursor: default;
              display: flex;
              height: 45px;
              margin-left: 83%;
              padding: 12px;
              position: absolute;
              top: -4px;

              .button-version {
                cursor: pointer;
                pointer-events: auto;
                position: relative;
                z-index: 30;

                svg {
                  fill: $color-secondary-font;
                }
              }
            }
          }
        }
      }
    }
  }

  .Autosave {
    align-items: flex-end;
    display: flex;
    margin-left: 24px;

    svg {
      height: 24px;
      width: 24px;
    }

    path {
      fill: $color-black-alpha-60;
    }

    span {
      color: $color-black-alpha-60;
      font-size: $font-size-15;
      font-weight: $font-weight-heavy;
      line-height: 1.5em;
      margin-left: .3em;
      white-space: nowrap;
    }
  }

  .chq-dd {
    & [role='option'] [data-chq-dropdown-check] {
      display: none;
    }

    .AspectRatio {
      &-container {
        align-items: center;
        display: flex;
        font-size: $font-size-normal;
        font-weight: $font-weight-normal;
      }

      &-Options {
        top: 45px;
        white-space: nowrap;
        z-index: 45;

        .AspectRatio-Option {
          padding: .5em 1em;
        }

        .section {
          color: #9c9fa7;
          font-size: $font-size-nav;
          margin-left: 3px;
        }
      }

      &-Option {
        &[role='option'] {
          &[aria-selected='true'] {
            .section {
              color: $color-primary-green;
            }
          }
        }
      }

      &-Icon {
        margin: 0 10px 0 0;

        path {
          fill: transparent;
        }
      }
    }

    .Download {
      &-container {
        align-items: center;
        display: flex;
        font-size: $font-size-normal;
        font-weight: $font-weight-normal;
      }

      &-Options {
        text-align: left;
        top: 3.7em;
        z-index: 1;

        .Download-Option {
          font-weight: $font-weight-normal;
          padding: .5em 1em;
        }

        .section {
          color: #9c9fa7;
          font-size: $font-size-nav;
          margin-left: 3px;
        }
      }
    }
  }

  .Option-Subtitles-btn {
    align-items: center;
    display: flex;
    font-size: 16px;

    svg {
      margin: 0;
    }

    path {
      fill: $color-secondary-font;
    }
  }

  .Dropdown-Option-Sub {
    font-size: 16px;

    .DownloadTh {
      &-container {
        align-items: center;
        display: flex;
        font-size: $font-size-normal;
        font-weight: $font-weight-normal;
      }

      &-Options {
        font-size: 16px;
        margin: 14px 0 0;
        padding: 10px 0;
        right: 0;
        text-align-last: left;
        top: 25px;
        white-space: nowrap;
        z-index: 25;


        .DownloadDp-Option {
          align-items: center;
          display: flex;
          font-weight: $font-weight-normal;
          padding: .5em 1em;

          .chq-pbn {
            align-items: center;
            color: $color-primary-font;
            display: flex;
          }
        }

        .nav-tabs {
          border-bottom: 0;
          display: flex;
          flex-direction: column;
          font-weight: 400;
          margin-right: 1em;

          .chq-pbn {
            border-bottom: 4px solid transparent;
            color: $color-secondary-font-darker;
            padding: 4px 15px;

            li {
              margin-bottom: -7px;
            }

            &:disabled {
              cursor: not-allowed;
              opacity: .5;
            }

            &.active {
              border-color: transparent;
              color: $color-secondary-font-darker;
              margin-left: -25px;

              path {
                fill: $color-primary-green;
                position: absolute;
              }
            }
          }
        }

        .section {
          color: #9c9fa7;
          font-size: $font-size-nav;
          margin-left: 3px;
        }

        svg {
          @include square(17px);
          fill: $color-primary-font;
          margin-right: 8px;
        }
      }
    }

    .DownloadDp {
      &-container {
        align-items: center;
        display: flex;
        font-size: $font-size-normal;
        font-weight: $font-weight-normal;
      }

      &-Options {
        font-size: 16px;
        margin: 14px 0 0;
        padding: 10px 0;
        right: 0;
        text-align-last: left;
        top: 25px;
        white-space: nowrap;
        width: 245px;
        z-index: 1;

        .DownloadDp-Option {
          align-items: center;
          border-radius: 0;
          display: flex;
          font-weight: $font-weight-normal;
          padding: .5em 1em;

          &.accordion-header {
            padding: 0;
          }

          .accordion {
            width: 100%;

            .accordion-header {
              align-items: center;
              display: flex;
              padding: .5em 1em;

              &:hover {
                background-color: $color-border;
                cursor: pointer;
                outline: none;
              }
            }

            .accordion-content {
              margin-top: 5px;

              .caption-div {
                &:hover {
                  background-color: $color-border;
                  cursor: pointer;
                  outline: none;
                }
              }
            }

            .captions-accordian {
              margin-left: 15px;
            }
          }
        }

        .nav-tabs {
          border-bottom: 0;
          display: flex;
          flex-direction: column;
          font-weight: 400;
          margin-right: 1em;

          .chq-pbn {
            border-bottom: 4px solid transparent;
            color: $color-secondary-font-darker;
            padding: 4px 15px;

            li {
              margin-bottom: -7px;
            }

            &:disabled {
              cursor: not-allowed;
              opacity: .5;
            }

            &.active {
              border-color: transparent;
              color: $color-secondary-font-darker;
              margin-left: -25px;

              path {
                fill: $color-primary-green;
                position: absolute;
              }
            }
          }
        }

        .subtitle-accordian {
          display: flex;
          flex-direction: column;
          font-weight: $font-weight-normal;
          margin-left: 15px;
          padding: .5em 1em;
        }

        .section {
          color: #9c9fa7;
          font-size: $font-size-nav;
          margin-left: 3px;
        }

        svg {
          @include square(16px);
          fill: $color-primary-font;
          margin-right: 8px;
          padding-right: 2px;
        }
      }
    }
  }

  .chq-sbar input {
    font-weight: 400;
    margin-bottom: 15px;
  }

  .Language-list {
    list-style: none;
    max-height: 60vh;
    overflow: scroll;
    padding: 15px;

    .chq-pbn {
      color: $color-primary-font;
      padding-bottom: 15px;
    }
  }

  .FontOptions-Secondary {
    align-items: center;
    display: flex;
    flex: 1;

    .chq-ffd2 {
      flex: 2;
      margin-left: 10px;
    }

    .chq-ffd {
      flex: 1;
      margin: 0;

      & + .chq-ffd {
        margin-left: 10px;
      }
    }
  }

  .nav-tabs {
    font-weight: $font-weight-normal;
    padding-left: 0;

    .chq-pbn {
      border-bottom: 4px solid transparent;
      color: $color-secondary-font-darker;
      padding: 10px 15px;

      &:disabled {
        cursor: not-allowed;
        opacity: .5;
      }

      &.active {
        border-color: $color-primary-green;
        color: $color-primary-green;
      }
    }
  }

  .On-Off-btn {

    align-items: center;
    display: flex;
    margin-right: 10px;
    padding: 5px;

    svg {
      @include square(20px);
    }

    &:focus {
      outline: none;
    }

    path {
      fill: $color-primary-font;
    }
  }

  .Options-radius-opacity {
    align-items: center;
    display: flex;
    flex: 1;
    margin: 20px 0;

    .chq-ffd {
      flex: 1;
      margin: 0;

      & + .chq-ffd-opacity {
        margin-left: 10px;
      }

      .chq-ffd--ctrl {
        border: 2px solid $color-border;
        border-radius: 6px;
      }
    }
  }

  .Preview-Editor,
  .Toolbar-Editor {
    height: 56px;
    max-height: 56px;
    padding-bottom: 0;
    padding-top: 0;

    &.timeline-row {
      max-height: initial;
    }

    .Preview-Editor,
    .Toolbar-Editor {
      &__Controls {
        border-bottom: 2px solid $color-border;
        margin-bottom: 0;
        padding: 5px 20px;

        &-Play {
          flex: 3;
          position: relative;
        }

        .Split-Btn {
          align-items: center;
          appearance: none;
          background: transparent;
          border: 0;
          color: $color-primary-font;
          display: flex;
          font-family: inherit;
          font-size: inherit;
          line-height: inherit;
          margin-left: 30px;
          outline: 0;
          padding: 5px 10px;

          svg {
            @include square(18px);
            margin: 0 7px 0 0;
            min-width: 18px;
          }
        }

        .Video-Utilities {
          flex: 2;

          svg {
            @include square(18px);
            margin: 0 7px 0 0;
          }

          .MinusIcon {
            @include square(18px);
            font-weight: $font-weight-heavy;
            margin-left: 0;
            margin-right: 10px;
            min-width: 18px;
          }

          .PlusIcon {
            @include square(18px);
            font-weight: $font-weight-heavy;
            margin-left: 10px;
            margin-right: 0;
            min-width: 18px;
          }
        }
      }

      &__Items { // sass-lint:disable-block no-vendor-prefixes
        margin: 0;
        padding: 10px 0 0 5px;

        &::-webkit-scrollbar {
          background-color: $color-white;
          height: 12px;
          width: 12px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #babac0;
          border: 2px solid #f7f7f8;
          border-radius: 16px;
        }

        &::-webkit-scrollbar-track {
          background-color: #f7f7f8;
          border-radius: 16px;

          &:hover {
            cursor: pointer;
          }
        }

        &__Image {
          padding: 10px 5px 0;
        }

        &-Slides {
          position: relative;

          .Story_Preview {
            border-radius: $size-border-radius;
            margin: 0 3px;

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }

          .marker {
            border: 12px solid transparent;
            border-top-color: $color-primary-blue;
            bottom: 0;
            content: '';
            height: 115%;
            left: -11px;
            position: absolute;

            &-stick {
              background-color: $color-primary-blue;
              height: 120%;
              width: 3px;
            }
          }
        }
      }
    }
  }

  .Subtitles {
    &-Header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      min-height: 36px;

      .download-caption-btn {
        align-items: center;
        display: flex;
        margin-right: 10px;
        padding: 5px;

        svg {
          @include square(20px);
        }

        &:focus {
          outline: none;
        }

        path {
          fill: $color-primary-font;
        }
      }

      &__Title {
        align-items: center;
        display: flex;

        h2 {
          font-size: 20px;
          margin: 0;
        }

        .chq-pbn {
          display: flex;
          margin-right: 5px;
        }

        path {
          fill: $color-primary-font;
        }
      }

      .Subtitles-Header__Dropdown {
        ul {
          max-height: 400px;
          overflow: scroll;
          right: 0;
          top: 2.5em;
          z-index: $z-index-medium;
        }

        path {
          fill: $color-primary-font;
        }

        .translate-btn {
          padding: 5px 20px;
        }
      }
    }

    &-Loading {
      font-weight: $font-weight-normal;
      padding: 30px;
      text-align: center;

      @media screen and (min-width: $screen-lg) {
        padding: 30px 60px;
      }

      &__Image {
        margin-top: 15px;
        max-width: 250px;
      }
    }
  }

  .Language-Options {
    margin-top: 20px;
  }

  .translate-options {
    display: grid;
    height: 100vh;
    justify-items: left;
    overflow: scroll;
    padding-left: 3px;

    .chq-pbn {
      color: $color-primary-font;
      padding-bottom: 15px;
    }
  }

  .VTT-Container {
    display: flex;
    flex-direction: column;

    &__Section {
      align-items: center;
      border-bottom: 1px solid $color-border;
      display: flex;
      justify-content: space-between;
      padding: 15px 0;
    }

    &__Text {
      flex: 2;
    }

    &__Time {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: 15px;

      .chq-ffd {
        margin: 0;
      }
    }

    &__Buttons {
      margin-left: 15px;

      .chq-btn {
        align-self: center;
        margin-top: 0;
        padding: 5px;

        &:hover {
          border-width: 1px;
        }

        svg {
          @include square(16px);
          align-items: center;
          display: flex;
          margin: 0;
        }
      }

      .DeleteBtn {
        border-color: $color-red;

        path {
          fill: $color-red;
        }
      }

      .Add-Section {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 15px;
      }

      .chq-ttp--bbl {
        left: -44px !important;
        top: -60px;
      }
    }
  }

  .TrimVideo-Container {
    margin-right: 15px;

    .TrimVideo-Btn {
      align-items: center;
      color: $color-primary-font;
      display: flex;

      svg {
        @include square(18px);
        margin-right: 7px;
      }

      path {
        fill: $color-primary-font;
      }
    }
  }

  .TrimVideo-Container-Thumbnail {
    margin-right: 15px;

    .TrimVideo-Btn {
      align-items: center;
      color: $color-primary-font;
      display: flex;

      svg {
        @include square(18px);
        margin-right: 7px;
      }

      path {
        fill: $color-primary-font;
      }
    }
  }

  .Stories-Container {
    position: relative;

    @media only screen and (min-width: $screen-xl) {
      padding: 30px;
    }

    .Captions-Header {
      &__Title {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        min-height: 36px;

        h2 {
          font-size: 20px;
          margin: 0;
        }

        .chq-btn {
          svg {
            margin-right: 5px;
          }
        }

        .invert-icon {
          svg {
            transform: scaleY(-1);
          }
        }
      }

      &__Actions {
        align-items: center;
        display: flex;
      }
    }

    .overlay-ms {
      align-items: start;
      background-color: rgba($color: $color-white, $alpha: .7);
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: $z-index-medium;

      .overlay {
        background-color: rgba($color: $color-black, $alpha: .8);
        border-radius: $size-border-radius;
        color: $color-white;
        margin-top: 150px;
        max-width: 340px;
        padding: 30px;
        text-align: center;
      }
    }

    .upload-image {
      display: flex;
      flex-direction: column;

      .thumbnail-selection {
        max-height: 400px;
        max-width: 100%;
        position: relative;
        width: fit-content;

        .Image-Crop {
          align-items: center;
          background-color: rgba(0, 0, 0, .8);
          border-color: transparent;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          outline: 0;
          padding: 6px 5px 1px;
          position: absolute;
          right: 7px;
          top: 7px;
          z-index: 20;

          path {
            fill: $color-white;
          }
        }
      }

      .show-image {
        border-radius: 15px;
        height: 100%;
        max-height: 300px;
        max-width: 100%;
        width: auto;
      }
    }
  }

  .chq-btn-iv {
    outline: 0;
  }

  .Trash-Icon {
    background-color: transparent;
    border: 0;
    outline: 0;

    &:hover {
      padding: 5px 10px;
    }

    svg {
      @include square(22px);
    }

    path {
      fill: $color-red;
    }
  }

  .Dropdown-Btn {
    background-color: transparent;
    border: 0;
    color: $color-primary-font;
    outline: 0;
    width: 100%;

    &:hover {
      background-color: $color-border;
      cursor: pointer;
      outline: none;
    }

    svg {
      @include square(16px);
      margin: 0 8px 0 0;
    }

    path {
      fill: $color-primary-font;
    }
  }

  .EmptyTextOptions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 10px;
  }

  .TextOption {
    margin-bottom: 10px;
    padding: 0 5px;

    .chq-pbn {
      background-color: $color-background;
      border-radius: $size-border-radius;
      min-height: 80px;
      padding: 10px 30px;
      width: 100%;
    }

    &-Image {
      max-width: 150px;
      width: 100%;
    }

    &.Heading {
      .TextOption-Image {
        max-width: 220px;
      }
    }

    &.Subheading {
      .TextOption-Image {
        max-width: 185px;
      }
    }
  }

  .col-xs-6 {
    .chq-pbn {
      min-height: 140px;
    }

    .TextOption-Image {
      max-width: 130px;
    }
  }

  .col-xs-12 {
    .TextOption-Image {
      max-width: 70px;
    }
  }

  .FontEffects {
    align-items: center;
    display: flex;
    margin: 20px 0;

    .FontEffects-Main {
      flex: 2 1;
      margin-right: 10px;
    }

    .FontEffects-Secondary {
      flex: 1 1;
    }

    .col-xs-6 {
      .chq-pbn {
        min-height: 70px;
        padding: 10px 20px;
      }
    }
  }

  .StylesDisabledContainer {
    opacity: .5;
  }

  .FontOptions {
    align-items: center;
    display: flex;
    margin: 20px 0;

    &--Font {
      flex: 2;
      margin-right: 10px;
    }

    .ColorField {
      flex: 1;
    }
  }

  .TimelineRuler {
    align-items: center;
    display: flex;
    margin-left: 10px;
    margin-top: 10px;

    &__hidden {
      margin-top: 0;
    }

    svg {
      font-size: $font-size-xs;
      overflow: visible;
    }
  }

  .TimelineZoom {
    &-Container {
      align-items: center;
      display: flex;
      justify-content: center;
      position: relative;

      .chq-pbn {
        align-items: center;
        color: $color-primary-font;
        display: flex;

        svg {
          margin-right: 5px;
        }
      }
    }

    &-Slider {
      align-items: center;
      background-color: $color-white;
      border-radius: 20px;
      display: flex;
      width: 200px;
      z-index: $z-index-high;

      .input-range {
        height: 3px;

        &__label {
          display: none;
        }

        &__slider {
          @include square(12px);
          background: $color-primary-green;
          border-color: $color-primary-green;
          margin-top: -9px;
        }

        &__track {
          &--active {
            background-color: $color-primary-green;
            height: 6px;
          }

          &--background {
            height: 6px;
            top: 0;
          }
        }
      }

      svg {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .TimelineIcon {
    margin-right: 10px;
    min-width: 22px;
  }

  .AudioIcon {
    background-color: $color-primary-green;
    right: 0;
    z-index: 10;

    path {
      fill: $color-bar-green;
    }
  }

  .MediaIcon {
    background-color: $color-primary-blue;
    right: 0;
    z-index: 10;

    path {
      fill: $color-bar-blue;
    }
  }

  .TextIcon {
    background-color: $color-primary-yellow;
    right: 0;
    z-index: 10;

    path {
      fill: $color-bar-text;
    }
  }

  .Grabber {
    @include square(14px);
    min-width: 14px;

    &-Start {
      left: 3px;
      padding-right: 1px;
      position: absolute;

      &__Video {
        left: 0;
      }
    }

    &-End {
      padding-left: 1px;
      position: absolute;
      right: 0;
      z-index: 2;

      &__Video {
        right: 0;
      }
    }

    &-Video {
      background-color: $color-black-alpha-80;
      height: 100%;
      padding: 7px;
      width: 30px;
      z-index: $z-index-medium;

      path {
        fill: $color-white;
      }
    }
  }

  .narrowBar .Grabber-Start,
  .narrowBar .TimelineIcon,
  .narrowBar .Grabber-End {
    opacity: 0;
  }

  .Select-Tags__Container {

    .chq-ffd--sl {
      .chq-ffd--ctrl {
        align-items: center;
        border: 0;
        display: flex;
        flex-wrap: wrap;
        padding-left: 7px;
      }
    }
  }

  .SubtitleIcon {
    background-color: #96caa1;
    right: 0;

    g,
    path {
      fill: $color-bar-subtitle;
    }
  }

  .chq-ffd--bg-img-container {
    background-color: #f3f3f3;
    height: 120px;

    .chq-ffd--bg-img--img {
      background-image: none !important;
      height: 200px;
    }

    .chq-ffd--im {
      min-height: 120px;

      &:hover {
        box-shadow: none;
      }

      .chq-ipv {
        display: none;
      }

      &--bt-bg {
        background-color: transparent;
        color: $color-secondary-font;

        path {
          fill: $color-secondary-font;
        }
      }
    }
  }

  .ColorOverlayField {
    align-items: center;
    border: 2px solid $color-border;
    border-radius: $size-border-radius;
    display: flex;
    height: 42px;
    justify-content: space-between;
    padding: 5px 15px;

    .boolean {
      background-color: initial;
      color: $color-primary-font;
      font-weight: $font-weight-light;
      margin-left: 0;
      padding: 0;

      .chq-cmk {
        color: $color-primary-font;
      }

      svg {
        background-color: initial;
        border-color: $color-primary-green;
      }
    }

    .ColorField-Btn {
      border: 0;
      height: initial;
      margin: 0;
      padding: 0;
    }
  }

  .Tooltip-Up-Down {
    margin: 0;
    min-width: 120px;
    text-align: center;
  }

  .Up-Down-Btn {
    display: flex;
    flex: 2;
    padding: 7px 0;

    .chq-ttp {
      display: flex;
    }

    .chq-pbn {
      align-items: center;
      display: flex;

      &:disabled {
        opacity: .5;

        &:hover {
          cursor: not-allowed;
        }
      }
    }

    svg {
      height: 28px;
      min-width: 28px;
      width: 28px;
    }

    .Up-Btn {
      margin-right: 15px;
    }
  }

  .Selectable-Content {

    display: flex;
    height: 100%;
    position: relative;
    width: 100%;

    .SelectableGroup {
      flex: 1;
    }
  }
}
