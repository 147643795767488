@import '../common';

.filter-bar {
  margin-bottom: 20px;
  margin-left: 15px;
  margin-top: 5px;
  padding-left: 15px;

  a {
    border-radius: 4px;
    color: $color-primary-light-font;
    font-size: $font-size-normal;
    margin-right: 10px;
    margin-top: 5px;
    padding: 4px 5px;
    padding-bottom: 2px;
    text-decoration: none;


    &:hover {
      background-color: $color-border;
      text-decoration: none;
    }

    &.active {
      background: none !important;
      border-bottom: 2px solid $color-primary-green;
      border-radius: 0;
      color: $color-primary-green;
    }
  }
}
