@import '../common';

$input-element-height: 42px;

.form-control {
  color: $color-primary-font;
  font-size: $font-size-normal;
}

textarea.form-control {
  background-color: $color-background;
  resize: none;
}

form {
  &.max-width {
    margin: 80px auto;
    max-width: $screen-xxs;
    width: 100%;
  }

  .form-group {
    margin-bottom: 20px;

    label {
      color: $color-primary-light-font;
      font-weight: 400;

      &.file-upload {
        color: $color-white;
      }
    }

    input.form-control,
    textarea.form-control {
      border: 0;
      border-bottom: 2px solid $color-border;
      border-radius: 0;
      box-shadow: none;
      font-size: $font-size-normal;
      font-weight: $font-weight-light;
      height: $input-element-height;
      margin-bottom: 5px;
      outline: none;
      padding-left: 0;
      transition: border-bottom .3s;

      &:active,
      &:focus {
        border-bottom: 2px solid $color-primary-green;
      }

      &::placeholder {
        color: $color-secondary-light-font;
        font-weight: $font-weight-light;
      }
    }

    .form-control.dropdown {
      border: 0;
      height: $input-element-height;
      padding: 0;

      .dropdown-toggle {
        border: 0;
        border-bottom: 2px solid $color-border;
        border-radius: 0;
        color: $color-primary-font;
        font-size: $font-size-normal;
        font-weight: $font-weight-light;
        height: $input-element-height;
        overflow: hidden;
        padding-left: 0;
        text-align: left;
        width: 100%;

        &:focus,
        &:hover {
          background: none;
          border-bottom: 2px solid $color-primary-green;
          box-shadow: none;
          outline: none !important;
        }

        .caret {
          float: right;
          position: absolute;
          right: 10px;
          top: 18px;
        }
      }

      .dropdown-menu {
        border-radius: 0;
        margin-top: 10px;
        min-width: 100%;

        &.active {
          display: inline;
        }

        li {
          cursor: pointer;

          a {
            color: $color-primary-font;
            font-size: $font-size-normal;
            font-weight: $font-weight-light;
            padding-left: 8px;
          }

          svg {
            height: 16px;
            margin-bottom: -2px;
            margin-right: 6px;
            margin-top: 2px;
            opacity: .7;
            width: 16px;

            path {
              fill: $color-black;
            }
          }

          &.active {
            a {
              background: $color-white;
              color: $color-primary-font;

              &:hover {
                background: $color-border;
              }
            }
          }

          &.inactive {
            svg {
              opacity: 0;
            }
          }
        }
      }
    }

    // Adjacent Sibling -> form feedback
    .dropdown + span,
    .upload-image + span,
    .input + span,
    input + span {
      color: $color-red;
      font-size: $font-size-small;
    }

    textarea.form-control {
      background: none;
      height: 160px;
    }

    [hidden] {
      display: none !important;
    }
  }

  .button {
    cursor: pointer;

    &.invalid {
      background-color: lighten($color-primary-green, 20%);
      cursor: not-allowed;
    }
  }
}

.form-title {
  color: $color-secondary-font;
  font-size: $font-size-nav;
  margin: 0;
  margin-bottom: 12px;
  padding-top: 15px;
  text-transform: uppercase;
}

.form-description {
  font-weight: $font-weight-light;
  margin-bottom: 15px;
}

.chq-mif {
  td {
    &:first-child {
      height: 120px;
      width: 120px;

      div {
        height: 120px;
        position: relative;
        width: 120px;
      }

      img {
        display: block;
        margin: 0 auto;
      }
    }

    &:last-child {
      text-align: right;
    }

    @media (max-width: 600px) {
      &:not(:first-child) {
        &:not(:last-child) {
          display: none;
        }
      }
    }
  }
}
