.UsersModal {
  border-radius: $size-border-radius;

  .chq-pan--hd {
    border-radius: $size-border-radius $size-border-radius 0 0;
  }

  .InstructionalVideo {
    background-position: center;
    border-radius: 600px;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    position: relative;

    &:hover {
      cursor: pointer;
    }

    &--Title {
      color: $color-white;
      font-size: $font-size-large;
      left: 15px;
      margin: 0;
      position: absolute;
      top: 15px;
    }

    &--Video {
      border-radius: $size-border-radius;
      max-height: 500px;
      object-fit: fill;
      width: 100%;

      &:fullscreen {
        object-fit: contain;
      }
    }
  }
}
