@import '../common';

.Feed {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: -15px;
  max-width: 1440px;

  @media only screen and (min-width: $screen-lg) {
    flex-direction: row;
  }

  .Featured {
    padding: 0;
  }

  &--right {
    padding-left: 0;
  }

  .Filters {
    padding-bottom: 15px;
    padding-right: 15px;

    @media only screen and (min-width: $screen-lg) {
      background-color: $color-white;
      box-shadow: .5px .5px 3px $color-black-alpha-20;
      height: 100vh;
      left: 0;
      overflow-y: scroll;
      padding-top: 90px;
      position: fixed;
      top: 0;
      width: 270px;
    }

    &__Container {
      @media only screen and (min-width: $screen-lg) {
        margin-top: 30px;
      }

      .e_label {
        display: flex;
        flex-grow: 1;
        margin-top: 10px;
        padding: 0 15px;

        @media only screen and (min-width: $screen-lg) {
          flex-direction: column;
          padding: 0;
        }

        li {
          border-bottom: 3px solid transparent;
          border-left: 0;
          margin: 0;
          padding-bottom: 5px;

          @media only screen and (min-width: $screen-lg) {
            border-bottom: 0;
            border-left: 3px solid transparent;
            padding-bottom: 5px;
          }

          .chq-btn {
            align-items: center;
            background-color: transparent;
            display: flex;
            padding: 0 10px;

            @media only screen and (min-width: $screen-lg) {
              padding-right: 0;
            }

            svg {
              margin: 0 7px 0 0;
            }

            .chq-nav--unseen {
              border-color: $color-primary-green;
              border-width: .5rem;
              height: 1.3rem;
              left: 0;
              margin-left: 2px;
              position: relative;
              top: -4px;
              width: 1.3rem;
            }
          }

          &.selected {
            background-color: transparent;
            border-bottom: 3px solid $color-primary-green;
            border-left: 0;

            @media only screen and (min-width: $screen-lg) {
              background-color: $color-selected-option;
              border-bottom: 0;
              border-left: 3px solid $color-primary-green;
            }

            .chq-btn {
              background-color: transparent;
            }
          }
        }
      }
    }

    &__Header {
      align-items: flex-start;
      color: $color-primary-light-font;
      display: none;
      font-size: 18px;
      font-weight: $font-weight-heavy;
      justify-content: space-between;

      @media only screen and (min-width: $screen-lg) {
        display: flex;
      }

      h1 {
        font-size: 18px;
        margin: 0;
      }

      .with-icon {
        align-items: flex-start;
        display: flex;

        h2 {
          font-size: 15px;
          margin: 2px 0 0;
        }

        .icon-container {
          position: relative;

          svg {
            height: 18px;
            margin-right: 8px;
            width: 18px;
          }

          path {
            fill: $color-primary-light-font;
          }
        }
      }
    }

    .view-sec {
      align-items: center;
      display: flex;
      flex-wrap: wrap;

      @media only screen and (min-width: $screen-lg) {
        display: block;
      }
    }

    hr {
      border: 0;
      border-top: 1px solid #eee;
      margin: 0 -15px -15px;
    }

    &__Active {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      min-height: initial;
      padding-top: 0;
    }

    &__button {
      flex-grow: 1;
      margin-right: 15px;

      &-wrapper {
        min-height: 55px;

        @media only screen and (min-width: $screen-lg) {
          padding-bottom: 25px;
        }
      }
    }
  }

  .Interests {
    margin-top: 30px;

    .chq-bdg {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .Select-Tags__Container {
    align-items: center;
    border: 0;
    display: flex;
    margin-left: 15px;
    position: initial;
    width: 100%;
    z-index: $z-index-low;

    .share-with-field {
      width: 100%;

      .chq-ffd--ctrl {
        border: 1px solid $color-secondary-light-font;
        border-radius: 30px;
        margin-bottom: 0;
        padding: 12px 40px 2px;

        &-fc {
          border: 1px solid $color-primary-blue;
          box-shadow: inset 0 1px 1px $color-form-inset, 0 0 8px $search-bar-shadow;
        }

        .chq-bdg {
          margin: 0 0 10px 10px;
          margin-left: 0;
          margin-right: 10px;
          margin-top: 0;

          &:hover {
            cursor: pointer;
          }
        }

        &:hover {
          cursor: text;
        }
      }

      .chq-ffd--sl {
        margin-left: 0;
        margin-right: 0;
        z-index: $z-index-low;

        &--caret {
          display: none;
        }

        &--place {
          color: $color-secondary-font;
          font-size: $font-size-large;
          font-weight: $font-weight-heavy;
          margin-bottom: 10px;
        }

        &--opts {
          border-radius: $size-border-radius;
          margin-left: 0;
          margin-top: 6px;
          width: 100%;
        }

        &--match {
          font-size: 18px;
          margin-bottom: 10px;
          margin-top: 0;
        }
      }

      .chq-ffd--lb {
        display: flex;
        justify-content: space-between;
        top: 15px;

        svg {
          display: block;
        }

        path {
          fill: $color-primary-light-font;
        }
      }
    }
  }
}

.FeedItem {
  background-color: $color-white;
  border-radius: 6px;
  box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);
  margin-bottom: 15px;
  min-height: 100px;
  position: relative;

  &__title {
    display: inline-block;
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
  }

  &.FeedItemPlaceholder {
    .FeedItem__date {
      background-color: rgba(0, 0, 0, .06);
      height: 20px;
      width: 40px;
    }

    .FeedItem__user {
      background-color: rgba(0, 0, 0, .06);
      border-radius: 30px;
      height: 45px;
      width: 45px;
    }

    .FeedItem__preview {
      height: 67px;

      &:hover {
        .QuickOptions {
          opacity: 1;
        }
      }

      &__title {
        p {
          background-color: rgba(0, 0, 0, .06);
          height: 16px;
          margin-bottom: 9px;
          width: 80%;
        }
      }

      > p {
        background-color: rgba(0, 0, 0, .06);
        height: 24px;
        margin-bottom: 15px;
        width: 100%;
      }
    }
  }

  &.fadeIn {
    animation: feedIn .5s linear;
    animation-fill-mode: both;
    z-index: 10;
  }

  &__SuggestedActions {
    box-shadow: .5px 1.5px 13px $color-black-alpha-20;
    margin-bottom: 10px;
    min-height: initial;

    // XS Screens
    @media only screen and (max-width: $screen-xxs) {
      .Thumbnail {
        display: none;
      }

      .FeedItem__preview__title {
        margin-bottom: 15px !important;
        padding-left: 0 !important;
      }

      .FeedItem__SuggestedActions__preview {
        margin-left: 0;

        .button {
          width: 100%;
        }
      }
    }

    .Thumbnail {
      border-radius: 40px !important;
      height: 70px !important;
      width: 70px !important;
    }

    .FeedItem__preview__title {
      align-items: center;
      color: $color-secondary-font;
      display: flex;
      font-size: 18px;
      font-weight: $font-weight-heavy;
      margin: 0 15px;
      min-height: initial;
      padding: 0;
      text-align: left;
      width: 100%;

      svg {
        margin-right: 10px;
      }

      path {
        fill: $color-secondary-font;
      }

      span {
        margin: auto;
        width: 100%;
      }

      &:hover {
        cursor: text;
      }
    }

    .FeedItem__preview {
      align-items: center;
      display: flex;
      height: 90px;
      justify-content: space-between;
      padding: 0;
    }

    &__preview {
      border-left: 1px solid $color-border;
      display: flex;
      padding-left: 15px;

      .chq-ttp {
        display: flex;
      }
    }

    .Button,
    .chq-btn {
      border-radius: $size-border-radius;
      display: flex;
      margin: 25px 5px;
      padding: 8px;

      &:hover,
      &:active {
        padding: 7px;
      }

      svg {
        height: initial;
        margin: 0;
      }
    }

    .main-container {
      display: flex;
      padding: 10px 15px;
      width: 100%;
    }

    .buttons-container {
      display: none;
      height: 100%;
      padding-right: 15px;

      @media only screen and (min-width: $screen-xs) {
        display: block;
      }
    }

    .chq-ttp--bbl {
      bottom: initial;
      top: -12px;
      width: max-content;
    }
  }

  &__Explore {
    .FeedItem__preview {
      border-top: 1px solid $color-border;
    }

    &-Suggested {
      color: $color-primary-light-font;
      margin-left: 110px;
      margin-right: 15px;
      padding-bottom: 15px;

      &-title {
        font-weight: $font-weight-heavy;
      }

      .chq-pbn {
        color: $color-primary-light-font;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-People {
      align-items: center;
      display: flex;

      &:hover {
        cursor: pointer;
      }

      &__Container {
        display: flex;
        flex-direction: column;
        padding: 15px;

        @media screen and (min-width: $screen-md) {
          align-items: center;
          flex-direction: row;
        }

        .Empty {
          &-Icon {
            background-color: $color-border;
            border-radius: 40px;
            height: 40px;
            margin-right: 15px;
            padding: 8px;
            width: 40px;

            path {
              fill: $color-primary-font;
            }
          }

          &-Description {
            color: $color-secondary-font;
            max-width: 365px;
          }
        }

        .EventButton {
          margin-top: 5px;

          @media screen and (min-width: $screen-md) {
            margin-left: 10px;
            margin-top: 0;
          }

          .Button {
            margin: 0;
            white-space: nowrap;
            width: fit-content;

            svg {
              margin-right: 5px;
            }
          }
        }
      }

      &__more {
        align-items: center;
        background-color: $color-border;
        color: $color-primary-font;
        display: flex;
        justify-content: center;
      }

      &__description {
        color: $color-link-blue;
        margin-left: 5px;
      }

      .Thumbnail {
        height: 40px !important;
        margin-left: -10px;
        min-width: 40px;
        width: 40px !important;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .Select-Tags__Container {
      .chq-bdg {
        svg {
          display: none;
        }

        .category-icon {
          display: block;
        }
      }

      .chq-ffd--ctrl {
        .option-group {
          margin-bottom: 10px;
          margin-top: 0;

          .chq-bdg {
            margin: 0 10px 0 0;
          }
        }
      }
    }

    .option-action {
      background-color: $color-primary-green;
      border-radius: 0 $size-border-radius $size-border-radius 0;
      margin-left: -17px;
      margin-right: 10px;
      margin-top: 0;
      padding: 8px;

      svg {
        display: block;
        height: 14px;
        width: 14px;

        path {
          fill: $color-white;
        }
      }
    }

    .clear-btn {
      height: 18px;
      position: absolute;
      right: 30px;
      top: 32px;
      width: 18px;
      z-index: $z-index-high;

      path {
        fill: $color-primary-light-font;
      }
    }
  }

  &--header {
    border-bottom: 1px solid $color-border;
    font-size: $font-size-nav;
    margin: 0 -15px 10px;
    padding: 0 50px 15px 15px;

    a {
      font-weight: $font-weight-heavy;
    }

    svg {
      @include square(20px);
      margin-right: 5px;
      vertical-align: bottom;
    }

    path {
      fill: $color-primary-font;
    }

    &__Story {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media only screen and (min-width: $screen-xs) {
        flex-direction: row;
      }

      .legend {
        align-items: center;
        color: $color-primary-font;
        display: flex;
        font-size: 16px;
        margin-bottom: 5px;

        @media only screen and (min-width: $screen-xs) {
          margin-bottom: 0;
        }

        .emoji {
          margin-right: 10px;
        }
      }

      .FeedItem__preview__title {
        align-items: center;
        display: flex;
        padding: 6px 14px;
        position: absolute;
        right: 45px;
        top: 10px;

        &:hover {
          cursor: pointer;
        }

        span {
          align-items: center;
          color: $color-primary-green;
          display: flex;
          font-size: 16px;
          font-weight: $font-weight-normal;
        }

        svg {
          background-color: $color-primary-green;
          border-radius: 20px;
          height: 18px;
          margin: 0 5px;
          padding: 2px;
          width: 18px;
        }

        path {
          fill: $color-white;
        }
      }
    }

    &_social {
      align-items: center;
      background: $linkedin-gradient;
      border-radius: $size-border-radius $size-border-radius 0 0;
      color: $color-white;
      display: flex;
      font-size: $font-size-normal;
      justify-content: space-between;
      margin-top: -18px;
      padding: 10px 15px;

      .chq-abn {
        align-items: initial;
        background-color: $color-white;
        color: $color-linkedin-blue;
        display: flex;
        font-size: $font-size-nav;
        font-weight: $font-weight-heavy;
        margin-left: 10px;
        padding: 5px 15px;
        white-space: nowrap;

        svg {
          margin-right: 8px;
        }

        path {
          fill: $color-linkedin-blue;
        }
      }

      .SocialBadge {
        background: initial;
        background-color: initial;
        color: $color-primary-font;
        font-weight: $font-weight-normal;
        margin: 0;
        padding: unset;
      }

      .QuickOptions {
        .QuickOptions__menu {
          circle,
          path,
          rect {
            fill: $color-link-blue;
          }

          .SocialOption__Container {
            padding: 9px 15px;
            width: 180px;
          }
        }
      }

      a {
        color: $color-white;
        text-decoration: underline;
      }
    }

    &_facebook {
      background: $facebook-gradient;

      .chq-abn {
        color: $color-facebook-blue;

        path {
          fill: $color-facebook-blue;
        }
      }
    }

    &_instagram {
      background: $instagram-gradient;

      .chq-abn {
        color: $color-instagram-red;

        path {
          fill: $color-instagram-red;
        }
      }
    }

    &_linkedin {
      background: $linkedin-gradient;

      .chq-abn {
        color: $color-linkedin-blue;

        path {
          fill: $color-linkedin-blue;
        }
      }
    }

    &_tiktok {
      background: $tiktok-gradient;

      .chq-abn {
        color: $color-black;

        path {
          fill: $color-black;
        }
      }
    }
  }

  &__preview {
    padding: 18px 15px 15px;
    white-space: pre-wrap;
    word-break: break-word;

    .chq-bdg {
      &:hover {
        cursor: pointer;
      }
    }

    .Everyone--Tag {
      &:hover {
        cursor: initial;
      }
    }

    &__social {
      position: relative;

      .gallery-lightbox__photo {
        .MultiImage__container {
          right: 15px;
        }
      }
    }

    .Story {
      .FeedItem__preview__title__Story {
        @media only screen and (min-width: $screen-sm) {
          padding-right: 32%;
        }

        @media only screen and (min-width: $screen-md) {
          padding-right: 21%;
        }

        @media only screen and (min-width: $screen-lg) {
          padding-right: 20%;
        }
      }

      .chq-edi--lk {
        color: $color-white;
      }
    }

    .chq-edi-op {
      margin-top: 5px;

      iframe {
        border-radius: 6px;
        box-shadow: 0 0 5px 0 $color-black-alpha-20;
        margin-bottom: 4px;
        margin-top: 10px;
        min-height: 435px;
        width: 100%;
      }

      video {
        border-radius: 6px;
        width: 100%;
      }
    }

    .chq-ffd-tags {
      margin-top: 5px;
    }

    .link-wrapper {
      color: initial;
      text-decoration: none;
    }

    .onebox { // sass-lint:disable-block
      background-color: #f5f5f5;
      border-radius: 6px;
      box-shadow: 0 0 5px 0 $color-black-alpha-20;
      margin-bottom: 10px;
      margin-top: 10px;
      padding-bottom: 40px;
      position: relative;

      .source {
        align-items: center;
        bottom: 0;
        display: flex;
        left: 15px;
        padding-bottom: 15px;
        position: absolute;

        .site-icon {
          margin-right: 5px;
          max-width: 24px;
        }

        a {
          color: $color-dark-blue;
          text-decoration: none;
        }
      }

      .onebox-body {
        display: flex;
        flex-direction: column;

        &:hover {
          text-decoration: none;
        }

        .thumbnail,
        .instagram-image {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          order: 0;
          width: 100%;
        }

        h3,
        h4 {
          order: 1;
          padding: 0 15px;
        }

        p,
        .instagram-description,
        .tweet {
          margin-bottom: 10px;
          order: 2;
          padding: 0 15px;
        }

        .date {
          order: 3;
          padding: 0 15px;
        }

        a {
          color: $color-black;
          text-decoration: none;
        }
      }
    }

    .QuickOptions {
      transition: opacity 150ms ease-in-out;

      .clickable {
        &:focus {
          outline: none;
        }
      }

      &--Actions {
        margin: 0;
        position: relative;
      }

      @media only screen and (max-width: $screen-sm) {
        opacity: 1;
      }
    }

    &:hover {
      .QuickOptions {
        opacity: 1;
      }
    }

    &__header {
      align-items: center;
      display: flex;
      margin-bottom: 15px;
      min-height: 40px;

      &__info {
        margin: 0 15px;
      }

      &__date {
        color: $color-primary-light-font;
      }

      p {
        margin-bottom: 2px;
      }
    }

    &__title {
      font-size: 18px;
      font-weight: bold;
    }

    .btn.button {
      padding: 7px 20px 10px;

      svg {
        height: 25px;
        margin-bottom: -8px;
        margin-top: 2px;
        width: 25px;

        path {
          fill: $color-white;
        }
      }
    }

    &__event_created {
      img {
        margin-top: 12px;
        max-width: $screen-xxs;
      }

      a {
        &:hover {
          cursor: pointer;
          text-decoration: none;
        }
      }

      .image {
        padding-right: 0;

        @media only screen and (max-width: $screen-md) {
          height: 200px;
          margin-bottom: 15px;
          padding-right: 15px;
        }

        a {
          &:focus {
            .image-wrapper {
              outline: 2px auto;
            }
          }
        }
      }

      .live-wrapper {
        position: absolute;
        right: 5px;
        top: 10px;
      }

      .live {
        background-color: rgba(#f00, .86);
        border-radius: 5px;
        color: $color-white;
        font-size: $font-size-15;
        opacity: .9;
        padding: 6px 14px;

        svg {
          height: 8px;
          margin-bottom: 1px;
          margin-right: 1px;
          margin-top: -2px;
          width: 8px;

          path {
            fill: $color-white;
          }
        }
      }

      .chq-ttp--bbl {
        min-width: 300px;
      }

      .row {
        display: flex;
        margin-bottom: 5px;

        @media only screen and (max-width: $screen-md) {
          display: block;
        }
      }

      .image-wrapper {
        background-color: rgba(0, 0, 0, .2);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 6px;
        height: 100%;
        position: relative;
      }

      .title-block {
        display: none;

        p.title {
          color: $color-white;
          font-size: $font-size-normal;
          font-weight: 200;
          margin-bottom: 0;
          margin-top: 0;
          text-align: center;
        }

        &__RSVP {
          border: 1px solid $color-red;
        }
      }

      .button {
        font-size: $font-size-normal;
      }

      .details {
        min-height: 200px;

        @media only screen and (max-width: $screen-md) {
          height: auto;
        }

        .host {
          color: inherit;
          text-decoration: none;
        }

        .name {
          font-weight: $font-weight-heavy;
          padding-left: 15px;
          position: absolute;
          width: 75px;
        }

        .canceled {
          opacity: .55;

          .name {
            font-weight: $font-weight-normal;
          }
        }

        .value {
          padding-left: 85px;
          padding-right: 15px;
        }

        .value-without-label {
          align-items: center;
          display: flex;
          padding-left: 15px;
          padding-right: 15px;

          .rsvp-container {
            margin-right: 5px;

            .QuickRSVP {
              margin-top: 0;
            }
          }
        }

        .info-container {
          .chq-atc {
            margin-right: 5px;

            @media only screen and (max-width: $screen-md) {
              margin-bottom: 15px;
              margin-left: 60px;
            }
          }
        }

        .btn {
          background-color: $color-white;
          border-color: $color-primary-green;
          color: $color-primary-green;
          display: inline-block;
          padding: 5px 30px 6px;
          width: 100%;

          @media only screen and (max-width: $screen-md) {
            display: block;
          }
        }

        .ecard-buttons_container {
          margin-top: -7px;
        }
      }
    }

    &__photo_created {
      img {
        border-radius: 6px;
        display: block;
        margin: 0 auto;
        max-height: 500px;
        max-width: 100%;
      }

      .DesktopAddStoryButton {
        display: block;
        position: absolute;
        right: 16px;
        top: 16px;
      }

      @media only screen and (max-width: $screen-md) {
        .DesktopAddStoryButton {
          display: none;
        }
      }
    }

    &__widget_survey_activated {
      margin-left: 57px;
    }

    &__Social {
      &__Container {
        border: 1px solid $color-border;
        border-radius: 24px;
        margin: 0 20px 15px 40px;
        padding: 15px 0 20px;
        position: relative;

        .clearfix {
          &::before,
          &::after {
            display: none;
          }
        }

        .Interactions-Container {
          align-items: center;
          color: $color-secondary-font;
          display: flex;
          font-size: $font-size-nav;
          font-weight: $font-weight-normal;
          justify-content: space-between;
          margin-top: 10px;
          padding: 8px 0;
        }

        .Story__information--feed  {
          .chq-edi-op {
            flex-direction: column-reverse;
          }
        }

        .FeedItem__preview__title__Story {
          padding: 0 30px;
        }

        .gallery-lightbox__photo {
          height: initial;
          max-height: 400px;
          min-height: initial;

          .gallery-lightbox__main-video {
            max-height: 400px;
          }
        }

        .PhotoGroup__thumbnail {
          padding: 0;

          .Story__information--feed {
            padding: 0;
          }

          .background-container {
            border-radius: 0;
          }

          .PhotoGroup__wrapper {
            border-radius: 0;
          }
        }

        .gallery-lightbox__photo--image {
          border-radius: 0;
        }

        .PlayIcon {
          @include square(40px);
          position: absolute;

          path {
            fill: rgba($color-white, .8);
          }
        }

        .SocialOption__Container {
          align-items: center;
          display: flex;
          margin-left: 5px;

          .social-icon {
            margin-right: 5px;
          }
        }
      }

      &__Header {
        padding: 0 30px;

        .FeedItem__preview__Social__Message {
          -webkit-box-orient: vertical; // sass-lint:disable-line no-vendor-prefixes
          -webkit-line-clamp: 6; // sass-lint:disable-line no-vendor-prefixes
          display: -webkit-box; // sass-lint:disable-line no-vendor-prefixes
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &__User {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        .User__Info-Container {
          align-items: center;
          display: flex;

          .Thumbnail {
            @include square(40px);
            margin-right: 10px;
            min-width: 40px;
          }
        }

        .social-icon {
          @include square(45px);
          margin: initial;
        }

        p {
          margin-bottom: 0;
        }
      }

      &__Footer {
        align-items: flex-start;
        color: $color-linkedin-blue;
        display: flex;
        font-weight: $font-weight-heavy;
        justify-content: start;
        margin-top: 20px;
        padding: 0 20px;

        span {
          margin-right: 5px;
          margin-top: 2px;
          white-space: nowrap;
        }

        .SocialTags {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
        }

        .social-linkedin {
          align-items: center;
          display: flex;
          margin-right: 15px;

          &__label {
            display: none;
            margin-left: 3px;

            @media only screen and (min-width: $screen-sm) {
              display: block;
            }
          }

          &__icon {
            display: block;
            margin-right: 5px;

            @media only screen and (min-width: $screen-sm) {
              display: none;
            }

            path {
              fill: $color-linkedin-blue;
            }
          }

          &__divider {
            display: none;
            font-size: 8px;
            margin-right: 15px;

            @media only screen and (min-width: $screen-sm) {
              display: block;
            }
          }
        }
      }
    }

    &__Linkedin {
      &__Container {
        padding-bottom: 0;

        .Interactions-Container {
          align-items: center;
          color: $color-secondary-font;
          display: flex;
          font-weight: $font-weight-normal;
          justify-content: space-between;
          margin-left: .4rem;
          margin-top: 10px;
          padding: 8px 0;

          &__Reactions {
            align-items: center;
            display: flex;
            margin-left: .4rem;
            position: relative;
          }

          .Reactions__Count {
            margin-left: 2px;
          }

          .Comments__Count {
            margin-left: 2px;
          }

          .Linkedin-Reaction {
            @include square(16px);
            margin-left: -.4rem;
          }

          .chq-ttp {
            margin-left: 5px;
            margin-top: 5px;

            svg {
              @include square(16px);
            }

            path {
              fill: $color-primary-font;
            }

            &--bbl {
              font-size: 1em;
              width: 300px;
            }
          }
        }

        .FeedItem__preview__Social__Footer {
          align-items: initial;
          flex-direction: column;
          margin-top: 0;
        }

        hr {
          margin: 0;
          width: 100%;
        }

        .Linkedin-Actions {
          display: flex;
          justify-content: space-around;
          padding: 4px 8px;
          position: relative;

          .chq-pbn {
            align-items: center;
            color: $color-primary-font;
            display: flex;
            padding: 10px 8px;

            svg {
              margin-right: 4px;
            }

            path {
              fill: $color-primary-font;
            }
          }

          .Linkedin-Reactions__Btn {
            .Linkedin-Reaction {
              @include square(22px);
              margin-bottom: 2px;
              margin-right: 4px;
            }

            .Linkedin-Reactions__Container {
              .Linkedin-Reaction {
                @include square(36px);
              }
            }
          }
        }
      }
    }

    &__Instagram {
      &__Container {
        .Preview-User__Info-Name {
          margin-top: 0;
        }

        .FeedItem__preview__Social__Footer {
          align-items: flex-start;
          flex-direction: column;
          margin-top: 20px;
        }

        p {
          color: $color-primary-font;
          font-weight: $font-weight-normal;
          margin-bottom: 0;
          margin-top: 10px;
        }

        .Preview-User__Info-Username {
          color: $color-primary-light-font;
          margin-top: 0;
        }

        .comment-text {
          color: $color-secondary-font;
        }

        .Interactions-Container {
          display: block;
          margin-top: 0;
          padding: 0;
        }
      }
    }

    &__Facebook {
      &__Container {
        padding-bottom: 0;

        .message {
          color: $color-primary-font;
        }

        .FeedItem__preview__Social__Footer {
          align-items: initial;
          flex-direction: column;
          margin-top: 0;
        }

        hr {
          margin: 0;
          width: 100%;
        }

        .Interactions-Container {
          align-items: center;
          color: $color-secondary-font;
          display: flex;
          font-weight: $font-weight-normal;
          justify-content: space-between;
          margin-top: 10px;
          padding: 8px 0;
        }
      }
    }

    &__Tiktok {
      &__Container {
        .FeedItem__preview__Social__User {
          align-items: flex-start;
          justify-content: initial;
          margin-bottom: 0;

          .Thumbnail {
            @include square(55px);
            margin-right: 0;
          }
        }

        .MainContainer {
          padding: 0 15px;
          width: 100%;
        }

        .Preview-User__Info-Name {
          font-weight: $font-weight-heavy;
        }

        .gallery-lightbox__photo {
          border-radius: $size-border-radius;
          height: calc(450px + (100vw - 768px) / 1152 * 100);
          margin-top: 12px;
          max-height: 450px;

          .gallery-lightbox__main-video {
            max-height: 450px;
          }
        }

        .Tiktok-Buttons {
          bottom: 15px;
          right: 30px;
        }

        .Tiktok-Views {
          align-items: center;
          background-color: rgba($color-black, .3);
          border-radius: $size-border-radius;
          bottom: 15px;
          color: $color-white;
          display: flex;
          left: 15px;
          padding: 2px 10px;
          position: absolute;

          svg {
            @include square(14px);
            margin-right: 5px;
          }

          path {
            fill: $color-white;
          }
        }
      }
    }

    .FacebookLink,
    .InstagramLink,
    .TiktokLink {
      color: inherit;
      outline: none;
      text-decoration: none;
    }
  }

  &__user {
    .Thumbnail {
      @include square(45px);
      border-radius: 30px;
    }
  }

  &__footer {
    border-top: 1px solid $color-border;
    padding: 15px 0;

    &__comment-button {
      background-color: $color-white;
      float: right;
      margin-top: -2px;
      padding: 2px 17px 7px;
      transition: background-color .3s;

      &:hover {
        background-color: $color-background;
      }

      &:focus {
        background-color: $color-background;
      }

      svg {
        margin-bottom: -6px;
        margin-top: 6px;
      }
    }

    @media only screen and (max-width: $screen-sm) {
      .cheers--cheer-list {
        display: block;
        margin: 10px 0 0 10px;
      }
    }
  }

  &__comments {
    > .row {
      border-radius: 0 0 $size-border-radius $size-border-radius;
      margin: 0;
    }
  }

  &__Search {
    display: flex;
    padding: 15px;

    .Thumbnail {
      height: 50px !important;
      width: 50px !important;
    }
  }

  &__PendingPrompts {
    box-shadow: none;
    min-height: initial;

    .Accordian {
      background-color: $color-light-blue;
      border: 1px solid transparent;
      padding: 15px 0;

      &__title--icon {
        &__container {
          background-color: initial;
          height: initial;
          padding: 0;
        }

        path {
          fill: $color-red;
        }
      }

      &__subtitle {
        margin: 0;
      }

      &__container {
        height: 50px;
        margin: 0 30px 0 20px;
      }

      &:hover {
        border-color: $color-primary-blue;
        box-shadow: none;
      }

      &.open {
        box-shadow: none;

        .Accordian__title {
          font-size: $font-size-28;

          &--icon__container {
            display: none;
          }
        }

        .Accordian__container {
          margin: 0 30px;
        }

        &:hover {
          border-color: transparent;
        }
      }
    }

    &_Title {
      color: $color-secondary-font;
      font-size: $font-size-xl;
      font-weight: $font-weight-normal;
      margin: 5px 30px 15px;
    }
  }

  .MultiImage__container {
    align-items: center;
    background-color: transparent;
    border-radius: 6px;
    bottom: 15px;
    display: flex;
    padding: 0;
    position: absolute;
    right: 25px;

    svg {
      margin-right: 0;
    }
  }
}

.recognitions__recognition {
  .FeedItem--header {
    margin-left: 0;
    margin-right: 0;
    padding-top: 15px;

    & + .chq-fdi--bd {
      padding-top: 0;

      .QuickOptions {
        top: 10px;
      }
    }
  }

  .chq-bdg {
    &:hover {
      cursor: pointer;
    }
  }
}

.user-list-mdl {
  &--row {
    padding: 8px 0;

    & + & {
      border-top: 1px solid $color-background;
    }
  }

  .chq-tmb {
    margin-left: 15px;
    margin-right: 25px;
  }
}

.Endorsement__preview__title {
  font-size: initial;
  font-weight: initial;
  margin-bottom: 15px;
  min-height: 40px;
  padding-left: 58px;
  padding-right: 30px;

  .FeedItem__user {
    left: 15px;
    position: absolute;
  }
}

.Endorsement__body {
  .chq-bdg {
    &:hover {
      cursor: pointer;
    }
  }
}
