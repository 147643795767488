@import '../common';

.Event {
  @media only screen and (max-width: $screen-sm) {
    margin: 0 auto;
  }

  h2 {
    font-size: $font-size-large;
  }

  .chq-pan--hd {
    h2 {
      font-size: $font-size-normal;
    }
  }

  .EventDetails {
    .description,
    .tags,
    .location {
      margin-bottom: 15px;
    }

    h2 {
      color: $color-secondary-font;
      font-size: $font-size-normal;
      font-weight: $font-weight-normal;
    }

    .location {
      color: $color-secondary-font;
      margin-top: 4px;

      svg {
        margin-bottom: -4px;
        padding-top: 4px;

        path {
          fill: $color-secondary-font;
        }
      }
    }
  }
}
