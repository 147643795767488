@import '../common';

.chq-pbn {
  &.cancel-event--type {
    border-radius: 5px;
    color: $color-primary-font;
    padding: 3px;
    text-align: left;

    &:focus {
      outline: none;
    }

    &.active {
      background-color: $color-background;
    }

    svg {
      display: block;
      margin: 38px auto;
      margin-left: 10px;
      margin-top: 30px;
    }
  }
}
