@font-face { // sass-lint:disable-block no-duplicate-properties
  font-family: 'BebasNeue';
  src: url('/fonts/bebas_neue/BebasNeue-Regular.eot');
  src: url('/fonts/bebas_neue/BebasNeue-Regular.woff') format('woff'),
  url('/fonts/bebas_neue/BebasNeue-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SourceSansPro';
  src: url('/fonts/source-sans-pro/SourceSansPro-Regular.ttf') format('truetype');
}

$color-activision: #121212;
$color-activision-light-black: #353535;
$color-activision-highlight: #ffc54e;

.activision-blizzard-nav {
  &.chq-nav {
    background-color: $color-activision;

    .chq-nav {
      &--search {
        input {
          background-color: $color-activision-light-black;
        }
      }

      &--items {
        a {
          border-color: $color-activision;

          &.active {
            border-color: $color-primary-yellow;
          }
        }
      }

      &--admint {
        &:hover {
          background-color: $color-activision-light-black;
        }
      }
    }
  }
}

.activisionblizzard,
.activision-blizzard {
  background-color: $color-activision;
  font-family: 'SourceSansPro', Arial, sans-serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'BebasNeue', Arial, sans-serif;
  }

  a {
    color: $color-activision-highlight;
  }

  .back-arrow {
    color: $color-white;

    path {
      fill: $color-white;
    }
  }

  .Trends-Slider {
    background-color: #2c2c2c;
  }

  .EmptyTrendSlider {
    &-Btn {
      background-color: #2c2c2c;

      &__Txt {
        background-color: $color-black-alpha-20;
      }

      &__User {
        background-color: $color-black-alpha-20;
      }
    }
  }

  &.gallery-lightbox--Story {
    border: 1px solid #373737;
    box-shadow: none;

    hr {
      border-color: #373737;
    }

    .chq-bdg {
      background-color: $color-activision;
      border: 1px solid $color-white;
      border-radius: 0;
      color: $color-white;

      g,
      path {
        fill: $color-white;
      }
    }

    .chq-edi-op {
      color: $color-white;
    }

    .chq-pan--bd {
      background-color: $color-activision;
    }

    .FeedItem__preview__title__Story {
      color: $color-white;

      .Title {
        color: $color-white;
        font-size: 26px;
      }

      .Accordian__title--icon__container {
        path {
          fill: $color-activision-highlight;
        }
      }

      .Subprompt {
        &-Arrow {
          path {
            fill: rgba($color-activision-highlight, .4);
          }
        }

        &-Container {
          color: $color-white;
        }
      }
    }

    .gallery-lightbox {
      &--actions {
        background-color: rgba($color-activision, .9);

        @media screen and (min-width: $screen-sm) {
          background-color: transparent;
        }

        path {
          fill: $color-white;
        }
      }

      &__details-container {
        background-color: $color-activision;

        .Accordian {
          background-color: $color-activision;
        }

        .scrollable-container {
          .bottom-shadow {
            background-image: linear-gradient(0deg, $color-activision 0%, rgba(255, 255, 255, 0) 100%);
          }
        }
      }

      &__uploader-date {
        color: $color-white;
      }

      &__uploader-name {
        color: $color-white;
      }
    }

    span {
      color: $color-white;
    }
  }

  .Gray-Button {
    background-color: rgba($color-activision, .9);
    border-radius: 0;
    color: $color-white;

    path {
      fill: $color-white;
    }
  }

  .LandingPageCallout--Preview {
    background-color: $color-activision;

    h2 {
      font-size: 1.6em;
    }

    .Person--title {
      color: $color-white;
    }

    .Preview {
      &-Header__Text {
        color: $color-white;
        font-size: 2.5em;
      }

      &-LeftColumn {
        .Filters__button {
          .toggle-filters {
            background-color: $color-activision;
            border-radius: 0;
            color: $color-white;
            outline: 0;

            path {
              fill: $color-white;
            }
          }
        }
      }

      &-Tags {
        .chq-pan {
          background-color: $color-activision;
          box-shadow: 0 0 4px rgba($color-white, .2);
        }

        .Tags__title {
          h2 {
            font-size: 1.4em;
          }
        }
      }

      &-Welcome {
        border-radius: 0;
      }
    }

    .Section__highlights {
      a {
        color: $color-white;
      }

      svg {
        path {
          fill: $color-white;
        }
      }
    }
  }

  .Preview {
    &-Body {
      color: $color-white;
    }

    &-Browser {
      &__Trends {
        .chq-pbn {
          background-color: $color-activision;
        }
      }
    }

    &-Tags {
      .chq-ffd-tags {
        .chq-bdg {
          background-color: $color-activision;
          border: 1px solid $color-white;
          border-radius: 0;
          color: $color-white;

          g,
          path {
            fill: $color-white;
          }

          &:hover {
            background-color: $color-white;
            border-color: rgba($color-activision, .5);
            color: $color-activision;

            g,
            path {
              fill: $color-activision;
            }
          }

          &.Selected {
            background-color: $color-white;
            border-color: rgba($color-activision, .5);
            color: $color-activision;

            g,
            path {
              fill: $color-activision;
            }
          }
        }
      }
    }

    &-Welcome {
      &__Icon {
        background-color: $color-activision;
      }
    }
  }

  .QuickOptions {
    .QuickOptions__menu {
      .dropdown-menu {
        background-color: $color-activision;
        border: 1px solid #373737;
        border-radius: 0;
        color: $color-white;

        .chq-btn {
          color: $color-white;

          path {
            fill: $color-white;
          }
        }

        li {
          border-radius: 0;

          &:hover {
            background-color: #373737;
          }
        }
      }
    }
  }

  .StoryItemPlaceholder {
    background-color: #2c2c2c;

    &__preview__title {
      p,
      .StoryItemPlaceholder__user {
        background-color: $color-black-alpha-20;
      }
    }
  }

  &.LandingPageUserCallout {
    .LandingPageCallout--Preview  {
      .e_label {
        border-color: #373737;

        li {
          .chq-btn {
            color: $color-white;
          }

          &.selected {
            border-color: $color-activision-highlight;

            .chq-btn {
              color: $color-activision-highlight;
            }
          }
        }
      }

      .Person--name {
        font-size: 2em;
      }

      .Preview-Browser__Stories  {
        h2 {
          font-size: 1.8em;
        }
      }

      .subtitle {
        font-size: 1.25em;
      }
    }

    .Preview {
      &-Tags {
        .chq-ffd-tags {
          .chq-bdg {
            &:hover {
              background-color: $color-activision;
              border: 1px solid $color-white;
              color: $color-white;

              g,
              path {
                fill: $color-white;
              }
            }

            &.Selected {
              background-color: $color-activision;
              border: 1px solid $color-white;
              color: $color-white;

              g,
              path {
                fill: $color-white;
              }
            }
          }
        }
      }

      &-Welcome {
        &__Icon {
          background-color: $color-activision;
        }
      }
    }
  }

  &.link-share  {
    border: 1px solid #373737;
    color: $color-white;

    path {
      fill: $color-white;
    }

    .chq-pan {
      &--ft {
        background-color: $color-activision;
        border-color: #373737;

        .chq-btn {
          background-color: $color-activision-highlight;
          border-color: $color-activision-highlight;
          color: $color-white;
          outline: 0;

          &:hover {
            background-color: rgba($color-activision-highlight, .9);
          }

          &-iv {
            background-color: $color-activision;
            border-color: $color-activision-highlight;
            color: $color-activision-highlight;

            &:hover {
              background-color: $color-activision;
            }
          }
        }
      }

      &--hd-pr {
        background-color: $color-activision;
      }
    }

    .chq-ffd {
      .chq-ffd--ctrl {
        background-color: $color-activision;
        color: $color-white;
      }
    }

    .chq-scs {
      background-color: lighten($color-activision-highlight, 30%);
      border-color: lighten($color-activision-highlight, 15%);
      color: $color-activision-highlight;
    }
  }
}
