@import '../common';

.approved-for-social-container {
  border: 2px solid $color-border;
  border-radius: 0 0 $size-border-radius $size-border-radius !important;
  border-top: unset;
  font-size: 1em;
  font-weight: 400;
  min-height: 240px;
}

.LinkedinShareModal {
  border-radius: $size-border-radius;

  .chq-pan {
    &--hd {
      background: $linkedin-gradient;
      border-radius: $size-border-radius $size-border-radius 0 0;

      h2 {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .social-icon {
        margin-right: 10px;
        width: 24px;
      }
    }

    &--bd {
      max-height: 70vh;
      overflow-y: scroll;
      padding: 15px 24px;

      .Body_Header {
        align-items: center;
        display: flex;
        padding-bottom: 15px;

        &--Information {
          display: flex;
          flex-direction: column;

          &_Creator {
            font-weight: $font-weight-heavy;
          }

          &_Title {
            color: $color-secondary-font;
          }
        }

        .chq {
          &-ffd {
            margin-right: 10px;
            margin-top: 0;

            &--sl--icon {
              margin-top: 7px;
              max-width: initial;
              padding-left: 20px;
            }

            &--sl--opts {
              z-index: 3;
            }

            &--ctrl {
              border: 2px solid $color-secondary-font;
              border-radius: 30px;
              color: $color-primary-font;
              cursor: pointer;
              font-weight: $font-weight-normal;
            }

            &--rq {
              position: absolute;
            }
          }

          &-tmb {
            height: 48px;
            margin-right: 15px;
            min-width: 48px;
            width: 48px;
          }
        }

        .options-creator {
          align-items: center;
          display: flex;
          z-index: 30;
        }
      }

      .textarea {
        border: 0;
        box-shadow: none;
        font-weight: $font-weight-normal;
        outline: 0;
        padding: 0;
        resize: none;

        &[contenteditable] {
          &:empty {
            &::before {
              color: $color-primary-font;
              content: 'Put your message here...';
            }
          }
        }
      }

      .gallery-lightbox__photo {
        border-radius: $size-border-radius;
        height: initial;
        margin-top: 15px;

        &--image {
          border-radius: $size-border-radius;
          max-width: 100%;
        }
      }

      .Linkedin-Share__Container {
        border: 1px solid $color-border;
        border-radius: $size-border-radius;

        &-Header {
          padding: 12px 8px 0;

          .share-message {
            margin-bottom: 0;
          }
        }
      }

      .Linkedin_Creator {
        align-items: center;
        display: flex;
        margin-bottom: 8px;

        &--Information {
          display: flex;
          flex-direction: column;

          &_Creator {
            font-size: $font-size-nav;
            font-weight: $font-weight-heavy;
          }

          &_Title {
            color: $color-secondary-font;
            font-size: $font-size-12;
            font-weight: $font-weight-normal;
          }
        }

        .chq {
          &-ffd {
            margin-right: 15px;
            margin-top: 0;

            &--sl--icon {
              margin-top: 7px;
            }
          }

          &-tmb {
            height: 40px;
            margin-right: 8px;
            min-width: 40px;
            width: 40px;
          }
        }
      }

      .container-lightbox {
        height: 690px;
        position: relative;

        .gallery-lightbox__photo--image {
          display: none;
          z-index: $z-index-low;

          &.active {
            display: block;
          }
        }
      }
    }

    &--ft {
      align-items: center;
      display: flex;
      justify-content: end;

      .chq-abn {
        background-color: $color-linkedin-blue;
        border-radius: 20px;
        color: $color-white;
        padding: 5px 20px;

        &[disabled] {
          cursor: not-allowed;
          opacity: .5;
        }
      }
    }
  }

  .schedule-later {
    align-items: center;
    display: flex;

    .chq-ffd {
      align-content: center;
      display: flex;
    }
  }

  .schedule-at-later {
    align-items: center;
    display: flex;
    margin-left: 5px;
    margin-right: 15px;
    position: relative;

    .chq-ffd {
      margin-bottom: 0;
    }

    .chq-pbn {
      background-color: transparent;
      margin-bottom: 0;
      padding-left: 30px;
    }

    .calendar-icon {
      left: 5px;
      position: absolute;
      z-index: $z-index-medium;

      &.disabled {
        path {
          fill: $color-secondary-font;
        }
      }
    }
  }

  .public-DraftEditorPlaceholder-root {
    z-index: unset;
  }

  .chq-edi {
    background-color: unset;
    border: unset;
    box-shadow: unset;

    &--cp {
      display: none;
    }

    &--sg {
      left: 0 !important;

      .chq-tmb {
        @include square(40px);
      }

      &--et {
        span {
          font-weight: $font-weight-normal;
          padding-left: 50px;
        }
      }

      .chq-icon {
        svg {
          @include square(40px);
        }
      }
    }
  }
}

.chq-ttp--bbl {
  font-weight: 400;
}
