.AIPromptModal {
  border-radius: $size-border-radius;
  position: relative;

  .chq {
    &-ldr {
      background-color: $color-black-alpha-50;
      border-radius: $size-border-radius;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: $z-index-medium;
    }

    &-pan {
      &--hd {
        border-radius: $size-border-radius $size-border-radius 0 0;
      }
    }

    &-btn-pr {
      svg {
        margin-right: 5px;
      }
    }

    &-ffd {
      &--ctrl {
        &--validation {
          bottom: 10px;
          top: initial;
        }
      }
    }
  }

  .AllowedTypes {
    margin-top: 20px;
  }

  &-SecondStep {
    min-width: 600px;
    width: 70vw;

    .chq {
      &-pan {
        &--bd {
          padding: 30px;
        }
      }
    }
  }

  .Footer-Buttons {
    display: flex;

    .Mapped-cmk {
      display: flex;
      height: 100%;
      margin-left: 15px;
      margin-top: 0;
    }
  }
}
