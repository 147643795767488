@import '../common';

.Accordian {
  background-color: $color-white;
  border: 1px solid $color-border-darker;
  border-radius: $size-border-radius;
  padding: 30px;
  position: relative;

  &.open {
    border-color: $color-primary-blue;
    box-shadow: inset 0 1px 1px rgba($color-black, .075), 0 0 8px $search-bar-shadow;
  }

  &:hover {
    border-color: $color-primary-blue;
    box-shadow: inset 0 1px 1px rgba($color-black, .075), 0 0 8px $search-bar-shadow;
  }

  &__container {
    align-items: center;
    display: flex;

    &:hover {
      cursor: pointer;
    }
  }

  &__title {
    font-size: $font-size-22;
    margin-bottom: 0;
    margin-top: 0;

    &--container {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &--icon {
      height: 40px;
      margin: 0 !important;
      width: 40px;

      &__container {
        align-items: center;
        background-color: $color-primary-blue;
        border-radius: 30px;
        display: flex;
        height: 60px;
        justify-content: center;
        margin-right: 25px;
        padding: 10px;
        position: relative;
      }

      &__exclamation {
        @include square(30px);
        border: 2px solid $color-light-blue;
        border-radius: 30px;
        bottom: -10px;
        position: absolute;
        right: -12px;
      }

      path {
        fill: $color-white;
      }
    }

    path {
      fill: $color-link-blue;
    }
  }

  &__subtitle {
    font-size: $font-size-17;
    font-weight: $font-weight-light;
    margin: 5px 0 0;
  }

  &__arrow {
    float: right;
    height: 30px;
    margin-left: 15px;
    width: 30px;

    path {
      fill: $color-secondary-font;
    }
  }

  &__body {
    max-height: 1000px;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: max-height 300ms;

    &.closed {
      max-height: 0;
    }

    .chq-pbn {
      outline: none;
      text-align: left;
    }

    input {
      margin-bottom: 8px;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      border-radius: 3px;
      color: $color-link-blue;
      cursor: pointer;
      padding: 2px;
      padding-left: 22px;
      position: relative;
      user-select: none;

      &:hover {
        color: darken($color-link-blue, 10%);
      }

      .small {
        color: $color-primary-light-font;
        font-size: $font-size-small;
      }

      svg {
        height: 14px;
        left: 4px;
        margin-bottom: 2px;
        margin-top: 2px;
        position: absolute;
        width: 14px;
      }

      path {
        fill: $color-link-blue;
      }
    }
  }

  &.OrganizationsAccordian {
    border: 0;
    padding: 0;

    &:hover {
      background-color: $color-background;
      box-shadow: none;
    }

    &.open {
      box-shadow: none;

      &:hover {
        background-color: initial;
      }
    }

    .Accordian__arrow {
      @include square(16px);
      margin-right: 20px;
    }
  }
}
