@font-face {
  font-family: 'VerizonNHGDS-Bold';
  src: url('/fonts/verizon-nhgds/VerizonNHGDS-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'VerizonNHGDS-Regular';
  src: url('/fonts/verizon-nhgds/VerizonNHGDS-Regular.ttf') format('truetype');
}

$color-verizon-lighter-black: #333;
$color-verizon-black: #000;
$color-verizon-red: #e00;
$color-verizon-white: #fff;
$color-verizon-dark-gray: #2c2c2c;

.verizon-nav {
  &.chq-nav {
    background-color: $color-verizon-black;

    .chq-nav {
      &--search {
        input {
          background-color: rgba(255, 255, 255, .3);
        }
      }

      &--items {
        a {
          border-color: $color-verizon-black;

          &.active {
            border-color: $color-verizon-white;
            color: $color-verizon-white;

            path {
              fill: $color-verizon-white;
            }
          }

          &:hover {
            border-bottom-color: $color-verizon-white;
          }
        }
      }

      &--notit {
        em {
          background-color: $color-verizon-red;
          color: $color-verizon-white;
        }
      }

      &--admin {
        em {
          background-color: $color-verizon-red;
          color: $color-verizon-white;
        }
      }

      &--admint {
        &:hover {
          background-color: $color-verizon-dark-gray;
        }
      }

      &--unseen {
        border-color: $color-verizon-red;
      }
    }
  }
}

.verizon {
  color: $color-verizon-lighter-black;
  font-family: 'VerizonNHGDS-Regular', Arial, sans-serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-verizon-black;
    font-family: 'VerizonNHGDS-Bold';
  }

  .back-arrow {
    color: $color-verizon-red;
    font-family: 'VerizonNHGDS-Bold';

    path {
      fill: $color-verizon-red;
    }
  }

  &.LandingPageCallout {
    .Gray-Button {
      color: $color-verizon-lighter-black;

      path {
        fill: $color-verizon-lighter-black;
      }
    }

    .LandingPageCallout--Preview {
      .Preview {
        &-Header__Text {
          font-family: 'VerizonNHGDS-Bold';
        }

        &-Tags {
          .chq-ffd-tags {
            .chq-bdg {
              &.Selected {
                background-color: $color-verizon-black;
                color: $color-verizon-white;

                path {
                  fill: $color-verizon-white;
                }
              }
            }
          }
        }
      }

      .Section__highlights {
        a {
          color: $color-verizon-lighter-black;
        }
      }
    }
  }

  .FeedItem__preview__title__Story {
    color: $color-verizon-black;

    .Title {
      color: $color-verizon-black;

      path {
        fill: $color-verizon-red;
      }
    }

    .Subprompt-Container {
      color: $color-verizon-lighter-black;
    }

    .Accordian__container {
      path {
        fill: $color-verizon-red;
      }
    }
  }

  .gallery-lightbox__uploader-name {
    color: $color-verizon-black;
    font-family: 'VerizonNHGDS-Bold';
  }

  .gallery-lightbox__uploader-date {
    color: $color-verizon-lighter-black;
  }

  .chq-bdg {
    color: $color-verizon-lighter-black;

    path {
      fill: $color-verizon-lighter-black;
    }

    &.Selected {
      background-color: $color-verizon-black;
      color: $color-verizon-white;

      path {
        fill: $color-verizon-white;
      }
    }
  }
}
