.ProfileOrganizations {
  padding-top: 100px;

  &__Container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__Name {
    font-size: $font-size-22;
    font-weight: $font-weight-heavy;
    margin-bottom: 20px;
  }

  &__Instructions {
    font-size: $font-size-xl;
    margin-bottom: 40px;
  }

  &__Option {
    width: 500px;
  }

  .OrganizationRow {
    background-color: $color-white;
    border: 1px solid $color-border;
    border-radius: $size-border-radius;
    margin-bottom: 15px;
    padding: 25px 20px;

    &:hover {
      box-shadow: $box-shadow-default;
    }

    &.Selected {
      border-color: $color-primary-blue;
      box-shadow: inset 0 1px 1px rgba($color-black, .075), 0 0 8px rgba($color-primary-blue, .5);
    }

    &-Image {
      border: 0;
      height: 40px;
      margin-right: 20px;
      max-width: 100px;
      min-width: 100px;
      object-fit: contain;
      padding: 0;
    }

    &-Name {
      font-size: $font-size-large;
    }

    svg {
      @include square(20px);
    }
  }
}
