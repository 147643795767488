$color-intellia-blue: #385dae;
$color-intellia-dark-blue: #5077cd;
$color-intellia-yellow: #ffb25b;

.intellia-therapeutics-nav {
  &.chq-nav {
    background-color: $color-intellia-blue;

    .chq-nav {
      &--search {
        input {
          background-color: $color-intellia-dark-blue;
        }
      }

      &--items {
        a {
          border-color: $color-intellia-blue;

          &.active {
            border-color: $color-intellia-yellow;
            color: $color-intellia-yellow;

            path {
              fill: $color-intellia-yellow;
            }
          }
        }
      }

      &--notit {
        em {
          background-color: $color-intellia-yellow;
        }
      }

      &--admin {
        em {
          background-color: $color-intellia-yellow;
        }
      }

      &--admint {
        &:hover {
          background-color: $color-intellia-dark-blue;
        }
      }

      &--unseen {
        border-color: $color-intellia-yellow;
      }
    }
  }
}
