.LanguageSelector {
  align-items: center;
  display: flex;
  margin-right: 15px;

  path {
    fill: $color-primary-font;
  }

  .chq-ffd {
    align-items: center;
    border: 2px solid $color-border;
    border-radius: $size-border-radius;
    display: flex;
    margin-bottom: 0;
    margin-right: 5px;
    padding: 0;

    &--lb {
      display: flex;
      left: 10px;
      margin-bottom: 0;
      position: absolute;
      z-index: $z-index-low;
    }

    &--ctrl {
      border: 0;
      font-weight: $font-weight-normal;
      margin-bottom: 0;
      padding: 0 10px 0 40px;

      &:focus {
        border-bottom: 0;
      }
    }
  }

  .chq-ttp {
    display: flex;

    &--bbl {
      min-width: 300px;
    }
  }
}
