@import '../common';

.AttendeesTable {
  .link {
    border-bottom: $color-link-blue;
    color: $color-link-blue;
  }

  table {
    margin-bottom: 0;
  }

  td {
    padding-top: 16px !important;
  }

  .input {
    padding-right: 45px;
  }

  &--search {
    background-color: $color-primary-blue;
    padding: 15px;
  }

  .nav-tabs {
    padding-top: 0;
  }
}

.nav-tabs {
  li.active {
    a {
      background-color: $color-white;
      border: 0;
      border-bottom-color: transparent;
      font-weight: $font-weight-normal;
    }
  }
}

.chq-mdl {
  &.attendees-mdl {
    width: 750px;

    .chq-pan--bd {
      padding-top: 0;
    }
  }
}

.AttendeesModalButton {
  .link {
    display: block;
    margin-bottom: 5px;
    margin-top: 2px;
  }

  .center {
    margin: 15px auto 0;
    text-align: center;
    width: 210px;
  }
}
