@font-face {
  font-family: 'OpenSans-VariableFont_wdth,wght';
  src: url('/fonts/open-sans/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
}

$color-deloitte-black: #000;
$color-deloitte-primary-green: #86bc25;
$color-deloitte-dark-gray: #2c2c2c;
$color-deloitte-light-gray: #53565a;

.deloitte-nav {
  &.chq-nav {
    background-color: $color-deloitte-black;

    .chq-nav {
      &--search {
        input {
          background-color: $color-deloitte-light-gray;
        }
      }

      &--items {
        a {
          border-color: $color-deloitte-black;

          &.active {
            border-color: $color-deloitte-primary-green;
            color: $color-deloitte-primary-green;

            path {
              fill: $color-deloitte-primary-green;
            }
          }
        }
      }

      &--notit {
        em {
          background-color: $color-deloitte-primary-green;
          color: $color-deloitte-black;
        }
      }

      &--admin {
        em {
          background-color: $color-deloitte-primary-green;
          color: $color-deloitte-black;
        }
      }

      &--admint {
        &:hover {
          background-color: $color-deloitte-dark-gray;
        }
      }

      &--unseen {
        border-color: $color-deloitte-primary-green;
      }
    }
  }
}

.deloitte {
  font-family: 'OpenSans-VariableFont_wdth,wght';
}
