.change-password {
  margin: 0 auto 15px;
  max-width: $screen-xxs;
  padding: 25px 0 40px;
  width: 100%;

  .chq-wrn {
    margin-top: 15px;
  }
}
