@import '../common';

$start-gradient-color: #0e1c38;
$end-gradient-color: #01378e;

.disableOverflowY {
  overflow-y: hidden !important;
}

.post-story-modal-ainteligence {
  background: linear-gradient(to bottom, $start-gradient-color, $end-gradient-color);
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  max-width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  width: 100vw;

  @media screen and (min-width: $screen-md) {
    padding: 15px 30px;
  }

  @media screen and (min-width: $screen-lg) {
    padding: 30px 60px;
  }

  .chq {
    &-ldr {
      background-color: $color-black-alpha-50;
      border-radius: $size-border-radius;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: $z-index-medium;
    }

    &-pan {
      &--bd {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 15px 15px 90px;

        @media screen and (min-width: $screen-sm) {
          padding-bottom: 15px;
        }

        .organization-logo {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 50px;
          min-height: 50px;
          width: 200px;
        }
      }

      &--hd {
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;

        @media screen and (min-width: $screen-sm) {
          right: 25px;
          top: 20px;
        }

        .chq-pbn {
          opacity: 1;
          right: 15px;
          top: 15px;

          svg {
            @include square(24px);

            @media screen and (min-width: $screen-sm) {
              @include square(34px);
            }
          }

          path {
            fill: $color-white;
          }
        }
      }
    }

    &-ffd--lb {
      color: $color-white;
    }

    &-cmk {
      color: $color-white;
    }

    &-wrn {
      border-radius: $size-border-radius;
      margin-top: 15px;
    }
  }

  .PostingContainer {
    h3 {
      color: $color-white;
    }
  }

  .IntroPage {
    align-items: center;
    flex-direction: column;
    height: 100%;

    @media screen and (min-width: $screen-sm) {
      display: flex;
      flex-direction: row;
    }

    .LeftColumn-ai {
      color: $color-white;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      @media screen and (min-width: $screen-sm) {
        height: calc(100% - 50px);
        margin-bottom: 0;
        margin-top: 50px;
      }

      &__container {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        @media screen and (min-width: $screen-lg) {
          margin-right: 100px;
        }
      }

      &__description {
        font-size: $font-size-large;

        @media screen and (min-width: $screen-sm) {
          font-size: $font-size-24;
        }
      }

      &__bottom-container {
        align-items: center;
        display: flex;
        flex-direction: column;

        @media screen and (min-width: $screen-lg) {
          flex-direction: row;
        }

        .middle-text {
          margin: 35px 30px 0;
        }

        .QR-Container {
          align-items: center;
          display: flex;
          flex-direction: column;

          &__Code {
            @include square(180px);
            align-items: center;
            display: flex;
            justify-content: center;
            position: relative;

            .QRCodeBorder {
              position: absolute;
            }
          }

          &__Text {
            font-size: $font-size-large;
            margin-bottom: 10px;
          }
        }
      }

      h2 {
        font-size: 30px;
        margin-bottom: 30px;

        @media screen and (min-width: $screen-sm) {
          font-size: 40px;
        }
      }

      h4 {
        margin-top: 30px;
      }
    }

    .RightColumn-ai {
      align-items: center;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      z-index: 1;

      @media screen and (min-width: $screen-sm) {
        align-items: start;
      }

      .storytelling-guide {
        align-items: center;
        background-color: $start-gradient-color;
        border: 2px solid rgba($color-white, .5);
        border-radius: 25px;
        box-shadow: 0 2px 40px rgba($end-gradient-color, .6);
        box-sizing: border-box;
        color: $color-white;
        display: flex;
        flex-direction: column;
        max-width: 400px;
        padding: 15px;

        @media screen and (min-width: $screen-md) {
          padding: 30px;
        }

        @media screen and (min-width: $screen-xl) {
          min-width: 400px;
        }

        .guide-title {
          -webkit-background-clip: text; // sass-lint:disable-line no-vendor-prefixes
          -webkit-text-fill-color: transparent; // sass-lint:disable-line no-vendor-prefixes
          background: linear-gradient(to right, #ffd24b, #8cb4d6);
          background-clip: text;
          font-size: $font-size-22;
          margin: 0;
          text-align: center;

          @media screen and (min-width: $screen-sm) {
            font-size: $font-size-24;
          }
        }

        .guide-paragraph {
          align-items: center;
          display: flex;
          margin-bottom: 15px;
          padding: 0;

          @media screen and (min-width: $screen-sm) {
            margin-bottom: 0;
            padding: 15px 5px;
          }

          &__sub {
            font-size: $font-size-large;
            margin-bottom: 15px;
            margin-top: 15px;
            max-width: 280px;
            text-align: center;

            @media screen and (min-width: $screen-sm) {
              font-size: $font-size-xl;
            }
          }

          span {
            font-size: $font-size-large;
          }

          svg {
            @include square(35px);
            margin-right: 25px;
            min-width: 35px;
          }
        }
      }
    }
  }

  .NextBtn {
    align-items: center;
    border-radius: 12px;
    display: flex;
    font-size: $font-size-xl;
    height: 60px;
    justify-content: center;
    width: 100%;

    @media screen and (min-width: $screen-sm) {
      font-size: $font-size-24;
      height: 80px;
      margin-top: 35px;
      width: 320px;
    }

    .arrow-icon {
      @include square(24px);
      margin-left: 8px;
      transform: rotate(180deg);
      transition: transform .3s ease;
    }
  }

  .MobileHeader {
    align-items: center;
    color: $color-white;
    display: flex;
    justify-content: space-between;
    padding: 0 0 15px;
    width: 100%;

    h4 {
      margin: 0;
    }

    svg {
      @include square(24px);
    }

    path {
      fill: $color-white;
    }

    .chq-pbn {
      display: flex;
    }
  }

  .MobileButtons {
    background-color: $end-gradient-color;
    bottom: 0;
    margin: 0 -15px;
    padding: 15px;
    position: fixed;
    width: 100%;
    z-index: $z-index-medium;
  }

  .MainContainerIntelligence {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 45px);
    justify-content: flex-start;
    overflow: hidden;
    position: relative;

    @media screen and (min-width: $screen-sm) {
      flex-direction: row;
      height: calc(100vh - 150px);
      justify-content: center;
      overflow: initial;
      padding-bottom: 60px;
    }

    &__Scrollable {
      overflow-x: hidden;
      overflow-y: scroll;
      width: 100%;
    }

    &__Edit {
      align-items: center;
      color: $color-white;
      display: flex;
      flex-direction: column;
      height: 100%;
      margin-top: 15px;

      @media screen and (min-width: $screen-sm) {
        height: initial;
        justify-content: center;
        margin-top: -50px;
      }
    }

    &__Navigation {
      bottom: 0;
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 100%;

      .chq-btn {
        padding: 10px;
        width: 190px;

        &:hover {
          border-width: 1px;
          padding: 10px;
        }
      }

      &-btn-prev {
        background: transparent;
        border-color: $color-white;
        color: $color-white;
      }

      &-between-text {
        color: $color-white;
        font-size: $font-size-xl;
        font-weight: $font-weight-heavy;

        &-details {
          color: $color-white;
          margin-left: 23%;
        }
      }
    }

    .story-information {
      border: 1px solid $color-secondary-font;
      border-radius: 8px;
      box-sizing: border-box;
      color: $color-white;
      margin-bottom: 15px;
      margin-top: 50px;
      max-width: 550px;
      padding: 15px;

      &__container {
        align-items: flex-start;
        display: flex;

        svg {
          @include square(18px);
          margin-right: 8px;
          min-width: 18px;

          path {
            fill: $color-link-blue;
          }
        }
      }
    }

    &__Textarea-txt {
      background: transparent;
      border: 0;
      box-sizing: border-box;
      font-size: $font-size-large;
      margin-top: 5px;
      min-height: 450px;
      overflow-y: scroll;
      padding: 0 30px;
      resize: none;
      width: 100%;

      @media screen and (min-width: $screen-sm) {
        width: 600px;
      }

      &.SmallTextArea {
        min-height: 300px;
      }

      &.Prompter {
        padding: 0 30px;
      }
    }

    &__Suggestions {
      flex: 1;
      overflow-x: hidden;
      overflow-y: scroll;
      position: relative;
      width: 100%;

      @media screen and (min-width: $screen-sm) {
        max-height: 450px;
      }
    }

    .Scrollable-Overlay {
      background-image: linear-gradient(0deg, $end-gradient-color 0, rgba($end-gradient-color, 0) 100%);
      bottom: 0;
      display: block;
      height: 10%;
      position: sticky;
      width: 100%;
    }

    .PostStory-Animation {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;

      @media screen and (min-width: $screen-sm) {
        height: initial;
      }

      .magic {
        &-Title {
          h2 {
            color: $color-white;
            font-size: $font-size-24;
            text-align: center;

            @media screen and (min-width: $screen-sm) {
              font-size: $font-size-30;
              text-align: left;
            }
          }
        }

        &-Container {
          margin-top: 50px;

          @media screen and (min-width: $screen-sm) {
            height: 400px;
            margin-top: 100px;
          }
        }
      }
    }

    .TextArea {
      &-Container {
        border: 1px solid $color-secondary-font;
        border-radius: $size-border-radius;
        padding-top: 20px;
        position: relative;
        width: 100%;

        @media screen and (min-width: $screen-sm) {
          width: 600px;
        }
      }

      &-Tip {
        align-items: center;
        background-color: $color-dark-blue;
        border-radius: $size-border-radius;
        color: $color-white;
        display: flex;
        margin: 0 30px 10px;
        padding: 10px 15px;

        svg {
          @include square(22px);
          margin-right: 10px;
          min-width: 22px;

          path {
            fill: $color-white;
          }
        }

        p {
          margin: 0;
        }
      }
    }

    &__Textarea-suggestion {
      background: transparent;
      border: 1px solid $color-secondary-font;
      border-radius: 10px;
      box-sizing: border-box;
      height: auto;
      margin-bottom: 15px;
      overflow-y: scroll;
      padding: 20px;
      resize: none;

      @media screen and (min-width: $screen-sm) {
        width: 600px;
      }

      h3 {
        font-size: $font-size-large;
        margin: 0;
      }

      .caption {
        margin: 15px 0 0;
        width: 100%;
      }
    }

    &__Title {
      align-items: center;
      display: flex;
      grid-gap: 10px;
      justify-content: center;

      h2 {
        display: flex;
        font-size: 30px;
        justify-content: center;
        margin-bottom: 20px;
      }

      svg {
        fill: $color-primary-green;
        height: 30px;
        width: 30px;
      }

      .refresh-icon {
        cursor: pointer;

        &:active {
          opacity: .7;
          transform: scale(.95);
        }
      }
    }

    &__Title-media {
      align-items: center;
      display: flex;
      grid-gap: 10px;
      justify-content: center;
      margin-bottom: 30px;

      h2 {
        display: flex;
        font-size: 30px;
        justify-content: center;
        margin: 0;
      }

      svg {
        fill: $color-primary-green;
        height: 30px;
        width: 30px;
      }
    }

    textarea {
      outline: none;
    }

    &__Toggle-container {
      background-color: transparent;
      border: 1px solid $color-border-darker;
      border-radius: 30px;
      display: flex;
      margin-bottom: 30px;
      padding: 0;
      position: relative;
      width: 300px;

      @media screen and (min-width: $screen-sm) {
        margin-top: 0;
      }
    }

    &__Toggle-button {
      background-color: $color-white;
      border: 0;
      border-radius: 30px;
      color: $color-secondary-font;
      cursor: pointer;
      font-weight: bold;
      padding: 10px 48px;
      transition: background-color .3s, color .3s;

      &:not(.active) {
        background-color: transparent;
        color: $color-white;
        padding: 10px 48px;
      }

      &:first-child {
        left: 0;
      }

      &:last-child {
        left: 100px;
      }

      &:hover {
        padding: 10px 48px;
      }

      &.active {
        background-color: $color-facebook-blue;
        color: $color-white;
        padding: 10px 56px;
      }
    }

    &__Display-media {
      align-items: center;
      border: 1px solid $color-secondary-font;
      display: flex;
      height: 300px;
      position: relative;
      width: 100%;

      .Empty-Media {
        align-items: center;
        color: $color-secondary-font;
        display: flex;
        flex-direction: column;

        .PromptIcon {
          @include square(70px);
          background: none;

          svg {
            @include square(60px);
          }

          path {
            fill: $color-secondary-font;
          }
        }
      }
    }

    &__Media-box {
      margin-top: 15px;
    }

    &__Title-script {
      align-items: center;
      display: flex;
      grid-gap: 10px;
      justify-content: center;
      margin-bottom: 25px;

      @media screen and (min-width: $screen-sm) {
        margin-top: 15px;
      }

      h4 {
        margin: 0;
      }

      svg {
        fill: $color-primary-green;
        height: 20px;
        width: 20px;
      }

      .refresh-icon {
        cursor: pointer;

        &:active {
          opacity: .7;
          transform: scale(.95);
        }
      }
    }

    &__ProgressBar {
      margin-top: 0;
    }
  }

  .PromptQuestion {
    &__Container {
      @media screen and (min-width: $screen-sm) {
        width: 800px;
      }
    }

    &__Title {
      color: $color-white;
      margin-bottom: 75px;
      margin-top: 100px;

      @media screen and (min-width: $screen-sm) {
        margin-bottom: 15px;
      }

      h2 {
        margin-bottom: 30px;
      }
    }

    .chq-ffd {
      &--ctrl {
        background: transparent;
        border: 0;
        border-bottom: 2px solid $color-primary-green;
        border-radius: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        box-shadow: none;
        box-sizing: border-box;
        color: $color-white;
        display: block;
        font-size: $font-size-large;
        margin-bottom: 5px;
        outline: none;
        padding: 6px 12px;
        resize: none;
        text-align: left;
        transition: border-bottom .3s;
        width: 100%;

        &:focus {
          border: 0;
          border-bottom: 2px solid $color-primary-green;
        }

        // sass-lint:disable no-vendor-prefixes
        &::-webkit-scrollbar {
          display: none;
        }

        @media screen and (min-width: $screen-sm) {
          padding-right: 75px;
        }
      }
    }

    .chq-sfx {
      align-items: center;
      display: flex;
      float: right;

      &:hover {
        cursor: not-allowed;
      }

      svg {
        margin-left: 5px;

        path {
          fill: rgba($color-white, .5);
        }
      }

      &-text {
        color: rgba($color-white, .5);
        font-weight: $font-weight-lightest;

        &__bolder {
          color: rgba($color-white, .75);
          font-weight: $font-weight-heavy;
        }
      }

      &--suffix-icon {
        @include square(32px);
        max-width: unset;
        transform: rotate(180deg);
      }
    }
  }

  .post-story-modal {
    margin-top: 30px;
    padding-bottom: 60px;
    width: 100%;

    .UserInformation {
      height: 100%;
      margin-top: 15px;
      min-height: calc(100vh - 150px);
      position: static;

      @media screen and (min-width: $screen-sm) {
        margin-top: 0;
        min-height: initial;
      }

      .Content {
        margin-top: 0;

        h2 {
          color: $color-white;
        }

        .Back {
          background: transparent;
          border-color: $color-white;
          border-width: 1px;
          bottom: 0;
          color: $color-white;
          left: 0;
          padding: 10px;
          width: 190px;
        }

        .Submit {
          align-items: center;
          border-radius: 12px;
          border-width: 1px;
          bottom: 15px;
          display: flex;
          font-size: 20px;
          height: 60px;
          justify-content: center;
          position: fixed;
          top: initial;
          width: calc(100% - 30px);

          @media screen and (min-width: $screen-sm) {
            border-radius: 5px;
            bottom: 0;
            font-size: $font-size-normal;
            height: initial;
            padding: 10px;
            position: absolute;
            right: 0;
            width: 190px;
          }
        }
      }
    }
  }

  .ImageSlider {
    align-items: center;
    background-color: $color-background-darker;
    border: 0;
    border-radius: 6px;
    flex-direction: column;
    height: 96%;
    justify-content: center;
    min-height: 300px;
    position: relative;

    .chq-ffd--bg-img-container {
      display: none;

      &.active {
        display: block;
      }
    }

    &-Delete {
      left: 15px;
      position: absolute;
      top: 15px;
      z-index: $z-index-medium;

      .chq-btn {
        @include square(40px);
        align-items: center;
        background-color: rgba(0, 0, 0, .8);
        border-color: transparent;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        outline: 0;

        svg {
          margin: 0;
        }
      }
    }

    &-Pagination {
      bottom: 15px;
      display: flex;
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
    }

    .ImageCrop {
      align-items: center;
      background-color: rgba(0, 0, 0, .8);
      border-color: transparent;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      margin-left: 8px;
      outline: 0;
      padding: 6px 5px 1px;
      width: fit-content;
      z-index: 20;

      path {
        fill: $color-white;
      }
    }

    .image-slider-arrow {
      @include square(35px);
      background: rgba(255, 255, 255, .8);
      border: 0;
      border-radius: 30px;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, .5);
      padding: 6px;
      position: absolute;
      top: calc(50% - 28px);
      z-index: $z-index-low;

      &-right {
        padding-right: 4px;
        right: 4px;
        top: 50%;
      }

      &-left {
        left: 3px;
        padding-left: 2px;
        top: 50%;
      }
    }
  }

  .VideoOptionContainer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 165px;

    .VideoOptions {
      .chq-btn {
        align-items: center;
        background-color: rgba(0, 0, 0, .8);
        border-color: transparent;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        outline: 0;
        padding: 5px 9px;

        &:hover {
          border-width: 1px;
        }

        svg {
          margin: 0 5px 0 0;
        }
      }
    }
  }
}

.MediaButtons {
  width: 100%;

  @media screen and (min-width: $screen-sm) {
    margin-bottom: 30px;
  }

  &__Container {
    display: flex;
    text-align: center;

    .chq-btn {
      align-items: center;
      background-color: $color-primary-green;
      display: flex;
      font-size: $font-size-large;
      font-weight: $font-weight-heavy;
      height: 65px;
      justify-content: center;
      width: 50%;

      svg {
        @include square(24px);
        margin: 0 5px 0 0;
      }
    }
  }

  &__AddRecord-btn {
    margin-left: 10px;
    width: 50%;
  }
}

.magic-wand {
  @include square(100px);
  $ease: cubic-bezier(.1, 0, .43, 1);
  animation: 1200ms magic-wand-bounce $ease infinite alternate;
  inset: 0;
  margin: auto;
  transform-origin: 0% 100%;

  @media screen and (min-width: $screen-sm) {
    @include square(200px);
  }

  .star {
    animation: 800ms star-fade $ease infinite alternate;
    transition: fill 400ms $ease;

    path {
      animation: 1600ms star-rotate $ease infinite alternate;
    }

    &-1 {
      fill: #e9454b;
    }

    &-2 {
      fill: #8bc53e;
    }

    &-3 {
      fill: #469fd5;
    }
  }

  svg {
    height: 120px;
    position: absolute;

    @media screen and (min-width: $screen-sm) {
      height: 220px;
    }
  }
}

.wand {
  fill: currentColor;
}

.star {
  fill: currentColor;

  path {
    animation-delay: inherit;
  }

  &-1 {
    animation-delay: 0ms;

    path {
      transform-origin: 256px 64px;
    }
  }

  &-2 {
    animation-delay: -200ms;

    path {
      transform-origin: 96px 128px;
    }
  }

  &-3 {
    animation-delay: -400ms;

    path {
      transform-origin: 448px 384px;
    }
  }
}

@keyframes magic-wand-bounce {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-15%) rotate(-10deg);
  }
}

@keyframes star-fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: .6;
    transform: scale(.9);
  }
}

@keyframes star-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(10deg);
  }
}

.ProgressBar {
  &__Container {
    background-color: transparent;
    border-top: 1px solid $color-border-darker;
    margin: 0 -15px;
    min-height: 8px;
    overflow: hidden;
    width: calc(100% + 30px);

    @media screen and (min-width: $screen-sm) {
      border-top: 0;
      margin: 0;
    }

    &-progress-bar {
      background-color: $color-primary-green;
      height: 8px;
      left: 0;
      top: 0;
      transition: width .3s ease;

      @media screen and (min-width: $screen-sm) {
        margin: 0;
        position: absolute;
      }
    }
  }
}

.SelectedMedia-ai {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
  width: 100%;
  z-index: 1;

  .StoryMedia-Background-current {
    background-color: transparent;
  }

  .storytelling-guide {
    align-items: center;
    background-color: $start-gradient-color;
    border: 2px solid rgba($color-white, .5);
    border-radius: 25px;
    box-shadow: 0 2px 40px rgba($end-gradient-color, .6);
    box-sizing: border-box;
    color: $color-white;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    min-width: 400px;
    padding: 30px;
    width: 100%;

    .guide-title {
      -webkit-background-clip: text; // sass-lint:disable-line no-vendor-prefixes
      -webkit-text-fill-color: transparent; // sass-lint:disable-line no-vendor-prefixes
      background: linear-gradient(to right, #ffd24b, #8cb4d6);
      background-clip: text;
      margin: 0;
      text-align: center;
    }

    .guide-paragraph {
      align-items: center;
      display: flex;
      padding: 15px 5px;

      &__sub {
        font-size: $font-size-xl;
        margin-bottom: 15px;
        margin-top: 15px;
        max-width: 280px;
        text-align: center;
      }

      span {
        font-size: $font-size-large;
      }

      svg {
        @include square(35px);
        margin-right: 25px;
        min-width: 35px;
      }
    }
  }

  .chq-ffd {
    position: relative;
    width: 100%;

    &--lb {
      margin-bottom: 0;
    }

    &--im {
      align-items: center;
      background-color: transparent;
      border: 0;
      border-radius: $size-border-radius;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 0;
      min-height: 300px;

      &:hover {
        box-shadow: none;
      }

      &--bt-bg {
        left: 0;
        position: absolute;
        right: 0;
      }

      .chq-ipv {
        left: initial !important;
      }

      .Empty-Media {
        align-items: center;
        color: $color-secondary-font;
        display: flex;
        flex-direction: column;

        .PromptIcon {
          @include square(70px);
          background: none;

          svg {
            @include square(60px);
          }

          path {
            fill: $color-secondary-font;
          }
        }
      }

      .Video-Loader {
        align-items: center;
        color: $color-black-alpha-80;
        display: flex;
        flex-direction: column;
        font-weight: $font-weight-normal;
        max-width: 75%;

        .Percentage {
          font-weight: $font-weight-heavy;
        }
      }
    }

    &--bg-img {
      .chq-ffd--video {
        height: 100%;
        left: 0;
        margin: 0 auto;
        max-height: 100%;
        min-width: 100%;
        position: absolute;
        right: 0;
      }
    }

    &--bg-img-container {
      margin-bottom: 0;

      .empty-container {
        background-color: transparent;
        border-radius: $size-border-radius;
        filter: none;
        height: 45%;
        opacity: 1;
        transform: none;

        .chq-ffd--im {
          &:hover {
            cursor: initial;
          }
        }
      }

      .image-container {
        .chq-ffd--im {
          height: 100%;

          &:hover {
            cursor: initial;
          }
        }
      }
    }
  }

  .Media-Iframe {
    iframe {
      min-height: 300px;
    }
  }
}

.FinalizePage {
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;

  @media screen and (min-width: $screen-sm) {
    height: initial;
    margin-top: -50px;
    overflow: initial;
    width: 90%;
  }

  &__Title {
    color: $color-white;
    text-align: center;
  }

  &-Container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $screen-sm) {
      flex-direction: row-reverse;
      margin-top: 60px;
    }

    &__LeftColumn {
      color: $color-white;
      display: flex;
      flex-direction: column;

      h2 {
        color: $color-white;
        margin-bottom: 30px;
      }

      h4 {
        color: $color-white;
        margin-top: 30px;
      }
    }

    &__RightColumn {
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 15px;
      margin-top: 30px;

      @media screen and (min-width: $screen-sm) {
        margin-bottom: 0;
        margin-top: 0;
      }

      .StoryMedia-Background-current {
        background-color: transparent;
      }

      .chq-ffd {
        position: relative;
        width: 100%;

        &--lb {
          margin-bottom: 0;
        }

        &--im {
          align-items: center;
          background-color: transparent;
          border: 0;
          border-radius: $size-border-radius;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 0;
          min-height: 350px;

          &:hover {
            box-shadow: none;
          }

          &--bt-bg {
            left: 0;
            position: absolute;
            right: 0;
          }

          .chq-ipv {
            left: initial !important;
          }

          .Empty-Media {
            align-items: center;
            color: $color-secondary-font;
            display: flex;
            flex-direction: column;

            .PromptIcon {
              @include square(70px);
              background: none;

              svg {
                @include square(60px);
              }

              path {
                fill: $color-secondary-font;
              }
            }
          }

          .Video-Loader {
            align-items: center;
            color: $color-black-alpha-80;
            display: flex;
            flex-direction: column;
            font-weight: $font-weight-normal;
            max-width: 75%;

            .Percentage {
              font-weight: $font-weight-heavy;
            }
          }
        }

        &--bg-img {
          .chq-ffd--video {
            height: 100%;
            left: 0;
            margin: 0 auto;
            max-height: 100%;
            min-width: 100%;
            position: absolute;
            right: 0;
          }
        }

        &--bg-img-container {
          margin-bottom: 0;

          .empty-container {
            background-color: transparent;
            border-radius: $size-border-radius;
            filter: none;
            height: 45%;
            opacity: 1;
            transform: none;

            .chq-ffd--im {
              &:hover {
                cursor: initial;
              }
            }
          }

          .image-container {
            .chq-ffd--im {
              height: 100%;

              &:hover {
                cursor: initial;
              }
            }
          }
        }
      }

      .Media-Iframe {
        iframe {
          min-height: 350px;
        }
      }

      .ImageSlider {
        align-items: center;
        background-color: #f3f3f3;
        border: 0;
        border-radius: 6px;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        min-height: 350px;
        position: relative;

        .chq-ffd--bg-img-container {
          display: none;

          &.active {
            display: block;
          }
        }

        &-Pagination {
          bottom: 15px;
          display: flex;
          justify-content: center;
          left: 0;
          position: absolute;
          right: 0;
        }
      }
    }

    &__final-story {
      display: flex;
      justify-content: center;
      padding: 10px;
      width: 48%;
    }

    &__right-column {
      display: flex;
      justify-content: center;
      padding: 10px;
      width: 48%;

      .StoryMedia-Background-current {
        height: 100%;
        width: 100%;
      }
    }

    &__Textarea-txt {
      background: transparent;
      border: 1px solid $color-secondary-font;
      border-radius: $size-border-radius;
      box-sizing: border-box;
      font-size: $font-size-large;
      height: 500px;
      min-height: 350px;
      overflow-y: scroll;
      padding: 20px 30px;
      resize: none;

      @media screen and (min-width: $screen-sm) {
        max-height: calc(100vh - 280px);
      }
    }
  }
}
