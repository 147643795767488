@import '../common';

.chart {
  padding: 0 15px 15px;
  padding-right: 15px;

  .chart-header {
    border-bottom: 1px solid $color-secondary-light-font;
    color: $color-secondary-font;
    font-weight: 400;
    margin-top: 15px;
    padding-bottom: 5px;
  }

  .chart-row {
    border-top: 1px solid $color-border;
    padding: 8px 0;
  }

  .row {
    p {
      margin-bottom: 2px;
      padding-top: 5px;
    }

    .actions {
      float: right;
    }
  }
}
