.playback {
  border-radius: 1px;
  margin: 30px 0 15px;
  position: relative;

  .seekable {
    background: transparent;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .not-seekable {
    background: $color-border;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    &-left {
      border-radius: $size-border-radius 0 0 $size-border-radius;
      right: 100%;
    }

    &-right {
      border-radius: 0 $size-border-radius $size-border-radius 0;
      left: 100%;
      right: -$grabber-width;
    }
  }

  .grabber {
    align-items: center;
    background-color: rgba(0, 0, 0, .7);
    bottom: 0;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    transition: transform .2s ease;
    width: $grabber-width;
    z-index: 1;

    svg {
      @include square(16px);
      -moz-user-select: none; // sass-lint:disable-line no-vendor-prefixes
      -webkit-user-drag: none; // sass-lint:disable-line no-vendor-prefixes
      margin-left: 4px;
      user-select: none;
    }

    path {
      fill: $color-white;
    }

    &.start {
      border-radius: $size-border-radius 0 0 $size-border-radius;
      left: 0;
    }

    &.end {
      border-radius: 0 $size-border-radius $size-border-radius 0;
      right: -$grabber-width;
    }

    &:hover {
      cursor: col-resize;
    }
  }

  .cursor-marker {
    background-color: $color-primary-blue;
    bottom: 0;
    cursor: pointer;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: -10px;
    width: 3px;

    &::before {
      border: 12px solid transparent;
      border-top-color: $color-primary-blue;
      bottom: calc(100% - 16px);
      content: '';
      left: -11px;
      position: absolute;
    }

    &:hover {
      cursor: col-resize;
    }
  }
}
