@import '../common';

.sortable__drag-handler {
  background-color: $color-border;
  background-image: linear-gradient(
    45deg,
    $color-border,
    $color-border 2px,
    $color-white 2px,
    $color-white 4px,
    $color-border 4px,
    $color-border 9px,
    $color-white 9px,
    $color-white 11px,
    $color-border 11px,
    $color-border 16px,
    $color-white 16px,
    $color-white 18px,
    $color-border 18px,
    $color-border 22px
  );
  background-size: 10px 20px;
  border-top: 2px solid $color-border;
  cursor: move;
  min-height: 3rem;
  position: relative;
  width: 1.4em;

  &:active {
    background-image: linear-gradient(
      45deg,
      $color-primary-blue,
      $color-primary-blue 2px,
      $color-white 2px,
      $color-white 4px,
      $color-primary-blue 4px,
      $color-primary-blue 9px,
      $color-white 9px,
      $color-white 11px,
      $color-primary-blue 11px,
      $color-primary-blue 16px,
      $color-white 16px,
      $color-white 18px,
      $color-primary-blue 18px,
      $color-primary-blue 22px
    );
    background-size: 10px 20px;
  }
}
