@import '../common';

.Manage {
  display: block;
  float: none;
  margin: 0 auto;
  max-width: 1120px;
  min-height: 20rem;
  padding: 0 15px;
  width: 100%;

  h2 {
    font-size: $font-size-xl;
    font-weight: 200;
    margin-bottom: 22px;
  }

  .empty {
    font-weight: $font-weight-light;
    margin: 20px;
    text-align: center;
  }
}

.chq-widget-preview {
  border-left: 1px solid $color-border;
}

.profile-link {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chq-tbl {
  th.actions {
    text-align: right;
    width: 200px;
  }

  a {
    float: none;
    margin-left: 0;
  }

  button {
    margin-left: 5px;
  }

  .max-width-300 {
    max-width: 300px;
  }

  tr {
    &.disabled {
      color: $color-primary-light-font;
    }
  }

  td {
    &:last-child {
      .link,
      .chq-abn {
        float: right;
        margin-left: 8px;
      }

      .actions-td {
        color: $color-primary-green;
      }
    }
  }

  .Actions-column {
    position: relative;
    text-align: right;

    .QuickOptions {
      margin-left: 0;
      position: relative;
    }

    .PromptShareDisabledTooltip {
      left: 22px;
      position: absolute;

      .chq-ttp {
        .chq-ttp--bbl {
          left: -170px !important;
          text-align: center;
          top: calc(100% + 16px) !important;
          width: 230px;

          .chq-ttp--tri {
            left: 47% !important;
          }
        }
      }

      .DisablePosition {
        position: static;
      }
    }
  }
}
