@import '../common';

.comment-form {
  margin-left: 45px;

  .chq-btn {
    position: absolute;
    right: 20px;
    top: 6px;
    z-index: 1;
  }

  .chq-edi {
    background-color: $color-white;
    padding-right: 65px;
  }

  .DraftEditor-root {
    padding-top: 5px;
  }

  div {
    &[data-contents] {
      min-height: 26px;
    }
  }
}

.comments {
  background-color: $color-background;
  border-top: 1px solid $color-border;
  margin-top: 15px;
  padding-top: 15px;

  &--show-more {
    margin-bottom: 10px;
  }

  &--avatar {
    float: left;
    margin-top: 5px;

    .chq-tmb {
      height: 30px;
      width: 30px;
    }
  }
}

.CommentListItem {
  margin-bottom: 20px;

  .comment-form {
    margin-left: 0;
    margin-top: 10px;
    padding-left: 30px;
    position: relative;
  }

  .chq-btn {
    right: 5px;
  }

  .comment-body {
    margin-bottom: 5px;
    margin-left: 35px;
    margin-right: 40px;

    a {
      text-decoration: none;
    }
  }

  .chq-edi--img--wrap {
    clear: left;
    display: block;
  }

  .chq-edi--img {
    border-radius: 6px;
    left: initial;
    margin-bottom: 10px;
    margin-top: 10px;
    max-height: 250px;
    padding: 0;
    position: relative;
    top: initial;
    transform: none;
  }

  .comment-author {
    display: inline;
    float: left;
    font-weight: 600;
    margin-right: 8px;
    position: relative;

    .chq-tmb {
      left: -35px;
      position: absolute;
      top: -2px;
    }
  }

  .comment-options {
    align-items: center;
    display: flex;
    margin-bottom: 10px;
    margin-left: 35px;

    a {
      &.reply-link {
        cursor: pointer;

        @media only screen and (max-width: $screen-sm) {
          margin-right: 8px;
        }
      }
    }

    .dot {
      padding: 5px;

      @media only screen and (max-width: $screen-sm) {
        display: none;
      }

      svg {
        height: 4px;
        margin-bottom: 2px;
        width: 4px;

        path {
          fill: $color-secondary-light-font;
        }
      }
    }

    .Linkedin-Reactions {
      &__Btn {
        align-items: center;
        color: $color-secondary-font;
        display: flex;
        font-weight: $font-weight-normal;
        height: 16px;
        position: relative;

        &-Text {
          margin-right: 10px;
        }

        .linkedin-icon {
          @include square(16px);

          path {
            fill: $color-primary-font;
          }
        }

        .Linkedin-Reaction {
          @include square(16px);
          margin-left: -.4rem;
        }

        .Reactions__Count {
          margin-left: 3px;
        }
      }

      &__Container {
        left: -40px;
        top: -36px;

        .Linkedin-Reaction {
          @include square(26px);
        }
      }
    }

    .reply-link {
      color: $color-secondary-font;
      font-weight: $font-weight-normal;
    }

    .Linkedin-Reaction__Label {
      margin-right: 3px;
    }
  }

  &__column {
    position: relative;

    > .QuickOptions {
      opacity: 0;
      top: 0;
      transition: opacity 150ms ease-in-out;
      @media only screen and (max-width: $screen-sm) {
        opacity: 1;
      }
    }

    &:hover {
      .QuickOptions {
        opacity: 1;
      }
    }
  }

  .date {
    color: $color-primary-light-font;
  }

  .reply,
  .delete {
    cursor: pointer;
    opacity: .5;
    position: absolute;
    right: 15px;
    top: 0;

    &:hover {
      opacity: 1;
    }

    svg {
      height: 11px;
      width: 11px;
    }
  }

  .reply {
    left: 15px;
    top: -1px;
    transform: rotate(180deg);
    width: 18px;

    svg {
      height: 19px;
      width: 19px;

      path {
        fill: $color-secondary-font;
      }
    }
  }

  .comment-child {
    margin-top: 15px;
    padding-left: 40px;
  }

  .Linkedin-Comments {
    .chq-ffd--ctrl {
      margin-left: 35px;
      width: calc(100% - 35px);
    }

    .chq-btn {
      top: 0 !important;
    }
  }
}
