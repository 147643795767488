@import '../common';

.LandingPage {
  padding: 0 30px;

  &--Empty {
    margin: 0 auto;
    max-width: 700px;
    text-align: center;
  }

  &--Image {
    border-radius: $size-border-radius;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    margin-bottom: 30px;
    margin-top: 10px;
    max-width: 100%;
  }

  &--Title {
    color: $color-primary-font;
    font-size: 30px;
    margin-bottom: 0;
    margin-top: 0;

    &__Container {
      align-items: center;
      display: flex;
      margin-bottom: 10px;
    }
  }

  &--Link {
    align-items: center;
    display: flex;
    font-weight: $font-weight-heavy;
    justify-content: center;
    margin-top: 15px;

    svg {
      height: 18px;
      margin-right: 5px;
      width: 18px;
    }

    path {
      fill: $color-link-blue;
    }

    &__Filled,
    &__Empty {
      align-items: center;
      color: $color-link-blue;
      display: flex;
      font-size: $font-size-large;
      margin: 10px 0;

      path {
        fill: $color-link-blue;
      }

      svg {
        margin-left: 5px;
      }
    }

    &__Empty {
      color: $color-primary-font;

      path {
        fill: $color-primary-font;
      }
    }
  }

  &--Tag {
    background-color: $color-red;
    border-radius: $size-border-radius;
    color: $color-white;
    font-size: $font-size-small;
    font-weight: $font-weight-heavy;
    margin-left: 15px;
    padding: 4px 15px;

    &.Live {
      background-color: lighten($color-primary-green, 25%);
      color: darken($color-primary-green, 25%);
    }
  }

  &--Edit {
    display: flex;
    justify-content: space-between;
    text-align: left;

    &_Buttons {
      align-items: flex-start;
      display: flex;

      .chq-btn {
        margin: 0 5px;
        width: auto;
      }

      .Gray-Button {
        background-color: $color-secondary-light-font;
        border-color: $color-secondary-light-font;
        color: $color-black-alpha-70;

        path {
          fill: $color-black-alpha-70;
        }
      }
    }

    .LandingPage--Title {
      font-size: $font-size-28;
    }
  }

  &--Create_Btn {
    display: block;
    margin: 20px auto;
  }

  &--Empty_Link {
    color: $color-primary-light-font;
    font-size: 16px;
  }

  svg {
    margin-right: 5px;
  }

  .Accordian {
    margin-top: 20px;
  }

  .Design {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    @media screen and (min-width: $screen-md) {
      flex-direction: row;
    }

    &-Preview {
      @media screen and (min-width: $screen-md) {
        border-left: 1px solid $color-border;
      }

      &__Link {
        align-items: center;
        display: flex;

        svg {
          height: 18px;
          margin-right: 15px;
          width: 18px;
        }

        path {
          fill: $color-link-blue;
        }
      }

      .Preview {
        border: 1px solid $color-border;
        border-radius: 6px;
        box-shadow: 0 0 3px $color-black-alpha-70;
        margin-top: 30px;
        padding-bottom: 30px;

        &-Body {
          padding-right: 15px;
          position: relative;
        }

        &-Browser {
          border-radius: 6px 6px 0 0;
          width: 100%;

          &__Body {
            border-radius: 0 0 6px 6px;
            margin-left: 8px;
            margin-top: -64px;
            width: 100%;
          }
        }

        &-Header {
          &__Image {
            background-position: center;
            background-size: cover;
            height: 83px;
            width: 100%;

            &--Header {
              filter: brightness(.5);
              height: 83px;
              position: absolute;
              width: 94%;
            }
          }

          &__Overlay {
            align-items: center;
            background: linear-gradient(90deg, rgba(0, 0, 0, .1) 0%, $color-black-alpha-40 35%, $color-black-alpha-40 65%, $color-black-alpha-10 100%);
            display: flex;
            height: 100%;
            justify-content: center;
            width: 100%;
          }

          &__Text {
            background-position: 0 100%;
            background-repeat: no-repeat;
            background-size: 100% .2em;
            color: $color-white;
            font-size: $font-size-normal;
            transition: background-size .25s ease-in;
            width: fit-content;
            z-index: $z-index-low;
          }
        }

        &-Logo {
          background-color: $color-white;
          border-radius: 5px;
          box-shadow: 0 0 15px rgba(0, 0, 0, .45);
          height: 70px;
          left: 30px;
          position: absolute;
          top: -55px;
          width: 70px;
        }

        &-Welcome {
          border: 2px solid;
          border-radius: 3px;
          font-size: 9px;
          margin-left: 26%;
          margin-top: 20px;
          padding: 5px 5px 5px 20px;
          position: relative;
          width: 58%;

          .chq-edi-op {
            display: flex;
            flex-direction: column;
            justify-content: start;
            min-height: 30px;
            overflow: hidden;
          }

          &__Icon {
            background-color: $color-white;
            left: -12px;
            padding: 2px;
            position: absolute;
            top: -8px;
          }

          &__Clue {
            margin-left: -20px;
            margin-top: -15px;
            width: 100%;
          }
        }
      }
    }

    .chq-ffd--im {
      border: 1px solid $color-border;
      height: 100%;
      min-height: initial;

      &--bt-bg {
        background-color: transparent;
        color: $color-secondary-font;
      }

      .chq-ipv {
        height: 100% !important;
        left: 0 !important;
        top: 0 !important;
        width: 100% !important;
      }

      path {
        fill: $color-secondary-font;
      }
    }

    .selected-image {
      .chq-ffd--lb {
        margin-bottom: 0;
      }

      .chq-ffd--im--bt-bg {
        background-color: $color-black-alpha-50;
        border-radius: $size-border-radius;
        color: $color-white;
        display: flex;
        height: 100%;
        justify-content: center;
        padding: 15px;
        width: 100%;
      }

      path {
        fill: $color-white;
      }
    }

    .logo-field {
      .chq-ffd--bg-img-container {
        height: 130px;
        overflow: initial;
        width: 130px;

        .chq-ffd--bg-img--img {
          height: initial;
        }
      }
    }

    .header-field {
      margin-top: 30px;

      .chq-ffd--bg-img-container {
        height: 100px;
        overflow: initial;
        width: 100%;

        .chq-ffd--bg-img--img {
          height: initial;
        }
      }
    }

    .header-text-field {
      margin-top: 30px;
    }

    .color-field {
      div {
        box-shadow: none !important;
      }

      label {
        display: none;
      }

      #rc-editable-input-2 { // sass-lint:disable-line no-ids
        margin-top: 0 !important;
      }

      .material-picker  {
        font-family: inherit !important;
        height: auto !important;
        padding: 0 !important;
      }

      .flexbox-fix {
        display: none !important;
      }
    }

    h4 {
      font-size: $font-size-normal;
    }

    .sketch-picker {
      box-shadow: none !important;
      padding: 0 !important;
    }
  }

  &--Preview.Design-Preview {
    background-color: $color-white;
    border: 1px solid $color-border-darker;
    border-radius: $size-border-radius;
    margin-top: 15px;
    padding: 30px;

    .LandingPage--Preview__Title {
      align-items: flex-end;
      display: flex;

      h1,
      h4 {
        margin: 0;
      }

      h1 {
        margin-right: 5px;
      }

      h4 {
        margin-left: 5px;
      }
    }

    .Preview {
      border: 0;
      box-shadow: none;
      max-height: 570px;
      overflow: hidden;
      position: relative;

      &-Body {
        padding: 0 75px;
      }

      &-Header {
        &__Image {
          border-radius: $size-border-radius $size-border-radius 0 0;
          height: 170px;
        }

        &__Text {
          font-size: 36px;
        }
      }

      &-Browser {
        &__Welcome {
          left: initial;
          margin: 45px auto;
          position: relative;
          right: initial;
        }
      }

      &-Welcome {
        font-size: $font-size-large;
        margin-left: 0;
        margin-top: 0;
        max-height: initial;
        padding: 15px 15px 15px 40px;
        width: initial;

        &__Icon {
          height: 50px;
          left: -28px;
          padding: 5px;
          top: -16px;
          width: 50px;
        }

        .chq-edi-op {
          max-height: initial;
        }
      }

      &-Logo {
        height: 140px;
        margin-top: -70px;
        position: initial;
        width: 140px;

        &__Icon {
          height: 40px;
          width: 40px;
        }
      }

      &-Footer {
        margin: 30px;
      }

      &__Overlay {
        background-image: linear-gradient(0deg, $color-white 0%, rgba(255, 255, 255, .22) 100%);
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: $z-index-low;
      }
    }

    h1 {
      color: $color-black;
      font-size: $font-size-26;
      font-weight: $font-weight-heavy;
    }

    h2 {
      color: $color-black;
      font-size: $font-size-22;
      font-weight: $font-weight-heavy;
    }
  }

  .EmptyState {
    &--container {
      align-items: center;
      display: flex;
      font-weight: $font-weight-heavy;
    }
  }

  .Approve {
    &-Button {
      border: 1px solid $color-border-darker;
      border-radius: $size-border-radius;
      color: $color-primary-font;
      margin: 2px;
      padding: 15px 45% 15px 15px;
      width: 98%;

      &--icon {
        height: 20px;
        margin: 0 !important;
        width: 20px;

        &__container {
          @include square(40px);
          align-items: center;
          background-color: $color-border;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          margin-right: 15px;
          padding: 10px;
        }

        path {
          fill: $color-primary-font;
        }
      }

      &:hover {
        border-color: $color-primary-blue;
        box-shadow: inset 0 1px 1px rgba($color-black, .075), 0 0 8px $search-bar-shadow;
      }
    }

    &-Header {
      margin-bottom: 15px;
      margin-top: 30px;
    }
  }

  .QuickOptions {
    .QuickOptions__menu {
      ul.dropdown-menu {
        li {
          button {
            align-items: center;
            display: flex;
            fill: $color-primary-font;
            width: 180px;

            svg {
              margin-right: 10px;
              margin-top: 0;
            }

            g,
            path {
              fill: $color-link-blue;
            }
          }
        }
      }
    }
  }
}
