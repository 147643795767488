@import '../common';

.ShareQuickOptions {
  .QuickOptions__menu {
    .dropdown-menu {
      .chq-btn {
        align-items: center;
        display: flex;
        font-weight: $font-weight-normal;

        svg {
          margin: 0 10px 0 -27px;
        }
      }
    }
  }
}
