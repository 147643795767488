@import '../common';

.story-question-modal {
  border-radius: $size-border-radius;
  min-width: 600px;

  .chq-pan--hd {
    border-radius: $size-border-radius $size-border-radius 0 0;
  }

  .Subprompt-Container {
    align-items: flex-start;
    display: flex;
    margin-top: 15px;

    .SubPrompt {
      flex: 8;
      margin: 0 15px 0 0;
    }

    .AllowedTypes {
      flex: 4;
      margin: 0;
    }
  }

  .chart {
    padding: 0 15px;

    .chart {
      &-header {
        color: $color-primary-font;
        font-weight: $font-weight-heavy;

        .prompt-title-header {
          margin-left: calc(1.4em + 15px);
        }
      }

      &-row {
        align-items: stretch;
        display: flex;
        padding: 0;

        &:last-child {
          border-bottom: 1px solid $color-secondary-light-font;
        }

        .column {
          padding: 10px 15px;
        }

        .sortable-column {
          display: flex;
        }

        .sortable__drag-handler {
          min-height: initial;
        }

        .prompt-title {
          align-items: flex-start;
          display: flex;
          margin-left: 15px;
          padding: 10px 0;

          .PromptIcon {
            background: none;
            height: initial;
            padding: 0;
            width: initial;

            svg {
              @include square(22px);
            }
          }

          svg {
            margin-right: 10px;
          }

          path {
            fill: $color-primary-font;
          }
        }

        .actions {
          @media screen and (min-width: $screen-md) {
            margin-left: 4%;
          }
        }
      }
    }

    .chq-pbn {
      margin-left: 0;

      path {
        fill: $color-primary-font;
      }
    }
  }

  .chq-ffd {
    &--lb {
      color: $color-primary-font;
      font-weight: $font-weight-heavy;
    }

    &--ctrl {
      &::placeholder {
        color: $color-primary-light-font;
      }

      &--validation {
        bottom: 10px;
        top: initial;
      }
    }

    &--sl--place {
      color: $color-primary-light-font;
    }
  }

  .Story-Share-Modal__label {
    color: $color-primary-font;
    font-weight: $font-weight-heavy;
  }

  .Subprompt-Add_Btn,
  .instructional-video-btn {
    border: 0;
    font-weight: $font-weight-heavy;
    outline: none;
    padding: 15px 10px;

    svg {
      margin-right: 5px;
    }
  }

  .Subprompt-Add_Btn {
    margin-left: 30px;

    &.EmptySubPrompts {
      border: 1px dashed rgba($color-primary-green, .5);
      margin: 10px 0;
      width: 100%;

      &:hover {
        border-style: solid;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .08), 0 0 8px rgba($color-primary-green, .5);
      }
    }
  }

  .Summary-Container {
    align-items: flex-start;
    display: flex;

    .chq-ffd {
      flex: 8;
      margin-right: 15px;

      &--ctrl-text {
        border: 2px solid $color-border;
        border-radius: $size-border-radius;
        min-height: 15em;
      }
    }

    .Video-Container {
      flex: 4;
      margin-top: 29px;

      &__Wrapper {
        align-items: center;
        background-color: $color-background-darker;
        border: 1px solid $color-border-darker;
        border-radius: $size-border-radius;
        display: flex;
        height: 192px;
        justify-content: center;
        position: relative;

        .Empty-Video {
          align-items: center;
          color: $color-secondary-font;
          display: flex;
          flex-direction: column;
          font-weight: $font-weight-normal;
          justify-content: center;
          text-align: center;
          width: 60%;

          svg {
            margin-right: 5px;
          }

          path {
            fill: $color-secondary-font;
          }
        }

        .TrimVideo {
          &-Btn {
            align-items: center;
            background-color: $color-black-alpha-70;
            border: 0;
            border-radius: $size-border-radius;
            color: $color-white;
            display: flex;
            left: 5px;
            padding: 5px 10px;
            position: absolute;
            top: 5px;

            svg {
              @include square(18px);
              margin-right: 5px;

              path {
                fill: $color-white;
              }
            }
          }

          &-Container {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;
            position: absolute;
            width: 100%;

            &:hover {
              cursor: pointer;
            }

            .play-icon {
              path {
                fill: $color-white;
              }
            }
          }
        }

        .close-icon {
          background-color: $color-white;
          border-radius: 20px;
          box-shadow: 1px -1px 5px rgba(0, 0, 0, .2);
          padding: 5px;
          position: absolute;
          right: -5px;
          top: -5px;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .Video-Buttons {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .media-btn {
          flex: none;
          margin-bottom: 0;
          margin-right: 0;
          width: calc(50% - 5px);

          .chq-ffd--im {
            border: 0;
            margin-bottom: 0;
          }
        }

        .chq-btn {
          align-items: center;
          background-color: $color-dark-blue;
          border: 1px solid $color-dark-blue;
          border-radius: $size-border-radius;
          color: $color-white;
          display: flex;
          font-weight: $font-weight-light;
          justify-content: center;
          padding: 6px 10px;
          white-space: nowrap;

          svg {
            float: initial;
            margin-left: initial;
            margin-right: 5px;
            margin-top: initial;
          }

          path {
            fill: $color-white;
          }
        }
      }

      .instructional-video-btn {
        flex: none;
        padding: 0 10px;
        width: calc(50% - 5px);
      }
    }

    &__Video {
      .Video-Container {
        &__Wrapper {
          align-items: center;
          background-color: $color-black-alpha-70;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .share-with-field {
    .chq-ffd--ctrl {
      align-items: center;
      border: 0;
      border-bottom: 2px solid $color-border;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 5px;
      padding: 6px 12px;

      .chq-bdg {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        width: initial;

        svg {
          margin: 0 0 0 6px;
        }

        .category-icon {
          height: 18px;
          margin: 0 6px 0 0;
          width: 18px;
        }
      }
    }

    .chq-ffd--sl--opts-open {
      max-height: 200px;
      overflow-y: auto;

      .option-icon {
        height: 20px;
        width: 20px;

        g {
          fill: $color-white;
        }
      }
    }
  }

  .Footer-Buttons {
    display: flex;

    .Mapped-cmk {
      display: flex;
      height: 100%;
      margin-left: 15px;
      margin-top: 0;
    }
  }
}

.progressline-trend {
  margin: 10px 0;
}

.new-story-question-modal {
  width: 70vw;

  .chq-pan--bd {
    padding: 30px;
  }
}

.nest-story-question-modal {
  position: relative;

  .chq-ldr {
    background-color: $color-black-alpha-50;
    border-radius: $size-border-radius;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: $z-index-medium;
  }

  .ChildPrompts {
    margin-bottom: 15px;

    &__Container {
      &:hover {
        border-style: dashed;
        box-shadow: none;
        cursor: auto;
      }
    }
  }
}
