$color-merck-green: #00857c;
$color-merck-light-green: #00a297;
$color-merck-yellow: #fff063;

.merck-nav,
.msd-nav {
  &.chq-nav {
    background-color: $color-merck-green;

    .chq-nav {
      &--search {
        input {
          background-color: $color-merck-light-green;
        }
      }

      &--items {
        a {
          border-color: $color-merck-green;

          &.active {
            border-color: $color-merck-yellow;
            color: $color-merck-yellow;

            path {
              fill: $color-merck-yellow;
            }
          }
        }
      }

      &--admin {
        em {
          background-color: $color-merck-yellow;
        }
      }

      &--admint {
        &:hover {
          background-color: $color-merck-light-green;
        }
      }

      &--unseen {
        border-color: $color-merck-yellow;
      }
    }
  }
}
