@import '../common';

.LoginSplash {
  background-color: $color-primary-blue;
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  @media only screen and (max-width: $screen-md) {
    display: block;
  }

  .left,
  .right {
    align-items: center;
    align-self: stretch;
    display: flex;

    @media only screen and (max-width: $screen-md) {
      align-items: flex-start;
      float: left;
      padding: 45px 15px;
      width: 100%;
    }
  }

  .left {
    background-color: $color-white;
    flex-grow: 1.5;
    position: relative;

    @media only screen and (max-height: $screen-sm) and (min-width: $screen-md + 1px) {
      -ms-overflow-style: -ms-autohiding-scrollbar; // sass-lint:disable-line no-vendor-prefixes
      align-items: flex-start;
      overflow-y: scroll;

      > div {
        margin: 50px auto;
      }

      .Login__SignIn {
        margin-top: 180px;
      }
    }
  }

  .right {
    background-color: $color-primary-blue;
    background-image: url('../../images/splash/background.jpg');
    background-size: cover;
    flex-grow: 2;
  }

  &__values {
    margin: 0 auto;
    max-width: 640px;
    padding: 15px;

    h1 {
      color: $color-white;
      font-size: $font-size-26;
      font-weight: $font-weight-light;
      letter-spacing: 2px;
      margin-bottom: 0;
      margin-top: 0;
      text-align: center;
      text-transform: uppercase;
    }

    p {
      color: $color-white;
      font-weight: $font-weight-light;
      margin-bottom: 35px;
    }

    .icon {
      background-position: center;
      background-size: contain;
      float: right;
      height: 55px;
      margin-top: -3px;
      width: 55px;
    }

    .icon-hr {
      background-image: url('../../images/splash/icon-hr.png');
    }

    .icon-people {
      background-image: url('../../images/splash/icon-people.png');
    }

    .icon-spending {
      background-image: url('../../images/splash/icon-spending.png');
    }
  }
}
