@import '../common';

.ReactModal__Body--open {
  overflow-y: hidden;
}

.ReactModalPortal {
  .ReactModal__Overlay {
    background-color: rgba(0, 0, 0, .7); //because is part of the in-line style
    opacity: 0;
    overflow-x: hidden;
    overflow-y: auto;
    perspective: 600;
    z-index: $z-index-high;

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }
  }

  .ReactModal__Content {
    opacity: 0;

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }

    &:active,
    &:focus {
      outline: none;
    }
  }
}
