@import '../common';

$animation-timing: .15s;

.chq-edi {
  background-color: $color-background;
  border: $color-border-darker solid 1px;
  border-radius: $size-border-radius;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  margin-bottom: 20px;
  padding: 6px 12px;
  position: relative;
  transition: border-color ease-in-out $animation-timing, box-shadow ease-in-out $animation-timing;

  div {
    &[data-contents] {
      min-height: 100px;
    }
  }

  &.chq-edi-pb {
    min-height: 34px;
  }

  &-fc {
    border-color: $color-primary-blue;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba($color-primary-blue, .6);
    outline: 0;

    .chq-edi--cp-open-default {
      section {
        border-color: $color-primary-blue;
        box-shadow: 0 0 8px rgba($color-primary-blue, .6);
      }
    }
  }

  &--cp {
    display: flex;
    flex-wrap: wrap;
    left: 0;
    position: absolute;
    top: calc(100% + 12px);
    z-index: -1;

    section {
      background-color: $color-white;
      border: $color-primary-blue solid 1px;
      box-shadow: 0 0 8px rgba($color-primary-blue, .6);
      display: inline-block;
      margin-bottom: 10px;
      margin-right: 10px;
      opacity: 0;

      &.chq-edi--cp--cl {
        background: transparent;
        border: 0;
        box-shadow: none;

        button {
          background: transparent;
          width: 25px;
        }

        svg {
          @include square(20px);
        }

        path {
          fill: $color-primary-light-font;
        }
      }
    }

    &-open,
    &-closed {
      z-index: $z-index-medium;

      section {
        animation: zoomIn $animation-timing forwards;
        border-radius: $size-border-radius;
      }

      &:hover {
        z-index: $z-index-high;
      }
    }

    &-open-default {
      section {
        border-color: $color-border-darker;
        box-shadow: none;
      }
    }

    &-closed {
      section {
        animation-direction: reverse;
      }
    }

    .chq-ttp {
      font-family: $font-default;

      &--bbl {
        width: 200px;
      }
    }
  }

  &--ct {
    background: $color-white;
    border: 0;
    color: inherit;
    font-family: monospace;
    height: 35px;
    padding: 0;
    vertical-align: bottom;
    width: 35px;

    svg {
      vertical-align: middle;

      path {
        fill: $color-primary-font;
      }
    }

    &:disabled {
      color: $color-border;

      path {
        fill: $color-border;
      }
    }

    &-ac {
      background-color: $color-primary-blue;
      color: $color-white;
    }

    & + & {
      border-left: $color-border solid 1px;
    }

    &-at {
      border-radius: $size-border-radius 0 0 $size-border-radius;
      border-right: $color-border solid 1px;
      font-family: $font-default;
    }

    &-left {
      border-radius: $size-border-radius 0 0 $size-border-radius;
      border-right: $color-border solid 1px;
    }

    &-right {
      border-bottom-right-radius: $size-border-radius;
      border-top-right-radius: $size-border-radius;
    }

    &-bd,
    &-it,
    &-ul {
      border-right: $color-border solid 1px;
      font-size: 1.1em;
    }

    &-bd {
      font-weight: bold;
    }

    &-it {
      font-style: italic;
    }

    &-ul {
      text-decoration: underline;
    }
  }

  &--lm {
    .chq-pan--bd {
      text-align: left;
    }
  }

  &--sg {
    background: $color-white;
    border-radius: $size-border-radius;
    border-top: 1px solid $color-border;
    box-shadow: 0 0 12px rgba(0, 0, 0, .2);
    cursor: pointer;
    padding: 8px 0;
    position: absolute;
    top: 100%;
    transform: scaleY(0);
    transform-origin: 50% 0%;
    z-index: 2147483647;

    .chq-tmb {
      margin-right: 8px;
      vertical-align: top;
    }

    .chq-icon {
      svg {
        background-color: $color-primary-green;
        border-radius: 25px;
        height: 25px;
        padding: 3px;
        width: 25px;
      }

      path {
        fill: $color-white;
      }
    }

    .chq-edi--sg--et {
      display: block;
      padding: 5px 8px;
      position: relative;
      text-align: left;
      width: 100%;

      .chq-tmb,
      .chq-icon {
        left: 8px;
        position: absolute;
      }

      span {
        display: inline-block;
        padding-left: 36px;
      }

      small {
        color: $color-primary-light-font;
      }

      &:hover,
      &[aria-current='true'] {
        background-color: $color-background;
      }
    }
  }

  &--lk {
    hyphens: auto;
    overflow-wrap: break-word;
    text-decoration: underline;
    word-break: break-word;
    word-wrap: break-word;
  }

  &--mn {
    animation: chqMentionCover 250ms forwards ease-out, chqMentionShrink 250ms forwards 225ms linear;
    background-image: linear-gradient(to right, #6a89af, #6a89af);
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    color: $color-primary-font;
    font-size: .9em;
    padding: 2px 2px 2px calc(2px + 1em);
    position: relative;
    text-decoration: none;

    &:hover {
      color: $color-white;
      text-decoration: none;
    }

    &::before {
      animation: chqMentionBounce 500ms forwards 225ms linear;
      content: '@';
      display: inline-block;
      left: -.7em;
      position: absolute;
      text-align: right;
      top: 0;
      width: 1.7em;
    }
  }

  &--img {
    border-radius: $size-border-radius;
    margin: 10px 0;
    max-height: 500px;
    max-width: 100%;

    &-pv {
      box-sizing: content-box;
      min-height: 200px;

      .chq-ipv {
        border-radius: $size-border-radius;
      }
    }

    &--wrap {
      max-width: 100%;
      outline: none;
    }
  }

  .react-tenor {
    background-color: $color-white;
    left: 0;
    max-width: inherit;
    position: absolute;
    top: calc(100% + 12px + 46px);
    width: 100%;
    z-index: 40;

    input {
      font-size: 1em;
    }
  }

  &--pg {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    bottom: 0;
    display: flex;
    height: 5px;
    left: 0;
    overflow: hidden;
    position: absolute;
    width: 100%;

    & > div {
      background-color: $color-white;

      div {
        background-color: $color-primary-blue;
        height: 100%;
        transition: background-color 250ms, width 250ms;
        width: 0%;

        &[data-value='100'] {
          background-color: $color-primary-green;
        }
      }
    }
  }

  &-op {
    .chq-edi--lt {
      margin: 0;
      padding: 0;

      &-0 {
        margin: 16px 0;
      }

      @for $depth from 0 through 4 {
        &-#{$depth} {
          li {
            margin-left: 1.5em * ($depth + 1);
          }
        }
      }
    }
  }
}

.chq-mention-list {
  display: grid;
  grid-gap: 6px;
  grid-template-columns: calc(50% - 3px) calc(50% - 3px);

  &--hd {
    border-bottom: $color-border solid 1px;
    margin-bottom: 4px;
    padding-bottom: 8px;
  }

  a,
  .new-user {
    padding: 8px 8px 8px 42px;
    position: relative;

    .chq-tmb {
      left: 10px;
      position: absolute;
    }

    small {
      color: $color-primary-font;
    }
  }
}

@keyframes chqMentionCover {
  from {
    background-size: 0% 100%;
  }

  to {
    background-size: 100% 100%;
    border-radius: 3px;
    color: $color-white;
  }
}

@keyframes chqMentionShrink {
  from {
    padding-left: calc(2px + 1em);
  }

  to {
    padding-left: 2px;
  }
}

@keyframes chqMentionBounce {
  0% {
    opacity: 1;
    transform: rotate(0deg);
  }

  20% {
    color: $color-primary-font;
  }

  33% {
    opacity: 1;
    transform: rotate(-180deg);
  }

  66% {
    left: -3em;
    width: 1.2em;
  }

  100% {
    color: $color-primary-font;
    left: -5em;
    opacity: 0;
    transform: rotate(-540deg);
    width: 1.7em;
  }
}
