@import '../common';

.Dropdown {
  .dropdown-toggle {
    font-size: $font-size-normal;
    font-weight: $font-weight-light;
    margin-top: 4px;
    outline: none;
    padding: 2px 12px 2px 6px;

    svg {
      height: 13px;
      margin-bottom: -1px;
      margin-right: -2px;
      margin-top: 0;
      width: 13px;

      path {
        fill: $color-secondary-light-font;
      }
    }
  }

  .dropdown {
    button {
      color: $color-primary-font;
    }

    .caret {
      margin-left: 6px;
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }

  ul.dropdown-menu {
    max-height: 400px;
    overflow-y: auto;

    &.active {
      display: inline;
    }

    li {
      cursor: pointer;

      a,
      button {
        color: $color-primary-font;
        font-size: $font-size-normal;
        font-weight: $font-weight-light;
        padding: 4px 5px 4px 12px;
        text-align: left;
        width: 100%;

        &:hover {
          background-color: $color-border;
        }
      }

      svg {
        height: 16px;
        margin-bottom: -2px;
        margin-right: 6px;
        margin-top: 2px;
        width: 16px;

        path {
          fill: $color-black;
        }
      }

      &.active {
        svg {
          opacity: 1;
        }

        a {
          background: $color-white;
          color: $color-primary-font;

          &:hover {
            background: $color-border;
          }
        }
      }

      &.inactive {
        svg {
          opacity: 0;
        }
      }
    }
  }
}
