@import '../common';

.sim {
  background-color: $color-red;
  color: $color-white;
  padding: 15px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z-index-medium + 1;

  & + .chq-nav {
    margin-top: 53px;
  }

  & + .chq-nav + .content {
    padding-top: 143px;
  }

  &--action {
    width: 100%;
  }

  &--open {
    display: inline-block;
  }

  .sim--end {
    border-bottom: 1px solid $color-white;
    color: $color-white;
    cursor: pointer;
    display: inline-block;
    margin-left: 8px;
    text-decoration: none;

    &:hover {
      color: $color-white;
      text-decoration: none;
    }
  }
}
