@import '../common';

.EmptyResults {
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: 280px;
  margin-top: 30px;
  padding-top: 210px;
  text-align: center;

  &-events {
    background-image: url('../../images/no-events.png');
  }

  &-recognitions {
    background-image: url('../../images/no-recognitions.png');
  }

  &-people,
  &-connections {
    background-image: url('../../images/no-people.png');
    padding-top: 280px;
  }

  &-connections {
    margin-top: 100px;
  }

  &-feed {
    background-image: url('../../images/no-feed-items.png');
    padding-top: 230px;
  }

  @media only screen and (max-width: $screen-sm) {
    background-size: 220px;
    margin-top: 40px;
    padding-top: 180px;
  }

  p {
    color: $color-primary-blue;
    font-size: 30px;
    font-weight: $font-weight-normal;
    margin: 1rem;
  }

  h3 {
    color: $color-primary-light-font;
    font-size: $font-size-normal;
    font-weight: $font-weight-light;
    margin: 1rem 1rem 3rem;
  }

  a {
    cursor: pointer;
    display: inline-block;

    &:hover {
      text-decoration: none;
    }
  }

  .button,
  .chq-btn {
    background-color: inherit;
    border: $color-primary-green solid 1px;
    border-radius: 20px;
    color: $color-primary-green;
    margin-bottom: 12px;
    padding: 8px 22px;

    &:hover {
      border-width: 2px;
      padding: 7px 21px;
    }
  }

  .chq-pbn {
    background: none;
  }
}
