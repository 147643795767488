@import '../common';

.Unsplash--Search {
  &__Title {
    position: relative;
    text-align: center;

    .underlined {
      text-decoration: underline;
    }
  }

  .Unsplash--Search__Title {
    .chq-btn {
      background-color: initial;
      border: 0;
      border-radius: 0 0 6px 6px;
      bottom: initial;
      color: $color-white;
      left: 0;
      padding: 0;
      position: absolute;
      width: initial;

      path {
        fill: $color-dark-blue;
      }
    }
  }

  .chq-sbar {
    margin-top: 15px;

    input {
      background-color: $color-white;
    }

    path {
      fill: $color-black;
    }
  }

  // max-height value is used on the FindImagesOnUnsplash component,
  // if you change the value, you need to update at handleOnScroll
  .images-results {
    margin-top: 15px;
    max-height: 370px;
    overflow-y: scroll;

    .image-result {
      margin: 1px;
      position: relative;

      &:hover {
        cursor: pointer;

        .image-author {
          display: block;
        }
      }

      .image {
        border-radius: 6px;
        height: auto;
        max-width: 100%;
      }

      .image-author {
        background-color: rgba(0, 0, 0, .7);
        bottom: 0;
        color: $color-white;
        display: none;
        overflow: hidden;
        padding: 2px 5px;
        position: absolute;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }
  }

  &-inline {
    background-color: $color-white;
    border: 1px solid $color-border-darker;
    border-radius: $size-border-radius;
    margin-top: 10px;
    padding: 15px;
    position: absolute;
    width: 100%;
    z-index: 40;

    .Unsplash--Search__Title {
      .chq-btn {
        left: 15px;
      }
    }
  }
}
