.PromptIcon {
  @include square(45px);
  align-items: center;
  border-radius: 80px;
  display: flex;
  justify-content: center;
  padding: 8px;

  svg {
    @include square(22px);
  }

  path {
    fill: $color-white;
  }

  &.just_images {
    background: linear-gradient(90deg, rgba(170, 140, 48, 1) 0%, $color-primary-yellow 100%);
  }

  &.just_videos {
    background: linear-gradient(90deg, rgba(78, 109, 135, 1) 0%, $color-primary-blue 100%);
  }

  &.all {
    background: linear-gradient(90deg, $color-primary-blue 0%, $color-primary-yellow 100%);
  }
}

.PromptIconButton {
  align-items: center;
  border-radius: 80px;
  color: $color-white;
  display: flex;
  justify-content: center;
  padding: 10px;

  @media only screen and (min-width: 425px) {
    padding: 10px 15px;
  }

  svg {
    @include square(22px);
  }

  &-Text {
    display: none;
    margin-left: 5px;

    @media only screen and (min-width: 425px) {
      display: block;
    }
  }

  path {
    fill: $color-white;
  }

  &.just_images {
    background: linear-gradient(90deg, rgba(170, 140, 48, 1) 0%, $color-primary-yellow 100%);
  }

  &.just_videos {
    background: linear-gradient(90deg, rgba(78, 109, 135, 1) 0%, $color-primary-blue 100%);
  }

  &.all {
    background: linear-gradient(90deg, $color-primary-blue 0%, $color-primary-yellow 100%);
  }
}
