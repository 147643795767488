@import '../common';

.EventView {
  padding-bottom: 30px;

  .left-col {
    padding-right: 0;
    right: 45%;
    width: 55%;

    @media only screen and (max-width: $screen-sm) {
      padding-right: 15px;
      right: 0%;
      width: 100%;
    }

    &__wrapper {
      padding: 0 15px;
      word-wrap: break-word;
    }
  }

  .QuickOptions__open-dropdown svg path {
    fill: $color-white;
  }

  .CommentListItem {
    .QuickOptions__open-dropdown svg path {
      fill: $color-secondary-font;
    }
  }

  .right-col {
    left: 55%;
    width: 45%;

    @media only screen and (max-width: $screen-sm) {
      left: 0%;
      width: 100%;
    }
  }

  .Photos {
    padding-bottom: 15px;
    padding-top: 15px;

    .button {
      display: block;
      margin: 8px auto 0;
      text-align: center;
      width: 163px;

      svg {
        height: 15px;
        margin-bottom: -2px;
        width: 15px;

        path {
          fill: $color-white;
        }
      }
    }
  }

  .section-actions {
    display: none;
  }

  &__AttendeesPanel {
    .attendee {
      height: 30px;
      margin-bottom: 5px;
      overflow: hidden;

      .Thumbnail {
        position: absolute;
      }

      .attendee-name {
        margin-left: 30px;
        overflow: hidden;
        padding-top: 2px;
        white-space: nowrap;
        width: 80%;
      }
    }

    .attendees-action-buttons {
      margin-top: 15px;

      a {
        text-align: center;
      }
    }
  }
}
