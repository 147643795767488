@import '../common';

.Start-The-Trend {
  margin-top: 25px;

  path {
    fill: $color-primary-font;
  }

  .Story {
    &--Link {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      text-decoration: none;

      .chq-btn {
        box-shadow: none;
        font-size: initial;
        margin-left: 15px;
        padding: 5px 10px;

        &:hover {
          border: 2px solid;
          padding: 4px 9px;
        }
      }
    }

    &--Share {
      align-items: center;
      display: flex;
      text-decoration: none;
    }

    &--Image {
      background-position: center;
      background-size: cover;
      border-radius: $size-border-radius;
      height: 50px;
      margin-right: 15px;
      min-width: 50px;
      width: 50px;
    }

    &--Texts {
      color: $color-primary-font;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &--Question {
      -webkit-box-orient: vertical; // sass-lint:disable-line no-vendor-prefixes
      -webkit-line-clamp: 2; // sass-lint:disable-line no-vendor-prefixes
      display: -webkit-box; // sass-lint:disable-line no-vendor-prefixes
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .chq-pan--hd {
    h2 {
      align-items: center;
      display: flex;
    }

    svg {
      margin-right: 10px;
    }
  }
}
