@import '../common';

.SurveyItemSingle {
  display: block;
  margin-bottom: 15px;

  h2 {
    color: $color-secondary-font;
    font-size: $font-size-normal;
  }

  .prompt {
    line-height: 1.4;
    margin-bottom: 15px;
  }

  .SurveyItemFreeText {
    input.input {
      background-color: $color-background;
      border-bottom: 0 !important;
      border-radius: 5px;
      padding: 5px 15px;
    }
  }

  input.button {
    margin-bottom: 15px;
  }

  .SurveyItemRange {
    margin-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;

    &.untouched {

      .input-range__slider {
        background-color: $color-border;
        border: 2px solid $color-border;
      }
    }

    .input-range__track {
      height: 10px;
    }

    .input-range__track--active {
      background: $color-primary-green;
      border-radius: 5px;
      height: 10px;
    }

    .input-range__label--value {
      color: $color-black;
      font-size: $font-size-normal;
      top: 14px;
      z-index: $z-index-medium;
    }

    .input-range__label--min,
    .input-range__label--max {
      bottom: -40px;
      color: $color-secondary-font;
      font-size: $font-size-normal;
    }

    .input-range__slider {
      background: $color-primary-green;
      border: 2px solid $color-primary-green;
      height: 25px;
      margin-left: -12px;
      margin-top: -18px;
      width: 25px;

      &::before {
        color: $color-white;
        content: 'L';
        display: block;
        font-size: $font-size-normal;
        font-weight: 800;
        height: 20px;
        margin-left: -2px;
        margin-top: -4px;
        transform: scaleX(-1) rotate(-35deg);
        width: 20px;
      }

      &:focus,
      &:active {
        box-shadow: 0 0 0 5px rgba(63, 81, 181, .2);
        transform: scale(1);
      }
    }
  }

  .SurveyItemRating {
    padding-left: 15px;
    padding-right: 15px;

    .options {
      display: block;

      .star {
        cursor: pointer;
        display: inline-block;
        float: left;
        transition-duration: .3s;
        transition-property: transform;

        &:hover {
          transform: scale(1.2);
        }

        &.active,
        &:hover {
          svg path {
            fill: $color-primary-yellow;
          }
        }

        svg {
          height: 40px;
          width: 40px;

          path {
            fill: $color-secondary-light-font;
          }
        }
      }
    }
  }

  .SurveyItemMultiChoice {
    margin-bottom: 15px;

    .options {
      list-style: none;
      padding-left: 0;

      .option {
        color: $color-primary-light-font;
        cursor: pointer;
        font-size: $font-size-large;
        margin-top: 15px;
        position: relative;

        span {
          display: block;
          left: 35px;
          padding-left: 34px;
        }

        svg {
          height: 25px;
          margin-bottom: -5px;
          margin-right: 8px;
          position: absolute;
          width: 25px;

          path {
            fill: $color-primary-light-font;
          }
        }
      }
    }
  }
}

.SurveyItems {
  .header {
    padding-bottom: 15px;
    padding-top: 15px;
  }

  &__actions {
    margin-bottom: 15px;

    .chq-btn {
      margin-right: 5px;

      @media only screen and (max-width: $screen-sm) {
        margin-bottom: 10px;
      }
    }
  }

  .chart-row {
    a.action {
      border-bottom: 0;
      float: right;
      text-decoration: none;
    }
  }

  .chart {
    table {
      margin-bottom: 0;
    }

    .row {
      .link {
        margin-left: 5px;
      }
    }
  }

  &__row {
    .EditSurveyItem {
      display: block;
      float: right;
    }

    .light {
      color: $color-primary-light-font;
    }
  }
}

.SurveyItem {
  margin-bottom: 15px;

  svg {
    margin-top: -1px;
    position: absolute;
  }
}
