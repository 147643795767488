@import '../common';

.ApproveProfileModal {
  border-radius: $size-border-radius;
  width: 75vw;

  &__Body--Top {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .chq-pbn {
      align-items: center;
      display: flex;
      font-weight: $font-weight-normal;

      svg {
        margin-right: 5px;
      }

      path {
        fill: $color-link-blue;
      }
    }
  }

  .chq-pan--bd {
    padding: 30px;
  }

  .Separator {
    border-color: $color-border;
    margin-left: -30px;
    margin-right: -30px;
  }

  &-Sort {
    margin-bottom: 0;

    .chq-ffd--sl--opts {
      z-index: $z-index-low;
    }

    .chq-ffd--ctrl {
      border: 1px solid $color-border;
      border-radius: $size-border-radius;
      margin-bottom: 0;
      padding: 10px 12px;

      &:focus {
        border-bottom: 1px solid $color-border;
        border-color: $color-primary-blue;
        box-shadow: inset 0 1px 1px rgba($color-black, .075), 0 0 8px rgba(140, 180, 214, .6);
      }
    }

    .chq-ffd--lb {
      margin-bottom: 0;
    }
  }

  &__SelectedUsers {
    align-items: center;
    display: flex;
    margin-left: 10px;

    &__more {
      align-items: center;
      background-color: $color-border;
      color: $color-primary-font;
      display: flex;
      font-weight: $font-weight-heavy;
      justify-content: center;
    }

    &__description {
      margin-left: 15px;
    }

    .Thumbnail {
      border-radius: 40px;
      height: 40px !important;
      margin-left: -10px;
      min-width: 40px;
      width: 40px !important;
    }
  }

  .chq-pan--hd {
    border-radius: $size-border-radius $size-border-radius 0 0;

    svg {
      height: 45px !important;
      margin-top: -7px;
      width: 45px !important;
    }
  }

  .People__cards {
    padding: initial;

    .SearchBar {
      padding-right: 0;
    }

    .chq-sbar {
      margin: 0;

      input {
        background-color: $color-border;
        border-color: $color-border-darker;
        box-shadow: none;
        color: $color-primary-font;
        font-weight: $font-weight-normal;
        margin-bottom: 0;
        padding-bottom: 10px;
        padding-top: 10px;
      }

      path {
        fill: $color-primary-font;
      }
    }

    .clearfix {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      padding: 0 7.5px;
    }

    .PersonCard {
      display: flex;

      .people-list-item {
        border-color: $color-border-darker;
        border-radius: $size-border-radius;
        box-shadow: 0 0 6px rgba(0, 0, 0, .2);
        height: auto;
        padding-bottom: 65px;
      }

      .header {
        height: 150px;
        padding: 0;

        .profile-photo {
          border-radius: 0;
          height: 150px;
          margin: 0;
          width: 100%;
        }
      }

      .name {
        margin-top: 15px;
      }

      .title {
        font-weight: $font-weight-normal;
      }

      .footer {
        bottom: 15px;
        position: absolute;

        .chq-btn,
        .chq-abn {
          align-items: center;
          border-radius: $size-border-radius;
          display: flex;
          font-weight: $font-weight-normal;
          justify-content: center;
          margin-top: 10px;
          padding: 8px 10px;
          width: 100%;

          svg {
            margin: 0 5px 0 0;
          }
        }

        .chq-btn {
          &:hover {
            padding: 7px 9px;
          }
        }

        .chq-abn {
          border: 1px solid $color-border-darker;
          color: $color-primary-font;

          path {
            fill: $color-primary-font;
          }

          &.disabled-button {
            opacity: .5;

            &:hover {
              background-color: $color-white;
            }
          }
        }
      }

      &__Selected {
        .header,
        .body {
          filter: opacity(.5);
        }

        .people-list-item {
          border: 0;
        }
      }
    }
  }

  .EmptyResults-people {
    background-position: 50% 30px;
    margin: 0 auto;
  }

  .EmptyState {
    &--container {
      align-items: center;
      display: flex;
      font-weight: $font-weight-heavy;
      padding: 0 45px 0 0;

      .Approve {
        &-Button {
          &--icon {
            height: 20px;
            margin: 0 !important;
            width: 20px;

            &__container {
              @include square(40px);
              align-items: center;
              background-color: $color-border;
              border-radius: 20px;
              display: flex;
              justify-content: center;
              margin-right: 15px;
              padding: 10px;
            }

            path {
              fill: $color-primary-font;
            }
          }
        }
      }
    }
  }

  .sortable__dragger {
    background-color: $color-dark-blue;
    background-image: none;
    border-radius: $size-border-radius;
    border-top: 0;
    height: 32px;
    margin-top: 5px;
    min-height: initial;
    padding: 5px;
    position: absolute;
    right: 5px;
    width: 32px;

    path {
      fill: $color-white;
    }
  }

  .ghost {
    opacity: .5;
  }
}

.ReactModalPortal {
  .ApproveProfileModal {
    &__SelectedUsers {
      margin-bottom: 15px;
    }
  }
}
