@import '../common';

.Trending-Stories {
  background-color: $color-white;
  border-radius: $size-border-radius;
  box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);
  margin-bottom: 15px;

  &__overlay {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 6px;
    height: 250px;
    padding: 15px 10px;
    position: relative;
    width: 100%;

    &--unselected {
      background-color: rgba(201, 201, 201, .8);
      border-radius: 6px;
      height: 210px;
      position: absolute;
      width: 220px;
    }
  }

  &__title {
    margin-top: 30px;
  }

  &__Share-btn {
    justify-content: center;
    width: 190px;
  }

  .chq-pbn {
    display: flex;
    flex-direction: column;

    &:hover {
      box-shadow: 0 0 6px $color-black-alpha-40;
    }
  }

  .Story-Tags {
    &__container {
      color: $color-white;
      display: block;
      margin-bottom: 15px;
      overflow: hidden;
      position: absolute;
      width: calc(100% - 10px);
    }

    &__item {
      align-items: center;
      display: inline-flex;
      font-size: 14px;
      margin-right: 10px;

      svg {
        height: 15px;
        margin-right: 5px;
        width: 15px;
      }

      path {
        fill: $color-white;
      }
    }

    &__legend {
      align-items: center;
      bottom: 15px;
      color: $color-white;
      display: flex;
      font-size: 14px;
      margin-top: 5px;
      position: absolute;

      &-emoticon {
        background-color: $color-white;
        border-radius: 20px;
        display: flex;
        margin-right: 5px;
        padding: 5px;

        svg {
          height: 16px;
          width: 16px;
        }
      }

      &-points-emoticon {
        margin-left: 15px;
      }
    }

    &__points {
      font-size: 12px;
      margin-left: 5px;
    }
  }

  .chq-btn {
    align-items: center;
    border: 0;
    border-radius: 20px;
    bottom: 15px;
    display: flex;
    font-size: 16px;
    padding: 7px 14px;
    position: absolute;

    &.no-profile {
      right: 25px;
    }
  }

  .Trending-Slider {
    justify-content: start;
  }

  .view-all-story-trends {
    display: flex;
    height: 32px;
    justify-content: center;
  }

  &__Empty {
    padding: 60px !important;

    .chq-btn {
      border: 1px solid $color-primary-green;
    }
  }
}

.selectedCard {
  .Trending-Stories {
    &__overlay {
      border: 3px solid $color-primary-green;
      box-shadow: 0 0 10px $color-primary-green;
    }
  }
}
