@import '../common';

.BadgeIcon {
  &.small {
    img {
      @include square(4rem);
    }
  }

  &.medium {
    img {
      @include square(60px);
    }
  }

  &.large {
    img {
      @include square(200px);
    }
  }

  img {
    display: block;
    margin: 0 auto;
  }

  .points {
    display: block;
    margin: 0 auto;
    margin-top: -12px;
    text-align: center;

    > span {
      background-color: rgba(0, 0, 0, .7);
      border-radius: 4px;
      color: $color-white;
      font-size: $font-size-small;
      font-weight: $font-weight-light;
      padding: .5px 7px;
    }
  }
}
