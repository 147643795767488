.library {
  margin-bottom: 20px;
  position: relative;

  h4 {
    font-size: $font-size-normal;
  }

  &-assets {
    &-videos {
      width: max-content;
    }
  }

  .Carousel {
    &-Container {
      height: 130px;
      padding: 0;
    }

    .Carousel-Btn {
      @include square(30px);
      top: calc(50% - 15px);

      svg {
        @include square(18px);
      }

      &__Left {
        left: -10px;
        padding: 6px 4px;
      }

      &__Right {
        padding: 6px 0 0 7px;
        right: -10px;
      }
    }
  }

  &__item-wrapper {
    @include square(130px);
    border-radius: 10px;
    margin-right: 5px;

    .chq-btn {
      display: none;
      left: 50%;
      position: absolute;
      top: 80%;
      transform: translate(-50%, -45%);
      width: 85%;
      z-index: 1;
    }

    &:hover {
      background-color: $color-border-darker;

      .chq-btn {
        display: block;
      }
    }

    .item-button {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }
}
