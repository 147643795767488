@import '../common';

.Login {
  &__Container {
    background-color: $color-white;
    border-radius: 12px;
    border-top: 15px solid $color-primary-blue;
    box-shadow: 0 0 70px rgba(0, 0, 0, .05);
    padding: 30px;
  }

  &__Links {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 45px;

    &--separator {
      color: $color-link-blue;
      font-size: 6px;
      margin: 0 7px;
    }

    &--Icon {
      height: 50px;

      &__google {
        margin-right: 15px;
      }
    }
  }

  .logo {
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
  }

  h2 {
    color: $color-primary-blue;
    font-size: 1.6em;
    font-weight: $font-weight-normal;
    margin-bottom: 45px;
    margin-top: 15px;
    text-align: center;
  }

  .chq-btn {
    margin-bottom: 15px;
    width: 100%;
  }

  .text-center {
    .link {
      display: inline-block;
      margin-bottom: 4px;
    }
  }

  &__SignIn {
    display: flex;
    flex-direction: column;
    height: calc(100%);
    justify-content: center;
    margin: 0 auto;
    max-width: 360px;

    @media only screen and (min-width: $screen-xs) {
      margin: 3% auto 0;
    }
  }

  &__Accept {
    margin: 0 auto;
    max-width: 420px;

    &__terms {
      margin-bottom: 2rem;

      button {
        display: flex;
        margin-right: 1rem;
      }

      a {
        display: inline;
      }
    }

    &__Disclaimer {
      font-size: $font-size-nav;
      margin: 20px 0;
    }
  }

  &__google,
  &__microsoft {
    @include border-radius;
    @include box-shadow;
    background-color: $color-white;
    border: $color-white solid 1px;
    color: $color-primary-font;
    display: inline-block;
    padding: 4px;
    text-align: center;
    width: 100%;

    &:hover {
      border: $color-border solid 1px;
      text-decoration: none;
    }

    img {
      @include square(30px);
      margin-right: 15px;
    }
  }

  &__microsoft {
    margin-top: 15px;
  }

  &__External--Container {
    margin-bottom: 15px;
  }

  &__Mobile {
    bottom: 0;
    display: block;
    left: 0;
    max-width: 100%;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;

    .logo {
      margin-bottom: 0;
      margin-top: 30px;
      max-width: 200px;
    }

    svg {
      height: auto;
      margin-bottom: 10px;
      max-width: 100%;
      padding: 0 20px;
    }

    .Onboarding-Image {
      height: auto;
      margin-top: 20px;
      max-width: 100%;
    }

    &--Container {
      align-items: center;
      background-color: $color-dark-blue;
      display: flex;
      flex-direction: column;
      max-height: 540px;
      overflow: hidden;
      padding: 0 25px 30px;

      .Container__Title {
        color: $color-white;
        font-weight: $font-weight-heavy;
        margin-bottom: 30px;
        margin-top: 30px;
        text-align: center;
      }
    }

    &--Footer {
      background-color: $color-white;
      padding: 30px;
      text-align: center;
    }

    &--Form {
      background-color: $color-white;
      padding: 30px;

      h2 {
        margin-top: 0;
      }
    }
  }
}

.Welcome {
  .Login {
    &__Mobile {
      &--Container {
        max-height: initial;
      }
    }
  }
}
