@import '../common';

.QuickOptions {
  position: absolute;
  right: 10px;
  top: 10px;

  &.active {
    .dropdown-menu {
      display: block;
    }

    .QuickOptions__open-dropdown svg {
      width: 18px;
    }
  }

  &.light {
    .QuickOptions__open-dropdown svg path {
      fill: $color-white;
    }
  }

  &__open-dropdown {
    z-index: $z-index-medium;

    &:hover {
      .QuickOptions__open-dropdown__wrapper {
        background-color: rgba(0, 0, 0, .05);
      }
    }

    svg {
      height: 25px;
      transform: rotate(90deg);
      width: 25px;

      path {
        fill: $color-secondary-font;
      }
    }

    &__wrapper {
      border-radius: 20px;
      height: 30px;
      padding-top: 5px;
      text-align: center;
      transition: background-color .5s;
      width: 30px;
    }
  }

  &__menu-wrapper {
    position: relative;
    z-index: $z-index-medium;
  }

  .QuickOptions__menu {
    background-color: $color-white;
    position: absolute;
    right: 5px;
    top: 5px;

    ul.dropdown-menu {
      border-radius: $size-border-radius;
      left: auto;
      padding: 0;
      right: 0;

      .chq-ttp--bbl {
        bottom: calc(100% + 8px);
        font-weight: 600;
        left: -165px !important;
        text-align: center;
        width: 150px;
      }

      .accordion-option {
        align-items: center;
        background-color: inherit;
        border: 0;
        color: $color-link-blue;
        cursor: pointer;
        display: block;
        float: left;
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        padding: 0;
        text-align: left;
        text-decoration: none;
        white-space: pre-wrap;
        width: 244px;
        word-wrap: break-word;

        .accordion {
          width: 100%;

          .accordion-header {
            align-items: center;
            display: flex;
            padding: .5em 1em;

            &:hover {
              background-color: $color-background;
              cursor: pointer;
              outline: none;
            }

            .accordion-btn {
              margin-left: 11px;
              padding-right: 10px;
            }

            svg {
              float: left;
              height: 25px;
              margin-left: -2px;
            }
          }

          .accordion-content {
            .icon-a {
              align-items: center;
              display: flex;
              padding: 7px 43px;

              &:hover {
                background-color: $color-background;
                cursor: pointer;
                outline: none;
              }

              a {
                color: $color-link-blue;
                text-decoration: none;
              }
            }

            .icon-b {
              align-items: center;
              display: flex;
              padding: 7px 43px;
              width: 250px;

              &:hover {
                background-color: $color-background;
                cursor: pointer;
                outline: none;
                width: 250px;
              }

              .download-caption-btn {
                text-align: left;
              }
            }
          }

          .captions-accordian {
            margin-left: 15px;
          }
        }
      }

      li {
        border-radius: $size-border-radius;
        float: left;

        &:hover {
          background-color: $color-background;
        }

        &:focus {
          background-color: $color-background;
          background-size: 150px auto;
        }

        a,
        button {
          background-color: inherit;
          border: 0;
          border-radius: $size-border-radius;
          color: $color-link-blue;
          cursor: pointer;
          display: block;
          float: left;
          font-size: $font-size-nav;
          font-weight: $font-weight-normal;
          margin: 0;
          padding: 9px 12px 11px 45px;
          text-align: left;
          text-decoration: none;
          white-space: pre-wrap;
          width: 245px;
          word-wrap: break-word;

          &:hover {
            path {
              fill: $color-link-blue;
            }
          }
        }

        .accordion {
          background-color: inherit;
          border: 0;
          border-radius: 6px;
          color: $color-link-blue;
          cursor: pointer;
          display: block;
          float: left;
          font-size: 14px;
          font-weight: 400;
          margin: 0;
          padding: 9px 12px 11px 45px;
          text-align: left;
          text-decoration: none;
          white-space: pre-wrap;
          width: 230px;
          word-wrap: break-word;

          &-caret {
            float: none;
            margin: 0 0 -7px 10px;
          }

          &-content {
            margin: 10px 0 0 -45px;
          }
        }
      }

      ul.question {
        background-color: inherit;
        border: 0;
        color: $color-link-blue;
        cursor: pointer;
        display: block;
        float: left;
        font-size: 14px;
        font-weight: 400;
        padding: 6px 0 0 15px;
        padding-block-end: 0;

        .question-btn {
          background-color: inherit;
          border: 0;
          border-radius: 6px;
          color: $color-link-blue;
          cursor: pointer;
          display: block;
          float: left;
          font-size: 14px;
          font-weight: 400;
          margin: 0;
          margin-left: -12px;
          padding: 8px 10px 8px 2px;
          text-align: left;
          text-decoration: none;
          white-space: pre-wrap;
          word-wrap: break-word;
        }

        &:hover {
          background-color: $color-background;
          cursor: pointer;
          outline: none;
        }
      }

      .ul.dropdown-li {
        align-items: center;
        display: flex;
        font-weight: $font-weight-normal;
        list-style-type: none;
        padding: .5em 1em;

        &.accordion-header {
          padding: 0;
        }
      }
    }

    svg {
      align-items: center;
      float: left;
      margin-left: -32px;
      width: 20px;

      path,
      polygon {
        fill: $color-link-blue;
      }
    }

    .image {
      float: left;
      height: 20px;
      margin-left: -27px;
      margin-top: -1px;
    }
  }
}
