@import '../common';

.Featured {
  padding: 15px;

  .recognitions__recognition-summary {
    padding: 8px 8px 15px;

    & + .recognitions__recognition-summary {
      border-top: 1px solid $color-border;
    }

    .recognitions__heads {
      margin-left: 8px;
      min-width: 55px;
      text-align: center;
    }

    .recognitions__recognition__header .Thumbnail {
      display: inline-block;
      float: none !important;
      height: 30px;
      margin-right: 0;
      width: 30px;
    }
  }

  .recognitions__recognition__date {
    color: $color-primary-light-font;
    display: block;
    font-size: $font-size-small;
    margin-top: 5px;
  }

  .chq-ffd-tags {
    margin-top: 5px;

    .chq-bdg {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
