.StoryTrend-Summary {
  border-radius: $size-border-radius;
  max-width: 90%;

  @media (min-width: $screen-sm) {
    max-width: initial;
    min-width: 600px;
  }

  &__Btn {
    align-items: center;
    color: $color-white;
    display: flex;
    font-size: $font-size-large;
    font-weight: $font-weight-heavy;

    svg {
      margin-left: 5px;
    }

    path {
      fill: $color-white;
    }
  }

  .Story {
    &-Prompt {
      align-items: center;
      color: $color-primary-font;
      display: flex;
      font-size: $font-size-28;
      font-weight: $font-weight-heavy;
      margin-bottom: 15px;
      margin-top: 0;

      svg {
        @include square(45px);
        margin-right: 15px;
      }

      path {
        fill: $color-primary-blue;
      }
    }

    &-Summary {
      font-weight: $font-weight-normal;
      white-space: pre-line;
    }
  }

  .chq-pan {
    &--hd {
      border-radius: $size-border-radius $size-border-radius 0 0;
    }

    &--bd {
      padding: 20px;
    }
  }
}
