@import '../common';

.InternalAccounts {
  .chart .row {
    p {
      margin-bottom: 0;
      padding-top: 0;
    }

    .light {
      color: $color-primary-light-font;
      font-size: $font-size-small;
    }
  }

  .row-actions > div {
    float: right;
  }
}
