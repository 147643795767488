.ManageModal {
  max-width: 500px;

  .Form {
    padding: 0;
  }

  .chq-btn {
    display: block;
    margin-top: 15px;
    width: 100%;
  }

  .links {
    display: block;
    margin-top: 15px;
    text-align: center;

    .link {
      text-align: center;
    }
  }
}

.ManageUserModal,
.ManageOrganizationModal {
  max-width: 600px;
}
