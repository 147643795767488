@import '../common';

$circle-size: 20px;

// This animation was originally built as an 80px x 80px square, with every
// other pixel value being precisely pixed. Instead of scaling every value that
// we want, I'm just going to scale from the original.
@function originally($value) {
  @return $value * ($circle-size / 80px);
}

.link-rsvp {
  &:hover {
    .circle-check__container,
    .circle-check {
      background-color: $color-border;
    }

    .circle-check {
      &::after,
      &::before {
        background-color: $color-border;
      }
    }
  }
}

.circle-check {
  @include square($circle-size);
  background-color: $color-background;
  border: originally(4px) solid $color-primary-green;
  border-radius: 50%;
  box-sizing: content-box;
  display: inline-block;
  padding: 0;
  position: relative;

  &.green {
    background-color: $color-primary-green;
    border: originally(4px) solid $color-background;

    &::after,
    &::before {
      background-color: $color-primary-green;
    }

    .circle-check__placeholder {
      border: originally(4px) solid rgba($color-background, .4);
    }

    .circle-check__tip,
    .circle-check__long {
      background-color: $color-background;
    }
  }

  &.white {
    background-color: $color-white;
    border: originally(4px) solid $color-primary-green;

    &::after,
    &::before {
      background-color: $color-white;
    }

    .circle-check__placeholder {
      border: originally(4px) solid rgba($color-primary-green, .4);
    }

    .circle-check__tip,
    .circle-check__long {
      background-color: $color-primary-green;
    }
  }

  &__container {
    @include square(originally(90px));
    bottom: 1px;
    display: inline-block;
    margin-right: 1px;
    position: relative;
    vertical-align: middle;
  }

  &::after,
  &::before {
    background-color: $color-background;
    content: '';
    height: originally(120px);
    position: absolute;
    transform: rotate(45deg);
    width: originally(60px);
  }

  &::before {
    border-radius: originally(40px) 0 0 originally(40px);
    height: $circle-size;
    left: originally(5px);
    top: originally(-17px);
    transform: rotate(-45deg);
    transform-origin: originally(60px) originally(60px);
    width: originally(26px);
  }

  &::after {
    border-radius: 0 originally(120px) originally(120px) 0;
    left: originally(30px);
    top: originally(-11px);
    transform: rotate(-45deg);
    transform-origin: 0 originally(60px);
  }

  &__placeholder {
    border: originally(4px) solid rgba($color-primary-green, .4);
    border-radius: 50%;
    box-sizing: content-box;
    height: $circle-size;
    left: originally(-4px);
    position: absolute;
    top: originally(-4px);
    width: $circle-size;
    z-index: $z-index-low;
  }

  &__fix {
    background-color: inherit;
    height: originally(90px);
    left: originally(26px);
    position: absolute;
    top: originally(8px);
    transform: rotate(-45deg);
    width: originally(8px);
  }

  &__tip,
  &__long {
    background-color: $color-primary-green;
    border-radius: originally(2px);
    height: originally(5px);
    position: absolute;
    z-index: $z-index-medium;
  }

  &__tip {
    left: originally(13.9px);
    top: originally(46px);
    transform: rotate(45deg);
    width: originally(25px);
  }

  &__long {
    right: originally(8px);
    top: originally(38px);
    transform: rotate(-45deg);
    width: originally(47px);
  }

  &.animate {
    &::after {
      animation: animRotatePlaceholder 4.25s ease-in;
    }

    .circle-check__tip {
      animation: animSuccessTip .75s;
    }

    .circle-check__long {
      animation: animSuccessLong .75s;
    }
  }
}

@keyframes animSuccessTip {
  0%,
  54% {
    left: originally(1px);
    top: originally(19px);
    width: 0;
  }

  65% {
    left: originally(-8px);
    top: originally(37px);
    width: originally(50px);
  }

  100% {
    left: originally(14px);
    top: originally(45px);
    width: originally(25px);
  }
}

@keyframes animSuccessLong {
  0%,
  65% {
    right: originally(46px);
    top: originally(54px);
    width: 0;
  }

  100% {
    right: originally(8px);
    top: originally(38px);
    width: originally(47px);
  }
}

@keyframes animRotatePlaceholder {
  0%,
  5% {
    transform: rotate(-45deg);
  }

  100%,
  12% {
    transform: rotate(-405deg);
  }
}
