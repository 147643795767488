$color-comcast-orange: #ff7112;
$color-comcast-gray: rgba(255, 255, 255, .25);
$color-comcast-light-gray: rgba(255, 255, 255, .3);

.comcast-nav {
  &.chq-nav {
    background-color: $color-black;

    .chq-nav {

      &--search {
        input {
          background-color: $color-comcast-light-gray;
        }
      }

      &--items {

        a {
          border-color: $color-black;

          &.active {

            border-color: $color-comcast-orange;
            color: $color-comcast-orange;

            path {
              fill: $color-comcast-orange;
            }
          }
        }
      }

      &--notit {

        em {
          background-color: $color-comcast-orange;
          color: $color-black;
        }
      }

      &--admint {

        .Notification-Badge {
          background-color: $color-comcast-orange;
          color: $color-black;
        }

        &:hover {

          background-color: $color-comcast-gray;
        }
      }

      &--unseen {
        border-color: $color-comcast-orange;
      }
    }
  }
}
