@import '../common';

.Person {
  &--name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 0;
  }

  &--biography {
    font-size: 16px;
    font-weight: $font-weight-normal;
    margin-bottom: 15px;
    margin-top: 0;
  }

  &--title {
    color: $color-primary-font;
    font-size: 24px;
    font-weight: $font-weight-normal;
    margin-bottom: 15px;
    margin-top: 0;
  }

  .profile-tabs {
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    margin-left: 30px;
    padding-left: 0;

    a {
      align-items: center;
      color: $color-primary-font;
      display: flex;
      padding: 15px;

      svg {
        margin-right: 5px;
      }

      path {
        fill: $color-primary-font;
      }

      &:hover {
        background-color: transparent;
        border-bottom: 2px solid $color-green-alpha-45;
        color: $color-primary-font !important;
      }

      &.active {
        background-color: transparent;
        border-bottom: 3px solid $color-primary-green;
        color: $color-primary-green !important;

        path {
          fill: $color-primary-green;
        }
      }
    }
  }

  .person-connected {
    .chq-abn {
      border: 1px solid $color-border-darker;
      border-radius: $size-border-radius;
      color: $color-primary-font;
      font-weight: 200;
      padding: 5px 10px;
    }
  }

  .add-tag {
    display: inline-block;
    float: none;

    .chq-btn {
      float: none !important;
    }
  }

  .chq-pan--hd {
    h2 {
      align-items: center;
      display: flex;
    }

    path {
      fill: $color-primary-font;
    }

    svg {
      margin-right: 10px;
    }
  }

  .User-Activity,
  .Profile-Settings {
    padding-left: 30px;

    .User--Feed {
      padding: 0 15px;
    }

    .clearfix {
      background-color: $color-white;
      border-radius: $size-border-radius;
      padding: 15px;
    }

    .user-community-tabs,
    .user-activity-tabs {
      background-color: transparent;
      border: 1px solid $color-border;
      border-radius: $size-border-radius;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      padding-left: 0;
      width: 100%;

      @media only screen and (min-width: $screen-xs) {
        display: flex;
        flex-direction: row;
        width: fit-content;
      }

      a {
        color: $color-primary-font;
        margin: 0;
        padding: 7px 30px;

        &.active {
          background-color: lighten($color-primary-green, 35%);
          border: 1px solid $color-primary-green;
          border-radius: $size-border-radius;
          color: $color-primary-green !important;
        }

        &:hover {
          color: $color-primary-green;
        }
      }
    }

    .chq-pan {
      box-shadow: none;

      &--hd {
        padding-top: 0;
      }
    }

    .Edit-Profile {
      margin: 0 15px;
    }

    .Edit-Social {
      .social-container {
        align-items: start;
        display: flex;
        padding: 10px 0;
        position: relative;
      }

      .social-icon {
        @include square(28px);
        height: auto;
        margin-right: 15px;
      }

      .social-photo {
        @include square(22px);
        margin-right: 5px;
      }

      .link-account {
        &-container {
          align-items: start;
          display: flex;

          &__tiktok {
            align-items: center;
          }
        }

        &-name {
          margin-right: 15px;
          white-space: nowrap;
        }

        &-related {
          font-size: $font-size-nav;

          &__category {
            color: $color-secondary-light-font;
          }
        }

        &-btn {
          align-items: center;
          border: 1px solid $color-secondary-font;
          border-radius: $size-border-radius;
          color: $color-secondary-font;
          display: flex;
          font-weight: $font-weight-heavy;
          padding: 2px 7px;

          svg {
            @include square(18px);
            margin-right: 5px;
          }

          path {
            fill: $color-secondary-font;
          }

          &-facebook {
            background-color: #1877f2;
            border: 0;
            color: $color-white;
          }
        }
      }

      .chq-ffd--ctrl {
        margin-left: 30px;
        max-width: 95%;
      }
    }
  }

  .Profile-Feed {
    .clearfix {
      background-color: transparent;
      box-shadow: none;
    }

    .chq-pan {
      box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);

      &--hd {
        padding-top: 15px;
      }
    }
  }

  .Community--SubNav {
    align-self: center;
    display: flex;
    justify-content: space-between;

    .chq-btn {
      background-color: $color-white;
      border: 1px solid $color-primary-green;
      color: $color-primary-green;
      padding: 7px 15px;
    }

    svg {
      margin-right: 5px;
    }

    path {
      fill: $color-primary-green;
    }
  }

  .Left-nav {
    text-align: center;

    &--Container {
      background-color: $color-white;
      border-radius: $size-border-radius;
      box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);
      padding: 15px;

      @media only screen and (min-width: $screen-lg) {
        box-shadow: none;
        padding: 0;
      }
    }

    .chq-pbn {
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 90px;
      cursor: pointer;
      display: block;
      height: 100px;
      margin: 0 auto 10px;
      outline: none;
      width: 100px;
    }

    .dropdown-menu {
      .chq-pbn {
        height: auto;
      }
    }

    .Section__title {
      align-items: center;
      color: $color-primary-light-font;
      display: flex;
      font-size: 18px;
      font-weight: $font-weight-heavy;
      justify-content: space-between;
      margin-bottom: 15px;
    }

    .Section__highlights {
      text-align: left;

      a {
        color: $color-primary-font;
        word-break: break-word;
      }

      p {
        align-items: flex-start;
        display: flex;
        margin-bottom: 15px;
      }

      svg {
        margin-right: 10px;
        min-width: 22px;

        path {
          fill: $color-primary-font;
        }
      }

      &--email {
        .chq-ttp {
          max-width: 90%;

          &--bbl {
            bottom: calc(100% + 8px);
          }
        }
      }
    }

    .subtitle {
      font-size: 18px;
      margin: 0;
    }
  }

  .Commonalities-Container {
    .chq-pbn {
      align-items: center;
      display: flex;
      height: auto;
      padding: 0 6px;
      width: auto;
    }

    .Commonality-Icon {
      margin-left: -5px;

      path {
        fill: $color-white;
      }
    }

    .Commonalities-Number {
      margin-left: 5px;
    }
  }

  .Commonalities-Actions {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 15px 0 30px;

    .chq-btn {
      border-color: #ccc;

      path {
        fill: $color-primary-font;
      }

      &:hover {
        border: 1px solid $color-primary-green;
        padding: 5px 10px;
      }
    }

    .Action-Button {
      padding: 5px 20px;
      width: auto;

      &:hover {
        padding: 5px 20px;
      }
    }

    .dropdown-menu {
      right: -40px !important;
    }

    .social {
      &-dropdown-menu {
        right: -70px !important;

        .social-link {
          padding: 10px;
        }
      }

      &-icon {
        height: auto;
        margin-right: 10px;
        width: 22px;
      }

      &-button {
        img {
          width: 22px;
        }
      }
    }
  }

  hr {
    margin-bottom: 15px;
  }

  &--Commonalities {
    hr {
      margin-bottom: 0;
    }

    .Commonality-Icon {
      margin-right: 10px;
    }

    .Commonality--Category {
      align-items: flex-start;
      display: flex;

      p {
        margin-bottom: 15px;
      }
    }
  }

  &--Similar-People,
  &--Suggested-Interests,
  &--Suggested-People {
    hr {
      margin-bottom: 0;
      margin-top: 15px;
    }

    .User {
      margin-bottom: 15px;

      .clearfix {
        align-items: flex-start;
        display: flex;
      }

      .Connection {
        align-items: flex-start;
        display: flex;
        flex: 70%;
      }
    }

    .suggested-interests {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .chq-pbn {
        flex-grow: 1;
        text-align: left;
      }
    }
  }

  th {
    color: $color-secondary-font;
    font-weight: 400;
  }

  table {
    margin-top: 20px;

    p {
      margin: 0;
    }
  }

  .edit {
    color: $color-secondary-font;
    font-size: $font-size-small;
    font-weight: 200;

    svg {
      fill: $color-secondary-font;
      height: 15px;
      width: 15px;
    }
  }

  .header {
    margin-bottom: 30px;

    h1 {
      color: $color-secondary-font;
      font-size: $font-size-28;
      font-weight: 700;
      margin-bottom: 5px;
      margin-top: 25px;
    }

    h2 {
      color: $color-secondary-font;
      font-size: $font-size-normal;
      font-weight: $font-weight-normal;
      margin-bottom: 20px;
      margin-top: 0;
    }

    .person-image {
      height: 200px;

      .person-image-wrapper {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 90px;
        cursor: pointer;
        display: block;
        height: 170px;
        margin: 25px auto 8px;
        outline: none;
        width: 170px;
      }
    }
  }

  .stats {
    text-align: center;

    .stat {
      margin-top: 20px;

      h2 {
        color: $color-primary-green;
        font-weight: 600;
      }

      h2,
      p {
        margin: 0 -50%;
      }
    }
  }

  .event-options {
    margin: 0 0 0 20px;
  }

  .chq-tbl,
  .chart-header {
    margin-top: 0;
  }

  &--buttons {
    align-items: center;
    display: flex;
    float: right;
    margin-top: 25px;

    button {
      width: auto;
    }
  }
}

.styles_common__16FLn {
  margin-left: 0;
}

.person-list-view {
  margin: 0 12.5px;

  .Person {
    margin-bottom: 15px;
  }

  .header {
    margin-bottom: 0;

    h1 {
      margin-top: 8px;
    }

    .person-image {
      .person-image-wrapper {
        @include square(125px);
        margin-top: 8px;
      }
    }
  }

  .Person--buttons {
    margin-top: 0;
  }
}

.person-connect {
  color: $color-primary-green;
  font-weight: $font-weight-normal;
  padding: 5px 0;

  svg {
    vertical-align: text-bottom;
  }

  path {
    fill: $color-primary-green;
  }
}
