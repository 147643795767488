@import '../common';

.TrendLayout {
  display: flex;
  justify-content: center;
  width: 100%;

  .Content {
    display: flex;
    justify-content: space-evenly;
    width: 85%;

    .principal {
      background-color: #fff;
      border-radius: 10px;
      box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);
      margin-right: 32px;
      padding: 15px 30px;

      .title {
        font-size: $font-size-xl;
        margin-bottom: 16px;
        margin-top: 0;
      }

      @media (max-width: 991px) {
        margin-right: unset;
        width: 100%;
      }

      @media (min-width: 1610px) {
        margin-right: unset;
      }

      .trend-list {
        .Accordian {
          border: unset;
          border-bottom: 1px solid $color-border;
          border-radius: 6px 0 0;
          padding: unset;

          .trend-item {
            border-bottom: unset;
          }

          &__arrow {
            margin-right: 15px;
          }

          &.open {
            box-shadow: unset;
          }

          &:hover {
            box-shadow: unset;
          }

          .ChildPrompts {
            box-shadow: .5px .5px 3px 0 $color-link-blue;
            margin: 5px 2px 25px 25px;
          }
        }

        .trend-item {
          border-bottom: 1px solid $color-border;
          display: flex;
          padding: 24px 10px;
          width: 100%;

          &__thumbnail {
            background-position: center;
            background-size: cover;
            border-radius: 10px;
            height: 110px;
            margin-right: 28px;
            width: 180px;

            .chq-ldr {
              padding: 0;
            }
          }

          a {
            &:focus {
              outline: none;
            }
          }

          .details {
            display: flex;
            flex-direction: column;
            overflow: hidden;

            h3 {
              margin: unset;
            }

            a {
              color: $color-primary-font;
              font-size: $font-size-xl;
            }

            .message {
              -webkit-box-orient: vertical; // sass-lint:disable-line no-vendor-prefixes
              -webkit-line-clamp: 2; // sass-lint:disable-line no-vendor-prefixes
              display: -webkit-box; // sass-lint:disable-line no-vendor-prefixes
              margin: 6px 2px;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .Story-Tags {
              &__legend {
                align-items: center;
                bottom: 15px;
                color: $color-secondary-font;
                display: flex;
                font-size: $font-size-normal;
                margin-top: 5px;

                &-emoticon {
                  background-color: $color-background;
                  border-radius: 20px;
                  display: flex;
                  margin-right: 5px;
                  padding: 5px;

                  svg {
                    height: 16px;
                    width: 16px;
                  }
                }

                &-points-emoticon {
                  margin-left: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}
