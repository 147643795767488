@import '../common';

.event-subsc {
  max-width: 420px;

  .chq-btn {
    margin-top: 5px;
    width: 100%;
  }
}
