.OrganizationRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 6px 20px;
  width: 100%;

  &:hover {
    background-color: $color-background;
    cursor: pointer;
  }

  &.SelectedOrganization {
    &:hover {
      background-color: initial;
    }
  }

  &-Container {
    align-items: center;
    display: flex;
  }

  &-Image {
    border: 1px solid $color-border;
    border-radius: $size-border-radius;
    flex: 1;
    height: 35px;
    margin-right: 15px;
    max-width: 60px;
    min-width: 60px;
    object-fit: contain;
    padding: 7px;
  }

  &-Name {
    color: #9c9fa7;
    flex: 2;
    font-size: $font-size-normal;
    font-weight: $font-weight-normal;
    margin: 0;
    text-align: left;
  }
}
