@import '../common';

.Points {
  h3 {
    font-weight: $font-weight-heavy;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  p.description {
    margin-bottom: 40px;
  }

  .badge-row {
    padding-bottom: 12px;
    padding-top: 12px;

    .count,
    .description {
      align-items: center;
      display: flex;
      height: 50px;

      @media only screen and (max-width: $screen-sm) {
        height: auto;
      }
    }

    .count {
      p {
        width: 100%;
      }
    }
  }

  .History {
    &__row {
      .image {
        margin-right: 11px;
        margin-top: -2px;
        width: 30px;
      }

      td {
        vertical-align: middle;
      }
    }
  }

  .bubble {
    background-color: $color-primary-blue;
    border-radius: 5px;
    color: $color-white;
    display: inline;
    padding: 4px 10px 4px 5px;

    svg {
      height: 18px;
      margin-bottom: -3px;
      margin-right: 5px;
      margin-top: 2px;
      width: 18px;

      path {
        fill: $color-white;
      }
    }
  }

  .Badge {
    display: block;

    img {
      height: 50px;
      width: 50px;
    }

    span {
      padding-left: 12px;
    }

    @media only screen and (max-width: $screen-sm) {
      img {
        display: block;
        margin: 0 auto;
      }

      span {
        display: block;
        padding-left: 0;
        padding-top: 8px;
        text-align: center;
      }
    }
  }

  .chart {
    font-size: $font-size-normal;
  }
}
