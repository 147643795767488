@import '../common';

.FilterAccordian {
  padding: 15px 15px 0;
  position: relative;

  &__title {
    color: $color-link-blue;
    font-size: $font-size-normal;
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 10px;

    svg {
      float: right;
      height: 15px;
      margin: 0 0 2px 5px;
      width: 15px;
    }

    path {
      fill: $color-link-blue;
    }
  }

  &__body {
    max-height: 300px;
    overflow-y: auto;
    transition: max-height 300ms;

    &.closed {
      max-height: 0;
    }

    &.open {
      max-height: 300px;
    }

    .chq-pbn {
      outline: none;
      text-align: left;
    }

    input {
      margin-bottom: 8px;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      border-radius: 3px;
      color: $color-link-blue;
      cursor: pointer;
      padding: 2px;
      padding-left: 22px;
      position: relative;
      user-select: none;

      &:hover {
        color: darken($color-link-blue, 10%);
      }

      .small {
        color: $color-primary-light-font;
        font-size: $font-size-small;
      }

      svg {
        height: 14px;
        left: 4px;
        margin-bottom: 2px;
        margin-top: 2px;
        position: absolute;
        width: 14px;
      }

      path {
        fill: $color-link-blue;
      }
    }
  }
}
