.trim-video-modal {
  border-radius: $size-border-radius;

  @media only screen and (min-width: $screen-md) {
    width: 50vw;
  }

  .chq-pan {
    &--hd {
      border-radius: $size-border-radius $size-border-radius 0 0;

      .trim-video-title {
        font-size: $font-size-normal;
        margin: 0;
      }

      .chq-mdl--cl {
        top: calc(50% - 12px);

        svg {
          @include square(24px);
        }
      }
    }

    &--ft {
      display: flex;
      justify-content: center;
      min-height: 65px;
      padding: 20px 15px;

      .chq-btn {
        bottom: 15px;
        position: absolute;
        right: 15px;
      }
    }
  }

  .chq-ldr {
    left: calc(50% - 100px);
    position: absolute;
    top: calc(50% - 100px);
  }

  .playback {
    background-color: $color-black;
    min-height: 80px;
    width: 100%;
  }

  .playback-ref {
    max-width: calc(100% - 24px);
  }

  .Story_Preview {
    background-repeat: repeat;
    background-size: contain;
    border: 0;
    border-radius: 8px 0 0 8px;
    height: 100%;
    min-height: 80px;
    min-width: 100%;
    position: relative;
  }

  .Video {
    &--Controls {
      background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
      bottom: 0;
      display: none;
      left: 0;
      padding: 20px 15px 10px;
      position: absolute;
      right: 0;

      &_Container {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      &-Play {
        align-items: center;
        display: flex;
        flex: 10;

        .chq-pbn {
          height: 22px;
          margin-right: 15px;
        }

        path {
          fill: $color-white;
        }
      }

      &-Utilities {
        display: flex;
        flex: 2;
        justify-content: end;

        .Volume-Btn {
          svg {
            @include square(24px);
          }

          path {
            fill: $color-white;
          }
        }
      }

      .Seek-Bar {
        border-radius: $size-border-radius;
      }
    }

    &-Duration {
      color: $color-white;
    }


    &-Container {
      background-size: cover;
      display: flex;
      justify-content: center;
      position: relative;

      &:hover {
        cursor: pointer;

        .Video--Controls {
          display: block;
        }
      }

      &__Video {
        max-height: 55vh;
        max-width: 100%;
      }

      &__Paused {
        .Video--Controls {
          display: block;
        }
      }
    }
  }

  .gallery-lightbox__photo {
    height: initial;
    max-height: initial;
  }
}
