.SocialBadge {
  align-items: center;
  background-color: $color-black-alpha-20;
  border-radius: $size-border-radius;
  color: $color-white;
  display: flex;
  padding: 4px 15px;
  width: fit-content;

  svg {
    @include square(14px);
    margin-right: 5px;

    path {
      fill: $color-white;
    }
  }

  img {
    margin-right: 5px;
  }

  &.instagram {
    background: linear-gradient(180deg, rgba(216, 0, 185, 1) 0%, rgba(255, 169, 0, 1) 100%);
  }

  &.facebook {
    background: linear-gradient(180deg, #1877f2 0%, #00b4ff 100%);
  }

  &.linkedin {
    background-color: #2864b4;

    .edit-icon {
      margin-left: 5px;
      margin-right: 0;
    }
  }

  &.tiktok {
    background-color: #2d0f1e;
  }
}
