.ColorField {
  &-Container {
    position: relative;

    .chrome-picker  {
      box-shadow: none !important;
    }

    .Pickers-Container {
      align-items: center;
      background: rgb(255, 255, 255);
      border-radius: 2px;
      box-shadow: rgba(0, 0, 0, .3) 0 0 2px, rgba(0, 0, 0, .3) 0 4px 8px;
      box-sizing: initial;
      display: flex;
      flex-direction: column;
      font-family: Menlo;
      justify-content: center;
      left: initial;
      overflow: hidden;
      padding: 10px;
      position: absolute;
      right: 0;
      top: 48px;
      z-index: $z-index-high + 15;

      &.Gray-Background {
        background-color: $color-background;
      }
    }
  }

  &-Btn {
    align-items: center;
    border: 2px solid $color-border;
    border-radius: $size-border-radius;
    color: $color-primary-font;
    display: flex;
    font-weight: $font-weight-light;
    height: 42px;
    margin-bottom: 5px;
    padding: 5px 15px;
    width: 100%;

    &__Icon {
      @include square(20px);
      position: absolute;
    }
  }

  &-Sample {
    @include square(20px);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, .15) 0 0 0 1px inset;
    margin-right: 10px;
    z-index: 0;
  }
}
