@import '../common';

.Profile {
  &__Content {
    margin: 0 15px;

    .ProfileStatus {
      padding: 15px 15px 45px;

      h3 {
        font-weight: $font-weight-heavy;
      }

      &__social-links {
        margin-bottom: 20px;
        margin-top: 10px;
      }

      &__social-link {
        margin-left: 10px;

        img {
          width: 40px;
        }
      }
    }
  }
}
