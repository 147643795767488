.chq-icon-pag {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 20px 25px 5px;

  .chq-pag--bn {
    border-radius: 20px;
    height: 16px;
    padding: 0;
    width: 16px;

    &-nav {
      align-items: center;
      background-color: transparent;
      border: 0;
      display: flex;
      height: initial;
      padding: 6px 12px;
      width: initial;

      svg {
        margin: 0 5px;
      }

      path {
        fill: $color-link-blue;
      }

      &:disabled {
        visibility: hidden;
      }

      &:hover {
        background-color: initial;

        path {
          fill: $color-dark-blue;
        }
      }
    }

    &-small {
      height: 12px;
      width: 12px;
    }
  }
}
