@import '../common';

.RSVPDropdown {
  margin-top: -10px;

  .dropdown {
    .caret {
      top: 21px;
    }
  }

  .dropdown-toggle {
    padding: 6px 12px 8px 10px;
    text-align: left;
    width: 100%;
  }
}
