@import '../common';

.keep-internal {
  @include border-radius;
  display: flex;
  flex-grow: 1;
  margin-right: 15px;
  overflow: hidden;
  position: relative;
  transition: opacity .2s;
  transition-delay: 1.5s;

  &-anim {
    opacity: 1;
  }

  &--circle {
    animation: animShrinkCircleStory forwards .3s;
    border: $color-white solid 350px;
    border-radius: 700px;
    display: block;
    position: absolute;
  }

  &--check-white {
    .legend {
      align-items: center;
      animation: animDisplayCheckmark forwards .3s;
      animation-delay: .3s;
      color: $color-primary-green;
      display: flex;
      font-weight: $font-weight-heavy;
      height: 18px;
      left: 45%;
      opacity: 0;
      position: absolute;
      top: 12px;
      transform: translate3d(-40%, 0, 0);
    }

    svg {
      margin-right: 5px;

      path {
        fill: $color-primary-green;
      }
    }
  }

  .chq-btn {
    align-items: center;
    background-color: transparent;
    border-color: $color-white;
    color: $color-white;
    display: flex;
    justify-content: center;

    svg {
      margin: 0 10px 0 0;

      path {
        fill: $color-white;
      }
    }
  }
}

@keyframes animShrinkCircleStory {
  0% {
    @include square(160px);
    left: -46px;
    top: -68px;
  }

  100% {
    @include square(700px);
    left: -7px;
    top: -350px;
  }
}
