.PendingPromptCard {
  background-color: $color-white;
  border-radius: 10px;
  box-shadow: 0 3px 17px rgba(178, 187, 194, .7);
  margin: 30px 30px 20px;
  padding: 20px;

  &__Header {
    font-size: $font-size-large;
    justify-content: space-between;
    margin-bottom: 20px;

    .chq-tmb {
      margin-right: 15px;
    }

    &__date {
      color: $color-primary-light-font;
      margin: 0;
    }

    &__points {
      position: relative;

      &-image {
        height: 50px;
        width: 50px;
      }

      &-label {
        background-color: rgba(0, 0, 0, .7);
        border-radius: $size-border-radius;
        bottom: -8px;
        color: $color-white;
        font-size: $font-size-nav;
        font-weight: $font-weight-light;
        left: calc(50% - 22px);
        padding: 0 10px;
        position: absolute;
        white-space: nowrap;
      }
    }
  }

  &__Body {
    margin-bottom: 30px;

    .chq-tmb-lg {
      border-radius: $size-border-radius;
      margin-right: 15px;
      min-width: 100px;
    }

    &--Title {
      font-size: $font-size-22;
      margin: 0;

      a {
        color: $color-primary-font;
      }
    }

    &--Description {
      -webkit-box-orient: vertical; // sass-lint:disable-line no-vendor-prefixes
      -webkit-line-clamp: 3; // sass-lint:disable-line no-vendor-prefixes
      display: -webkit-box; // sass-lint:disable-line no-vendor-prefixes
      margin-bottom: 0;
      margin-top: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-line;
    }

    .expanded {
      .PendingPromptCard__Body--Description {
        -webkit-box-orient: initial; // sass-lint:disable-line no-vendor-prefixes
        -webkit-line-clamp: initial; // sass-lint:disable-line no-vendor-prefixes
        display: initial; // sass-lint:disable-line no-vendor-prefixes
      }
    }
  }

  &__Footer {
    justify-content: space-between;

    &-buttons {
      .chq-btn {
        &:first-child {
          margin-right: 10px;
        }
      }

      &-inverted {
        background-color: transparent;
        border-color: $color-border-darker;
        color: $color-secondary-font;

        &:hover {
          background-color: transparent;
          border-color: $color-border-darker;
          color: $color-secondary-font;
        }
      }
    }

    &__Users {
      align-items: center;
      display: flex;
      margin-left: 10px;

      &:hover {
        cursor: pointer;
      }

      &__more {
        align-items: center;
        background-color: $color-border;
        color: $color-primary-font;
        display: flex;
        font-weight: $font-weight-heavy;
        justify-content: center;
      }

      &__description {
        margin-left: 7px;
      }

      .Thumbnail {
        border-radius: 35px;
        height: 35px !important;
        margin-left: -10px;
        min-width: 35px;
        width: 35px !important;
      }
    }
  }
}
