.SurveyPage {
  position: relative;

  .info {
    margin-top: 15px;
  }

  @media only screen and (max-width: $screen-sm) {
    padding-left: 0;
  }
}

.survey-share,
.link-share {
  .chq-scs {
    animation: chqSurveyCopy .5s forwards;
    margin: 15px 0 0;
    opacity: .5;
  }

  .chq-pan--ft {
    display: flex;

    div {
      flex-basis: 50%;
      text-align: center;
    }

    .chq-btn-iv {
      margin-top: 3px;
    }
  }
}

@keyframes chqSurveyCopy {
  from {
    opacity: .5;
  }

  to {
    opacity: 1;
  }
}
