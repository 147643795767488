@import '../common';

.post-update-modal {
  border-radius: $size-border-radius;
  padding-bottom: 50px;
  width: 780px;

  @media only screen and (min-width: $screen-modal-sm) {
    padding-bottom: 0;
  }

  .chq-pan--hd-pr {
    background-color: $color-white;
    border-radius: $size-border-radius;
    color: $color-primary-green;
    text-align: left;

    h2 {
      font-size: 1.25em;
      font-weight: bold;
    }
  }

  .chq-pan--bd {
    display: flex;

    .chq-tmb {
      height: 50px;
      margin-right: 15px;
      min-width: 50px;
    }

    .chq-edi--sg {
      .chq-tmb {
        height: 50px;
        margin-right: 15px;
        min-width: 25px;
      }
    }

    .form {
      flex-grow: 1;

      .chq-edi {
        background-color: $color-white;
        border-color: $color-border-darker;
        border-radius: $size-border-radius;
        padding-bottom: 115px;

        &-fc {
          border-color: $color-primary-blue;
        }

        &--sg {
          .chq-tmb {
            height: 25px;
            margin-right: 15px;
            width: 25px;
          }
        }
      }

      .chq-ffd {
        margin-bottom: 10px;
        margin-top: 0;

        &--lb {
          position: absolute;
          top: 10px;
          width: 100%;
          z-index: $z-index-low;
        }

        &--ctrl {
          border: 1px solid $color-border-darker;
          border-radius: $size-border-radius;
          width: 70%;

          &:focus {
            border-color: $color-primary-blue;
            box-shadow: inset 0 1px 1px $color-form-inset, 0 0 8px $search-bar-shadow;
          }

          &::placeholder {
            color: $color-secondary-light-font;
          }
        }
      }

      .public-DraftEditorPlaceholder-inner {
        color: $color-secondary-light-font;
        font-weight: 200;
      }

      .chq-btn {
        bottom: 0;
        left: 0;
        margin-bottom: 10px;
        margin-left: 15px;
        margin-right: 15px;
        padding: 9px 30px;
        position: absolute;
        right: 0;
        z-index: $z-index-low;

        &:hover {
          border: $color-primary-green solid 1px;
        }

        @media only screen and (min-width: $screen-modal-sm) {
          left: initial;
        }
      }

      .share-with-field {
        margin-bottom: 0;

        .chq-ffd {
          &--lb {
            .chq-ttp {
              position: absolute;
              right: 10px;

              .chq-ttp--bbl {
                top: -80px;
                width: max-content;
              }
            }

            svg {
              height: 22px;
              margin-left: 10px;
              width: 22px;
            }

            path {
              fill: $color-primary-green;
            }
          }

          &--ctrl {
            align-items: center;
            border: 0;
            display: flex;
            flex-wrap: wrap;
            font-size: initial;
            margin-bottom: 5px;
            padding: 6px 12px;
            width: 100%;

            &:hover {
              box-shadow: none;
            }

            .chq-bdg {
              align-items: center;
              display: flex;
              flex-direction: row-reverse;
              width: initial;

              svg {
                margin: 0 0 0 6px;
              }

              .category-icon {
                height: 18px;
                margin: 0 6px 0 0;
                width: 18px;
              }
            }
          }

          &--sl {
            margin-left: 30px;
            margin-right: 30px;
            z-index: $z-index-medium;

            &--place {
              color: $color-primary-green;
            }

            &--opts {
              margin-left: -30px;
              max-height: 200px;
              overflow-y: auto;

              .option-icon {
                height: 20px;
                width: 20px;

                g {
                  fill: $color-white;
                }
              }
            }
          }
        }
      }
    }

    .chq-ffd-tags {
      margin-top: 105px;

      @media only screen and (min-width: $screen-modal-sm) {
        margin-top: 60px;
      }

      .chq-bdg {
        &:hover {
          cursor: initial;
        }
      }
    }
  }

  .chq-bdg {
    background-color: $color-background;
    border: 0;
    border-radius: $size-border-radius;
    font-weight: $font-weight-heavy;
    margin-right: 5px;
    padding: 5px 12px;
    width: initial;
  }

  .chq-pan--ft {
    background-color: $color-white;
    display: flex;
    margin-top: 80px;
    padding-bottom: 55px;
    position: relative;

    @media only screen and (min-width: $screen-modal-sm) {
      margin-top: 25px;
    }

    .modal-footer-column {
      flex-grow: 1;
      margin-top: 0;
      max-width: 210px;
    }

    .slack-channel-field {
      bottom: 10px;
      margin-bottom: 0;
      position: absolute;

      .chq-ffd {
        margin-bottom: 0;
        margin-right: 10px;
        margin-top: 0;

        &--lb {
          margin-bottom: 0;
        }

        &--ctrl {
          border: 1px solid $color-border-darker;
          border-radius: $size-border-radius;
          margin-bottom: 0;
          padding-left: 40px;
          padding-right: 25px;
        }

        &--sl--opts {
          max-height: 200px;
          overflow-x: hidden;
          overflow-y: scroll;

          .chq-pbn {
            overflow-wrap: anywhere;
          }
        }
      }
    }

    .chq-ffd--sl--icon {
      max-width: 42px;
      padding: 8px;
    }
  }

  .chq-ffd--sl--opts {
    border-radius: $size-border-radius;
    max-height: none;
    overflow: initial;
  }
}
