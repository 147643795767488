@import '../common';

.Users-Slider {
  background-color: transparent;
  border-radius: $size-border-radius;
  margin-bottom: 15px;

  &__Empty {
    box-shadow: none;

    .chq-btn {
      border: 1px solid $color-primary-green;
    }
  }

  &__overlay {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .2) 70%, rgba(0, 0, 0, .6) 100%);
    border-radius: $size-border-radius;
    display: flex;
    flex-direction: column;
    height: 200px;
    justify-content: flex-end;
    padding: 15px 10px;
    width: 200px;

    &--unselected {
      background-color: rgba(201, 201, 201, .8);
      border-radius: 6px;
      height: 200px;
      position: absolute;
      width: 200px;
    }
  }

  &__Share-btn {
    justify-content: center;
    width: 190px;
  }

  .chq-pbn {
    display: flex;
    flex-direction: column;

    &:hover {
      box-shadow: 0 0 6px $color-black-alpha-40;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .Story-Tags {
    &__container {
      color: $color-white;
      display: flex;
      margin-bottom: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    &__item {
      align-items: center;
      display: flex;
      font-size: 14px;
      margin-right: 10px;

      svg {
        height: 15px;
        margin-right: 5px;
        width: 15px;
      }

      path {
        fill: $color-white;
      }
    }

    &__legend {
      align-items: center;
      bottom: 15px;
      color: $color-white;
      display: flex;
      font-size: 14px;
      margin-top: 5px;
      position: absolute;

      &-emoticon {
        background-color: $color-white;
        border-radius: 20px;
        display: flex;
        margin-right: 5px;
        padding: 5px;

        svg {
          height: 16px;
          width: 16px;
        }
      }

      &-points-emoticon {
        margin-left: 15px;
      }
    }

    &__points {
      font-size: 12px;
      margin-left: 5px;
    }
  }

  .chq-btn {
    align-items: center;
    border: 0;
    border-radius: 20px;
    bottom: 15px;
    display: flex;
    font-size: 16px;
    padding: 7px 14px;
    position: absolute;
  }
}
