@import '../common';

@keyframes chqUnseenPop {
  0% {
    opacity: 0;
    transform: scale(.8);
  }

  1% {
    opacity: .5;
    transform: scale(.8);
  }

  50% {
    opacity: 1;
    transform: scale(1.4);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.chq-nav {
  z-index: $z-index-medium;

  &--feed {
    position: relative;
  }

  &--unseen {
    @include box-shadow;
    @include square(1.5rem);
    animation: chqChrPop .2s ease-in-out forwards;
    border: $color-primary-yellow solid .6rem;
    border-radius: 1.5rem;
    left: .7em;
    opacity: 0;
    position: absolute;
    top: .3em;
    transform: scale(.8);
  }

  &--search {
    display: inline-block;
    position: relative;
    vertical-align: top;

    input {
      @include border-radius;
      background-color: #506172;
      border: 0;
      color: $color-background;
      margin: 8px;
      outline: 0;
      padding: 5px 27px 5px 15px;
      vertical-align: top;
      width: 12em;

      &::placeholder {
        color: $color-background;
        font-weight: $font-weight-light;
      }
    }

    button {
      background: none;
      border: 0;
      padding: 5px;
      position: absolute;
      right: 12px;
      top: 9px;

      svg {
        @include square(12px);
      }

      path {
        fill: $color-secondary-light-font;
      }
    }
  }

  &--sec {
    display: none;

    @media only screen and (min-width: $screen-lg) {
      display: inline-block;
    }
  }

  &--sep {
    display: inline-block;
    width: 20px;
  }

  &--results {
    background-color: $color-white;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    left: 0;
    padding-left: 0;
    position: absolute;
    top: 101%;
    width: 100%;
    z-index: $z-index-medium;
  }

  &--result {
    align-items: center;
    border-top: $color-border solid 1px;
    color: $color-primary-font;
    cursor: pointer;
    display: flex;
    min-height: 54px;
    overflow: hidden;
    padding: 8px 12px;
    position: relative;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    &:first-child {
      border-top: 0;
    }

    &:hover {
      background-color: $color-border;
      color: $color-primary-font;
    }

    strong {
      font-weight: $font-weight-normal;
    }

    small {
      color: $color-primary-light-font;
    }

    em {
      background-color: $color-primary-green;
      border-radius: 20px;
      left: 18px;
      padding: 6px 5px 0;
      position: absolute;
      top: 10px;
    }

    svg {
      @include square(20px);
    }

    path {
      fill: $color-white;
    }

    span {
      display: inline-block;
      padding-left: 50px;
    }
  }

  .right-menu {
    align-items: center;
    display: flex;
  }

  .chq-wrn {
    background-color: $color-border;
    border: 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    color: $color-primary-font;
    left: 0;
    margin-bottom: 0;
    position: absolute;
    top: 101%;
    width: 100%;
    z-index: $z-index-medium;

    svg {
      margin: -3px 10px 0 0;
      vertical-align: middle;
    }

    path {
      fill: $color-primary-font;
    }
  }

  &--items {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding-left: 0;
    vertical-align: top;

    li {
      display: inline-block;

      & + li {
        margin-left: 20px;
      }
    }

    svg {
      margin-right: 5px;
      vertical-align: bottom;
    }

    path {
      fill: $color-background;
    }

    a {
      align-items: center;
      border-bottom: solid 4px $color-dark-blue;
      color: $color-background;
      display: flex;
      font-weight: $font-weight-light;
      padding: 12px 0;

      &:hover,
      &:focus {
        text-decoration: none;
      }

      &:hover {
        border-bottom-color: rgba($color-primary-yellow, .75);
      }

      &.active {
        border-bottom-color: $color-primary-yellow;
        color: $color-primary-yellow;

        path {
          fill: $color-primary-yellow;
        }
      }
    }
  }

  &--profile {
    display: inline-block;
    margin-left: 6px;
    position: relative;

    & > .chq-pbn {
      display: inline-block;
      margin: 5px 0;
    }

    .chq-tmb {
      @include square(35px);
    }

    .caret {
      vertical-align: text-top;
    }
  }

  &--noti {
    display: flex;
    justify-content: center;
    position: relative;
    vertical-align: top;
  }

  &--notit {
    cursor: pointer;
    padding: 11px 15px 8px 0;
    position: relative;

    svg {
      @include square(26px);
    }

    path {
      fill: $color-background;
    }

    em {
      background-color: $color-primary-yellow;
      border-radius: 30px;
      color: $color-dark-blue;
      font-size: $font-size-small;
      font-style: normal;
      min-width: initial;
      padding: 2px 8px;
      position: absolute;
      right: 7px;
      text-align: center;
      top: 0;
    }

    &-bell {
      padding: 10px 15px 6px 0;

      svg {
        @include square(29px);
      }
    }
  }

  &--drop {
    background-color: $color-white;
    box-shadow: 0 0 12px rgba(0, 0, 0, .175);
    color: $color-primary-light-font;
    display: none;
    list-style: none;
    min-width: 240px;
    padding: 10px 0;
    position: absolute;
    right: 0;
    top: 115%;
    transform-origin: top center;
    z-index: $z-index-medium;

    &::before {
      background-color: $color-white;
      box-shadow: 2px -2px 3px rgba(0, 0, 0, .1);
      content: '';
      height: 20px;
      position: absolute;
      right: 22px;
      top: -10px;
      transform: rotate(-45deg);
      width: 20px;
      z-index: $z-index-medium;

      @media only screen and (max-width: $screen-sm) {
        left: 77%;
      }
    }

    &-open {
      animation: animProfileDropdown .2s;
      display: block;
    }

    &-points {
      right: -30px;
      width: 300px;

      &::before {
        right: 48px;
      }

      li {
        &:first-child a {
          color: $color-primary-light-font;
          display: inline-block;
          font-weight: $font-weight-heavy;
          padding: 10px 20px 15px;
          text-decoration: none;
        }
      }

      .notification-drawer__notifications {
        max-height: 450px;
        overflow: auto;
      }

      .notification-drawer__notification {
        transform: none;
      }

      &--points {
        background-color: $color-primary-blue;
        border-radius: 10px;
        color: $color-white;
        font-size: $font-size-nav;
        margin-left: 10px;
        padding: 3px 10px;
      }
    }

    hr {
      border-top-color: #e5e5e5;
      margin: 9px 0;
    }
  }

  .chq-nav--dropi {
    color: $color-primary-light-font;
    cursor: pointer;
    display: block;
    padding: 5px 20px;
    text-align: left;
    white-space: nowrap;
    width: 100%;

    &:focus,
    &:hover {
      color: $color-primary-light-font;
      text-decoration: none;
    }

    &:hover {
      background-color: $color-background;
    }

    svg {
      @include square(16px);
      margin-right: 5px;
      vertical-align: text-top;
    }

    path {
      fill: lighten($color-primary-font, 15%);
    }
  }

  &--dropf {
    border-top: 3px solid $color-border;
    font-weight: $font-weight-heavy;
    padding: 15px 20px 5px;

    a {
      &:hover {
        color: $color-link-blue;
      }
    }
  }

  &--welcome {
    padding: 10px 0 2px 68px;

    a {
      font-size: $font-size-normal;

      &:focus {
        text-decoration: none;
      }
    }

    p {
      margin-bottom: 5px;
    }

    .chq-tmb {
      @include square(40px);
      left: 17px;
      position: absolute;
      top: 20px;
    }
  }

  .chq-ham {
    display: none;
    margin: 5px 0 0 5px;
  }

  &--admin {
    display: inline-block;
    margin-right: 5px;
    position: relative;
    vertical-align: top;

    &__container {
      border-right: solid 1px $color-secondary-font;
      margin-right: 20px;
    }

    .chq-nav--drop {
      right: -120px;
      top: 150%;

      &::before {
        left: 36%;
      }
    }

    em {
      background-color: $color-primary-yellow;
      border-radius: 30px;
      color: $color-dark-blue;
      font-size: 13px;
      font-style: normal;
      min-width: initial;
      padding: 2px 8px;
      position: absolute;
      right: 3px;
      text-align: center;
      top: -9px;
    }
  }

  &--admint {
    align-items: center;
    background: none;
    border: 0;
    border-radius: $size-border-radius;
    color: $color-white;
    display: flex;
    margin-right: 2px;
    outline: 0;
    padding: 4px 15px;

    &:hover {
      background-color: #445f79;
      color: $color-white;
      text-decoration: none;
    }

    &:focus {
      color: $color-white;
      outline: 0;
      text-decoration: none;
    }

    svg {
      vertical-align: top;

      @media only screen and (min-width: 355px) {
        margin-right: 5px;
      }
    }

    path {
      fill: $color-background;
    }

    span {
      display: none;

      @media only screen and (min-width: 355px) {
        display: block;
      }
    }
  }
}

.chq-nav-full-width {
  left: 0;
}

@media screen and (max-width: $screen-lg) {
  .chq-nav {
    padding: 0;

    .chq-logo {
      display: none;
      margin: 9px 10px 0 20px;
    }

    &--search {
      clear: both;
      display: none;

      input {
        flex: 1;
        margin-right: 20px;
        margin-top: 13px;
      }

      button {
        right: 26px;
        top: 14px;
      }
    }

    &--results,
    .chq-wrn {
      top: 112px;
    }

    &--profile {
      margin-right: 20px;
    }

    &--notit {
      padding-bottom: 0;
      padding-top: 0;

      &-bell {
        padding-bottom: 0;
      }
    }

    &--sep {
      display: block;
      width: 0;
    }

    &--items {
      display: none;
      width: 100%;

      li {
        display: block;

        & + li {
          margin-left: 0;
        }
      }

      a {
        border-bottom: 0;
        border-top: lighten($color-dark-blue, 10%) solid 1px;
        display: block;
        padding: 15px 20px;
      }
    }

    .chq-ham {
      display: block;
    }

    &-mopen {
      .chq-logo { display: inline; }
      .chq-nav--search { display: flex; }
      .chq-nav--items { display: block; }

      .chq-nav--sec {
        display: block;
      }
    }
  }
}

@keyframes animProfileDropdown {
  0% {
    transform: scale3d(1, .2, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}
