@import '../common';

.StoryApprovals-Header-Container {
  align-items: center;
  display: flex;
  height: 61.85px;
  justify-content: flex-end;
  padding: 17px 15px 15px;
}

.Settings {
  .StoryApprovals {
    position: relative;

    .form-description {
      align-items: flex-start;
      display: flex;
      flex-direction: column;

      ul {
        padding-left: 20px;
      }
    }

    .Link-Social__Media {
      align-items: center;
      background-color: $color-primary-green;
      border-radius: $size-border-radius;
      color: $color-white;
      display: flex;
      font-weight: $font-weight-normal;
      margin-bottom: 15px;
      margin-top: 15px;
      padding: 7px 15px;
      text-decoration: none;

      &:hover {
        background-color: #89ba8d;
      }

      svg {
        @include square(18px);
        margin-right: 5px;
      }

      path {
        fill: $color-white;
      }
    }

    &-Tabs {
      background-color: transparent;

      li {
        a {
          align-items: center;
          color: $color-primary-font;
          display: flex;
          padding: 15px;

          &:hover {
            background-color: transparent;
            border-bottom: 2px solid $color-green-alpha-45;
            color: $color-primary-font !important;
          }

          &.active {
            background-color: transparent;
            border-bottom: 3px solid $color-primary-green;
            color: $color-primary-green !important;
          }
        }
      }
    }

    .Container-mapped {
      align-items: center;
      display: flex;
      grid-gap: 20px;
      justify-content: center;

      &__search {
        flex: 3;
        position: relative;
      }

      &__filter {
        display: block;
        flex: 1;
        font-size: $font-size-normal;
        margin-top: 0;
        padding: 6px 12px;
        position: relative;
        text-align: left;
        text-wrap: nowrap;
      }

      .chq-dd {
        font-size: $font-size-normal;

        [aria-haspopup='listbox'] {
          padding-right: 130px;
        }

        [role='option'] {
          color: $color-primary-font !important;
          padding-left: 15px;
        }

        [role='listbox'] {
          margin-top: -1em;
          width: 150px;
        }
      }

      svg {
        display: none;
      }
    }
  }
}
